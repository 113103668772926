<footer class="main-footer">
  <div class="float-right d-none d-sm-block">
    <span>
      <a href="{{linkEtichetta}}" target="_blank">Info</a> |
    </span>
    <span *ngIf="linkPrivacy !== undefined">
      <a href="{{linkPrivacy}}" target="_blank">Privacy Policy</a> |
    </span>
    <span *ngIf="linkDisclaimer !== undefined">
      <a href="{{linkDisclaimer}}" target="_blank">Disclaimer</a> |
    </span>
    <b>Version</b> {{version}}
  </div>
  <strong>Copyright &copy; 2020
    <a href="http://riatlas.it" class="linkRiatlas">Riatlas.it</a>.</strong>
  All rights reserved.
</footer>