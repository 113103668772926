<!-- <button (click)="creaQuestionario()">Crea questionario</button> -->
<!-- <button (click)="aggiornaQuestionario()">Aggiorna questionario</button> -->
<h1 class="mt-3" style="text-align:center">Scala MRS</h1>
<div class="p-fluid mt-3">
    <div class="row mt-3 ml-1" *ngFor="let domanda of domande">
        <ng-container *ngIf="domanda.tipo === 'quantity'">
            <div class="col-md-12 mt-2">
                <h5>{{domanda.testo}}: {{domanda.risposta !== undefined ? domanda.risposta : ""}}</h5>
            </div>
        </ng-container>
        <ng-container *ngIf="domanda.tipo === 'integer'">
            <div class="col-md-12">
                <h5>{{domanda.testo}}</h5>
            </div>
            <form style="margin-left: 15px">
                <div class="col-md-12 custom-control custom-radio" *ngFor="let risposta of domanda.risposte">
                    <input type="radio" class="custom-control-input" name="{{domanda.linkId}}{{risposta.value}}"
                        [value]="risposta.value" [(ngModel)]="domanda.risposta"
                        id="{{domanda.linkId}}{{risposta.value}}" (ngModelChange)="dirty = true">
                    <label class="custom-control-label"
                        for="{{domanda.linkId}}{{risposta.value}}">{{risposta.label}}</label>
                </div>
            </form>
        </ng-container>
    </div>
    <div class="row" style="margin-top: 50px;">
        <div class="col-md-12">
            <div class="float-right" style="margin-right: 15px; margin-bottom: 15px;">
                <button *ngIf="checkPermessoModifica()" pButton label="Salva" icon="fa fa-save" iconPos="left"
                    class="p-button-success" type="button" (click)="salva()"></button>
            </div>
        </div>
    </div>
</div>