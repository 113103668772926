export const statoCivileOpt = [
    { value: '', label: 'Seleziona una opzione', disabled: true },
    { value: 'A', label: 'Matrimonio nullo' },
    { value: 'D', label: 'Divorziato' },
    { value: 'I', label: 'Soggetto al periodo di separazione' },
    { value: 'L', label: 'Legalmente separato' },
    { value: 'M', label: 'Sposato' },
    { value: 'P', label: 'Poligamo' },
    { value: 'S', label: 'Scapolo/Celibe' },
    { value: 'T', label: 'Convivente' },
    { value: 'W', label: 'Vedovo' },
    { value: 'UNK', label: 'Sconosciuto' },
];

export const livelloDiIstrOpt = [
    { value: '', label: 'Seleziona una opzione', disabled: true },
    { value: 'LA15611-9', label: 'Scuola elementare' },
    { value: 'LA15614-3', label: 'Scuola media' },
    { value: 'LA15564-0', label: 'Diploma' },
    { value: 'LA12460-4', label: 'Laurea I livello' },
    { value: 'LA12461-2', label: 'Laurea II Livello' },
    { value: 'LA15626-7', label: 'Dottorato' },
    { value: 'LA12688-0', label: 'Sconosciuto' }
];

export const fumatoreOpt = [
    { value: '', label: 'Seleziona una opzione', disabled: true },
    { value: 'LA18976-3', label: 'Ogni giorno' },
    { value: 'LA18977-1', label: 'Non tutti i giorni' },
    { value: 'LA15920-4', label: 'Ex fumatore' },
    { value: 'LA18978-9', label: 'Mai fumato' },
    { value: 'LA18979-7', label: 'Fumatore con stato non specificato' },
    { value: 'LA18980-5', label: 'Sconosciuto' },
    { value: 'LA18981-3', label: 'Fumatore assiduo' },
    { value: 'LA18982-1', label: 'Fumatore occasionale' },
];

export const alcoliciOpt = [
    { value: '', label: 'Seleziona una opzione', disabled: true },
    { value: 'LA6270-8', label: 'Mai' },
    { value: 'LA18926-8', label: 'Mensilmente o meno' },
    { value: 'LA18927-6', label: '2 - 4 volte al mese' },
    { value: 'LA18928-4', label: '2 - 4 volte a settimana' },
    { value: 'LA18929-2', label: '4 o più volte a settimana' },
];

export const alimentazioneOpt = [
    { value: '', label: 'Seleziona una opzione', disabled: true },
    { value: 'hypocaloric', label: 'Ipocalorica' },
    { value: 'hypercaloric', label: 'Ipercalorica' },
    { value: 'normocaloric', label: 'Normocalorica' },
];

export const attivitaFisicaOpt = [
    { value: '', label: 'Seleziona una opzione', disabled: true },
    { value: 'none', label: 'Nessuna' },
    { value: 'moderate', label: 'Moderata' },
    { value: 'intense', label: 'Intensa' },
];

