import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { itLocale } from 'ngx-bootstrap/locale';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/service/app.service';
import { CustomizationService } from 'src/app/service/customization.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../../service/pazienti.service';


@Component({
  selector: 'app-dettagli-attivazione-servizio',
  templateUrl: './dettagli-attivazione-servizio.component.html',
  styleUrls: ['./dettagli-attivazione-servizio.component.scss']
})
export class DettagliAttivazioneServizioComponent implements OnInit {

  datePickerConf = { isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' };

  locale = 'it';

  formAmmissione: FormGroup;

  patient;

  patientOauth;

  patientId;

  typeUse = 'activation';

  org;

  today;

  informativaPrivacy;

  terminiCondizioni;

  policyVisible = true;

  @Output() saved = new EventEmitter<boolean>();

  constructor(private localeService: BsLocaleService, private fb: FormBuilder, private toastr: ToastrService,
    private pazientiService: PazientiService, private http: HttpClient, private datePipe: DatePipe,
    private router: Router, private appServ: AppService, private userService: UserService, private customizationService: CustomizationService) { }

  ngOnInit() {
    this.typeUse = this.appServ.getTypeViewList();
    this.formAmmissione = this.fb.group({
      data_ammissione: [new Date(), Validators.required],
      //periodo_attivazione: [Validators.required],
      //id_monitoring_device: '',
      //mac_monitoring_device: '',
      policy_acceptance: [false, Validators.requiredTrue]
    });

    if (this.pazientiService.getOauthPatientId() == null && (this.typeUse === 'Pazienti in Valutazione' || this.typeUse === 'Pazienti in Attivazione')) {
      this.router.navigate(['/main/pazienti/listapazientiattivazione']);
    } else {
      if (this.typeUse === 'Pazienti in Valutazione' || this.typeUse === 'Pazienti in Attivazione') {
        this.getPatientFromOauth();
      }
      itLocale.invalidDate = 'Data non valida';
      defineLocale(this.locale, itLocale);
      this.localeService.use(this.locale);
      this.patientId = this.pazientiService.getPatientId();
      this.getPatientFromFHIR();
      this.org = this.userService.getCurrentOrganization();
      if (this.org === 'VIVISOL' || this.org === 'AQUA') {
        this.org = 'NEMO';
      }
    }

    this.today = new Date();

    this.informativaPrivacy = this.customizationService.getInformativaPaziente();
    this.terminiCondizioni = this.customizationService.getTerminiCondizioniPaziente();
  }


  getPatientFromFHIR() {
    if (this.patientId !== null) {
      this.http.get(endpoints.getPatientByPatientId + this.patientId).toPromise().then((resp: any) => {
        if (resp && resp.success == "1") {
          this.patient = resp.message;
          this.buildForm(this.patient);
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero del Paziente');
      });
    }
  }

  getPatientFromOauth() {
    this.http.get(endpoints.userbyid + '?id=' + this.pazientiService.getOauthPatientId()).toPromise().then(resp => {
      if (resp) {
        this.patientOauth = resp;
      }
    }).catch(err => {
      console.error(err);
      if (err.status == 403) {
        this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
        return;
      }
      this.toastr.error('Errore nel recupero del Paziente');
    });
  }


  buildForm(patientIn) {
    if (patientIn.extension && patientIn.extension.length > 1) {
      this.policyVisible = false;

      let extensionDataAmmissione = patientIn.extension.find(elem => elem.url && elem.url === 'http://riatlas.it/extensions#admissiondate');
      if (extensionDataAmmissione) {
        extensionDataAmmissione = extensionDataAmmissione.valueDateTime ? this.adattaDataSet(extensionDataAmmissione.valueDateTime) : new Date();
      } else {
        extensionDataAmmissione = null;
      }

      /*let extensionPeriodoAttivazione = patientIn.extension.find(elem => elem.url && elem.url === 'http://riatlas.it/extensions#activationperiod');
      if (extensionPeriodoAttivazione) {
        extensionPeriodoAttivazione = extensionPeriodoAttivazione.valuePeriod ? [new Date(extensionPeriodoAttivazione.valuePeriod.start), new Date(extensionPeriodoAttivazione.valuePeriod.end)] : [];
      } else {
        extensionPeriodoAttivazione = [];
      }*/

      this.formAmmissione = this.fb.group({
        data_ammissione: [extensionDataAmmissione],
        //periodo_attivazione: [extensionPeriodoAttivazione],
        //id_monitoring_device: '',
        //mac_monitoring_device: '',
        policy_acceptance: true
      });
    }

    if (!this.checkPermessoModifica()) {
      this.formAmmissione.disable();
    }

    /*if (this.getDeviceByIdFromPatient(patientIn, 'account_monitoring_device'))
      this.formAmmissione.controls['id_monitoring_device'].setValue(this.getDeviceByIdFromPatient(patientIn, 'account_monitoring_device').value);

    if (this.getDeviceByIdFromPatient(patientIn, 'mac_monitoring_device'))
      this.formAmmissione.controls['mac_monitoring_device'].setValue(this.getDeviceByIdFromPatient(patientIn, 'mac_monitoring_device').value);
      */
  }

  getDeviceByIdFromPatient(patient, id: string) {
    for (const d of patient.identifier) {
      if (d.use !== undefined) {
        continue;
      }
      if (d.type.coding[0].code === id) {
        return d;
      }
    }
    return null;
  }

  getExtByIdFromPatient(patient, id: string) {
    if (patient.extension === undefined) {
      return null;
    }
    for (const d of patient.extension) {
      if (d.url === id) {
        return d;
      }
    }
    return null;
  }

  adattaDataSet(date: string): string {
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }

  adattaData(date): any {
    if (typeof date === 'string') {
      const dateParts = date.split('/');
      // tslint:disable-next-line: radix
      const dateToTrans = new Date(Number.parseInt(dateParts[2]), Number.parseInt(dateParts[1]) - 1, Number.parseInt(dateParts[0]));
      return this.datePipe.transform(dateToTrans, 'yyyy-MM-dd');
    } else {
      return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
  }


  savePatient() {
    if (!this.formAmmissione.valid) {
      this.toastr.warning('Compilare tutti i campi');
      return;
    }
    const datiForm = this.formAmmissione.getRawValue();
    if (!this.addExtensionToPatient()) {
      this.toastr.warning('Compilare tutti i campi');
      return;
    }
    this.patient.active = true;
    const objMonitoringDevice: any = {};
    /*if (datiForm.id_monitoring_device != '') {
      objMonitoringDevice["patientId"] = this.patientId;
      objMonitoringDevice["account_monitoring_device"] = datiForm.id_monitoring_device;
    }

    if (datiForm.mac_monitoring_device != '') {
      objMonitoringDevice["patientId"] = this.patientId;
      objMonitoringDevice["mac_monitoring_device"] = datiForm.mac_monitoring_device;
    }*/

    let firstpassword = this.patient.extension.find(elem => {
      if (elem.url && elem.url === 'http://riatlas.it/extensions#firstpassword') {
        return elem;
      }
    });
    if (firstpassword === undefined) {
      firstpassword = '';
    } else {
      firstpassword = firstpassword.value ?? (firstpassword.valueString ?? '');
    }
    const send = {
      'enabled': 'true',
      'firstpassword': firstpassword
    }

    // Aggiorno i dispositivi di monitoraggio
    if (objMonitoringDevice.patientId !== undefined) {
      if (objMonitoringDevice["mac_monitoring_device"] != undefined) {
        const oldMAC = this.patient.identifier.find(x => x.type && x.type.coding && x.type.coding[0].code == 'mac_monitoring_device');
        if (oldMAC == undefined || (oldMAC != undefined && oldMAC.value != objMonitoringDevice["mac_monitoring_device"])) {
          // Controllo se il MAC address è duplicato
          return this.http.get(endpoints.isDuplicatedMACAddress + objMonitoringDevice["mac_monitoring_device"]).toPromise().then((risp: any) => {
            if (risp == true) {
              this.toastr.error('Errore: Il dispositivo con il MAC address ' + objMonitoringDevice["mac_monitoring_device"] + ' è già assegnato ad un altro paziente.');
              return;
            } else {
              return this.attivaPaziente(send, this.patientOauth != undefined, objMonitoringDevice);
            }
          });
        }
      }
      return this.attivaPaziente(send, this.patientOauth != undefined, objMonitoringDevice);
    } else {
      return this.attivaPaziente(send, this.patientOauth != undefined);
    }
  }

  /**
   * Attiva il paziente nel FHIR, nell'Oauth ed assegna i dispositivi di monitoraggio.
   * @param send estensione per il FHIR
   * @param attivare indica se bisogna attivare il paziente nell'oauth
   * @param objMonitoringDevice se presente, dispositivi di monitoraggio da salvare
   */
  attivaPaziente(send: any, attivare: boolean, objMonitoringDevice?) {
    // Aggiorno il paziente per attivarlo
    this.http.put(endpoints.putPatientByPatientIdByObject + this.patientId, this.patient).toPromise().then(resp => {
      if (objMonitoringDevice != undefined) {
        this.http.post(endpoints.updateMonitorningDevicesFromAnagrafica, objMonitoringDevice).toPromise()
          .then((resp: any) => {
            if (resp.success !== '1') {
              this.toastr.error('Errore nel salvataggio dei dispositivi.' + (resp.message ? " " + resp.message : ""));
            }
          }).catch(err => {
            console.error(err);
            if (err.status == 403) {
              this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
              return;
            }
            this.toastr.error('Errore nel salvataggio del dispositivo.', 'ERRORE');
          });
      }
      // Se riesco ad aggiornare, aggiorno l'oauth
      if (resp && (this.typeUse === 'Pazienti in Valutazione' || this.typeUse === 'Pazienti in Attivazione')) {
        if (attivare) {
          // Attivo il paziente nell'oauth
          this.http.put(endpoints.enableUser + '?id=' + this.pazientiService.getOauthPatientId(), send).toPromise().then((respp: any) => {
            if (respp) {
              if (respp.status === 'OK') {
                this.toastr.success('Paziente ' + (this.typeUse.includes("Valutazione") ? "valutato" : "attivato") + " correttamente");
                this.saved.next(true);
              }
            }
          }).catch(err => {
            this.saved.next(false);
            console.error(err);
            if (err.status == 403) {
              this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
              return;
            }
            this.toastr.error('Errore nel recupero del Paziente');
          });
        } else {
          this.toastr.success('Paziente ' + (this.typeUse.includes("Valutazione") ? "valutato" : "attivato") + " correttamente");
          this.saved.next(true);
        }
      } else {
        this.toastr.success('Il paziente è stato modificato correttamente.');
        return;
      }
    }).catch(err => {
      this.saved.next(false);
      console.error(err);
      if (err.status == 403) {
        this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
        return;
      }
      this.toastr.error('Errore nel recupero del Paziente');
    });
  }

  addExtensionToPatient() {
    const datiForm = this.formAmmissione.getRawValue();
    /*if (datiForm.periodo_attivazione === null || datiForm.periodo_attivazione === []) {
      return false;
    }*/
    if (datiForm.data_ammissione === null || datiForm.data_ammissione === []) {
      return false;
    }

    let identifier = this.getExtByIdFromPatient(this.patient, 'http://riatlas.it/extensions#admissiondate');
    if (identifier == null) {
      this.patient.extension.push({
        url: 'http://riatlas.it/extensions#admissiondate',
        valueDateTime: this.adattaData(datiForm.data_ammissione)
      });
    } else {
      identifier.valueDateTime = this.adattaData(datiForm.data_ammissione)
    }

    /*identifier = this.getExtByIdFromPatient(this.patient, 'http://riatlas.it/extensions#activationperiod');
    if (identifier == null) {
      this.patient.extension.push({
        url: 'http://riatlas.it/extensions#activationperiod',
        valuePeriod: {
          start: this.adattaData(datiForm.periodo_attivazione[0]),
          end: this.adattaData(datiForm.periodo_attivazione[1])
        }
      });
    } else {
      identifier.valuePeriod = {
        start: this.adattaData(datiForm.periodo_attivazione[0]),
        end: this.adattaData(datiForm.periodo_attivazione[1])
      }
    }*/
    return true;
  }

  ValidateDate(control: AbstractControl) {
    if (control.get('birthDate').value && !moment(control.get('birthDate').value, 'DD/MM/YYYY', true).isValid()) {
      this.toastr.error('Data non valida.');
    }
  }

  checkPermessoModifica() {
    return this.userService.checkPermission('Anagrafica pazienti', 'read', this.patient) || this.userService.checkPermission('Anagrafica pazienti', 'modify', this.patient) || this.userService.checkPermission('Dettagli', 'modify', this.patient);
  }


}
