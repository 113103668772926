<!-- Prima parte. Ci sono più layout -->
<ng-container *ngIf="layout==0">
    <ng-container *ngTemplateOutlet="primaPagina"></ng-container>
</ng-container>
<ng-container *ngIf="layout==1">
    <ng-container *ngTemplateOutlet="primaPaginaAlt"></ng-container>
</ng-container>
<ng-container *ngIf="layout2==0">
    <ng-container *ngTemplateOutlet="diagnosiPrognosi1"></ng-container>
</ng-container>
<ng-container *ngIf="layout2==1">
    <ng-container *ngTemplateOutlet="diagnosiPrognosi2"></ng-container>
</ng-container>
<ng-container *ngIf="grafici.length > 0 && pagineGrafici.length > 0">
    <ng-container *ngFor="let pagina of pagineGrafici">
        <ng-container *ngTemplateOutlet="paginaGrafici;context:{$implicit: {pagina:pagina}}">
        </ng-container>
    </ng-container>
</ng-container>


<div class="break">{{gestioneLayout()}}</div>



<!-- Template utilizzati -->
<ng-template #generale>
    <div class="col-md-1">
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-header bg-primary">
                <h4>Anagrafica del paziente</h4>
            </div>
            <div class="card-body row">
                <div class="col-md-12 row">
                    <div class="col-md-6 text-left">
                        <h5 class="display-inline">Paziente: </h5>
                        <h6 class="display-inline">{{patient.nominativo || ""}}</h6>
                    </div>
                    <div class="col-md-6 text-left">
                        <h5 class="display-inline">Data di nascita: </h5>
                        <h6 class="display-inline">{{patient.birthDate || ""}}</h6>
                    </div>
                    <div class="col-md-6 text-left">
                        <h5 class="display-inline">Genere: </h5>
                        <h6 class="display-inline">{{patient.gender || ""}}</h6>
                    </div>
                    <div class="col-md-6 text-left">
                        <h5 class="display-inline">CF: </h5>
                        <h6 class="display-inline">{{patient.cf || ""}}</h6>
                    </div>
                    <div class="col-md-6 text-left">
                        <h5 class="display-inline">Cellulare: </h5>
                        <h6 class="display-inline">{{patient.cell || ""}}</h6>
                    </div>
                    <div class="col-md-6 text-left">
                        <h5 class="display-inline">Email: </h5>
                        <h6 class="display-inline">{{patient.email || ""}}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card">
            <div class="card-header bg-primary">
                <h4>Periodo di osservazione</h4>
            </div>
            <div class="card-body row">
                <div class="col-md-12 row">
                    <div class="col-md-12 row">
                        <h5>Periodo Osservazione:</h5>
                    </div>
                    <div class="col-md-12 row">
                        <h5>{{inizioOsservazione || ""}} - {{fineOsservazione || ""}}</h5>
                    </div>
                    <div class="col-md-12 row" style="text-align:center">
                        <h5>Data presa in carico:</h5>
                    </div>
                    <div class="col-md-12 row">
                        <h5> {{dataPresaInCarico || ""}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-1">
    </div>
</ng-template>

<ng-template #diagnosiPrognosi1>
    <div class="row col-md-12 pagina">
        <ng-container *ngTemplateOutlet="intestazione"></ng-container>
        <div class="col-md-12 row afterIntestazione">
            <div class="col-md-1">
            </div>
            <div class="col-md-10">
                <ng-container *ngTemplateOutlet="diagnosi"></ng-container>
            </div>
            <div class="col-md-1">
            </div>
        </div>
        <div class="col-md-12 row">
            <div class="col-md-1">
            </div>
            <div class="col-md-10">
                <ng-container *ngTemplateOutlet="prognosi"></ng-container>
            </div>
            <div class="col-md-1">
            </div>
        </div>
        <!-- <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container> -->
    </div>
</ng-template>

<ng-template #diagnosiPrognosi2>
    <div class="row col-md-12 pagina">
        <ng-container *ngTemplateOutlet="intestazione"></ng-container>
        <div class="col-md-12 row afterIntestazione">
            <div class="col-md-1">
            </div>
            <div class="col-md-10">
                <ng-container *ngTemplateOutlet="diagnosi"></ng-container>
            </div>
            <div class="col-md-1">
            </div>
        </div>
        <!-- <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container> -->
    </div>
    <div class="row col-md-12 pagina">
        <ng-container *ngTemplateOutlet="intestazione"></ng-container>
        <div class="col-md-12 row afterIntestazione">
            <div class="col-md-1">
            </div>
            <div class="col-md-10">
                <ng-container *ngTemplateOutlet="prognosi"></ng-container>
            </div>
            <div class="col-md-1">
            </div>
        </div>
        <!-- <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container> -->
    </div>

</ng-template>

<ng-template #intestazione>
    <div id="cardIntestazione">
        <div class="col-md-12 row text-center intestazione">
            <div class="col-md-3">
                <!-- <img src="assets/img/provaLogo.png" alt="Errore: Logo Nemo" class="imgIntestazione"> -->
                <!-- Logo Nemo -->
            </div>
            <div class="col-md-6">
                <h1 *ngIf="patient.identificativo === undefined">Report Telemonitoraggio</h1>
                <h1 *ngIf="patient.identificativo !== undefined && patient.identificativo.length < 8">Report
                    Telemonitoraggio
                    {{patient.identificativo}}</h1>
                <h3 *ngIf="patient.identificativo !== undefined && patient.identificativo.length > 7"
                    style="padding-top:5mm">
                    Report Telemonitoraggio {{patient.identificativo}}</h3>
            </div>
            <div class="col-md-3">
                <!-- <img src="assets/img/Logo-Login1.png" alt="Errore: Logo RiAtlas"> -->
                <!-- Logo RiAtlas -->
            </div>
        </div>
        <div class="col-md-12 row generale">
            <ng-container *ngTemplateOutlet="generale"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #pieDiPagina>
    <div class="col-md-12 piePagina row">
        <div class="col-md-4 text-left"></div>
        <div class="col-md-4 text-center">
            <h5>
                <!-- Indirizzo cliente -->
            </h5>
        </div>
        <div class="col-md-4 text-right">
            <h5 class="numeroPagina">{{setNumeroPagina()}}</h5>
        </div>
    </div>
</ng-template>

<ng-template #templateAnamnesi>
    <div class="card" id="cardAnamnesi">
        <div class="card-header bg-primary">
            <h4>Anamnesi patologica</h4>
        </div>
        <div class="card-body row">
            <div class="col-md-12">
                <span id="anamnesiPatologicaProssima" [(innerHTML)]="descrizioneAnamnesiPatologicaProssima">
                    <p>Nessuna anamnesi patologica prossima.</p>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #templateTerapie>
    <div class="card" id="cardTerapie">
        <div class="card-header bg-primary">
            <h4>Terapie</h4>
        </div>
        <div class="card-body row">
            <div class="col-md-12">
                <!-- Descrizione dei campi Terapie e Terapie domiciliari-->
                <span id="descrizioneTerapie" [(innerHTML)]="terapie">
                    <p>Nessuna terapia.</p>
                </span>
            </div>
            <div class="col-md-12 mt-2">
                <span id="descrizioneTerapieDomiciliari" [(innerHTML)]="terapieDomiciliari">
                    <p>Nessuna terapia domiciliare.</p>
                </span>
            </div>
            <div class="col-md-12 mt-3" *ngIf="listaFarmaci.length > 0">
                <span id="listaFarmaci">
                    <h4>Lista Farmaci:</h4>
                </span>
            </div>
            <ng-container *ngIf="listaFarmaci.length > 0">
                <p-table [value]="listaFarmaci" class="p-fluid" [responsive]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Descrizione</th>
                            <th>Stato</th>
                            <th>Periodo</th>
                            <th>Frequenza</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-farmaco>
                        <tr>
                            <td>{{getDisplayFromCode(farmaco.descrizione)}}</td>
                            <td>{{getStatoFromFarmaco(farmaco.stato)}}</td>
                            <td>{{getPeriodoFromFarmaco(farmaco)}}</td>
                            <td>{{farmaco.tipoFrequenza != "Giornaliero" ?
                                farmaco.tipoFrequenza + ": " + convertiGiorni(farmaco) : "Giornaliero"}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #diagnosi>
    <div class="card" id="cardDiagnosi" *ngIf="pezzotto">
        <div class="card-header bg-primary">
            <h4>Diagnosi</h4>
        </div>
        <div class="card-body row">
            <div class="col-md-12">
                <span id="testoDiagnosi" class="{{layout2==1 ? 'span-fisso-full' : 'span-fisso-mid'}}"
                    [(innerHTML)]="diagnosis">
                    <p>Nessuna diagnosi.</p>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #prognosi>
    <div class="card" id="cardPrognosi" *ngIf="pezzotto">
        <div class="card-header bg-primary">
            <h4>Prognosi</h4>
        </div>
        <div class="card-body row">
            <div class="col-md-12">
                <span id="testoPrognosi" class="{{layout2==1 ? 'span-fisso-full' : 'span-fisso-mid'}}"
                    [(innerHTML)]="prognosis">
                    <p>Nessuna prognosi.</p>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #templateCondizioniCliniche>
    <div class="card" id="cardCondizioniCliniche">
        <div class="card-header bg-primary">
            <h4>Condizioni cliniche</h4>
        </div>
        <div class="card-body row">
            <p-table [columns]="cols" [value]="condizioniCliniche" [responsive]="true">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{col.label}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td *ngFor="let col of columns">
                            <span class="p-column-title">{{col.value}}</span>
                            {{rowData.code.coding[0][col.value]}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colspan]="columns.length">
                            <label>Nessuna condizione clinica assegnata.</label>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</ng-template>

<ng-template #primaPagina>
    <!-- Entra tutto in una pagina -->
    <div class="row col-md-12 pagina primaPagina">
        <ng-container *ngTemplateOutlet="intestazione"></ng-container>
        <div class="col-md-12 row afterIntestazione">
            <div class="col-md-1">
            </div>
            <div class="col-md-10">
                <ng-container *ngTemplateOutlet="templateAnamnesi"></ng-container>
            </div>
            <div class="col-md-1">
            </div>
        </div>
        <div class="col-md-12 row">
            <div class="col-md-12 row">
                <div class="col-md-1">
                </div>
                <div class="col-md-10">
                    <ng-container *ngTemplateOutlet="templateCondizioniCliniche"></ng-container>
                </div>
                <div class="col-md-1">
                </div>
            </div>
        </div>
        <div class="col-md-12 row">
            <div class="col-md-1">
            </div>
            <div class="col-md-10">
                <ng-container *ngTemplateOutlet="templateTerapie"></ng-container>
            </div>
            <div class="col-md-1">
            </div>
        </div>
        <!-- <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container> -->
    </div>
</ng-template>

<ng-template #primaPaginaAlt>
    <div class="row col-md-12 pagina primaPagina">
        <ng-container *ngTemplateOutlet="intestazione"></ng-container>
        <div class="col-md-12 row afterIntestazione">
            <div class="col-md-1">
            </div>
            <div class="col-md-10">
                <ng-container *ngTemplateOutlet="templateAnamnesi"></ng-container>
            </div>
            <div class="col-md-1">
            </div>
        </div>
        <div class="col-md-12 row">
            <div class="col-md-1">
            </div>
            <div class="col-md-10">
                <ng-container *ngTemplateOutlet="templateCondizioniCliniche"></ng-container>
            </div>
            <div class="col-md-1">
            </div>
        </div>
        <!-- <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container> -->
    </div>



    <div class="row col-md-12 pagina">
        <ng-container *ngTemplateOutlet="intestazione"></ng-container>
        <div class="col-md-12 row afterIntestazione">
            <div class="col-md-1">
            </div>
            <div class="col-md-10">
                <ng-container *ngTemplateOutlet="templateTerapie"></ng-container>
            </div>
            <div class="col-md-1">
            </div>
        </div>
        <!-- <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container> -->
    </div>
</ng-template>

<ng-template let-parametri #paginaGrafici>
    <div class="col-md-12 ml-2 row pagina">
        <ng-container *ngTemplateOutlet="intestazione"></ng-container>
        <div class="col-md-12 row afterIntestazioneGrafici">
            <div class="col-12">
                <div class="card" *ngFor="let card of parametri.pagina.cards">
                    <div class="card-header">
                        <h4>{{card.header}}</h4>
                    </div>
                    <div class="card-body">
                        <div class="row" *ngFor="let grafico of card.grafici">
                            <ng-container
                                *ngTemplateOutlet="singoloGrafico;context:{$implicit: {grafico:grafico}}"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <ng-container *ngTemplateOutlet="pieDiPagina"></ng-container> -->
    </div>
</ng-template>

<ng-template #singoloGrafico let-parametri>
    <ng-container *ngIf="parametri.grafico == 'smartwatch-fr'">
        <div class="col-md-10">
            <app-grafici-werable altezzaGrafico="270" larghezzaGrafico="850" [showHeader]="false"
                tipoGrafico="Frequenza respiratoria" [identifier]="identifier" [source]="'wearable'" [date]="date"
                (stats)="getMeasure('BR',$event)">
            </app-grafici-werable>
        </div>
        <div class="col-md-2" style="padding-left: 65px;margin-top: 110px">
            <p>FR min: {{statsBR.min}} </p>
            <p>FR avg: {{statsBR.avg}}</p>
            <p>FR max: {{statsBR.max}}</p>
        </div>
    </ng-container>
    <ng-container *ngIf="parametri.grafico == 'smartwatch-steps'">
        <div class="col-md-12">
            <app-grafici-werable altezzaGrafico="270" larghezzaGrafico="900" [showHeader]="false" tipoGrafico="Passi"
                [identifier]="identifier" [source]="'wearable'" [date]="date">
            </app-grafici-werable>
        </div>
    </ng-container>
    <ng-container *ngIf="parametri.grafico == 'smartwatch-sleep'">
        <div class="col-md-12">
            <app-grafici-werable altezzaGrafico="270" larghezzaGrafico="900" [showHeader]="false" tipoGrafico="Sonno"
                [identifier]="identifier" [source]="'wearable'" [date]="date">
            </app-grafici-werable>
        </div>
    </ng-container>
    <ng-container *ngIf="parametri.grafico == 'smartwatch-fc'">
        <div class="col-md-10">
            <app-grafici-werable altezzaGrafico="270" larghezzaGrafico="850" [showHeader]="false"
                tipoGrafico="Frequenza cardiaca" [identifier]="identifier" [source]="'wearable'" [date]="date"
                (stats)="getMeasure('HR',$event)">
            </app-grafici-werable>
        </div>
        <div class="col-md-2" style="padding-left: 65px;margin-top: 110px">
            <p>FC min: {{statsHR.min}} </p>
            <p>FC avg: {{statsHR.avg}}</p>
            <p>FC max: {{statsHR.max}}</p>
        </div>
    </ng-container>
    <ng-container *ngIf="parametri.grafico == 'smartwatch-bp'">
        <div class="col-md-10">
            <app-grafici-werable altezzaGrafico="270" larghezzaGrafico="850" [showHeader]="false"
                tipoGrafico="Pressione sanguigna" [identifier]="identifier" [source]="'wearable'" [date]="date"
                (stats)="getMeasure('SYS',$event)">
            </app-grafici-werable>
        </div>
        <div class="col-md-2" style="padding-left: 65px;margin-top: 40px">
            <p>Sis. min: {{statsSys.min}} </p>
            <p>Sis. avg: {{statsSys.avg}}</p>
            <p>Sis. max: {{statsSys.max}}</p>
            <p>Dia. min: {{statsDis.min}} </p>
            <p>Dia. avg: {{statsDis.avg}}</p>
            <p>Dia. max: {{statsDis.max}}</p>
        </div>
    </ng-container>
    <ng-container *ngIf="parametri.grafico == 'smartwatch-spo2'">
        <div class="col-md-10">
            <app-grafici-werable altezzaGrafico="270" larghezzaGrafico="850" [showHeader]="false"
                tipoGrafico="Ossigenazione" [identifier]="identifier" [source]="'wearable'" [date]="date"
                (stats)="getMeasure('SPO',$event)">
            </app-grafici-werable>
        </div>
        <div class="col-md-2" style="padding-left: 65px;margin-top: 110px">
            <p>Spo2 min: {{statsSpo.min}} </p>
            <p>Spo2 avg: {{statsSpo.avg}}</p>
            <p>Spo2 max: {{statsSpo.max}}</p>
        </div>
    </ng-container>
    <ng-container *ngIf="parametri.grafico == 'pulseoximeter-all'">
        <!-- Intero periodo -->
        <div class="col-{{showMediaPulsossimetroAirBp2 ? '10' : '12'}}">
            <app-grafici-pulse-oximeter [identifier]="identifier" altezzaGrafico="200"
                larghezzaGrafico="{{showMediaPulsossimetroAirBp2 ? '850' : '950'}}" [showHeader]="false" [date]="date"
                (stats)="setStats('statsPulsossimetroAll', $event)" [onlyLast]="false"></app-grafici-pulse-oximeter>
        </div>
        <div class="col-2 stats" style="margin-top: 20px" *ngIf="showMediaPulsossimetroAirBp2">
            <p>HR min: {{statsPulsossimetroAll.hr.min}} </p>
            <p>HR avg: {{statsPulsossimetroAll.hr.avg}}</p>
            <p>HR max: {{statsPulsossimetroAll.hr.max}}</p>
            <p>O2 min: {{statsPulsossimetroAll.o2.min}} </p>
            <p>O2 avg: {{statsPulsossimetroAll.o2.avg}}</p>
            <p>O2 max: {{statsPulsossimetroAll.o2.max}}</p>
        </div>
    </ng-container>
    <ng-container *ngIf="parametri.grafico == 'pulseoximeter-last'">
        <!-- Ultima misurazione -->
        <div class="col-{{showMediaPulsossimetroAirBp2 ? '10' : '12'}}">
            <app-grafici-pulse-oximeter [identifier]="identifier" altezzaGrafico="200"
                larghezzaGrafico="{{showMediaPulsossimetroAirBp2 ? '850' : '950'}}" [showHeader]="false" [date]="date"
                (stats)="setStats('statsPulsossimetroDettagli', $event)" [onlyLast]="true"></app-grafici-pulse-oximeter>
        </div>
        <div class="col-2 stats" style="margin-top: 20px" *ngIf="showMediaPulsossimetroAirBp2">
            <p>HR min: {{statsPulsossimetroDettagli.hr.min}} </p>
            <p>HR avg: {{statsPulsossimetroDettagli.hr.avg}}</p>
            <p>HR max: {{statsPulsossimetroDettagli.hr.max}}</p>
            <p>O2 min: {{statsPulsossimetroDettagli.o2.min}} </p>
            <p>O2 avg: {{statsPulsossimetroDettagli.o2.avg}}</p>
            <p>O2 max: {{statsPulsossimetroDettagli.o2.max}}</p>
        </div>
    </ng-container>
    <ng-container *ngIf="parametri.grafico == 'airbp2-all'">
        <div class="col-{{showMediaPulsossimetroAirBp2 ? '10' : '12'}}">
            <app-grafici-werable [identifier]="identifier" [source]="'AirBP'" [date]="date" #graficoAirbp
                altezzaGrafico="250" larghezzaGrafico="{{showMediaPulsossimetroAirBp2 ? '850' : '950'}}"
                [showHeader]="false" (stats)="setStats('airbp2', $event)">
            </app-grafici-werable>
        </div>
        <div class="col-2 stats" style="margin-top: 20px" *ngIf="showMediaPulsossimetroAirBp2">
            <p>Sis. min: {{statsAirbp2.sys.min}} </p>
            <p>Sis. avg: {{statsAirbp2.sys.avg}}</p>
            <p>Sis. max: {{statsAirbp2.sys.max}}</p>
            <p>Dia. min: {{statsAirbp2.dya.min}} </p>
            <p>Dia. avg: {{statsAirbp2.dya.avg}}</p>
            <p>Dia. max: {{statsAirbp2.dya.max}}</p>
        </div>
    </ng-container>
</ng-template>