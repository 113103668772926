import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/service/app.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { environment } from 'src/environments/environment';
import { PazientiService } from '../pazienti/service/pazienti.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  datePipe = new DatePipe('en-US');

  totalPatient = 0;

  patients = [];

  numeroPazientiDisattivati = 0;

  clinicalImpressionList = [];

  alertList = [];

  practitioners;

  organizationChildrenSelected;

  constructor(private http: HttpClient, private userService: UserService, private router: Router, private pazientiService: PazientiService,
    private appSrv: AppService) { }

  ngOnInit() {
    this.organizationChildrenSelected = { display: this.userService.getCurrentOrganizationObj().organization, id: "" + this.userService.getCurrentOrganizationObj().id, ref: (this.userService.getCurrentOrganizationObj() as any).referenceId };
    this.userService.getPractitionersAsPromise(this.http).then((resp: any) => {
      this.practitioners = resp;
      this.countPatient();
      this.countPazientiDisattivati();
      this.getClinicalImpressionList();
      this.getAlertList();
    });
  }

  countPatient() {
    this.http.get(endpoints.getCountPatient + this.practitioners).subscribe((resp: any) => {
      this.totalPatient = resp.total;
      if (resp.entry) {
        this.patients = resp.entry.map(elem => ({
          id: elem.resource.id,
          identifier: this.pazientiService.getPatientIdFromPatientObject(elem.resource),
          // tslint:disable-next-line: max-line-length
          nome: this.pazientiService.getPatientName(elem.resource),
          // tslint:disable-next-line: max-line-length
          cognome: this.pazientiService.getPatientSurname(elem.resource),
          birthDate: elem.resource.birthDate ? this.datePipe.transform(new Date(Date.parse(elem.resource.birthDate)), 'dd/MM/yyyy') : '',
          gender: this.getGender(elem.resource.gender)
        })
        );
      } else {
        this.patients = [];
      }
    });
  }

  countPazientiDisattivati() {
    const query = endpoints.getDisabledUsersByOrganization + '?pageIndex=0&pageSize=1&&organizations=' + this.organizationChildrenSelected.ref.split('/')[1];
    this.http.get(query).subscribe((resp: any) => {
      this.numeroPazientiDisattivati = resp.object.totalElements;
    });
  }

  getGender(gender) {
    if (gender === 'male') {
      return 'Maschio';
    }
    if (gender === 'female') {
      return 'Femmina';
    }
    if (gender === 'other') {
      return 'Altro';
    }
    if (gender === 'unknown') {
      return 'Sconosciuto';
    }
  }

  goToDettagli(elem) {
    this.pazientiService.setPatientId(elem);
    this.appSrv.setPatientListFrom('stiledivita');
    this.router.navigate(['/main/pazienti/profilosanitario', elem, 'stiledivita']);
  }

  goToVisit(id, patId) {
    this.pazientiService.setPatientId(patId);
    this.appSrv.setPatientListFrom('visite');
    this.router.navigate(['/main/pazienti/visite', patId, 'osservazioni', id, 'stiledivita']);
  }

  getClinicalImpressionList() {
    this.http.get(endpoints.getClinicalImpressionListApi + this.practitioners).toPromise()
      .then((resp: any) => {
        if (resp && resp.total > 0) {
          this.clinicalImpressionList = resp.entry.map(elem => ({
            id: elem.resource.id,
            date: elem.resource.date ? this.datePipe.transform(new Date(Date.parse(elem.resource.date)), 'dd/MM/yyyy') : '',
            // tslint:disable-next-line: max-line-length
            patientId: elem.resource.subject.reference.split('/')[1],
            descrizione: elem.resource.description,
            type: elem.resource.description === 'Prima visita' ? 'badge-success' : 'badge-warning',
            patient: this.getPatient(elem.resource.subject.reference.split('/')[1])
          }))
        }
      }).catch(err => {
        console.error(err);
      });
  }


  getPatient(patientId) {
    return this.http.get(endpoints.getPatientByPatientId + patientId).toPromise().then((resp: any) => {
      if (resp && resp.message.identifier) {
        const identifier = resp.message.identifier.find(identifierCode => identifierCode.use == "usual");
        return identifier ? identifier.value : "";
      } else {
        return "";
      }
    });
  }


  getAlertList() {
    const query = endpoints.getAlerts + '/vital-signs/registed';
    this.http.get(query).subscribe((resp: any) => {
      if (resp.entry) {
        this.alertList = resp.entry.map(elem => ({
          id: elem.resource.id,
          patient: this.getPatient(elem.resource.subject.reference.split('/')[1]),
          // tslint:disable-next-line: max-line-length
          misura: this.getTypeMeasure(elem.resource.code.coding[0].code),
          value: elem.resource.valueQuantity.value,
          // tslint:disable-next-line: max-line-length
          date: elem.resource.effectiveDateTime ? this.datePipe.transform(new Date(Date.parse(elem.resource.effectiveDateTime)), 'dd/MM/yyyy HH:mm') : '',
          message: this.getMessage(elem.resource.valueQuantity.value, elem.resource.referenceRange[0]),
          level: this.getLevel(elem.resource.valueQuantity.value, elem.resource.referenceRange[0]),
          // tslint:disable-next-line: max-line-length
          detail: 'Verificato il ' + this.datePipe.transform(new Date(Date.parse(elem.resource.meta.lastUpdated)), 'dd/MM/yyyy HH:mm'),
          actions: elem.resource.comment
        })
        );
      } else {
        this.alertList = [];
      }
    });
  }

  getTypeMeasure(code) {
    switch (code) {
      case '8867-4': return 'Frequenza cardiaca';
      case '59408-5': return 'Ossigenazione';
      case '8462-4': return 'Diastolica';
      case '8480-6': return 'Sistolica';
      case 'pain': return 'Dolore';
      default: return '';
    }
  }

  getLevel(value, range) {
    if (value > range.high.value) {
      if ((value - range.high.value) <= range.high.value / 100 * 10) {
        return 1;
      } else {
        return 2;
      }
    } else if (value < range.low.value) {
      if ((value - range.low.value) <= range.low.value / 100 * 10) {
        return 1;
      } else {
        return 2;
      }
    }
  }

  getMessage(value, range) {
    if (value > range.high.value) {
      return 'Valore sopra la soglia impostata di ' + range.high.value;
    } else if (value < range.low.value) {
      return 'Valore sotto la soglia impostata di ' + range.low.value;
    }
  }

  goToListPatient() {
    this.pazientiService.resetPatient();
    this.appSrv.setPatientListFrom('lista_pazienti');
    this.router.navigate(['/main/pazienti/listapazienti']);
  }

  goToVisits() {
    this.appSrv.setPatientListFrom('visite');
    this.router.navigate(['/main/pazienti/listapazienti']);
  }

  goToAlerts() {
    this.appSrv.setPatientListFrom('alert');
    this.router.navigate(['/main/pazienti/listapazienti']);
  }

  checkPermessoLetturaNotifiche(): boolean {
    return this.userService.checkOnlyPermission('Notifiche', 'read');
  }

  checkPermessoLetturaVisite(): boolean {
    return this.userService.checkOnlyPermission('Visite', 'read');
  }
}
