import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { CustomizationService } from 'src/app/service/customization.service';
import { NetworkService } from 'src/app/service/network-service.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../../service/pazienti.service';
import { DateFromTo } from '../Model/date-from-to';
import { getBarChartOptions, yaxisDiarreaChart, yaxisNauseaChart, yaxisStipsiChart } from '../Utils/apexchart-opt';
import { cutSeries, getSeriesResponses, getSeriesScores, SerieType } from '../Utils/grafici-utils';

@Component({
  selector: 'app-grafici-sintomi',
  templateUrl: './grafici-sintomi.component.html',
  styleUrls: ['./grafici-sintomi.component.scss']
})
export class GraficiSintomiComponent implements OnInit, OnDestroy {

  @Input() identifier;

  @Input('date') set date(date: DateFromTo) {
    if (!date.compare(this._date)) {
      this.observer.next(date);
    }
  }
  // tslint:disable-next-line: variable-name
  _date: DateFromTo;

  // Colori dei sintomi definiti dal tema
  COLORE_FATICA = '#ff6c00';
  COLORE_NAUSEA = '#fedb00';
  COLORE_DOLORE = '#de2826';

  observer: BehaviorSubject<DateFromTo>;

  dateFromString: string;
  dateToString: string;
  dateFromTimeStamp;
  dateToTimeStamp;

  opt: SelectItem[];

  selected = 'Sintomi';

  patientId;

  strokeBar: ApexStroke = {
    width: 1
  };

  sintomiChartArray: SerieType[] = [
    { name: 'Dolore', color: this.COLORE_DOLORE, serie: [], topic: 'dolore', type: 'column', display: ['Dolore'], markers: true },
    { name: 'Fatica', color: this.COLORE_FATICA, serie: [], topic: 'fatica', type: 'column', display: ['Fatica'], markers: true },
    { name: 'Nausea', color: this.COLORE_NAUSEA, serie: [], topic: 'nausea', type: 'column', display: ['Nausea'], markers: true },
  ];

  diarreaChartArray: SerieType[] = [
    // tslint:disable-next-line: max-line-length
    { name: 'Evacuazioni giornaliere (frequenza)', color: '#008FFB', serie: [], topic: '1', display: ['Diarrea (Frequenza)'], type: 'column', markers: true },
    { name: 'Diarrea', color: '#FA4443', serie: [], topic: '3', display: ['Diarrea (Farmaci)'], type: 'line' },
  ];

  stipsiChartArray: SerieType[] = [
    // tslint:disable-next-line: max-line-length
    { name: 'Evacuazioni giornaliere (frequenza)', color: '#008FFB', serie: [], topic: '1', display: ['Stipsi (Frequenza)'], type: 'column', markers: true },
    { name: 'Stipsi', color: '#FA4443', serie: [], topic: '4', display: ['Stipsi (Farmaci)'], type: 'line' },
  ];

  nauseaChartArray: SerieType[] = [
    // tslint:disable-next-line: max-line-length
    { name: 'Nausea', color: ['#008FFB', '#FA4443'], serie: [], topic: '10', display: ['Nausea'], type: 'column', markers: true },
    { name: 'Nausea Farmaci', color: '#FA4443', serie: [], topic: '11', display: ['Nausea (Farmaci)'], type: 'line' },
  ];

  pesoChartArray: SerieType[] = [
    // tslint:disable-next-line: max-line-length
    { name: 'Peso corporeo', color: '#008FFB', serie: [], topic: 'valutazione', display: ['Peso (kg)'], type: 'line', maxyaxis: 200, markers: true },
  ];

  temperaturaChartArray: SerieType[] = [
    // tslint:disable-next-line: max-line-length
    { name: 'Temperatura', color: '#008FFB', serie: [], topic: 'valutazione', display: ['Temperatura (°C)'], type: 'line', maxyaxis: 45, markers: true },
  ];

  @ViewChild('chart', { static: true }) chartRef: ElementRef;
  chart: ApexCharts;

  organization = undefined;

  // tslint:disable-next-line: max-line-length
  constructor(private http: HttpClient, private toastr: ToastrService, private datePipe: DatePipe, private network: NetworkService, private userService: UserService, private pazientiService: PazientiService, private customizationService: CustomizationService) {
    this.observer = new BehaviorSubject<DateFromTo>(null);
  }

  ngOnInit() {
    const paziente = this.pazientiService.getPatient();
    this.patientId = paziente.id;
    this.observer.subscribe((input) => {
      if (this.identifier && input) {
        this._date = input;
        this.dateFromString = this.datePipe.transform(input.getDateFrom(), 'yyyy-MM-dd');
        this.dateToString = this.datePipe.transform(input.getDateTo(), 'yyyy-MM-dd');
        this.dateFromTimeStamp = Math.round(input.getDateFrom().getTime());
        this.dateToTimeStamp = Math.round(input.getDateTo().getTime());
        this.dropdownChange(this.selected);
        this.pazientiService.getPatientOrganizationAsPromiseById(this.patientId).then((resp: any) => {
          this.organization = resp.desc;
          this.opt = this.customizationService.getOptionGraficiSintomiByOrganization(this.organization);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.observer.unsubscribe();
  }

  dropdownChange(event) {
    switch (event) {
      case 'Sintomi':
        this.getSintomiChartData('SintomiChartID', 'Dolore vs Fatica vs Nausea', this.sintomiChartArray);
        break;
      case 'Diarrea':
        this.getSintomiChartData('DarreaChartId', 'Diarrea (Frequenza) vs Diarrea (Farmaci)', this.diarreaChartArray, yaxisDiarreaChart);
        break;
      case 'Stipsi':
        this.getSintomiChartData('StipsiChartId', 'Stipsi (Frequenza) vs Stipsi (Farmaci)', this.stipsiChartArray, yaxisStipsiChart);
        break;
      case 'Nausea':
        this.getSintomiChartData('NauseaChartId', 'Nausea (Frequenza) vs Nausea (Farmaci)', this.nauseaChartArray, yaxisNauseaChart);
        break;
      case 'Peso':
        this.getSintomiChartData('PesoChartId', 'Peso corporeo', this.pesoChartArray);
        break;
      case 'Temperatura':
        this.getSintomiChartData('TemperaturaChartId', 'Temperatura corporea', this.temperaturaChartArray);
        break;

      default:
        break;
    }
  }

  getSintomiChartData(id, title, chartArray, assiY?) {

    forkJoin(chartArray.map(sintomo => {
      return this.getSelfReportedData(sintomo.topic);
    })).subscribe((resp: any) => {
      if (resp) {
        let series: ApexAxisChartSeries = [];
        let labels = [];
        let countResp = 0;
        resp.forEach((response, index) => {
          let seriesLabels = null;
          if (response.hits && response.hits.hits && response.hits.hits.length > 0) {
            // tslint:disable-next-line: max-line-length
            if (response.hits.hits[0]._source.idDomanda === 'fatica' || response.hits.hits[0]._source.idDomanda === 'dolore' || response.hits.hits[0]._source.idDomanda === 'nausea') {
              seriesLabels = getSeriesScores(response, chartArray[index].display, chartArray[index].type);
            } else if (response.hits.hits[0]._index.includes('answers')) {
              seriesLabels = getSeriesResponses(response, chartArray[index].name, chartArray[index].display, chartArray[index].type, this.organization, this.customizationService);
            }
            if (Array.isArray(seriesLabels.serie)) {
              seriesLabels.serie.forEach(element => {
                series.push(element);
              });
            } else {
              series.push(seriesLabels.serie);
            }
            if (countResp === 0) {
              labels = seriesLabels.labels;
            }
            countResp++;
          } else {
            series.push({
              data: new Array(),
              type: chartArray[index].type,
              name: chartArray[index].display
            });
          }
        });
        if (id !== 'SintomiChartID' && series[0] && series[1]) {
          series = cutSeries(series[0], series[1]);
        } else if (series[0] && series[1] && series[1]) {
          const newSeries = cutSeries(series[0], series[1]);
          const au = cutSeries(series[2], series[1]);
          series = new Array();
          series.push(newSeries[0]);
          series.push(newSeries[1]);
          series.push(au[0]);
        }
        const options = getBarChartOptions(id, title, series, chartArray, labels, assiY, null, chartArray[0].markers);
        options.tooltip.shared = true;
        options.tooltip.intersect = false;
        options.tooltip.followCursor = true;
        if (this.chart) {
          this.chart.updateOptions(options, false, false, false);
        } else {
          this.chart = new ApexCharts(this.chartRef.nativeElement, options);
          this.chart.render();
        }
      }
    }, (err) => {
      console.error(err);
      this.toastr.error('Errore nel recupero dei dati.');
    });

  }

  getSelfReportedData(topic) {
    return this.http.get(endpoints.elasticQuery + "getSintomi" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + topic + "/-answers");
  }
}
