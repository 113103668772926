import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-etichetta-prodotto',
  templateUrl: './etichetta-prodotto.component.html',
  styleUrls: ['./etichetta-prodotto.component.scss']
})
export class EtichettaProdottoComponent implements OnInit {

  righe = [
    [{ symbol: "assets/img/iconaEtichetta2.png", description: "accedi a IFU", link: "/manuali" }, { symbol: "assets/img/mdIcon.png", description: "RIATLAS HEALTHCARE" }],
    [{ symbol: "assets/img/iconaEtichetta3.png", description: "" }, { symbol: "assets/img/lotIcon.png", description: "3.0.0" }],
    [{ symbol: "assets/img/udiIcon.png", description: ["(01)8050624650008", "(22)SE", "(8012)3.0.0", "(11)230100"], isMultiline: true }, { symbol: "assets/img/productionDateIcon.png", description: "2023-01-00" }],
  ];

  testoSotto = [`RIATLAS HEALTHCARE è un dispositivo medico software per la presa in carico di pazienti che avviano,
  dopo una fase acuta, un percorso terapeutico-assistenziale, con frequenti rientri in ospedale. Il
  software ha l’obiettivo di fornire al medico informazioni che non richiedono azioni immediate o a breve
  termine, sull’andamento del percorso di cura domiciliare e di favorire la partecipazione attiva dei
  pazienti al piano terapeutico. Più nello specifico, il software consente al personale sanitario di
  osservare l’evoluzione di biomarcatori (es. codici ICF del WHO) durante il percorso terapeutico
  assistenziale; monitorare lo stile di vita e visualizzare i sintomi e i parametri vitali, dovuti ad effetti
  collaterali, tossicità e/o reazioni ai farmaci; ottenere informazioni, anche tramite l’applicazione di
  algoritmi di intelligenza artificiale, per valutare lo stato di salute del paziente; analizzare i risultati clinici
  del paziente (ad es. appropriatezza e aderenza alla terapia, tossicità correlata al trattamento, ecc.). Il
  software permette inoltre, attraverso un’app mobile, di raccogliere informazioni e dati del paziente,
  tramite l’utilizzo di un dispositivo indossabile (wearable compatibile, rif. par. 3.1 “ELENCO DISPOSITIVI
  WEARABLE COMPATIBILI” del manuale utente relativo al profilo “Paziente”) e tramite la possibilità di
  compilare questionari (i.e., self-assessment).`, `RIATLAS HEALTHCARE consente l’accesso solo al personale sanitario autorizzato dalla struttura
  ospedaliera in cui la licenza viene resa disponibile. L’accesso ai pazienti viene concesso dal personale
  sanitario autorizzato in seguito a verifica dell’identità`];

  infoRiAtlas = ["RIATLAS S.r.L.", "Piazza Vittorio Emanuele III, 10 - località Penta 84084, Fisciano (SA)", "c/o l’edificio denominato “Ex Immacolata Concezione”", "C.F. e P. IVA: 05626670656", "info@riatlas.it", "https://riatlas.it"];

  revisione = "rev.1 del 19-05-2021";

  constructor() { }

  ngOnInit(): void {
  }

}
