import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TreeModule } from 'primeng/tree';
import { GlobalModule } from 'src/app/global/global.module';
import { SanitizePipe } from 'src/app/utils/sanitize.pipe';
import { RelatedPersonComponentComponent } from '../related-person-component/related-person-component.component';
import { RelatedPersonDetailComponent } from '../related-person-detail/related-person-detail.component';
import { SchedaPazienteComponent } from '../scheda-paziente/scheda-paziente.component';
import { AlberoICComponent } from './albero-ic/albero-ic.component';
import { AnamnesiPatologicaProssimaComponent } from './anamnesi-patologica-prossima/anamnesi-patologica-prossima.component';
import { CondizioniClinicheComponent } from './condizioni-cliniche/condizioni-cliniche.component';
import { DevicesComponent } from './devices/devices.component';
import { GestioneICDComponent } from './gestione-icd/gestione-icd.component';
import { PazienteCardComponent } from './paziente-card/paziente-card.component';
import { StatoDiSaluteTabComponent } from './stato-di-salute-tab/stato-di-salute-tab.component';
import { StatoDiSaluteComponent } from './stato-di-salute/stato-di-salute.component';
import { InfoUserComponent } from 'src/app/info-user/info-user.component';
import { ScaleDiValutazioneSDCComponent } from '../visite/visite/osservazioni/scale-di-valutazione/scale-di-valutazione-sdc/scale-di-valutazione-sdc.component';
import { ScaleDiValutazioneBIDeambulazioneComponent } from '../visite/visite/osservazioni/scale-di-valutazione/scale-di-valutazione-bideambulazione/scale-di-valutazione-bideambulazione.component';
import { ScaleDiValutazioneNLABComponent } from '../visite/visite/osservazioni/scale-di-valutazione/scale-di-valutazione-nlab/scale-di-valutazione-nlab.component';
import { ScaleDiValutazioneMRSComponent } from '../visite/visite/osservazioni/scale-di-valutazione/scale-di-valutazione-mrs/scale-di-valutazione-mrs.component';
import { ScaleDiValutazioneSAHFEComponent } from '../visite/visite/osservazioni/scale-di-valutazione/scale-di-valutazione-sahfe/scale-di-valutazione-sahfe.component';
import { ScaleDiValutazioneBarthelComponent } from '../visite/visite/osservazioni/scale-di-valutazione/scale-di-valutazione-barthel/scale-di-valutazione-barthel.component';
import { ScaleDiValutazioneMMSEComponent } from '../visite/visite/osservazioni/scale-di-valutazione/scale-di-valutazione-mmse/scale-di-valutazione-mmse.component';
import { ScaleDiValutazionePERCORSOCOVID19Component } from '../visite/visite/osservazioni/scale-di-valutazione/scale-di-valutazione-percorsocovid19/scale-di-valutazione-percorsocovid19.component';
import { DettagliAttivazioneServizioComponent } from '../gestione-attivazione-pazienti/dettagli-attivazione-servizio/dettagli-attivazione-servizio.component';
import { ListboxModule } from 'primeng/listbox';
import { MonitoringComponent } from '../visite/visite/osservazioni/monitoring/monitoring.component';
import { InputMaskModule } from 'primeng/inputmask';
import { FieldsetModule } from 'primeng/fieldset';
import { StileDiVitaComponent } from './stile-di-vita/stile-di-vita.component';



@NgModule({
  declarations: [
    PazienteCardComponent,
    CondizioniClinicheComponent,
    StatoDiSaluteComponent,
    SchedaPazienteComponent,
    StileDiVitaComponent,
    DevicesComponent,
    StatoDiSaluteTabComponent,
    AnamnesiPatologicaProssimaComponent,
    AlberoICComponent,
    GestioneICDComponent,
    RelatedPersonDetailComponent,
    RelatedPersonComponentComponent,
    ScaleDiValutazioneNLABComponent,
    ScaleDiValutazioneMRSComponent,
    ScaleDiValutazioneSAHFEComponent,
    ScaleDiValutazioneBIDeambulazioneComponent,
    ScaleDiValutazioneSDCComponent,
    ScaleDiValutazioneBarthelComponent,
    ScaleDiValutazioneMMSEComponent,
    ScaleDiValutazionePERCORSOCOVID19Component,
    InfoUserComponent,
    DettagliAttivazioneServizioComponent,
    MonitoringComponent
  ],
  imports: [
    CommonModule,
    FieldsetModule,
    MultiSelectModule,
    GlobalModule,
    DropdownModule,
    InputTextModule,
    InputMaskModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    PanelModule,
    TreeModule,
    DynamicDialogModule,
    DialogModule,
    EditorModule,
    TableModule,
    AutoCompleteModule,
    ScrollPanelModule,
    SelectButtonModule,
    CheckboxModule,
    ListboxModule,
    Ng5SliderModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [
    DatePipe,
    SanitizePipe,
    DialogService
  ],
  exports: [
    MonitoringComponent,
    FieldsetModule,
    InfoUserComponent,
    PazienteCardComponent,
    CondizioniClinicheComponent,
    StatoDiSaluteComponent,
    SchedaPazienteComponent,
    StileDiVitaComponent,
    DevicesComponent,
    StatoDiSaluteTabComponent,
    AnamnesiPatologicaProssimaComponent,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    ButtonModule,
    PanelModule,
    TreeModule,
    DynamicDialogModule,
    DialogModule,
    EditorModule,
    TableModule,
    ListboxModule,
    AutoCompleteModule,
    AlberoICComponent,
    GestioneICDComponent,
    RelatedPersonDetailComponent,
    RelatedPersonComponentComponent,
    ScaleDiValutazioneNLABComponent,
    ScaleDiValutazioneMRSComponent,
    ScaleDiValutazioneSAHFEComponent,
    ScaleDiValutazioneBIDeambulazioneComponent,
    ScaleDiValutazioneSDCComponent,
    ScaleDiValutazioneBarthelComponent,
    ScaleDiValutazioneMMSEComponent,
    ScaleDiValutazionePERCORSOCOVID19Component,
    Ng5SliderModule,
    DettagliAttivazioneServizioComponent,
    FormsModule
  ]
})
export class SharedPazientiModule { }
