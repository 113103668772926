import { Injectable } from '@angular/core';
import { MenuItem, SelectItem } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class CustomizationService {

  /**
   * Lista COMPLETA delle tab dei questionari.
   * label -> Label della tab che verrà visualizzata
   * routerLink -> Pagina che verrà visualizzata. Deve essere un child
   */
  private tabsQuestionari = [
    { label: 'Generali', routerLink: 'generali' },
    { label: 'Questionario EORTC QLQ-C30', routerLink: 'eortcqlqc30' },
    { label: 'Questionario MDS-UPDRS', routerLink: 'mdsupdrs' },
    { label: 'Questionario N-Lab', routerLink: 'nlab' },
    { label: 'Questionario Barthel', routerLink: 'barthel' },
    { label: 'Questionario FIM', routerLink: 'fim' },
    { label: 'Questionario COVID19', routerLink: 'covid19' },
    { label: 'Questionario Motricity Index', routerLink: 'motricity-index' },
    { label: 'Questionario MMSE', routerLink: 'mmse' },
    { label: 'Questionario TCT', routerLink: 'tct' },
    { label: 'Questionario MDS', routerLink: 'mds' },
    { label: 'Questionario MNA', routerLink: 'mna' },
    { label: 'Questionario CIRS-G', routerLink: 'cirsg' },
    { label: 'Questionario BIA', routerLink: 'bia' },
    { label: 'Questionario SPPB', routerLink: 'sppb' },
    { label: 'Questionario PASBAQ', routerLink: 'pasbaq' },
    { label: 'Questionario EuroQol', routerLink: 'euroqol' },
    { label: 'Questionario LSQ', routerLink: 'lsq' },
    { label: 'Questionario ESAS', routerLink: 'esas' },
    { label: 'Questionario Plicometria', routerLink: 'plicometria' },
    { label: 'Questionario PG-SGA', routerLink: 'pgsga' },
    { label: 'Questionario NRS', routerLink: 'nrs' },
    { label: 'Questionario MST', routerLink: 'mst' },
    { label: 'Questionario MUST', routerLink: 'must' },
    { label: 'Questionario Dinamometro', routerLink: 'dinamometro' },
    { label: 'Questionario IADL', routerLink: 'iadl' },
    { label: 'Questionario MRS', routerLink: 'mrs' },
    { label: 'Questionario SAHFE', routerLink: 'sahfe' },
    { label: 'Questionario BI Deambulazione', routerLink: 'bideambulazione' },
    { label: 'Questionario Scala disabilità comunicativa', routerLink: 'sdc' },
    { label: 'Questionario Percorso COVID19', routerLink: 'percorsocovid19' }
  ];

  // TODO: Scorporare i questionari della tab 'Generali' in modo da poter abilitare Karnowsky e l'altro
  private questionariAbilitati = {
    default: [
      "Generali",
      'Questionario EORTC QLQ-C30',
      'Questionario MDS-UPDRS',
      'Questionario N-Lab',
      'Questionario Barthel',
      'Questionario FIM',
      'Questionario COVID19',
      'Questionario Motricity Index',
      'Questionario MMSE',
      'Questionario TCT',
      'Questionario MDS',
      'Questionario MNA',
      'Questionario CIRS-G',
      'Questionario BIA',
      'Questionario SPPB',
      'Questionario PASBAQ',
      'Questionario EuroQol',
      'Questionario LSQ',
      'Questionario ESAS',
      'Questionario Plicometria',
      'Questionario PG-SGA',
      'Questionario NRS',
      'Questionario MST',
      'Questionario MUST',
      'Questionario Dinamometro',
      'Questionario IADL',
      'Questionario MRS',
      'Questionario SAHFE',
      'Questionario BI Deambulazione',
      'Questionario Scala disabilità comunicativa',
      'Questionario Percorso COVID19'
    ]
    ,
    RUGGI: [
      "Generali",
      'Questionario EORTC QLQ-C30',
    ]
    ,
    NEMO: [
      'Questionario N-Lab',
      'Questionario Barthel',
      'Questionario MRS',
      'Questionario SAHFE',
      'Questionario Scala disabilità comunicativa',
      'Questionario Percorso COVID19'
    ]
    ,
    "150910-IRCCS-Pascale": [
      "Generali",
      'Questionario IADL',
    ],
    "USEMED": [
      "Generali",
      'Questionario EORTC QLQ-C30',
    ]
  };

  private singoliQuestionariAbilitati = {
    default: ["Karnofsky", "ECOG"],
    "150910-IRCCS-Pascale": ["Karnofsky"]
  }

  /**
   * Array di configurazioni che va popolato nel costruttore
   */
  private configurazioni = new Array<Configuration>();

  // Grafici
  /**
   * Lista degli header che viene utilizzata per i grafici piccoli
   * (rosso, giallo, ecc...)
   */
  private listChartHeader = {
    default: ['dolore', 'fatica', 'nausea', 'sintomi', 'farmaci', 'domande', 'Checkme'],
    NEMO: ['dolore', 'fatica', 'funzionalita', 'domande', 'Checkme']
  };

  private opt2 = {
    default: [
      { label: 'Dolore', value: 'Dolore' },
      { label: 'Fatica', value: 'Fatica' },
      { label: 'Nausea', value: 'Nausea' },
      // { label: 'Diarrea (Frequenza)', value: 'Diarrea (Frequenza)' },
      // { label: 'Stipsi (Frequenza)', value: 'Stipsi (Frequenza)' },
      { label: 'Regorafenib', value: 'Regorafenib' },
      { label: 'Capecitabina', value: 'Capecitabina' },
      { label: 'Lonsurf/Tas 102', value: 'Lonsurf/Tas 102' }

    ] as SelectItem[],
    nemo: [
      { label: 'Dolore', value: 'Dolore' },
      { label: 'Fatica', value: 'Fatica' },
      { label: 'Funzionalità fisica', value: 'Funzionalita' },
    ] as SelectItem[]
  };

  private optDolore = {
    default: [
      { label: 'Frequenza cardiaca (Checkme)', value: 'Frequenza cardiaca' },
      { label: 'Pressione sanguigna (Checkme)', value: 'Pressione sanguigna' },
      { label: 'Frequenza respiratoria (Checkme)', value: 'Frequenza respiratoria' },
      { label: 'Ossigenazione (Checkme)', value: 'Ossigenazione' },
      // { label: 'Mood', value: 'Mood' },
      // { label: 'Energia', value: 'Energia' },
      // { label: 'Passi', value: 'Passi' },
      // { label: 'Regorafenib', value: 'Regorafenib' },
      // { label: 'Capecitabina', value: 'Capecitabina' },
      // { label: 'Lonsurf/Tas 102', value: 'Lonsurf/Tas 102' }

    ] as SelectItem[]
  };

  private defaultQuestionariGrafici = {
    default: "onco",
    nemo: "nemo",
    vivisol: "nemo",
    ruggi: "nemo",
    "150910-IRCCS-Pascale": "150910-IRCCS-Pascale",
  };

  /**
   * Struttura dove definire le domande dei questionari che vengono somministrati tramite la mobile app.
   */
  // TODO: Sostituire eda con "150910-IRCCS-Pascale"
  private questionariGrafici = {
    onco: {
      evaquazioniGiornaliere_frequenza: {
        graficoName: 'Evacuazioni giornaliere (frequenza)',
        topic: 'Evacuazioni giornaliere (frequenza)',
        testo: 'Quante volte al giorno è andato di corpo?',
        code: '1'
      } as Questions,
      evaquazioniGiornaliere_tipo: {
        graficoName: 'Evacuazioni giornaliere (tipo)',
        topic: 'Evacuazioni giornaliere (tipo)',
        testo: 'Che consistenza hanno le feci?',
        code: '2'
      } as Questions,
      diarrea: {
        graficoName: 'Diarrea',
        topic: 'Diarrea',
        testo: 'Quante volte al giorno ha assunto farmaci per la diarrea?',
        code: '3'
      } as Questions,
      stipsi: {
        graficoName: 'Stipsi',
        topic: 'Stipsi',
        testo: 'Quante volte al giorno ha assunto farmaci per la stitichezza (o stipsi)?',
        code: '4'
      } as Questions,
      dolore1: {
        graficoName: 'Dolore 1',
        topic: 'Dolore',
        testo: 'Ha avuto dolore?',
        code: '37'
      } as Questions,
      dolore2: {
        graficoName: 'Dolore 2',
        topic: 'Dolore',
        testo: 'Il dolore ha interferito con le sue attività quotidiane?',
        code: '6'
      } as Questions,
      fatica1: {
        graficoName: 'Fatica 1',
        topic: 'Fatica',
        testo: 'Ha avuto bisogno di riposo?',
        code: '7'
      } as Questions,
      fatica2: {
        graficoName: 'Fatica 2',
        topic: 'Fatica',
        testo: 'Si è sentito/a debole?',
        code: '8'
      } as Questions,
      fatica3: {
        graficoName: 'Fatica 3',
        topic: 'Fatica',
        testo: 'Si è sentito/a stanco/a?',
        code: '9'
      } as Questions,
      nausea1: {
        graficoName: 'Nausea 1',
        topic: 'Nausea',
        testo: 'Ha avuto nausea?',
        code: '10'
      } as Questions,
      nausea2: {
        graficoName: 'Nausea 2',
        topic: 'Nausea',
        testo: 'Quante volte al giorno ha assunto farmaci per la nausea?',
        code: '11'
      } as Questions,
      vomito: {
        graficoName: 'Vomito',
        topic: 'Vomito',
        testo: 'Ha vomitato oggi?',
        code: '12'
      } as Questions,
      rash1: {
        graficoName: 'Rash Cutaneo 1',
        topic: 'Rash cutaneo',
        testo: 'Ha avuto manifestazioni della pelle (es. arrossamenti, brufoli, spaccature vicino alle unghie, ecc.)?',
        code: '13'
      } as Questions,
      rash2: {
        graficoName: 'Rash Cutaneo 2',
        topic: 'Rash cutaneo',
        testo: 'Quante parti del corpo sono interessate?',
        code: '14'
      } as Questions,
      rash3: {
        graficoName: 'Rash Cutaneo 3',
        topic: 'Rash cutaneo',
        testo: 'Quante volte al giorno ha dovuto assumere antibiotici o cortisone topici o sistemici?',
        code: '15'
      } as Questions,
      rash4: {
        graficoName: 'Rash Cutaneo 4',
        topic: 'Rash cutaneo',
        testo: 'Per quanti giorni ha dovuto assumere antibiotici o cortisone topici o sistemici?',
        code: '16'
      } as Questions,
      stomatite1: {
        graficoName: 'Stomatite 1',
        topic: 'Stomatite',
        testo: 'Ha avuto bruciore o dolore all\'interno della bocca?',
        code: '17'
      } as Questions,
      stomatite2: {
        graficoName: 'Stomatite 2',
        topic: 'Stomatite',
        testo: 'Quante volte al giorno ha assunto farmaci per la stomatite?',
        code: '18'
      } as Questions,
      stomatite3: {
        graficoName: 'Stomatite 3',
        topic: 'Stomatite',
        testo: 'Per quanti giorni ha assunto farmaci per la stomatite?',
        code: '19'
      } as Questions,
      parestesie: {
        graficoName: 'Parestesie',
        topic: 'Parestesie',
        testo: 'Ha avuto formicolio, riduzione del tatto a mani e/o piedi?',
        code: '20'
      } as Questions,
      cefalea: {
        graficoName: 'Cefalea',
        topic: 'Cefalea',
        testo: 'Ha avuto mal di testa?',
        code: '21'
      } as Questions,
      febbre1: {
        graficoName: 'Febbre 1',
        topic: 'Febbre',
        testo: 'Ha avuto febbre?',
        code: '22'
      } as Questions,
      febbre2: {
        graficoName: 'Febbre 2',
        topic: 'Febbre',
        testo: 'Quante volte al giorno ha assunto antibiotici?',
        code: '23'
      } as Questions,
      febbre3: {
        graficoName: 'Febbre 3',
        topic: 'Febbre',
        testo: 'Per quanti giorni ha assunto antibiotici?',
        code: '24'
      } as Questions,
      febbre4: {
        graficoName: 'Febbre 4',
        topic: 'Febbre',
        testo: 'Ha dovuto fare iniezioni di fattori di crescita?',
        code: '25'
      } as Questions,
      umore1: {
        graficoName: 'Umore 1',
        topic: 'Umore',
        testo: 'Si è sentito/a teso/a?',
        code: '26'
      } as Questions,
      umore2: {
        graficoName: 'Umore 2',
        topic: 'Umore',
        testo: 'Si è sentito/a preoccupato/a?',
        code: '27'
      } as Questions,
      umore3: {
        graficoName: 'Umore 3',
        topic: 'Umore',
        testo: 'Si è sentito/a irritabile?',
        code: '28'
      } as Questions,
      umore4: {
        graficoName: 'Umore 4',
        topic: 'Umore',
        testo: 'Si è sentito/a depressa?',
        code: '29'
      } as Questions,
      difficoltaFinanziarie: {
        graficoName: 'Difficoltà finanziarie',
        topic: 'Difficoltà finanziarie',
        testo: 'Le Sue condizioni fisiche o il Suo trattamento medico Le hanno causato difficoltà finanziarie?',
        code: '30'
      } as Questions
    }
    ,
    nemo: {
      dolore1: {
        graficoName: 'Dolore 1',
        topic: 'Dolore',
        testo: 'Ha avuto dolore?',
        code: '37'
      } as Questions,
      dolore2: {
        graficoName: 'Dolore 2',
        topic: 'Dolore',
        testo: 'Il dolore ha interferito con le sue attività quotidiane?',
        code: '6'
      } as Questions,
      fatica1: {
        graficoName: 'Fatica 1',
        topic: 'Fatica',
        testo: 'Ha avuto bisogno di riposo?',
        code: '7'
      } as Questions,
      fatica2: {
        graficoName: 'Fatica 2',
        topic: 'Fatica',
        testo: 'Si è sentito/a debole?',
        code: '8'
      } as Questions,
      fatica3: {
        graficoName: 'Fatica 3',
        topic: 'Fatica',
        testo: 'Si è sentito/a stanco/a?',
        code: '9'
      } as Questions,
      umore1: {
        graficoName: 'Umore 1',
        topic: 'Umore',
        testo: 'Si è sentito/a teso/a?',
        code: '26'
      } as Questions,
      umore2: {
        graficoName: 'Umore 2',
        topic: 'Umore',
        testo: 'Si è sentito/a preoccupato/a?',
        code: '27'
      } as Questions,
      umore3: {
        graficoName: 'Umore 3',
        topic: 'Umore',
        testo: 'Si è sentito/a irritabile?',
        code: '28'
      } as Questions,
      umore4: {
        graficoName: 'Umore 4',
        topic: 'Umore',
        testo: 'Si è sentito/a depressa?',
        code: '29'
      } as Questions,
      funzionalitaFisica1: {
        graficoName: 'Funzionalità fisica 1',
        topic: 'Funzionalita',
        // tslint:disable-next-line: max-line-length
        testo: 'Ha avuto difficoltà a svolgere le normali attività della vita quotidiana (ad es. lavarsi, vestirsi, preparare i pasti, etc)?',
        code: '43'
      } as Questions,
      funzionalitaFisica2: {
        graficoName: 'Funzionalità fisica 2',
        topic: 'Funzionalita',
        testo: 'Ha avuto difficoltà nel camminare',
        code: '44'
      } as Questions,
      funzionalitaFisica3: {
        graficoName: 'Funzionalità fisica 3',
        topic: 'Funzionalita',
        testo: 'Si è sentito affaticato tanto da avere la necessità di sedersi o stare a letto?',
        code: '45'
      } as Questions,
    }
    ,
    edaAltro: {
      domanda11: {
        graficoName: 'Quanti pasti ha consumato oggi?',
        topic: 'Alimentazione',
        testo: 'Quanti pasti ha consumato oggi?',
        code: '46'
      } as Questions,
      domanda13: {
        graficoName: 'Quanti bicchieri d\'acqua e bevande ha assunto?',
        topic: 'Alimentazione',
        testo: 'Quanti bicchieri d\'acqua e bevande ha assunto?',
        code: '48'
      } as Questions,
      domanda14: {
        graficoName: 'Ha camminato oggi?',
        topic: 'Ha camminato oggi?',
        testo: 'Ha camminato oggi?',
        code: '-1'
      } as Questions,
      domanda16: {
        graficoName: 'Ha assunto la terapia prescritta?',
        topic: 'Ha assunto la terapia prescritta?',
        testo: 'Ha assunto la terapia prescritta?',
        code: '-1'
      } as Questions,
      domanda21: {
        graficoName: 'Capacità di movimento',
        topic: 'Capacità di movimento',
        testo: 'Capacità di movimento',
        code: '31'
      } as Questions,
      domanda22: {
        graficoName: 'Cura della persona',
        topic: 'Cura della persona',
        testo: 'Cura della persona',
        code: '32'
      } as Questions,
      domanda23: {
        graficoName: 'Attività abitudinali',
        topic: 'Attività abitudinali',
        testo: 'Attività abitudinali',
        code: '33'
      } as Questions,
    },
    edaCorpo: {
      domanda1: {
        graficoName: 'Quante volte al giorno è andato di corpo?',
        topic: 'Evacuazioni giornaliere (frequenza)',
        testo: 'Quante volte al giorno è andato di corpo?',
        code: '1'
      } as Questions,
      domanda2: {
        graficoName: 'Che consistenza hanno le feci?',
        topic: 'Evacuazioni giornaliere (tipo)',
        testo: 'Che consistenza hanno le feci?',
        code: '2'
      } as Questions,
      domanda8: {
        graficoName: 'Le urine sono chiare o ematiche?',
        topic: 'Le urine sono chiare o ematiche?',
        testo: 'Le urine sono chiare o ematiche?',
        code: '-1'
      } as Questions,
      domanda9: {
        graficoName: 'La ferita secerne?',
        topic: 'La ferita secerne?',
        testo: 'La ferita secerne?',
        code: '-1'
      } as Questions,
      domanda10: {
        graficoName: 'Ha vomitato oggi?',
        topic: 'Vomito',
        testo: 'Ha vomitato oggi?',
        code: '12'
      } as Questions,
      domanda12: {
        graficoName: 'Se è portatore di drenaggio, quanto drena in un giorno?',
        topic: 'Se è portatore di drenaggio, quanto drena in un giorno?',
        testo: 'Se è portatore di drenaggio, quanto drena in un giorno?',
        code: '-1'
      } as Questions,
      domanda15: { // TODO: Controllare poiché la domanda è un po' diversa (onco 13)
        graficoName: 'Ha avuto manifestazioni della pelle (rash cutaneo)?',
        topic: 'Rash cutaneo',
        testo: 'Ha avuto manifestazioni della pelle (rash cutaneo)?',
        code: '13'
      } as Questions,
      domanda27: {
        graficoName: 'Peso corporeo (kg)',
        topic: 'Peso',
        testo: 'Peso corporeo (kg)',
        code: '58'
      } as Questions,
    },
    edaStatoSalute: {
      domanda3: {
        graficoName: 'Ha avuto la febbre?',
        topic: 'Febbre',
        testo: 'Ha avuto la febbre?',
        code: '22'
      } as Questions,
      domanda4: {
        graficoName: 'Il dolore ha interferito con le sue attività quotidiane?',
        topic: 'Dolore',
        testo: 'Il dolore ha interferito con le sue attività quotidiane?',
        code: '6'
      } as Questions,
      domanda5: {
        graficoName: 'Ha avuto bisogno di riposo oggi?',
        topic: 'Fatica',
        testo: 'Ha avuto bisogno di riposo oggi?',
        code: '7'
      } as Questions,
      domanda6: {
        graficoName: 'Si è sentito debole oggi?',
        topic: 'Fatica',
        testo: 'Si è sentito debole oggi?',
        code: '8'
      } as Questions,
      domanda7: {
        graficoName: 'Si è sentito stanco oggi?',
        topic: 'Fatica',
        testo: 'Si è sentito stanco oggi?',
        code: '9'
      } as Questions,
      domanda24: { // TODO: La domanda è un po' diversa
        graficoName: 'Quanto dolore ha avuto oggi?',
        topic: 'Dolore',
        testo: 'Quanto dolore ha avuto oggi?',
        code: '-1'
      } as Questions,
      domanda25: {
        graficoName: 'Dolore o fastidio',
        topic: 'Dolore o fastidio',
        testo: 'Dolore o fastidio',
        code: '34'
      } as Questions,
    },
    edaStatoAnimo: {
      domanda17: {
        graficoName: 'Si è sentito preoccupato?',
        topic: 'Umore',
        testo: 'Si è sentito preoccupato?',
        code: '27'
      } as Questions,
      domanda18: {
        graficoName: 'Si è sentito irritabile?',
        topic: 'Umore',
        testo: 'Si è sentito irritabile?',
        code: '28'
      } as Questions,
      domanda19: {
        graficoName: 'Si è sentito depresso?',
        topic: 'Umore',
        testo: 'Si è sentito depresso?',
        code: '29'
      } as Questions,
      domanda20: {
        graficoName: 'Le sue condizioni fisiche o il suo trattamento medico le hanno causato difficoltà finanziarie?',
        topic: 'Difficoltà finanziarie',
        testo: 'Le sue condizioni fisiche o il suo trattamento medico le hanno causato difficoltà finanziarie?',
        code: '30'
      } as Questions,
      domanda26: {
        graficoName: 'Ansia o depressione',
        topic: 'Ansia o depressione',
        testo: 'Ansia o depressione',
        code: '35'
      } as Questions,
    },
  };

  // Ogni tipo di grafico ha header e lista di domande selezionabili tramite il dropdown
  // TODO: Sostituire eda con "150910-IRCCS-Pascale"
  private graficiMonitoraggio = {
    mobileAppSintomi: {
      tipo: 'sintomi',
      style: 'col-6'
    },
    weareable: {
      tipo: 'wearable',
      source: 'wearable',
      style: 'col-12',
    },
    weareableCheckme: {
      tipo: 'wearable',
      source: 'Checkme',
      style: 'col-12',
    },
    domandeOnco: {
      tipo: 'domande',
      style: 'col-6',
      domande: this.questionariGrafici.onco,
    },
    domandeNemo: {
      tipo: 'domande',
      style: 'col-6',
      domande: this.questionariGrafici.nemo,
    },
    domandeEdaAltro: {
      titolo: 'Altro',
      tipo: 'domande',
      style: 'col-6',
      domande: this.questionariGrafici.edaAltro,
    },
    domandeEdaCorpo: {
      titolo: 'Corpo',
      tipo: 'domande',
      style: 'col-6',
      domande: this.questionariGrafici.edaCorpo,
    },
    domandeEdaStatoSalute: {
      titolo: 'Stato di salute',
      tipo: 'domande',
      style: 'col-6',
      domande: this.questionariGrafici.edaStatoSalute,
    },
    domandeEdaStatoAnimo: {
      titolo: 'Stato d\'animo',
      tipo: 'domande',
      style: 'col-6',
      domande: this.questionariGrafici.edaStatoAnimo,
    },
    farmaci: {
      tipo: 'farmaci',
      style: 'col-6'
    },
    vs: {
      tipo: 'vs',
      style: 'col-12'
    },
    airbp2: {
      tipo: 'wearable',
      source: 'airbp2',
      style: 'col-12'
    },
    pulseoximeterAll: {
      tipo: 'pulseoximeter-all',
      source: 'pulseoximeter', // Attualmente non utilizzato
      style: 'col-12'
    },
    pulseoximeterDetail: {
      tipo: 'pulseoximeter-detail',
      source: 'pulseoximeter', // Attualmente non utilizzato
      style: 'col-12'
    },
    peso: { // Inizio predisposizione per migrazione
      tipo: 'weight',
      source: 'mobileapp',
      style: 'col-6'
    },
    ecg: {
      tipo: 'ecg',
      source: 'wearable',
      style: 'col-12'
    },
    patientdigitalcard: {
      tipo: 'patient-digital-card',
      source: 'mobileapp',
      style: 'col-6'
    },
    pulseoximeterAllFromMonitoringEvents: {
      tipo: 'pulseoximeter-all-from-monitoring-events',
      source: 'pulseoximeter', // Attualmente non utilizzato
      style: 'col-12'
    },
    airbp2FromMonitoringEvents: {
      tipo: 'wearable-from-monitoring-events',
      source: 'airbp2',
      style: 'col-12'
    },
    weareableCheckmeFromMonitoringEvents: {
      tipo: 'wearable-from-monitoring-events',
      source: 'Checkme',
      style: 'col-12',
    },
    noDevice: {
      tipo: 'no-device-from-monitoring-events',
      style: 'col-6'
    },
    ecgFromMonitoringEvents: {
      tipo: 'ecg-from-monitoring-events',
      source: 'wearable',
      style: 'col-12'
    },
    tabbedECG: {
      tipo: 'tabbed',
      source: 'ecg',
      style: 'col-12 mt-3',
    },
    tabbedAirbp: {
      tipo: 'tabbed',
      source: 'airbp',
      style: 'col-6 mt-3',
    },
    tabbedCheckme: {
      tipo: 'tabbed',
      source: 'checkme',
      style: 'col-6 mt-3',
    },
    tabbedPulseoximeter: {
      tipo: 'tabbed',
      source: 'pulseoximeter',
      style: 'col-6 mt-3'
    }
  };

  /**
   * Struttura che definisce quali grafici visualizzare nella pagina del monitoraggio
   */
  private configurazioneMonitoraggio = {
    default: [
      this.graficiMonitoraggio.mobileAppSintomi,
      this.graficiMonitoraggio.domandeOnco,
      // this.graficiMonitoraggio.patientdigitalcard,
      this.graficiMonitoraggio.farmaci,
      // this.graficiMonitoraggio.weareable,
      this.graficiMonitoraggio.weareableCheckme,
      this.graficiMonitoraggio.vs,
      this.graficiMonitoraggio.airbp2,
      // this.graficiMonitoraggio.airbp2FromMonitoringEvents,
      // this.graficiMonitoraggio.pulseoximeterAllFromMonitoringEvents,
      this.graficiMonitoraggio.pulseoximeterAll,
      this.graficiMonitoraggio.pulseoximeterDetail,
      // this.graficiMonitoraggio.weareableCheckmeFromMonitoringEvents,
      this.graficiMonitoraggio.ecg,
      // this.graficiMonitoraggio.ecgFromMonitoringEvents,
      // this.graficiMonitoraggio.noDevice,
      // this.graficiMonitoraggio.tabbedAirbp,
      // this.graficiMonitoraggio.tabbedCheckme,
      // this.graficiMonitoraggio.tabbedECG,
      // this.graficiMonitoraggio.tabbedPulseoximeter,

    ],
    "150910-IRCCS-Pascale": [
      this.graficiMonitoraggio.mobileAppSintomi,
      this.graficiMonitoraggio.weareable,
      this.graficiMonitoraggio.domandeEdaAltro,
      this.graficiMonitoraggio.domandeEdaCorpo,
      this.graficiMonitoraggio.domandeEdaStatoAnimo,
      this.graficiMonitoraggio.domandeEdaStatoSalute,
      this.graficiMonitoraggio.farmaci,
      this.graficiMonitoraggio.vs
    ],
    L0: [
      // this.graficiMonitoraggio.pulseoximeterAll,
      // this.graficiMonitoraggio.pulseoximeterAllFromMonitoringEvents,
      // this.graficiMonitoraggio.airbp2FromMonitoringEvents,
      // this.graficiMonitoraggio.patientdigitalcard,
      // this.graficiMonitoraggio.mobileAppSintomi,
      // this.graficiMonitoraggio.weareable,
      // this.graficiMonitoraggio.domandeEdaAltro,
      // this.graficiMonitoraggio.domandeEdaCorpo,
      // this.graficiMonitoraggio.domandeEdaStatoAnimo,
      // this.graficiMonitoraggio.domandeEdaStatoSalute,
      // this.graficiMonitoraggio.farmaci,
      // this.graficiMonitoraggio.vs
    ]
  };

  /**
   * Array che definisce i valori della select da cui è possibile scegliere quale csv scaricare
   * All'indice manca l'organization che deve essere messa prima di fare la chiamata.
   */
  private indiciSelectDownloadCSVMonitoraggio = {
    default: [
      { display: 'Frequenza cardiaca', index: '-hr-br', code: '1', type: '1' },
      { display: 'Frequenza respiratoria', index: '-hr-br', code: '4', type: '1' },
      { display: 'Sistolica', index: '-pressure', code: '2', type: '1' },
      { display: 'Diastolica', index: '-pressure', code: '3', type: '1' },
      { display: 'Ossigenazione', index: '-spo2', code: '5', type: '1' },
      { display: 'Passi', index: '-steps', code: '6', type: '3' },

      { display: 'Dolore', index: '-answers', code: 'dolore', type: '2' },
      { display: 'Fatica', index: '-answers', code: 'fatica', type: '2' },
      { display: 'Funzionalità fisica', index: '-answers', code: 'funzionalita_fisica', type: '2' },

      { display: 'Dolore 1', index: '-answers', code: '37', type: '2' },
      { display: 'Dolore 2', index: '-answers', code: '6', type: '2' },
      { display: 'Fatica 1', index: '-answers', code: '7', type: '2' },
      { display: 'Fatica 2', index: '-answers', code: '8', type: '2' },
      { display: 'Fatica 3', index: '-answers', code: '9', type: '2' },
      { display: 'Umore 1', index: '-answers', code: '26', type: '2' },
      { display: 'Umore 2', index: '-answers', code: '27', type: '2' },
      { display: 'Umore 3', index: '-answers', code: '28', type: '2' },
      { display: 'Umore 4', index: '-answers', code: '29', type: '2' },
      { display: 'Funzionalità fisica 1', index: '-answers', code: '43', type: '2' },
      { display: 'Funzionalità fisica 2', index: '-answers', code: '44', type: '2' },
      { display: 'Funzionalità fisica 3', index: '-answers', code: '45', type: '2' }
    ]
  };

  /**
   * Lista dei farmaci assegnabili al paziente
   */
  private optionFarmaci = {
    default: [
      { label: 'Regorafenib', value: 'Regorafenib' },
      { label: 'Capecitabina', value: 'Capecitabina' },
      { label: 'Lonsurf/Tas 102', value: 'Lonsurf/Tas 102' }
    ],
    TEST: [
      { label: 'Farmaco0', value: 'Farmaco0' },
      { label: 'Farmaco1', value: 'Farmaco1' },
      { label: 'Farmaco2', value: 'Farmaco2' }
    ]
  };

  private optionGraficiSintomi = {
    default: [
      { label: 'Sintomi', value: 'Sintomi' },
      { label: 'Diarrea', value: 'Diarrea' },
      { label: 'Stipsi', value: 'Stipsi' },
      { label: 'Nausea', value: 'Nausea' },
      // { label: 'Peso', value: 'Peso' },
      // { label: 'Temperatura', value: 'Temperatura' },
    ],
    "150910-IRCCS-Pascale": [
      { label: 'Sintomi', value: 'Sintomi' },
      { label: 'Diarrea', value: 'Diarrea' },
      { label: 'Stipsi', value: 'Stipsi' },
      // { label: 'Peso', value: 'Peso' },
      // { label: 'Temperatura', value: 'Temperatura' },
    ]
  };

  private tabsListaPazienti = {
    default: [
      { label: 'Pazienti presi in carico', icon: '' },
      // { label: 'Pazienti in Attivazione', icon: '' },
      { label: 'Pazienti Disattivati', icon: '' },
    ] as MenuItem[],
    NEMO: [
      { label: 'Pazienti presi in carico', icon: '' },
      { label: 'Pazienti in Valutazione', icon: '' },
      { label: 'Pazienti Disattivati', icon: '' },
    ] as MenuItem[],
    VIVISOL: [
      { label: 'Pazienti presi in carico', icon: '' },
      { label: 'Pazienti in Valutazione', icon: '' },
      { label: 'Pazienti Disattivati', icon: '' },
    ] as MenuItem[],
    AQUA: [
      { label: 'Pazienti presi in carico', icon: '' },
      { label: 'Pazienti in Valutazione', icon: '' },
      { label: 'Pazienti Disattivati', icon: '' },
    ] as MenuItem[],
    /*L2: [
      { label: 'Pazienti presi in carico', icon: '' },
      { label: 'Pazienti in Valutazione', icon: '' },
      { label: 'Pazienti Disattivati', icon: '' },
    ] as MenuItem[],*/
  };

  constructor() {
    this.configurazioni.push(new Configuration("VIVISOL", undefined, "https://riatlas.it/policy/nemo/paziente/termini_e_condizioni.html/",
      "https://riatlas.it/policy/nemo/medico/privacy_policy.html/", "https://riatlas.it/policy/nemo/paziente/privacy_policy.html/",
      "https://riatlas.it/policy/nemo/medico/cookie_policy.html/", this.findQuestionariAbilitatiByOrganizationName("NEMO")));

    this.configurazioni.push(new Configuration("NEMO", undefined, "https://riatlas.it/policy/nemo/paziente/termini_e_condizioni.html/",
      "https://riatlas.it/policy/nemo/medico/privacy_policy.html/", "https://riatlas.it/policy/nemo/paziente/privacy_policy.html/",
      "https://riatlas.it/policy/nemo/medico/cookie_policy.html/", this.findQuestionariAbilitatiByOrganizationName("NEMO")));

    this.configurazioni.push(new Configuration("AQUA", undefined, "https://riatlas.it/policy/nemo/paziente/termini_e_condizioni.html/",
      "https://riatlas.it/policy/nemo/medico/privacy_policy.html/", "https://riatlas.it/policy/nemo/paziente/privacy_policy.html/",
      "https://riatlas.it/policy/nemo/medico/cookie_policy.html/", this.findQuestionariAbilitatiByOrganizationName("NEMO")));

    this.configurazioni.push(new Configuration("RUGGI", "https://riatlas.it/policy/ruggi/medico/termini_e_condizioni.html/",
      "https://riatlas.it/policy/ruggi/paziente/termini_e_condizioni.html/", "https://riatlas.it/policy/ruggi/medico/privacy_policy.html/",
      "https://riatlas.it/policy/ruggi/paziente/privacy_policy.html/", "https://riatlas.it/policy/ruggi/medico/cookie_policy.html/",
      this.findQuestionariAbilitatiByOrganizationName("RUGGI")));

    this.configurazioni.push(new Configuration("150910-IRCCS-Pascale", "http://www.google.it", "http://www.google.it", "http://www.google.it",
      "http://www.google.it", "http://www.google.it", this.findQuestionariAbilitatiByOrganizationName("150910-IRCCS-Pascale")));

    this.configurazioni.push(new Configuration("L0", "http://www.google.it", "http://www.google.it", "http://www.google.it",
      "http://www.google.it", "http://www.google.it", this.findQuestionariAbilitatiByOrganizationName("150910-IRCCS-Pascale")));

    this.configurazioni.push(new Configuration("default", "http://www.google.it", "http://www.google.it", "http://www.google.it",
      "http://www.google.it", "http://www.google.it", this.findQuestionariAbilitatiByOrganizationName("default")));

    this.configurazioni.push(new Configuration("TEST", "http://www.riatlas.it", "http://www.riatlas.it", "http://www.riatlas.it",
      "http://www.riatlas.it", "http://www.riatlas.it", this.findQuestionariAbilitatiByOrganizationName("default")));
    this.configurazioni.push(new Configuration("USEMED", "http://www.riatlas.it", "http://www.riatlas.it", "http://www.riatlas.it",
      "http://www.riatlas.it", "http://www.riatlas.it", this.findQuestionariAbilitatiByOrganizationName("default")));

    this.configurazioni.push(new Configuration("USEMED", "http://www.google.it", "http://www.google.it", "http://www.google.it",
      "http://www.google.it", "http://www.google.it", this.findQuestionariAbilitatiByOrganizationName("USEMED")));

  }

  public getQuestionariAbilitatiByOrganizationName(name?: string): MenuItem[] {
    if (name == undefined) {
      name = this.getOrganizationFromUrl();
    }
    const qa = this.findConfigurationByOrganizationName(name).questionariAbilitati;
    return this.tabsQuestionari.filter(t => qa.includes(t.label));
  }

  public getSingoliQuestionariAbilitatiByOrganizationName(name?: string): string[] {
    return this.singoliQuestionariAbilitati[name] ?? this.singoliQuestionariAbilitati["default"];
  }

  /**
   * Restituisce la link dei cookie dell'organization calcolata tramite l'url
   * @returns Link dei cookie
   */
  public customizeCookie() {
    return this.findConfigurationByOrganizationName(this.getOrganizationFromUrl()).cookieLink;
  }

  /**
   * In base all'url restituisce il nome dell'organization.
   * @returns Nome dell'organization
   */
  public getOrganizationFromUrl() {
    if (window.location.href.includes('vivisol')) {
      return "VIVISOL";
    } else if (window.location.href.includes('aqua')) {
      return "AQUA";
    } else if (window.location.href.includes('ruggi')) {
      return "RUGGI";
    } else if (window.location.href.includes('nia') || window.location.href.includes('nemolab')) {
      return "NEMO";
    } else if (window.location.href.includes('app.riatlas')) {
      return "TEST2";
    } else if (window.location.href.includes('arketipo')) {
      return "ARKETIPO";
    } else if (window.location.href.includes('localhost')) {
      localStorage.setItem('privacy_user_v1_test', 'accepted');
      localStorage.setItem('cookie_policy_v1_test', 'accepted');
      return "TEST";
    } else if (window.location.href.includes('host.docker.internal')) {
      localStorage.setItem('privacy_user_v1_test', 'accepted');
      localStorage.setItem('cookie_policy_v1_test', 'accepted');
      return "TEST";
    } else if (window.location.href.includes('eda-cwa-frontend-collaudo')) {
      // Dato che le variabili non possono iniziare con numeri, nelle configurazioni: eda
      return "150910-IRCCS-Pascale";
    } else if (window.location.href.includes('usemed')) {
      return "USEMED";
    } else if (window.location.href.includes('riatlas.it')) {
      // Dato che le variabili non possono iniziare con numeri, nelle configurazioni: eda
      return "TEST";
    }
  }

  public getInformativaPaziente() {
    return this.findConfigurationByOrganizationName(this.getOrganizationFromUrl()).informativePatient;
  }

  public getInformativaMedico() {
    return this.findConfigurationByOrganizationName(this.getOrganizationFromUrl()).informativeDoctor;
  }

  public getTerminiCondizioniPaziente() {
    return this.findConfigurationByOrganizationName(this.getOrganizationFromUrl()).termsConditionsPatientLink;
  }

  public getTerminiCondizioniMedico() {
    return this.findConfigurationByOrganizationName(this.getOrganizationFromUrl()).termsConditionsDoctorLink;
  }

  private findConfigurationByOrganizationName(name: string) {
    return this.configurazioni.find(x => x.organizationName == name) ?? this.findConfigurationByOrganizationName("default");
  }

  private findQuestionariAbilitatiByOrganizationName(name: string) {
    return this.questionariAbilitati[name] ?? this.questionariAbilitati["default"];
  }

  public showChartHeaderByOrganizations(organization: string, chart: string) {
    const listaOrg = this.listChartHeader[organization] ?? this.listChartHeader["default"];
    return listaOrg.includes(chart) ? chart : undefined;
  }

  public getDefaultQuestionnaireNameByOrganizationName(name: string) {
    let d: any = this.defaultQuestionariGrafici[name] ?? this.defaultQuestionariGrafici["default"];
    return this.getQuestionariGraficiByName(d);
  }

  public getQuestionariGraficiByName(name: string) {
    return this.questionariGrafici[name];
  }

  public getOptDoloreByOrganizationName(name: string) {
    return this.optDolore[name] ?? this.optDolore["default"];
  }

  public getOpt2ByOrganizationName(name: string) {
    return this.opt2[name] ?? this.opt2["default"];
  }

  public getGraficiMonitoraggio(organizationName: string): any[] {
    return this.configurazioneMonitoraggio[organizationName] ?? this.configurazioneMonitoraggio["default"];
  }

  public getIndiciDownloadCSVConfiguration(organization) {
    return this.indiciSelectDownloadCSVMonitoraggio[organization] ?? this.indiciSelectDownloadCSVMonitoraggio.default;
  }

  public getOptionFarmaciByOrganization(organization) {
    return this.optionFarmaci[organization] ?? this.optionFarmaci["default"];
  }

  public getOptionGraficiSintomiByOrganization(organization) {
    return this.optionGraficiSintomi[organization] ?? this.optionGraficiSintomi["default"];
  }

  public getTabsListaPazientiByOrganization(organization) {
    return this.tabsListaPazienti[organization] ?? this.tabsListaPazienti["default"];
  }

  // TODO: In futuro ci sarà un endpoint per il recupero di queste informazioni
  public getClassiDispositivo() {
    return [
      { label: "Classe A", value: "classA" },
      { label: "Classe A sterile", value: "classAsterile" },
      { label: "Classe B", value: "classB" },
      { label: "Classe C", value: "classC" },
      { label: "Classe D", value: "classD" }
    ];
  }

  // TODO: In futuro ci sarà un endpoint per il recupero di queste informazioni
  public getTipiDiCollegamento() {
    return [{ label: "Bluetooth", value: "bluetooth" }, { label: "WiFi", value: "wifi" }, { label: "Cavo", value: "wired" }];
  }

  // TODO: In futuro ci sarà un endpoint per il recupero di queste informazioni
  public getTipiDiAlimentazione() {
    return [{ label: "Batteria", value: "battery" }, { label: "Cavo", value: "wired" }];
  }
}

export interface Questions {
  graficoName: string;
  topic: string;
  testo: string;
  code: string;
}

export class Configuration {
  public organizationName: string;
  public termsConditionsDoctorLink: string;
  public termsConditionsPatientLink: string;
  public informativeDoctor: string;
  public informativePatient: string;
  public cookieLink: string;
  public questionariAbilitati;


  constructor(organizationName: string, termsConditionsDoctorLink?: string, termsConditionsPatientLink?: string,
    informativeDoctor?: string, informativePatient?: string, cookieLink?: string, questionariAbilitati?) {
    this.organizationName = organizationName ?? "";
    this.termsConditionsDoctorLink = termsConditionsDoctorLink ?? "";
    this.termsConditionsPatientLink = termsConditionsPatientLink ?? "";
    this.informativeDoctor = informativeDoctor ?? "";
    this.informativePatient = informativePatient ?? "";
    this.cookieLink = cookieLink ?? "";
    this.questionariAbilitati = questionariAbilitati;
  }
}
