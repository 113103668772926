import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgcCookieConsentConfig, NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { endpoints } from 'src/endpoint/endpoints';
import { environment } from 'src/environments/environment';
import { PazientiService } from '../main/pazienti/service/pazienti.service';
import { PrivacyModalComponent } from '../modals/privacy-modal/privacy-modal.component';
import { AppService } from '../service/app.service';
import { CustomizationService } from '../service/customization.service';
import { TokenService } from '../service/token.service';
import { UserService } from '../service/user-service.service';
import { markAllAsTouched, updateValueAndValidity } from '../utils/utils';
import { NonLoggedUser } from './../model/non-logged-user';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  cookieConfig: NgcCookieConsentConfig = {
    "cookie": {
      "domain": "tinesoft.github.io"
    },
    "position": "bottom",
    "theme": "classic",
    "palette": {
      "popup": {
        "background": "#011a54",
        "text": "#ffffff",
        "link": "#ffffff"
      },
      "button": {
        "background": "#009479",
        "text": "#ffffff",
        "border": "transparent"
      }
    },
    "type": "opt-in",
    "content": {
      "message": "Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di cookie necessari al funzionamento ed utili alle finalità illustrate nella cookie policy. <br>Se vuoi saperne di più o negare il consenso a tutti o ad alcuni cookie, consulta la <a href=\"https://www.riatlas.it\">cookie policy</a>. <br>Cliccando sul bottone \"Accetta\", acconsenti all’uso dei cookie.",
      "dismiss": "Accetta",
      "allow": "Accetta",
      "deny": "Rifiuta",
      "link": "",
      "href": "https://cookiesandyou.com",
      "policy": "Cookie Policy"
    }
  };

  loginForm: FormGroup = this.fb.group({});

  visiblePopupRecuperoPassword = false;

  // @ViewChild('username', { static: true }) username: ElementRef;
  @ViewChild('password', { static: true }) password: ElementRef;

  incorrectLogin = false;
  incorrectLoginMessage;
  recuperoFromUsername: any;
  nonLoggedUser: NonLoggedUser;
  invalidUser = false;

  privacyVersion = "v1";
  cookieVersion = "v1";

  currentOrg = 'RIATLAS';

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  linkTermini;
  linkInformativa;

  enableFormStatus = false;

  privacyAccepted;



  constructor(private fb: FormBuilder, private http: HttpClient, private tokenService: TokenService, private router: Router,
    private render: Renderer2, private userService: UserService, private toastr: ToastrService,
    @Inject(DOCUMENT) document, private r: Renderer2, private pazienti: PazientiService, private appService: AppService,
    private dialogService: DialogService, private ccService: NgcCookieConsentService, private customizationService: CustomizationService) {
    r.addClass(document.body, 'login-page');
    // this.bodyTag.setAttribute('style', 'background-color: #343a40;');
    // r.setStyle(document.body, 'background-image', 'linear-gradient(#5C8DA6, #0F9782 , #2B7C7F)');
  }

  ngOnInit() {
    this.pazienti.resetPatient();
    this.privacyVersion = "v1_" + this.customizationService.getOrganizationFromUrl().toLocaleLowerCase();
    this.cookieVersion = "v1_" + this.customizationService.getOrganizationFromUrl().toLocaleLowerCase();
    this.linkTermini = this.customizationService.getTerminiCondizioniMedico();
    this.loginForm = this.fb.group({
      userName: ['', { validators: Validators.required, updateOn: 'change' }],
      password: ['', Validators.required],
      checkTerms: [false, this.linkTermini != undefined ? Validators.requiredTrue : []],
      checkPrivacy: [false, Validators.requiredTrue],
      checkUsage: [false, Validators.requiredTrue],
    });
    this.loginForm.valueChanges.subscribe(el => {
      this.incorrectLogin = false;
    });
    if (!this.tokenService.checkToken()) {
      if (this.userService.retriveUser() && this.userService.retriveUser().accountNotnew) {
        if (!localStorage.getItem('privacy_user_' + this.privacyVersion) || !localStorage.getItem('cookie_policy_' + this.cookieVersion)) {
          this.tokenService.deleteToken();
          this.userService.deleteUser();
          this.router.navigate(['/login']);
        } else {
          this.router.navigate(['/main/dashboard']);
        }
      }
    }

    if (window.location.href.includes('nia') || window.location.href.includes('nemolab')) {
      this.currentOrg = 'NEMO';
      this.r.setStyle(document.body, 'background-image', 'linear-gradient(#5C8DA6, #0F9782 , #2B7C7F)');
    } else {
      this.currentOrg = 'RIATLAS';
      this.r.setStyle(document.body, 'background-color', '#e7e9f1');
    }

    let linkPolicyCookie = this.customizationService.customizeCookie();

    this.ccService.getConfig().content.message = "Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di cookie necessari al funzionamento ed utili alle finalità illustrate nella cookie policy. <br>Se vuoi saperne di più o negare il consenso, consulta la <a href=\"" + linkPolicyCookie + "\">cookie policy</a>. <br><br>Cliccando sul bottone \"Accetta\", acconsenti all’uso dei cookie.";

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (event.status == "allow") {
          localStorage.setItem('cookie_policy_' + this.cookieVersion, 'accepted');
          this.enableFormStatus = true;
        }
      });

    try {
      this.ccService.destroy();
    }
    catch (err) {

    }

    if (!localStorage.getItem('cookie_policy_' + this.cookieVersion)) {
      this.ccService.init(this.ccService.getConfig());
      this.enableFormStatus = false;
    } else {
      this.enableFormStatus = true;
    }
    this.privacyAccepted = localStorage.getItem('privacy_user_' + this.privacyVersion);
    if (this.privacyAccepted) {
      this.loginForm.get('checkPrivacy').setValue(true);
      this.loginForm.get('checkUsage').setValue(true);
      this.loginForm.get('checkTerms').setValue(true);
    }

    this.enableForm();

    this.linkInformativa = this.customizationService.getInformativaMedico();

  }

  enableForm() {
    if (localStorage.getItem('privacy_user_' + this.privacyVersion) && localStorage.getItem('cookie_policy_' + this.cookieVersion)) {
      this.loginForm.enable();
    }
  }

  ngOnDestroy(): void {
    // this.bodyTag.removeAttribute('style');
    this.r.removeStyle(document.body, 'background-color');
    this.r.removeClass(document.body, 'login-page');
  }

  ngAfterViewInit(): void {
  }

  showPolicyModal() {
    const ref = PrivacyModalComponent.open(this.dialogService, null, '25%');
    const subscribePolicy = ref.onClose.subscribe((res: any) => {
      subscribePolicy.unsubscribe();
      //this.router.navigate(['/main/dashboard']);
      this.enableForm();
    });
  }

  obtainAccessToken() {
    markAllAsTouched(this.loginForm);
    updateValueAndValidity(this.loginForm);
    if (!this.loginForm.get('checkUsage').value || !this.loginForm.get('checkPrivacy').value) {
      this.incorrectLogin = true;
      this.incorrectLoginMessage = 'Per poter proseguire è necessario accettare l\'informativa sulla privacy e il trattamento dei dati ';
    }
    if (this.linkTermini !== undefined && !this.loginForm.get('checkTerms').value) {
      this.incorrectLogin = true;
      this.incorrectLoginMessage = 'Per poter proseguire è necessario accettare l\'informativa sulla privacy e il trattamento dei dati ';
    }
    if (!this.loginForm.invalid) {
      localStorage.setItem('privacy_user_' + this.privacyVersion, 'accepted');
      const params = new URLSearchParams();
      params.append('username', this.loginForm.get('userName').value.trim());
      params.append('password', this.loginForm.get('password').value);
      params.append('grant_type', 'password');
      params.append('client_id', 'resource_ad');

      this.http.post(environment.authServer + '/oauth/token', params.toString()).subscribe(data => {
        this.tokenService.saveToken(data);
        this.http.get(environment.authServer + '/api/me').subscribe(resp => {
          if (resp) {
            this.userService.saveUser(resp);
            if (this.userService.isPaziente()) {
              this.setInvalidWiew();
              this.tokenService.deleteToken();
              console.error('User Not Found');
              return;
            }

            if (!this.userService.isSuperAdmin() && this.userService.getCurrentOrganization() !== this.customizationService.getOrganizationFromUrl()) {
              this.incorrectLogin = true;
              this.incorrectLoginMessage = 'Username o password errati';
              this.logout();
              return;
            }

            if (!this.userService.isAccountNotnew()) {
              this.router.navigate(['global/password-change', { fromLogin: true }]);
            }
            this.pazienti.resetPatient();
            this.pazienti.setVisita(null);
            this.http.get(endpoints.getSections).toPromise().then((res: any) => {
              if (res) {
                this.appService.setListSections(res.object);
                this.appService.setPatientListFrom(null);
                if (this.userService.getUser() && localStorage.getItem('privacy_user_' + this.privacyVersion) && localStorage.getItem('cookie_policy_' + this.cookieVersion)) {
                  this.router.navigate(['/main/dashboard']);
                }
              }
            }).catch(err => {
              console.error('Errore nel caricamento della lista delle sezioni.');
            });
          }
        }, err => {
          console.error(err);
          this.router.navigate(['/login']);
          this.tokenService.deleteToken();
          this.toastr.error('Errore nella retrive dell\'utente.');
        });

      },
        err => {
          if (err.error.error === 'invalid_grant') {
            // this.setInvalidWiew();
            if (err.error.error_description === 'User credentials have expired') {
              this.incorrectLoginMessage = 'password scaduta';
              this.toastr.error('a breve ti verrà inviato un link per resettare la password');
            } else if (err.error.error_description === 'Bad credentials') {
              this.incorrectLoginMessage = 'Username o password errati';
              // this.setInvalidWiew();
              console.error(err);
            }

          } else {
            this.incorrectLoginMessage = 'Username o password errati';
            // this.setInvalidWiew();
            console.error(err);
          }
          this.incorrectLogin = true;
        });
    } else {
      // this.setInvalidWiew();
    }
  }

  setInvalidWiew() {
    this.render.addClass(this.password.nativeElement, 'is-invalid');
  }

  showPopupRecuperoPassword() {
    this.visiblePopupRecuperoPassword = true;
  }

  logout() {
    this.tokenService.deleteToken();
    this.userService.deleteUser();
  }

  recuperoPassword() {
    this.invalidUser = false;
    this.nonLoggedUser = new NonLoggedUser();
    this.nonLoggedUser.username = this.recuperoFromUsername;
    this.http.put(endpoints.recuperaPassword, this.nonLoggedUser).toPromise().then((resp: any) => {
      if (resp) {
        if (resp.status === 'OK') {
          this.visiblePopupRecuperoPassword = false;
          this.toastr.success('A breve ti verrà inviato un link per resettare la password');
        } else {
          if (resp.descr == "Utente non attivo") {
            this.visiblePopupRecuperoPassword = false;
            this.toastr.error('Non è possibile recuperare la password dell\'utente inserito perchè non ha ancora effettuato il primo accesso');
          } else {
            this.invalidUser = true;
            this.toastr.error('Username non valido');
          }

        }
      }
    })
      .catch(err => {
        console.error(err);
        this.toastr.error('Errore nella richiesta di recupero password');
      });
  }
}
