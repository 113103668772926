import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/dynamicdialog';
import { endpoints } from 'src/endpoint/endpoints';
import { AlberoICComponent } from '../albero-ic/albero-ic.component';

@Component({
  selector: 'app-gestione-icd',
  templateUrl: './gestione-icd.component.html',
  styleUrls: ['./gestione-icd.component.scss']
})
export class GestioneICDComponent implements OnInit {

  selected;
  listIcd = [];

  @Output() saved = new EventEmitter<any>();

  constructor(private dialogService: DialogService, private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit() {
  }

  show() {
    const input = {
      alberoType: 'ICD'
    };
    const ref = AlberoICComponent.open(this.dialogService, input, '70%', '70%', 20000);
    ref.onClose.subscribe((res: any) => {
      this.selected = res;
    });
  }

  search(event) {
    this.http.post(endpoints.searchICD, event.query).toPromise()
      .then((resp: any) => {
        this.listIcd = new Array();
        if (resp && resp.hits && resp.hits.hits && resp.hits.hits.length > 0) {
          resp.hits.hits.map(element => {
            const el = {
              data: '' + element._source.codice,
              label: element._source.codice_completo.replace('-', ' ')
            };
            this.listIcd.push(el);
          });
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  aggiungi() {
    if (this.selected) {
      if (this.selected.label) {
        this.selected.label = this.selected.label.replace(/^\d[0-9]* /g, '');
        this.saved.emit(this.selected);
        this.selected = null;
      } else {
        this.toastr.warning('Selezionare un ICD');
      }
    }
  }
}
