import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { endpoints } from 'src/endpoint/endpoints';

@Injectable({
  providedIn: 'root'
})
export class PazientiService {

  patientId: BehaviorSubject<number>;

  patient: BehaviorSubject<any>;

  patientOauthId = null;

  visita: BehaviorSubject<any>;

  dateFromTo: BehaviorSubject<any>;

  aggiornaCondCli: EventEmitter<boolean>;

  aggiornaStatSal: EventEmitter<boolean>;

  aggiornaListaPazienti: EventEmitter<boolean>;

  /** Nome dell'organizzazione di cui fa parte il paziente */
  patientOrganizationName = undefined;

  constructor(private http: HttpClient) {
    this.patientId = new BehaviorSubject(null);
    this.patient = new BehaviorSubject(null);
    this.dateFromTo = new BehaviorSubject(null);
    this.visita = new BehaviorSubject({});
    this.aggiornaCondCli = new EventEmitter<boolean>();
    this.aggiornaStatSal = new EventEmitter<boolean>();
    this.aggiornaListaPazienti = new EventEmitter<boolean>();
    // this.getPatientFormServer();
  }

  getPatientNameOrEmpty(pat = this.patient.value) {
    let toReturn = this.getPatientName(pat);
    if (toReturn.includes("**")) {
      return "";
    }
    return toReturn;
  }

  getPatientSurnameOrEmpty(pat = this.patient.value) {
    if (pat.cognome != undefined) {
      return pat.cognome ?? "";
    }
    let toReturn = this.getPatientSurname(pat);
    if (toReturn.includes("**")) {
      return "";
    }
    return toReturn;
  }

  getPatientName(pat = this.patient.value) {
    if (pat.nome != undefined) {
      if (pat.nome.length == 0) {
        pat.nome = null;
      }
      return pat.nome ?? '**********';
    }
    if (pat != null && pat.name != undefined) {
      let na = pat.name.filter(x => x.use == undefined);
      if (na != undefined && na.length > 0) {
        na = na[0];
        if (na.given) {
          return na.given[0] ?? '**********';
        }
      }
    }
    return '**********';
  }

  getPatientSurname(pat = this.patient.value) {
    if (pat.cognome != undefined) {
      return pat.cognome ?? '**********';
    }
    if (pat != null && pat.name != undefined) {
      let na = pat.name.filter(x => x.use == undefined);
      if (na != undefined && na.length > 0) {
        na = na[0];
        if (na.family) {
          return na.family ?? '**********';
        }
      }
    }
    return '**********';
  }

  resetPatient() {
    localStorage.removeItem('patient');
    localStorage.removeItem('patientId');
    this.patient.next(null);
    this.patientId.next(null);
    this.patientOrganizationName = undefined;
  }

  setPatientId(patientId) {
    localStorage.setItem('patientId', patientId);
    this.patientId.next(patientId);
  }

  getPatientId() {
    if (this.patientId.getValue()) {
      return this.patientId.getValue();
    } else {
      return localStorage.getItem('patientId');
    }
  }

  getPatientIdFromPatientObject(patient) {
    if (patient.nome != undefined) {
      return patient.referenceId.split("/")[1];
    }
    let ids = patient.identifier.filter(x => x.use != undefined && x.use == "usual" && x.code == undefined && x.system == undefined);
    if (ids != undefined && ids.length > 0) {
      return "" + ids[0].value;
    }
    return "";
  }

  setPatient(patient) {
    if (patient.message != undefined) {
      patient = patient.message;
    }
    localStorage.setItem('patient', JSON.stringify(patient));
    localStorage.setItem('patientId', patient.id);
    this.patient.next(patient);
    this.retrieveAndSetPatientOrganization("Patient/" + patient.id);
  }

  setOauthPatientId(id) {
    this.patientOauthId = id;
  }

  getOauthPatientId() {
    return this.patientOauthId;
  }

  getPatient() {
    if (this.patient.getValue()) {
      return this.patient.getValue();
    } else {
      return JSON.parse(localStorage.getItem('patient'));
    }
  }

  setVisita(visita) {
    this.visita.next(visita);
  }

  getVisita() {
    return this.visita.getValue();
  }

  getVisitaObject() {
    return this.visita;
  }

  aggiornamentoCondCli(input) {
    this.aggiornaCondCli.emit(input);
  }

  getAggiornaCondCli(): EventEmitter<boolean> {
    return this.aggiornaCondCli;
  }

  aggiornamentoStatSal(input) {
    this.aggiornaStatSal.emit(input);
  }

  getAggiornaStatSal(): EventEmitter<boolean> {
    return this.aggiornaStatSal;
  }

  getaggiornaListaPazienti(): EventEmitter<boolean> {
    return this.aggiornaListaPazienti;
  }

  // NOTA: Non viene mai chiamato
  aggiornaVisita() {
    this.http.get(endpoints.getClinicalImpressionByClinicalImpressionIdVisite + '/' + this.getVisita().id).toPromise()
      .then((resp: any) => {
        if (resp && resp.resourceType !== 'OperationOutcome') {
          this.setVisita(resp);
        }
      });
  }

  getPatientFromServerByPatientId(patientId) {
    if (!patientId) {
      console.error('pazienti service: patientId non settato.');
      return undefined;
    }
    return this.http.get(endpoints.getPatientByPatientId + patientId).toPromise();
  }

  getPatientFromServer() {
    // let subPatId = this.patientId.subscribe(id => {
    this.patientId.subscribe(id => {
      if (id) {
        if (id === -1) {
          return undefined;
        }
        this.http.get(endpoints.getPatientByPatientId + id).toPromise().then((resp: any) => {
          if (resp && resp.success == "1") {
            this.setPatient(resp.message);
          }
          // subPatId.unsubscribe();
        }).catch(err => {
          console.error('pazienti service ', err);
          if (err.status == 403) {
            console.error("Non si dispone dei permessi necessari per eseguire questa operazione.", err);
            return;
          }
          // subPatId.unsubscribe();
          // this.toastr.error('Errore nel retrive del Paziente');
        });
      }
    });
  }

  getDateFromTo() {
    return this.dateFromTo.getValue();
  }

  setDateFromTo(dateFromTo) {
    this.dateFromTo.next(dateFromTo);
  }

  public isMyPractitioner(patient, practitioner): boolean {
    if (patient.resource) {
      // Risorsa del FHIR
      patient = patient.resource;
      if (patient.generalPractitioner === undefined || (patient.generalPractitioner.filter(p => p.reference === practitioner.referenceId)).length === 0) {
        return false;
      }
    } else {
      // Risorsa dell'oauth
      if (patient.practitionersRef === undefined || (patient.practitionersRef.split(";").filter(p => p === practitioner.referenceId)).length === 0) {
        return false;
      }
    }
    return true;
  }

  getListaICD(patientId): any {
    this.getListaICDRequest(patientId).then((resp: any) => {
      let condizioniCliniche = new Array();
      condizioniCliniche = resp;
      condizioniCliniche = condizioniCliniche.filter(elem => {
        if (elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaRemota' ||
          elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaProssima') {
          return false;
        } else {
          return true;
        }
      });
      return condizioniCliniche;
    }).catch(err => {
      console.error(err);
    });
  }

  getListaICDRequest(patientId) {
    return this.http.get(endpoints.getCondCli + '?patientId=' + patientId).toPromise();
  }

  getCondizioniCliniche(patientId) {
    return this.http.get(endpoints.getCondizioniCliniche + '?patientId=' + patientId).toPromise();
  }

  getAnamnesiPatologicaRemotaRequest(patientId) {
    return this.http.get(endpoints.getAnamnesiPatologicaRemota + '?patientId=' + patientId).toPromise();
  }

  getOauthIdRequestByReferenceIdFromOauth(referenceId?: string) {
    if (referenceId == undefined) {
      referenceId = this.patientId + '';
    }
    if (referenceId == undefined) {
      return undefined;
    }
    return this.http.get(endpoints.userbyreferenceid + '?referenceid=Patient/' + referenceId).toPromise();
  }

  /**
   * Utilizzando i dati del paziente ricevuto dall'oauth, crea un paziente che
   * ha la stessa struttura del paziente nel fhir.
   * @param oauthPatient risorsa ricevuta dall'oauth
   * @returns paziente fittizio che rispecchia la struttura del fhir con i dati dell'oauth
   */
  public createPatientObjectFromOauthPatient(oauthPatient) {
    let patient: any = {
      id: oauthPatient.referenceId.split("/")[1],
      identifier: [
        {
          use: 'usual',
          type: { text: oauthPatient.username },
          value: oauthPatient.username
        }
      ],
      name: [
        {
          use: 'usual',
          given: [oauthPatient.username]
        }
      ],
      generalPractitioner: []
    };
    for (const practitioner of oauthPatient.practitionersRef.split(";")) {
      // lo split potrebbe generare una stringa vuota
      if (practitioner.length == 0) continue;
      patient.generalPractitioner.push({ reference: practitioner });
    }

    return patient;

  }

  /**
 * Recupera (tramite getPatientOrganizationAsPromiseById) il nome dell'organization del paziente attraverso il suo id e lo memorizza.
 * @param referenceId id del paziente nel FHIR. Se è Patient/id allora viene preso solo l'id
 */
  private retrieveAndSetPatientOrganization(referenceId) {
    if (referenceId.includes("/")) {
      referenceId = referenceId.split("/")[1];
    }
    this.getPatientOrganizationAsPromiseById(referenceId).then((resp: any) => {
      // Setto l'organization del paziente
      this.patientOrganizationName = resp.desc;
    }).catch(error => {
      console.error("Errore nel retrieve dell'organizzazione del paziente", error);
    });
  }

  public getPatientOrganization() {
    return this.patientOrganizationName;
  }

  /**
   * Richiede al server, se necessario, il nome dell'organization del paziente.
   * @param id identificativo del paziente del FHIR, quindi la parte destra di Patient/<id>
   * @returns oggetto Promise con dentro il nome dell'organization se già c'è oppure la chiamata all'oauth
   */
  public getPatientOrganizationAsPromiseById(id) {
    if (id == this.patientId && this.patientOrganizationName != undefined) {
      // Già ho quello che serve
      return Promise.resolve({ "status": "OK", "desc": this.patientOrganizationName, "object": null });
    }
    // Lo richiedo al server
    return this.http.get(endpoints.getOrganizationNameByUserReferenceId + "/Patient/" + id).toPromise();
  }

  public getPatientIdentifier(fhirPatient) {
    return (fhirPatient.identifier.find(i => i.use === "usual")).value
  }

}
