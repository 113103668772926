<!-- <button (click)="creaQuestionario()">Crea questionario</button> -->
<!-- <button (click)="aggiornaQuestionario()">Aggiorna questionario</button> -->
<h1 class="mt-3" style="text-align:center">Scala MMSE</h1>
<div class="p-fluid mt-3">
  <div class="row mt-3" *ngFor="let domanda of domande">
    <ng-container *ngIf="domanda.testo.includes('somministrabile') || +domandaSomministrabile.risposta == 1">
      <div class="col-md-12 row mt-2" *ngIf="domanda.tipo === 'integer' || domanda.tipo === 'boolean'">
        <div class="col-md-7">
          <h5 class="ml-2" *ngIf="domanda.testo !== 'Coefficiente di aggiustamento'">{{domanda.testo}}</h5>
          <h4 class="ml-2" *ngIf="domanda.testo === 'Coefficiente di aggiustamento'">{{domanda.testo}}</h4>
        </div>
        <div class="col-md-5 custom-slider" *ngIf="domanda.testo !== 'Coefficiente di aggiustamento'">
          <ng5-slider style="white-space: nowrap;" [options]="domanda.options" [(ngModel)]="domanda.risposta"
            (click)="dirty = true">
          </ng5-slider>
        </div>
        <div class="col-md-12 row" *ngIf="domanda.testo === 'Coefficiente di aggiustamento'">
          <div class="col-md-6 mt-2">
            <h5 class="ml-2">Intervallo di et&agrave;</h5>
          </div>
          <div class="col-md-6 mt-2">
            <p-selectButton [options]="intervalliEta" optionLabel="label" [disabled]="!checkPermessoModifica()"
              [(ngModel)]="intervalloEtaSelezionato" (ngModelChange)="dirty = true">
            </p-selectButton>
          </div>

          <div class="col-md-6 mt-2">
            <h5 class="ml-2">Anni di scolarizzazione</h5>
          </div>
          <div class="col-md-6 mt-2">
            <p-selectButton [options]="anniScolarizzazione" optionLabel="label" [disabled]="!checkPermessoModifica()"
              [(ngModel)]="anniScolarizzazioneSelezionati" (ngModelChange)="dirty = true">
            </p-selectButton>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-3" *ngIf="domanda.tipo === 'quantity'">
        <h4>{{domanda.testo}}: {{domanda.risposta || '-'}} {{!domanda.testo.includes('corretto') ? "/30" : ""}}</h4>
      </div>
    </ng-container>
  </div>
  <div class="row" style="margin-top: 50px;">
    <div class="col-md-12">
      <div class="float-right" style="margin-right: 15px; margin-bottom: 15px;">
        <button *ngIf="checkPermessoModifica()" pButton label="Salva" icon="fa fa-save" iconPos="left"
          class="p-button-success" type="button" (click)="salva()"></button>
      </div>
    </div>
  </div>
</div>