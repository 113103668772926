<h3 style="text-align: center;">Spuntare i parametri vitali da monitorare e definire le soglie</h3>
<form [formGroup]="monitoringForm">
    <!-- <div class="row">
        <div class="col-md-12 custom-slider">
            <label>Soglia sistolica</label>
            <ng5-slider [options]="options" formControlName="diastolic-threshold"></ng5-slider>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 custom-slider mt-4">
            <label>Soglia diastolica</label>
            <ng5-slider [options]="options" formControlName="systolic-threshold"></ng5-slider>
        </div>
    </div> -->
    <div class="row">
        <div class="col-md-12 custom-slider mt-4">
            <p-checkbox label="Frequenza cardiaca" (onChange)="unableSlider2($event)" formControlName="check-box1"
                [binary]="true">
            </p-checkbox>
            <ng5-slider [options]="optionsF" formControlName="8867-4"
                class="{{optionsF.disabled == false ? '':'sliderDisabilitato'}}">
            </ng5-slider>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 custom-slider mt-4 pt-2">
            <p-checkbox label="Ossigenazione" (onChange)="unableSlider1($event)" formControlName="check-box2"
                [binary]="true">
            </p-checkbox>
            <ng5-slider [options]="optionsO" formControlName="59408-5"
                class="{{optionsO.disabled == false ? '':'sliderDisabilitato'}}"></ng5-slider>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 custom-slider mt-4 pt-2">
            <p-checkbox label="Sistolica" (onChange)="unableSlider4($event)" formControlName="check-box3"
                [binary]="true"></p-checkbox>
            <ng5-slider [options]="optionsS" formControlName="8480-6"
                class="{{optionsS.disabled == false ? '':'sliderDisabilitato'}}"></ng5-slider>

        </div>
    </div>
    <div class="row">
        <div class="col-md-12 custom-slider mt-4 pt-2">
            <p-checkbox label="Diastolica" (onChange)="unableSlider3($event)" formControlName="check-box4"
                [binary]="true"></p-checkbox>
            <ng5-slider [options]="optionsD" formControlName="8462-4"
                class="{{optionsD.disabled == false ? '':'sliderDisabilitato'}}"></ng5-slider>
        </div>
    </div>
    <!--div class="row">
        <div class="col-md-12 custom-slider mt-4 pt-2">
            <label>Dolore</label>
            <ng5-slider [options]="options3" formControlName="pain"></ng5-slider>
        </div>
    </div-->
    <div class="row mt-5">
        <div class="col-md-12">
            <div class="float-right">
                <button *ngIf="checkPermessoModifica()" pButton label="Salva" icon="fa fa-save" iconPos="left"
                    class="p-button-success" type="button" (click)="salva()"></button>
            </div>
        </div>
    </div>
</form>