import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApexOptions } from 'apexcharts';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../../service/pazienti.service';
import { DateFromTo } from '../Model/date-from-to';
import { SerieType } from '../Utils/grafici-utils';

@Component({
  selector: 'app-grafici-pulse-oximeter-data-details',
  templateUrl: './grafici-pulse-oximeter-data-details.component.html',
  styleUrls: ['./grafici-pulse-oximeter-data-details.component.scss']
})
export class GraficiPulseOximeterDataDetailsComponent implements OnInit {
  @Input('date') set date(date: DateFromTo) {
    this.dateRange = date;
    this.getDateForPulseOximeterDropdown();
    this.reset();
    this.finalInit();
    if (this.chart1 && this.chart2) {
      this.chart1.updateOptions(this.chartOptions1, false, false, false);
      this.chart2.updateOptions(this.chartOptions2, false, false, false);
    }
    // FUNZIONE AVG
    //this.resetAvg();
  }
  @Input() annotations: ApexAnnotations;

  @ViewChild('chart1', { static: true }) chartRef1: ElementRef;
  @ViewChild('chart2', { static: true }) chartRef2: ElementRef;
  chart1: ApexCharts;
  chart2: ApexCharts;

  stroke: ApexStroke = {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    width: 3,
    dashArray: 0,
  };

  bpChartArray: SerieType =
    {
      name: 'Pulsossimetro',
      color: ['#008FFB', '#f70d1a'],
      serie: [],
      display: ['O2', 'HR'],
      type: ['area', 'area'],
      maxyaxis: 250,
      stroke: this.stroke,
      markers: false,
      fill: true,
    };
  chartOptions1;
  chartOptions2;
  // FUNZIONE AVG
  //labelPO;
  //avgHr = 0;
  //avgO2 = 0;
  chartArr = new Array<SerieType>();

  constructor(private http: HttpClient, private toastr: ToastrService, private patientService: PazientiService) { }

  labelPulseOximeter = [];
  data = [];
  dataO2 = [];
  dataHr = [];
  timestamp = [];
  minuteCounter = 0;
  opt = [];
  options;
  selected;
  dateRange;
  datePipe = new DatePipe('en-US');
  go: boolean = false;

  ngOnInit() {
    this.finalInit();
    delay(500).then(() => {
      this.go = true;
      this.getDateForPulseOximeterDropdown();
    });
  }
  // FUNZIONE AVG
  /*
  resetAvg() {
    this.labelPO = undefined;
  }
  */
  dropdownChange(timestamp) {
    if (this.timestamp.length != 0) {
      this.reset();
    }
    this.minuteCounter = 0;
    this.http.get(endpoints.getPulseOximeter + timestamp.value).toPromise().then((response: any) => {

      for (let data of response) {
        this.data = JSON.parse(data.value);
        if (data.type_measure === "5") {
          for (let value of this.data) {
            this.dataO2.push(value.value);
            this.timestamp.push(value.timestamp);
          }
        } else {
          for (let value of this.data) {
            this.dataHr.push(value.value);
          }
        }
      }
      // FUNZIONE AVG
      //this.avgHr = this.dataHr.reduce((a, b) => a + b, 0) / this.dataHr.length;
      //this.avgO2 = this.dataO2.reduce((a, b) => a + b, 0) / this.dataO2.length;
      //this.labelPO = [{ timestampOperation: timestamp.value, hr: this.avgHr, o2: this.avgO2 }];
      this.finalInit();

      if (this.chart1) {
        this.chart1.updateOptions(this.chartOptions1, false, false, false);
        // ApexCharts.exec(id,'updatethis.chartOptions1',options)
      } else {
        this.chart1 = new ApexCharts(this.chartRef1.nativeElement, this.chartOptions1);
        this.chart1.render();
      }
      if (this.chart2) {
        this.chart2.updateOptions(this.chartOptions2, false, false, false);
        // ApexCharts.exec(id,'updatethis.chartOptions1',options)
      } else {
        this.chart2 = new ApexCharts(this.chartRef2.nativeElement, this.chartOptions2);
        this.chart2.render();
      }

      this.chartOptions1.yaxis = [
        {
          max: 250,
          min: 0,
          enabled: true,
          seriesName: 'HR',
          title: {
            text: 'HR',
            style: { color: '#f70d1a' }
          },
          labels: {
            style: {
              colors: '#f70d1a'
            }
          },
          tooltip: {
            show: false
          }
        },
        {
          max: 100,
          min: 0,
          opposite: true,
          seriesName: 'O2',
          labels: {
            style: {
              colors: '#008FFB'
            }
          },
          title: {
            text: 'O2',
            style: { color: '#008FFB' }
          },
          tooltip: {
            show: false
          }
        }];

      if (this.chart1) {
        this.chart1.updateOptions(this.chartOptions1, false, false, false);
        // ApexCharts.exec(id,'updatethis.chartOptions1',options)
      } else {
        this.chart1 = new ApexCharts(this.chartRef1.nativeElement, this.chartOptions1);
        this.chart1.render();
      }

      //ApexCharts.exec("chartObj", "updateOptions", this.chartOptions1);


    }).catch((error) => {
      console.error("Errore pulsossimetro ", error)
      this.toastr.error("Errore nel recupero valori pulsossimetro ");
    });
  }
  getDateForPulseOximeterDropdown() {
    if (!this.go) {
      return;
    }
    this.opt = [];
    this.opt.push({ value: '', label: 'Seleziona una data', disabled: true });
    let from = new Date(this.dateRange.dateFrom).getTime();
    let to = new Date(this.dateRange.dateTo).getTime()
    this.http.get(endpoints.getDatePulseOximeter + this.patientService.getPatientId() + '/' + from + '/' + to).toPromise().then((response: Array<any>) => {
      response.forEach(timestamp => {
        this.opt.push({ label: this.datePipe.transform(timestamp.toString(), 'dd/MM/YYYY [HH:mm:ss]'), value: new Date(timestamp).getTime() });
      });
      this.minuteCounter = 0;
      if (this.opt.length > 1) {
        this.opt = this.sortByDate(this.opt);
      }
    }).catch((err) => {
      if (err.status == 404) {
        return;
      }
      this.toastr.error("Errore nel recupero date Pulsossimetro")
    });

  }
  sortByDate(array) {
    return array.sort((a, b) => <any>new Date(b.value) - <any>new Date(a.value));
  }
  getDataO2() {
    let arr = [];
    this.dataO2.forEach((data, index) => {
      arr.push([this.timestamp[index], data]);
    });
    return arr;
  }
  getDataHr() {
    let arr = [];
    this.dataHr.forEach((data, index) => {
      arr.push([this.timestamp[index], data]);
    });
    return arr;
  }
  reset() {
    this.data = [];
    this.dataO2 = [];
    this.dataHr = [];
    this.timestamp = [];
    this.labelPulseOximeter = [];
  }
  finalInit() {
    this.annotations = {
      xaxis: this.buildXLineTicks()
    }
    this.chartOptions1 = {
      annotations: this.annotations,
      series: [
        {
          name: "HR",
          data: this.getDataHr()
        },
        {
          name: "O2",
          data: this.getDataO2(),
        }
      ],
      chart: {
        id: "chart1",
        type: "area",
        height: 230,
        toolbar: {
          autoSelected: "pan",
          show: false
        },
        zoom: {
          enabled: false,
          autoScaleYaxis: false
        }
      },
      tooltip: {
        x: {
          format: "dd/MM/yyyy [HH:mm:ss]"
        }
      },
      colors: ["#f70d1a", "#008FFB"],
      stroke: {
        width: 3
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: 1
      },
      markers: {
        size: 0
      },
      xaxis: {
        type: "datetime",
        tickPlacement: 'on',
        max: this.setMax(),
        labels: {
          datetimeUTC: false
        }
      },
      yaxis: [
        {
          max: 250,
          enabled: true,
          seriesName: 'HR',
          title: {
            text: 'HR',
            style: { color: '#f70d1a' }
          },
          labels: {
            style: {
              colors: '#f70d1a'
            }
          },
          tooltip: {
            show: false
          }
        },
        {
          max: 100,
          opposite: true,
          seriesName: 'O2',
          labels: {
            style: {
              colors: '#008FFB'
            }
          },
          title: {
            text: 'O2',
            style: { color: '#008FFB' }
          },
          tooltip: {
            show: false
          }
        }],
    };
    this.chartOptions1.tooltip.shared = true;
    this.chartOptions1.tooltip.intersect = false;
    this.chartOptions1.tooltip.followCursor = true;


    this.chartOptions1.tooltip.fixed = {
      enabled: true,
      position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
      offsetY: 30,
      offsetX: 60
    };

    this.chartOptions2 = {
      legend: {
        show: false
      },
      series: [
        {
          name: "HR",
          data: this.getDataO2()
        },
        {
          name: "O2",
          data: this.getDataHr()
        }
      ],
      chart: {
        events: {
          brushScrolled: (chartContext, { xaxis, yaxis }) => {


            chartContext.options.yaxis = [
              {
                max: 250,
                min: 0,
                enabled: true,
                seriesName: 'HR',
                title: {
                  text: 'HR',
                  style: { color: '#f70d1a' }
                },
                labels: {
                  style: {
                    colors: '#f70d1a'
                  }
                },
                tooltip: {
                  show: false
                }
              },
              {
                max: 100,
                min: 0,
                opposite: true,
                seriesName: 'O2',
                labels: {
                  style: {
                    colors: '#008FFB'
                  }
                },
                title: {
                  text: 'O2',
                  style: { color: '#008FFB' }
                },
                tooltip: {
                  show: false
                }
              }];

            if (this.chart1) {
              this.chart1.updateOptions(chartContext.options, false, false, false);
              // ApexCharts.exec(id,'updatechartContext',options)
            } else {
              this.chart1 = new ApexCharts(this.chartRef1.nativeElement, chartContext.options);
              this.chart1.render();
            }
          }
        },
        id: "chart2",
        height: 130,
        type: "area",
        brush: {
          target: "chart1",
          enabled: true,
          autoScaleYaxis: false
        },
        selection: {
          enabled: true,
          xaxis: {
            min: this.timestamp[0],
            max: this.setMax()
          }
        },
      },
      colors: ["#f70d1a", "#008FFB"],
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.91,
          opacityTo: 0.1
        }
      },
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false,
        },
        labels: {
          datetimeUTC: false
        }
      },
      yaxis: [{
        max: 250,
        seriesName: 'HR',
        labels: {
          show: false
        },
      },
      {
        max: 100,
        seriesName: 'O2',
        labels: {
          show: false
        },
      }
      ]
    };
  }
  buildXLineTicks() {
    let xLineTicks = [];
    for (let i = 0; i < this.timestamp.length; i = i + 60) {
      xLineTicks.push(this.buildLines(i));
    }
    return xLineTicks;
  }
  buildLines(index) {
    if (index != 0 && index % 60 == 0) {
      this.minuteCounter++;
      return {
        x: this.timestamp[index],
        strokeDashArray: 0.5,
        borderColor: '#000000',
        label: {
          orientation: 'horizontal',
          offsetY: -20,
          style: {
            color: '#000000',
          },
          text: this.minuteCounter + " min"
        }
      }
    }
  }
  setMax() {
    if (this.timestamp && this.timestamp.length < 300) {
      return this.timestamp[this.timestamp.length - 1];
    }
    return this.timestamp[0] + 300000;
  }
}
export type ChartOptions = {
  options: ApexOptions;
  series: ApexAxisChartSeries;
  seriesType: SerieType[];
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
};
function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}