<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Dashboard</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/main">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard</li>
                </ol>
            </div>
        </div>
    </div><!-- /.container-fluid -->
</section>
<section class="content mycontent">
    <div class="container-fluid">

        <div class="col-md-12">

            <!-- /.content-header -->

            <!-- Main content -->

            <div class="container-fluid">
                <!-- Small boxes (Stat box) -->
                <div class="row">

                    <div class="col-lg-6 col-6">
                        <!-- small box -->
                        <div class="small-box bg-box-blue">
                            <div class="inner">
                                <div class="mt-3 ml-3">
                                    <h3 style="font-size: 40px;">{{totalPatient}}</h3>

                                    <p style="font-size: 20px;">Pazienti alla presa in carico</p>
                                </div>
                            </div>
                            <div class="icon mt-1 mr-1" style="color:white !important;">
                                <i class="fas fa-users"></i>
                            </div>
                            <a href="javascript:void(0)" (click)="goToListPatient()"
                                class="small-box-footer small-box-footer-blue">Vai alla lista <i
                                    class="fa fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                    <!-- ./col -->
                    <div class="col-lg-6 col-6">
                        <!-- small box -->
                        <div class="small-box bg-box-blue">
                            <div class="inner">
                                <div class="mt-3 ml-3">
                                    <h3 style="font-size: 40px;">{{numeroPazientiDisattivati}}</h3>

                                    <p style="font-size: 20px;">Pazienti disattivati</p>
                                </div>
                            </div>
                            <div class="icon mt-1 mr-1" style="color:white !important;">
                                <i class="fas fa-users"></i>
                            </div>
                            <a href="javascript:void(0)" (click)="goToListPatient()"
                                class="small-box-footer small-box-footer-blue">Vai alla lista <i
                                    class="fa fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                    <!-- ./col -->
                    <!-- ./col -->
                </div>
                <!-- /.row -->
                <!-- Main row -->


                <div class="row">
                    <!-- Left col -->
                    <div class="col-md-2" *ngIf="!checkPermessoLetturaVisite()"></div>
                    <section
                        [ngClass]="checkPermessoLetturaVisite() ? 'col-lg-6 connectedSortable p-sortable' : 'col-lg-8 connectedSortable p-sortable'">
                        <!-- Custom tabs (Charts with tabs)-->
                        <div class="card card-primary">
                            <div class="card-header bg-primary bg-primary">
                                <h3 class="card-title">Ultimi pazienti inseriti</h3>


                            </div>
                            <!-- /.card-header -->
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                    <table class="table m-0">
                                        <thead>
                                            <tr>
                                                <th>Identificativo</th>
                                                <th>Nome e Cognome</th>
                                                <th>Sesso</th>
                                                <th>Data di nascita</th>
                                                <th *ngIf="checkPermessoLetturaVisite()">Dettagli</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngIf="patients.length == 0">
                                                <td colspan="5">Nessun paziente inserito</td>
                                            </tr>
                                            <tr *ngFor="let patient of patients">
                                                <td>{{patient.identifier}}</td>
                                                <td>{{patient.nome}} {{patient.cognome}}</td>
                                                <td>{{patient.gender}}</td>
                                                <td>{{patient.birthDate}}</td>
                                                <td><button pButton *ngIf="checkPermessoLetturaVisite()" type="button"
                                                        (click)="goToDettagli(patient.id)" label="Visualizza"
                                                        class="p-button-primary"></button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- /.table-responsive -->
                            </div>
                            <!-- /.card-body -->
                            <div class="card-footer clearfix">
                                <button pButton type="button" (click)="goToListPatient()" label="Vedi Tutti"
                                    class="p-button-primary float-right"></button>
                            </div>


                            <!-- /.card-footer -->
                        </div>


                        <!-- /.card -->
                    </section>
                    <div class="col-md-2" *ngIf="!checkPermessoLetturaVisite()"></div>
                    <section class="col-lg-6 connectedSortable" *ngIf="checkPermessoLetturaVisite()">
                        <!-- Custom tabs (Charts with tabs)-->

                        <div class="card card-primary">
                            <div class="card-header bg-primary border-transparent">
                                <h3 class="card-title">Ultime visite effettuate</h3>

                            </div>
                            <!-- /.card-header -->
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                    <table class="table m-0">
                                        <thead>
                                            <tr>
                                                <th>Visita</th>
                                                <th>Paziente</th>
                                                <th>Tipo</th>
                                                <th>Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngIf="clinicalImpressionList.length == 0">
                                                <td colspan="5">Nessuna visita inserita</td>
                                            </tr>
                                            <tr *ngFor="let visit of clinicalImpressionList">
                                                <td> <button pButton type="button"
                                                        (click)="goToVisit(visit.id, visit.patientId)"
                                                        label="Visualizza" class="p-button-primary"></button></td>
                                                <td>{{visit.patient.__zone_symbol__value}}</td>
                                                <td><span class="badge {{visit.type}}">{{visit.descrizione}}</span></td>
                                                <td>{{visit.date}}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- /.table-responsive -->
                            </div>
                            <!-- /.card-body -->
                            <div class="card-footer clearfix">
                                <button pButton type="button" (click)="goToVisits()" label="Vedi Tutte"
                                    class="p-button-primary float-right"></button>
                            </div>
                            <!-- /.card-footer -->
                        </div>
                        <!-- /.card -->
                    </section>

                </div>
                <div class="row" *ngIf="checkPermessoLetturaNotifiche()">
                    <div class="col-md-2"></div>
                    <section class="col-md-8  connectedSortable">
                        <div class="card card-primary">
                            <div class="card-header bg-primary border-transparent">
                                <h3 class="card-title">Ultimi Alert registrati</h3>

                            </div>
                            <!-- /.card-header -->
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                    <table class="table m-0">
                                        <thead>
                                            <tr>
                                                <th>Paziente</th>
                                                <th>Misura</th>
                                                <th>Data</th>
                                                <th>Valore</th>
                                                <th>Dettaglio</th>
                                                <th>Livello</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngIf="alertList.length == 0">
                                                <td colspan="5">Nessun alert</td>
                                            </tr>
                                            <tr *ngFor="let alert of alertList">
                                                <td>{{alert.patient.__zone_symbol__value}}</td>
                                                <td>{{alert.misura}}</td>
                                                <td>{{alert.date}}</td>
                                                <td>{{alert.value}}</td>
                                                <td>{{alert.message}}</td>
                                                <td><i style="font-size:1.5em" [ngClass]="{
                                                    'text-warning':alert.level === 1,
                                                    'text-danger':alert.level === 2
                                                    }" class="fas fa-dot-circle"></i></td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- /.table-responsive -->
                            </div>
                            <!-- /.card-body -->
                            <div class="card-footer clearfix">
                                <button pButton type="button" (click)="goToAlerts()" label="Vedi Tutti"
                                    class="p-button-primary float-right"></button>
                            </div>
                            <!-- /.card-footer -->
                        </div>
                    </section>
                    <div class="col-md-2"></div>
                </div>

                <!-- /.row (main row) -->

            </div>
            <!-- /.container-fluid -->

            <!-- /.content -->
        </div>

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">

        </aside>
    </div>
</section>