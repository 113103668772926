// import { moment } from 'ngx-bootstrap/chronos/test/chain';

import { CustomizationService } from "src/app/service/customization.service";

export interface SeriesLabels {
    serie: any;
    labels: any;
}

export interface SerieType {
    name: string;
    color: any;
    serie?: any[];
    topic?: string;
    type: any;
    yaxis?: any;
    maxyaxis?: number;
    xaxis?: any;
    display: string[];
    stroke?: ApexStroke;
    markers?: boolean;
    fill?: boolean;
    grid?: boolean;
}

export function getSeriesScores(response, nameSeries, typeCol): SeriesLabels {
    const ret = {} as SeriesLabels;
    const labels = new Array();
    if (response.hits && response.hits.hits) {
        const serieArray = new Array();
        nameSeries.forEach((el, i) => {
            const serie = {
                data: new Array(),
                type: Array.isArray(typeCol) ? typeCol[i] : typeCol,
                name: el
            };
            serieArray.push(serie);
        });
        response.hits.hits.forEach(el => {

            const numb = Number.parseInt('' + el._source.value, 10);
            const xy = {} as any;
            const d = new Date(el._source.timestamp);
            xy.x = el._source.timestamp;
            xy.y = numb;
            serieArray[0].data.push(xy);
            labels.push(el._source.timestamp);
        });
        ret.serie = serieArray[0];
        ret.labels = labels;
    }
    return ret;
}

export function getSeriesScoresV2(response, nameSeries, typeCol): SeriesLabels {
    const ret = {} as SeriesLabels;
    const labels = new Array();
    if (response.hits && response.hits.hits) {
        const serieArray = new Array();
        nameSeries.forEach((el, i) => {
            const serie = {
                data: new Array(),
                type: Array.isArray(typeCol) ? typeCol[i] : typeCol,
                name: el
            };
            serieArray.push(serie);
        });
        response.hits.hits.forEach(el => {
            const numb = Number.parseInt('' + el._source.value, 10);
            const xy = {} as any;
            const d = new Date(el._source.timestamp);
            xy.x = el._source.timestamp;
            xy.y = numb;
            serieArray[0].data.push(xy);
            labels.push(el._source.timestamp);
        });
        ret.serie = serieArray;
        ret.labels = labels;
    }
    return ret;
}

export function getSeriesDataValue(response, nameSeries, typeCol, index): SeriesLabels {
    const ret = {} as SeriesLabels;
    const labels = new Array();
    if (response.hits && response.hits.hits) {
        const serieArray = new Array();
        nameSeries.forEach((el, i) => {
            const serie = {
                data: new Array(),
                type: Array.isArray(typeCol) ? typeCol[i] : typeCol,
                name: el
            };
            serieArray.push(serie);
        });
        if (index === 'Passi') {
            response.aggregations.stepsDays.buckets.forEach(el => {
                const xy = {} as any;
                const d = new Date(el.key);
                xy.x = el.key;
                const numb = Number.parseInt('' + el.stepsDay.value, 10);
                xy.y = numb;
                serieArray[0].data.push(xy);
                labels.push(el.key);
            });
        } else if (index === 'Frequenza cardiaca' && response.aggregations && response.aggregations.date_aggs.buckets) {
            response.aggregations.date_aggs.buckets.forEach(el => {
                const xy = {} as any;
                const d = new Date(el.key + (30 * 60));
                xy.x = el.key + (30 * 60);
                const numb = Number.parseInt('' + el.avg_value.value, 10);
                xy.y = numb;
                serieArray[0].data.push(xy);
                labels.push(el.key + (30 * 60));
            });
        } else {
            response.hits.hits.forEach(el => {
                el._source.timestamp = el._source.timestamp + (30 * 60);
                if (index === 'Pressione sanguigna') {
                    const xy = {} as any;
                    xy.x = el._source.timestamp;
                    const numb = Number.parseInt('' + el._source.value, 10);
                    xy.y = numb;
                    if (el._source.code === '2') {
                        serieArray[0].data.push(xy);
                    } else {
                        serieArray[1].data.push(xy);
                    }
                } else if (index === 'Temperatura') {
                    const xy = {} as any;
                    xy.x = el._source.timestamp;
                    const numb = Number.parseFloat('' + el._source.value);
                    xy.y = numb;
                    serieArray[0].data.push(xy);
                } else if (index === 'Ossigenazione') {
                    const xy = {} as any;
                    xy.x = el._source.timestamp;
                    if (el._source.value > 100) {
                        el._source.value = (el._source.value / 10.0)
                    }
                    const numb = Number.parseFloat('' + el._source.value);
                    xy.y = numb;
                    serieArray[0].data.push(xy);
                } else if (index === 'Mood') {
                    const xy = {} as any;
                    xy.x = el._source.timestamp;
                    let numb;
                    if (el._source.value === '3') {
                        numb = 75;
                    } else if (el._source.value === '2') {
                        numb = 45;
                    } else {
                        numb = 15;
                    }
                    xy.y = numb;
                    serieArray[0].data.push(xy);
                } else if (index === 'Fatica' || index === 'Energia') {
                    const xy = {} as any;
                    xy.x = el._source.timestamp;
                    const numb = Number.parseInt('' + el._source.value, 10);
                    xy.y = numb;
                    serieArray[0].data.push(xy);
                } else if (index === 'Sonno') {
                    const xy = {} as any;
                    const xy2 = {} as any;
                    xy.x = el._source.timestamp + (30 * 60);
                    xy2.x = el._source.timestamp + (30 * 60);
                    if (el._source.code === '11') {
                        const numb = (el._source.value / 60);
                        xy.y = numb;
                        serieArray[0].data.push(xy);
                        labels.push(el._source.timestamp);
                    } else if (el._source.code === '12') {
                        const numb = (el._source.value / 60);
                        xy2.y = numb;
                        serieArray[1].data.push(xy2);
                    }
                } else if (index === 'Capecitabina' || index === 'Regorafenib' || index === 'Lonsurf/Tas 102') {
                    const xy = {} as any;
                    xy.x = new Date(el._source.timestamp).getTime();
                    xy.y = Number.parseInt('' + el._source.value, 10);
                    serieArray[0].data.push(xy);
                }
                else {
                    const xy = {} as any;
                    xy.x = el._source.timestamp;
                    let numb;
                    if (el._source.value) {
                        numb = Number.parseInt('' + el._source.value, 10);
                    } else if (el._source.dataValue) {
                        numb = Number.parseInt('' + el._source.dataValue, 10);
                    }
                    xy.y = numb;
                    serieArray[0].data.push(xy);
                }
                if (index === 'Mood') {
                    labels.push(el._source.timestamp);
                } else if (index === 'Sonno') {
                    //labels.push(el._source.timestamp  + (30 * 60));
                } else {
                    labels.push(el._source.timestamp);
                }
            });
        }
        serieArray.forEach(elem => {
            elem.data.sort((a, b) => {
                return a - b;
            });
        });
        ret.serie = serieArray;
        labels.sort((a, b) => {
            return a - b;
        });
        ret.labels = labels;
    }
    return ret;
}

export function getSeriesResponses(response, topic, nameSeries: string[], typeCol, organization, customizationService: CustomizationService): SeriesLabels {

    const ret = {} as SeriesLabels;
    const labels = new Array();
    let questions = customizationService.getDefaultQuestionnaireNameByOrganizationName(organization);
    if (response.hits && response.hits.hits) {
        const serieArray = new Array();
        nameSeries.forEach((el, i) => {
            const serie = {
                data: new Array(),
                type: Array.isArray(typeCol) ? typeCol[i] : typeCol,
                name: el
            };
            serieArray.push(serie);
        });
        response.hits.hits.forEach(el => {
            const xy = {} as any;
            // const elem = new Array();
            if (el._source.timestamp) {
                const d = new Date(el._source.timestamp);
                xy.x = el._source.timestamp;
            } else if (el._source.data) {
                const d = new Date(el._source.timestamp);
                xy.x = el._source.timestamp;
            }
            if (topic === 'Diarrea' || topic === 'Stipsi') {
                const numb = Number.parseInt('' + el._source.value, 10);
                xy.y = numb;
                serieArray[0].data.push(xy);
            } else if (topic === 'Evacuazioni giornaliere (frequenza)') {
                if (el._source.value === '0') {
                    xy.y = 0;
                } else if (el._source.value === '1-3') {
                    xy.y = 1;
                } else if (el._source.value === '3-8') {
                    xy.y = 2;
                } else if (el._source.value === '>8') {
                    xy.y = 3;
                }
                serieArray[0].data.push(xy);
            } else if (questions.evaquazioniGiornaliere_tipo && topic === questions.evaquazioniGiornaliere_tipo.topic) {
                const numb = Number.parseInt('' + el._source.idRisposta, 10);
                xy.y = numb;
                serieArray[0].data.push(xy);
            } else if ((questions.nausea2 && questions.nausea2.graficoName === topic)
                || (questions.rash2 && questions.rash2.graficoName === topic)
                || (questions.rash3 && questions.rash3.graficoName === topic)
                || (questions.rash4 && questions.rash4.graficoName === topic)
                || (questions.stomatite2 && questions.stomatite2.graficoName === topic)
                || (questions.stomatite3 && questions.stomatite3.graficoName === topic)
                || (questions.febbre2 && questions.febbre2.graficoName === topic)
                || (questions.febbre3 && questions.febbre3.graficoName === topic)) {
                const numb = Number.parseInt('' + el._source.value, 10);
                xy.y = numb;
                serieArray[0].data.push(xy);
            } else if ((questions.dolore2 && questions.dolore2.graficoName === topic)
                || (questions.dolore1 && questions.dolore1.graficoName === topic)
                || (questions.fatica1 && questions.fatica1.graficoName === topic)
                || (questions.fatica2 && questions.fatica2.graficoName === topic)
                || (questions.fatica3 && questions.fatica3.graficoName === topic)
                || (questions.nausea1 && questions.nausea1.graficoName === topic)
                || (questions.vomito && questions.vomito.graficoName === topic)
                || (questions.rash1 && questions.rash1.graficoName === topic)
                || (questions.stomatite1 && questions.stomatite1.graficoName === topic)
                || (questions.parestesie && questions.parestesie.graficoName === topic)
                || (questions.cefalea && questions.cefalea.graficoName === topic)
                || (questions.febbre1 && questions.febbre1.graficoName === topic)
                || (questions.febbre4 && questions.febbre4.graficoName === topic)
                || (questions.umore1 && questions.umore1.graficoName === topic)
                || (questions.umore2 && questions.umore2.graficoName === topic)
                || (questions.umore3 && questions.umore3.graficoName === topic)
                || (questions.umore4 && questions.umore4.graficoName === topic)
                || (questions.difficoltaFinanziarie && questions.difficoltaFinanziarie.graficoName === topic)
                || (questions.funzionalitaFisica1 && questions.funzionalitaFisica1.graficoName === topic)
                || (questions.funzionalitaFisica2 && questions.funzionalitaFisica2.graficoName === topic)
                || (questions.funzionalitaFisica3 && questions.funzionalitaFisica3.graficoName === topic)) {
                const numb = Number.parseInt('' + el._source.idRisposta, 10);
                xy.y = numb;
                serieArray[0].data.push(xy);
            } else if (el._source.idDomanda === '10') {
                const numb = Number.parseInt('' + el._source.idRisposta, 10);
                xy.y = numb;
                serieArray[0].data.push(xy);
            } else if (el._source.idDomanda === '11') {
                const numb = Number.parseInt('' + el._source.value, 10);
                xy.y = numb;
                serieArray[0].data.push(xy);
            } else if (topic === 'Peso corporeo') {
                const numb = Number.parseInt('' + el._source.valore, 10);
                xy.y = numb;
                serieArray[0].data.push(xy);
            } else if (topic === 'Temperatura') {
                const numb = Number.parseFloat('' + el._source.valore);
                xy.y = numb;
                serieArray[0].data.push(xy);
            }
            if (el._source.timestamp) {
                const d = new Date(el._source.timestamp);
                xy.x = el._source.timestamp;
                labels.push(el._source.timestamp);
            } else if (el._source.data) {
                const d = new Date(el._source.timestamp);
                xy.x = el._source.timestamp;
                labels.push(el._source.timestamp);
            }
        });
        ret.serie = serieArray;
        ret.labels = labels;
    }
    return ret;
}

export function cutSeries(seriesA, seriesB) {
    const ret = new Array();
    if (seriesA && seriesB) {
        const newSerieData = new Array();
        const newSerieDataBase = new Array();
        const seriesBase = Object.assign({}, seriesA);
        const seriesToCut = Object.assign({}, seriesB);
        if (seriesBase && seriesToCut) {
            seriesBase.data.forEach(elem => {
                seriesToCut.data.forEach((element) => {
                    if (!Array.isArray(element)) {
                        if (element.x === elem.x) {
                            newSerieData.push(element);
                            newSerieDataBase.push(elem);
                        }
                    } else {
                        if (element[0] === elem[0]) {
                            newSerieData.push(element);
                            newSerieDataBase.push(elem);
                        }
                    }
                });
            });
            seriesToCut.data = newSerieData;
            seriesBase.data = newSerieDataBase;
        }
        ret.push(seriesBase);
        ret.push(seriesToCut);
    }

    return ret;
}

export function cutSeriesV2(arr) {
    const ret = new Array();
    if (arr) {
        const base = Object.assign({}, arr[0]);
        arr.forEach((x, i) => {
            const next = arr[i + 1];
            if (next) {
                const newSerieData = new Array();
                const newSerieDataBase = new Array();
                base.data.forEach(elem => {
                    next.data.forEach((element) => {
                        if (!Array.isArray(element)) {
                            const dateElem = new Date(elem.x);
                            const dateElemStr = dateElem.getFullYear() + '/' + dateElem.getMonth() + '/' + dateElem.getDate();
                            const dateElement = new Date(element.x);
                            const dateElementStr = dateElement.getFullYear() + '/' + dateElement.getMonth() + '/' + dateElement.getDate();
                            if (dateElemStr === dateElementStr) {
                                const newElem = Object.assign({}, elem);
                                newElem.x = element.x;
                                newSerieData.push(element);
                                newSerieDataBase.push(newElem);
                            }
                        } else {
                            if (element[0] === elem[0]) {
                                newSerieData.push(element);
                                newSerieDataBase.push(elem);
                            }
                        }
                    });
                });
                next.data = newSerieData;
                if (i === 0) {
                    arr[0].data = newSerieDataBase;
                    ret.push(arr[0]);
                }
                ret.push(next);
            }
        });
    }
    return ret;
}

export function cutSeriesV3(arr) {
    const ret = new Array();
    if (arr) {
        const base = Object.assign({}, arr[0]);
        const next = arr[1];
        const passi = new Array();
        base.data.forEach(el => {
            let maxperDays = el;
            const dateElem = new Date(el.x);
            const dateElemStr = dateElem.getFullYear() + '/' + dateElem.getMonth() + '/' + dateElem.getDate();
            base.data.forEach(elem => {
                const dateElemCurr = new Date(elem.x);
                const dateElemStrCurr = dateElemCurr.getFullYear() + '/' + dateElemCurr.getMonth() + '/' + dateElemCurr.getDate();
                if (dateElemStr === dateElemStrCurr) {
                    if (elem.y > maxperDays.y) {
                        maxperDays = elem;
                    }
                }
            });
            if (passi.findIndex(e => e.x === maxperDays.x) === -1) {
                passi.push(maxperDays);
            }
        });
        passi.sort((a, b) => a.x - b.x);
        base.data = passi;
        if (next) {
            // const newSerieData = new Array();
            // base.data.forEach(elembase => {
            //     const find = next.data.find(el => {
            //         const dateElem = new Date(el.x);
            //         const dateElemStr = dateElem.getFullYear() + '/' + dateElem.getMonth() + '/' + dateElem.getDate();
            //         const dateElement = new Date(elembase.x);
            //         const dateElementStr = dateElement.getFullYear() + '/' + dateElement.getMonth() + '/' + dateElement.getDate();
            //         if (dateElemStr === dateElementStr) {
            //             return true;
            //         } else {
            //             return false;
            //         }
            //     });
            //     if (!find) {
            //         const newFind = next.data.slice().reverse().find(el => el.x >= elembase.x);
            //         const newElem = Object.assign({}, newFind);
            //         // newElem.y = null;
            //         newSerieData.push(newElem);
            //     } else {
            //         newSerieData.push(find);
            //     }
            // });
            // next.data = newSerieData;
            ret.push(base);
            ret.push(next);
        }
        ret.forEach(x => {
            x.data.sort((a, b) => a.x - b.x);
        });
    }

    return ret;
}

export function getDaysLabels(dateFrom, dateTo) {
    const ret = new Array();
    const diff = dateTo * 1000 - dateFrom * 1000;
    const days = diff / (1000 * 3600 * 24);
    for (let i = 0; i <= days; i++) {
        ret.push(dateFrom + i);
    }
    return ret;
}

export function findMaxSeries(arr) {
    const max = 0;
    let ret;
    arr.forEach(x => {
        if (x.data.length > max) {
            ret = x;
        }
    });
    return ret;
}

export function refillSeries(seriesA, seriesB) {

    const ret = new Array();
    if (seriesA && seriesB) {
        seriesA.data.forEach(element => {
            const find = seriesB.data.find(elem => elem.x === element.x);
            if (!find) {
                const newElem = Object.assign({}, element);
                newElem.y = null;
                seriesB.data.push(newElem);
            }
        });

        seriesB.data.forEach(element => {
            const find = seriesA.data.find(elem => elem.x === element.x);
            if (!find) {
                const newElem = Object.assign({}, element);
                newElem.y = null;
                seriesA.data.push(newElem);
            }
        });
        ret.push(seriesA);
        ret.push(seriesB);
    }
    return ret;
}

export function refillSeriesV2(seriesA, seriesB) {
    const ret = new Array();
    if (seriesA && seriesB) {
        seriesA.data.forEach(element => {
            const find = seriesB.data.find(elem => elem.x === element.x);
            if (!find) {
                const newElem = Object.assign({}, element);
                newElem.y = 0;
                seriesB.data.push(newElem);
            }
        });

        seriesB.data.forEach(element => {
            const find = seriesA.data.find(elem => elem.x === element.x);
            if (!find) {
                const newElem = Object.assign({}, element);
                newElem.y = 0;
                seriesA.data.push(newElem);
            }
        });

        ret.push(seriesA);
        ret.push(seriesB);
    }
    return ret;
}

export function getAllDefaultColors() {
    return [
        {
            serieName: 'Dolore',
            color: '#de2826'
        },
        {
            serieName: 'Fatica',
            color: '#ff6c00'
        },
        {
            serieName: 'Nausea',
            color: '#fedb00'
        },
        {
            serieName: 'Pressione (Min)',
            color: "#008FFB"
        },
        {
            serieName: 'Pressione diastolica',
            color: "#008FFB"
        },
        {
            serieName: 'Pressione (Max)',
            color: '#FA4443'
        },
        {
            serieName: 'Pressione sistolica',
            color: '#FA4443'
        },
        {
            serieName: 'Frequenza cardiaca',
            color: '#fa4443'
        },
        {
            serieName: 'Temperatura',
            color: '#fa4443'
        },
        {
            serieName: 'Temperatura corporea',
            color: '#fa4443'
        },
        {
            serieName: 'Ossigenazione',
            color: '#008FFB'
        },
        {
            serieName: 'Peso',
            color: '#fa4443'
        },
        {
            serieName: 'Peso (kg)',
            color: '#fa4443'
        },
    ];
}

// Colori definiti in base al parametro
export function getDefaultColorBySerieName(serieName) {
    let defaultColors = getAllDefaultColors();
    return defaultColors.find(dc => dc.serieName == serieName)?.color;
}
