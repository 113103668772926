<div class="row mt-3" *ngIf="descrizione && descrizione.code.coding[0]">
    <div class="col-md-12">
        <label>Descrizione</label>
        <p-editor [readonly]="!checkPermessoModifica()" [(ngModel)]="descrizione.code.coding[0].display"
            [style]="{'height':'150px'}" (ngModelChange)="dirty = true"></p-editor>
    </div>
</div>
<div class="row mt-3" *ngIf="listICD && listICD.length > 0">
    <div class="col-md-12">
        <label>Lista delle condizioni cliniche</label>
        <p-table [columns]="cols" [value]="isInPdfEdit ? listICDPdfEdit : listICD" [responsive]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                        {{col.label}}
                    </th>
                    <th style="width:8em" *ngIf="checkPermessoModifica()"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns">
                        <span class="p-column-title">{{col.value}}</span>
                        {{rowData.code.coding[0][col.value]}}
                    </td>
                    <td *ngIf="checkPermessoModifica()">
                        <span class="p-column-title">Azioni:</span>
                        <button pButton class="table-button p-button-danger" icon="far fa-trash-alt" label="Elimina"
                            (click)="elimina(rowData)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length+1">
                        <label>Nessuna condizione clinica assegnata.</label>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<div class="mt-3" *ngIf="checkPermessoModifica()">
    <app-gestione-icd (saved)="aggiungi($event)"></app-gestione-icd>
</div>
<div class="row mt-5" *ngIf="org != 'NEMO' ">
    <div class="col-md-12" *ngIf="!isInPdfEdit">
        <div class="card">
            <div class="card-header bg-primary">
                <h3 class="card-title">Informazioni aggiuntive</h3>
            </div>
            <div class="card-body">
                <div class="col-md-12">
                    <form [formGroup]="formOnco" class="p-fluid">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Data della diagnosi</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><i class="far fa-calendar-alt"
                                            style="line-height: 0.5;"></i></span>
                                    <input type="text" pInputText placeholder="" formControlName="dataDiagnosi"
                                        bsDatepicker [maxDate]="today" [bsConfig]="datePickerConf">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Data avvio trattamento</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><i class="far fa-calendar-alt"
                                            style="line-height: 1.25;"></i></span>
                                    <input type="text" pInputText placeholder="" formControlName="dataTrattamento"
                                        bsDatepicker [bsConfig]="datePickerConf">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Stadio</label>
                                <p-dropdown [options]="stageOpt" formControlName="stage"
                                    dropdownIcon="fas fa-chevron-down"></p-dropdown>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- <div class="card" *ngIf="percorsoCovid">
            <div class="card-header bg-primary">
                <h3 class="card-title">Percorso covid</h3>
            </div>
            <div class="card-body">
                <div class="col-md-12" *ngIf="percorsoCovid">
                    <form [formGroup]="formCovid" class="p-fluid">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Data della diagnosi</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><i class="far fa-calendar-alt"
                                            style="line-height: 0.5;"></i></span>
                                    <input type="text" pInputText placeholder="" formControlName="dataDiagnosiCovid"
                                        bsDatepicker
                                        [maxDate]="today"
                                        [bsConfig]="datePickerConf">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Data avvio trattamento</label>
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><i class="far fa-calendar-alt"
                                            style="line-height: 1.25;"></i></span>
                                    <input type="text" pInputText placeholder=""
                                        formControlName="dataTrattamentoCovid" bsDatepicker
                                        [bsConfig]="datePickerConf">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Stadio Covid</label>
                                <p-dropdown [options]="stageOptCovid" formControlName="stageCovid"
                                    dropdownIcon="fas fa-chevron-down"></p-dropdown>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div> -->
        <!-- <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <label>Aggiungi percorso terapeutico</label>
                    </div>
                </div>
                <div class="row p-fluid">
                    <div class="mt-2 col-lg-8 col-md-6 col-sm-12">
                        <p-dropdown [options]="percorsi" [(ngModel)]="percorso" dropdownIcon="fas fa-chevron-down">
                        </p-dropdown>
                    </div>
                    <div class="mt-2 col-lg-2 col-md-3 col-sm-12">
                        <button type="button" (click)="aggiungiPercorso()" pButton icon="fas fa-plus" label="Aggiungi"
                            class="p-button-raised"></button>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row mt-3">
            <div class="card" *ngIf="percorsoCovid && !isInVisita">
                <div class="card-header bg-primary">
                    <h3 class="card-title">Questionario covid</h3>
                </div>
                <div class="card-body">
                    <div class="col-md-12">
                        <app-scale-di-valutazione-percorsocovid19 (statusQuestionnaire)="verificaPercorsoCovid($event)">
                        </app-scale-di-valutazione-percorsocovid19>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <div class="float-right">
            <button *ngIf="checkPermessoModifica()" pButton label="Salva" icon="fa fa-save" iconPos="left"
                styleClass="p-button-raised" type="button" class="p-button-success" (click)="salva()"></button>
        </div>
    </div>
</div>