import { environment } from 'src/environments/environment';

export const endpoints = {

    // Gestione organization
    createFHIROrganizationAndUpdateOauth: environment.resourceServer + '/api/createFHIROrganizationAndUpdateOauth',

    // Gestione Ruoli
    getAuthorities: environment.authServer + '/api/getAuthorities',
    authoritiesByOrganization: environment.authServer + '/api/authoritiesByOrganization',
    getOrganizations: environment.authServer + '/api/getOrganizations',
    getOrganizationRef: environment.authServer + '/api/organizationbyId',
    organizations: environment.authServer + '/api/organizations',
    createOrganization: environment.resourceServer + '/api/createfullorganization',
    modifyorganization: environment.resourceServer + '/api/modifyorganization',
    configurazionebyorg: environment.resourceServer + '/api/configurazionebyorg',
    getSections: environment.authServer + '/api/getsections',
    authority: environment.authServer + '/api/authority',
    authorities: environment.authServer + '/api/authorities',
    countByAuthority: environment.authServer + '/api/countByAuthority',
    getAllChildrenOrganization: environment.authServer + '/api/getAllChildrenOrganization',

    // Gestione Utenti
    me: environment.authServer + '/api/me',
    getUsers: environment.authServer + '/api/getUsers',
    user: environment.authServer + '/api/user',
    userbyid: environment.authServer + '/api/userbyid',
    validateUsernameAndCode: environment.authServer + '/api/validateusernameandcode',
    userFromResource: environment.resourceServer + '/api/user',
    changePassword: environment.authServer + '/api/changepassword',
    changePasswordIsNew: environment.authServer + '/api/changepasswordnewuser',
    salvaUtente: environment.resourceServer + '/api/salvaUser',
    sequencevalidation: environment.authServer + '/api/sequencevalidation',
    historyvalidation: environment.authServer + '/api/historyvalidation',
    usernamevalidation: environment.authServer + '/api/usernamevalidation',
    recuperaPassword: environment.authServer + '/api/recuperapassword',
    getDisabledUsersByOrganization: environment.authServer + '/api/getDisabledUsersByOrganization',
    searchDisabledUserByOrganizationByName: environment.authServer + '/api/searchDisabledUserByOrganizationByName',
    searchInActivationUserByOrganizationByName: environment.authServer + '/api/searchInActivationUserByOrganizationByName',
    getInActivationUsersByOrganization: environment.authServer + '/api/getInActivationUsersByOrganization',
    enableUser: environment.authServer + '/api/enableUser',
    cambiopasswordlogged: environment.authServer + '/api/cambiopasswordlogged',
    userbyreferenceid: environment.authServer + '/api/userbyreferenceid',
    getOrganizationNameByUserReferenceId: environment.authServer + '/api/getOrganizationNameByUserReferenceId',

    // Condizioni Cliniche
    getCondCli: environment.resourceServer + '/api/GetListAnamnesiPatologicaProssima',
    getAnamnesiPatologicaRemota: environment.resourceServer + '/api/GetListAnamnesiPatologicaRemota',
    getCondizioniCliniche: environment.resourceServer + '/api/getCondizioniCliniche',

    // Stato Di Salute
    getAllClinicalImpressionByPatientId: environment.resourceServer + '/api/getAllClinicalImpressionByPatientId',
    getFindingICFVisite: environment.resourceServer + '/api/getFindingICFVisite',
    getFindingICFDettagli: environment.resourceServer + '/api/getFindingICFDettagli',
    getFindingICFMonitoraggio: environment.resourceServer + '/api/getFindingICFMonitoraggio',
    // TODO: Non so dove vengano chiamati questi metodi...
    addInvestigationDettagli: environment.resourceServer + '/api/addInvestigationDettagli',
    addInvestigationVisite: environment.resourceServer + '/api/addInvestigationVisite',

    // Pazienti
    removePatient: environment.resourceServer + '/api/removePatient',
    getPatientByPatientId: environment.resourceServer + '/api/getPatientByPatientId?patientId=',
    putPatientByPatientIdByObject: environment.resourceServer + '/api/putPatientByPatientIdByObject?patientId=',
    getPatientByPatientIdByObject: environment.resourceServer + '/api/getPatientByPatientIdByObject/', //TODO: Verificare se può essere sostituito con metodo già esistente
    getPatientFromAnagrafica: environment.resourceServer + '/api/getPatientFromAnagrafica?patientId=',
    getPatientFromDettagli: environment.resourceServer + '/api/getPatientFromDettagli?patientId=',
    addPatient: environment.resourceServer + '/api/addPatient',
    updatePatient: environment.resourceServer + '/api/updatePatient',
    isDuplicatedMACAddress: environment.resourceServer + '/api/isDuplicatedMACAddress?macAddress=',
    activatePatientByFHIRId: environment.authServer + '/api/activatePatientByFHIRId/',
    deactivatePatientByFHIRId: environment.authServer + '/api/deactivatePatientByFHIRId/',
    getAllPatients: environment.resourceServer + '/api/getAllPatients',
    syncronizePatients: environment.resourceServer + '/api/syncronizePatients',
    getAllPatientsByPractitioner: environment.resourceServer + '/api/getAllPatientsByPractitioner',
    getAllPatientsByPractitionerByCountByActiveByOffsetBySort: environment.resourceServer + '/api/getAllPatientsByPractitionerByCountByActiveByOffsetBySort',
    getAllPatientsByCountByActiveByOffsetBySort: environment.resourceServer + '/api/getAllPatientsByCountByActiveByOffsetBySort',
    isPatientDeleted: environment.resourceServer + '/api/isPatientDeleted',
    //patient: environment.fhirServer + '/Patient/',
    getCountPatient: environment.resourceServer + '/api/getCountPatient/',
    getClinicalImpressionListApi: environment.resourceServer + '/api/getClinicalImpressionListApi/',
    setMonitoringEvaluation: environment.resourceServer + '/api/setMonitoringEvaluation/',
    exportMonitoringEvaluation: environment.resourceServer + '/api/exportMonitoringEvaluation',
    getInfoMonitoraggioByPatientId: environment.resourceServer + '/api/getInfoMonitoraggioByPatientId/',

    // Observation  // TODO: i primi 3 non vengono mai chiamati
    putObservationAlert: environment.resourceServer + '/api/updateFHIRObservationWithPUTAlert',
    putObservationDettagli: environment.resourceServer + '/api/updateFHIRObservationWithPUTDettagli',
    putObservationVisite: environment.resourceServer + '/api/updateFHIRObservationWithPUTVisite',
    postObservationDettagli: environment.resourceServer + '/api/updateFHIRObservationWithPOSTDettagli',
    postObservationVisite: environment.resourceServer + '/api/updateFHIRObservationWithPOSTVisite',
    updateObservationVisite: environment.resourceServer + '/api/updateObservationVisite',
    updateObservationDettagli: environment.resourceServer + '/api/updateObservationDettagli',

    // Stile di vita
    getLifeStyleByPatientIdVisite: environment.resourceServer + '/api/getLifeStyleByPatientIdVisite?patientId=',
    getLifeStyleByPatientIdDettagli: environment.resourceServer + '/api/getLifeStyleByPatientIdDettagli?patientId=',
    getLifeStyleByClinicalImpressionIdVisite: environment.resourceServer + '/api/getSocialHistoryVisite?clinicalImpressionId=',
    getLifeStyleByClinicalImpressionIdDettagli: environment.resourceServer + '/api/getSocialHistoryDettagli?clinicalImpressionId=',
    getVitalSignsForAlert: environment.resourceServer + '/api/getVitalSignsForAlert',
    getVitalSignsForMonitoraggio: environment.resourceServer + '/api/getVitalSignsForMonitoraggio',
    getAlerts: environment.resourceServer + '/api/getAlerts',
    //getLifeStyleByPatientId: environment.fhirServer + '/Observation?category=social-history&subject=',
    getLifeStyleByPatientIdApiVisite: environment.resourceServer + '/api/getLifeStyleByPatientIdApiVisite/',
    getLifeStyleByPatientIdApiDettagli: environment.resourceServer + '/api/getLifeStyleByPatientIdApiDettagli/',
    getLifeStyleByClinicalImpressionId: environment.resourceServer + '/api/getSocialHistory?clinicalImpressionId=',
    //getVitalSignsByPatientId: environment.fhirServer + '/Observation?category=vital-signs&subject=',
    getVitalSignsApi: '/api/getVitalSignsApi',   //Verificare se può essere sostituito con endpoints.getVitalSignsForAlert

    // Devices
    getDevicesByPatientIdDettagli: environment.resourceServer + '/api/getDevicesByPatientIdDettagli',
    getDevicesByPatientIdVisite: environment.resourceServer + '/api/getDevicesByPatientIdVisite',
    addDevicesDettagli: environment.resourceServer + '/api/addDevicesDettagli',
    addDevicesVisite: environment.resourceServer + '/api/addDevicesVisite',
    updateMonitorningDevicesFromAnagrafica: environment.resourceServer + '/api/updateMonitorningDevicesFromAnagrafica',
    updateMonitorningDevicesFromDettagli: environment.resourceServer + '/api/updateMonitorningDevicesFromDettagli',
    getDevicesByClinicalImpressionId: environment.resourceServer + '/api/getDevicesByClinicalImpressionId?clinicalImpressionId=',
    // TODO: Non vengono chiamati??
    putDeviceVisite: environment.resourceServer + '/api/putDeviceByDeviceIdByObjectVisite',
    putDeviceDettagli: environment.resourceServer + '/api/putDeviceByDeviceIdByObjectDettagli',

    // Stato Di Salute Tab
    getHealthStatusByPatientIdVisite: environment.resourceServer + '/api/getHealthStatusByPatientIdVisite?patientId=',
    getHealthStatusByPatientIdDettagli: environment.resourceServer + '/api/getHealthStatusByPatientIdDettagli?patientId=',
    // TODO: Implementare a backend x 2
    getHealthStatusByCIVisite: environment.resourceServer + '/api/getHealthStatusVisite?clinicalImpressionId=',
    getHealthStatusByCIDettagli: environment.resourceServer + '/api/getHealthStatusDettagli?clinicalImpressionId=',
    addInvestigation: environment.resourceServer + '/api/addInvestigation',

    // Stato Di Salute Tab
    //getHealthStatusByPatientId: environment.fhirServer + '/Observation?category=health-status&subject=',
    getHealthStatusByPatientIdApiDettagli: environment.resourceServer + '/api/getHealthStatusByPatientIdApiDettagli',
    getHealthStatusByPatientIdApiVisite: environment.resourceServer + '/api/getHealthStatusByPatientIdApiVisite',
    getHealthStatusByCI: environment.resourceServer + '/api/getHealthStatus?clinicalImpressionId=',



    // ICD Tree
    // FIXME: Se metto i permessi non si avvia il progetto
    getDataTreeICD: environment.resourceServer + '/api/icdapi/getDataTree',
    searchICD: environment.resourceServer + '/api/icdapi/searchICD',

    // ICF Tree
    // TODO: Mettere i permessi, ma crasha se li metto
    getDataTreeICF: environment.resourceServer + '/api/icfapi/getDataTree',
    getDataTreeCORESET_ICF: environment.resourceServer + '/api/icfapi/getCoreSetTree',

    searchICF: environment.resourceServer + '/api/icfapi/searchICF',

    deleteFindingICF: environment.resourceServer + '/api/deleteFindingICF',
    addFindingICF: environment.resourceServer + '/api/addFindingICF',

    // Supporto ICF
    // TODO: Non viene usato x 3
    getAllICFByPatientId: environment.resourceServer + '/api/supportICF?patientId=',
    getICFsByPatientId: environment.resourceServer + '/api/getICFsByPatientId?patientId=',
    getICFsGroupByPatientId: environment.resourceServer + '/api/getICFsGroupByPatientId?patientId=',
    getSupportICF: environment.resourceServer + '/api/supportICFNew',

    // Anamnesi Patologica Prossima
    getAnaPatProx: environment.resourceServer + '/api/GetListAnamnesiPatologicaProssima',
    getOncologyInfoByPatientId: environment.resourceServer + '/api/getOncologyInfoByPatientId?patientId=',
    getCovidInfoByPatientId: environment.resourceServer + '/api/getCovidInfoByPatientId?patientId=',
    deleteAnamnesiPatologicaProssimaDettagli: environment.resourceServer + '/api/DeleteAnamnesiPatologicaProssimaDettagli',
    deleteAnamnesiPatologicaProssimaVisite: environment.resourceServer + '/api/DeleteAnamnesiPatologicaProssimaVisite',
    addListAnamnesiPatologicaProssimaDettagli: environment.resourceServer + '/api/AddListAnamnesiPatologicaProssimaDettagli',
    addListAnamnesiPatologicaProssimaVisite: environment.resourceServer + '/api/addListAnamnesiPatologicaProssimaVisite',

    getOncologyInfoByCIId: environment.resourceServer + '/api/getOncologyInfoByClinicalImpressionId?clinicalImpressionId=',
    getCovidInfoByCIId: environment.resourceServer + '/api/getCovidInfoByClinicalImpressionId?clinicalImpressionId=',
    getFindingICD: environment.resourceServer + '/api/getFindingICD?clinicalImpressionId=',
    addFindingICD: environment.resourceServer + '/api/addFindingICD',
    deleteFindingICD: environment.resourceServer + '/api/deleteFindingICD',
    getFindingICDFromLastClinicalImpressionForDettagliByPatientId: environment.resourceServer + '/api/getFindingICDFromLastClinicalImpressionForDettagliByPatientId/',

    // Terapie farmacologiche tab
    getTerapieFarmacologicheByPatientId: environment.resourceServer + '/api/getTerapieFarmacologicheByPatientId?patientId=',
    // tslint:disable-next-line: max-line-length
    eliminaTerapiaFarmacologicaByMedicationStatementId: environment.resourceServer + '/api/removeMedicationStatement?medicationStatementId=',
    // tslint:disable-next-line: max-line-length
    aggiornaTerapiaFarmacologicaByMedicationStatementId: environment.resourceServer + '/api/updateMedicationStatement',
    aggiungiNuovaTerapiaFarmacologicaDettagli: environment.resourceServer + '/api/addMedicationStatementDettagli',
    aggiungiNuovaTerapiaFarmacologicaVisite: environment.resourceServer + '/api/addMedicationStatementVisite',

    // Anamnesi Patologica Remota
    getAnaPatRem: environment.resourceServer + '/api/GetListAnamnesiPatologicaRemota',
    deleteAnaPatRem: environment.resourceServer + '/api/DeleteAnamnesiPatologicaRemota',
    addAnaPatRem: environment.resourceServer + '/api/AddListAnamnesiPatologicaRemota',

    // Visite
    putClinicalImpressionByClinicalImpressionIdByObjectVisite: environment.resourceServer + '/api/putClinicalImpressionByClinicalImpressionIdByObjectVisite',
    getClinicalImpressionByClinicalImpressionIdVisite: environment.resourceServer + '/api/getClinicalImpressionByClinicalImpressionIdVisite',
    getAllClinicalImpression: environment.resourceServer + '/api/getAllClinicalImpression',
    clinicalImpressionList: environment.resourceServer + '/api/clinicalImpressionList?patientId=',
    updateEpicrisiByClinicalImpressionId: environment.resourceServer + '/api/updateEpicrisiByClinicalImpressionId/',

    getLastClinicalImpressionByPatientIdMonitoraggio: environment.resourceServer + '/api/getLastClinicalImpressionByPatientIdMonitoraggio',
    getLastClinicalImpressionByPatientIdDettagli: environment.resourceServer + '/api/getLastClinicalImpressionByPatientIdDettagli',
    getLastClinicalImpressionByPatientIdVisite: environment.resourceServer + '/api/getLastClinicalImpressionByPatientIdVisite',
    addClinicalImpression: environment.resourceServer + '/api/addClinicalImpression',
    getVitalSignsByClinicalImpressionId: environment.resourceServer + '/api/getVitalSignsByClinicalImpressionId?clinicalImpressionId=',
    //clinicalImpression: environment.fhirServer + '/ClinicalImpression',
    getClinicalImpressionApi: environment.resourceServer + '/api/getClinicalImpressionApi/',
    //getLastClinicalImpression: environment.fhirServer + '/ClinicalImpression?_sort=-date&_count=1&subject=',

    // Esami Clinici
    getLaboratoryExams: environment.resourceServer + '/api/getLaboratoryExams?clinicalImpressionId=',
    getBiomarkers: environment.resourceServer + '/api/getBiomarkers?clinicalImpressionId=',

    // Observation
    //observation: environment.fhirServer + '/Observation',
    putObservation: environment.resourceServer + '/api/putObservation/',
    postObservation: environment.resourceServer + '/api/postObservation/',
    //observation: environment.fhirServer + '/Observation',
    putApiObservationDettagli: environment.resourceServer + '/api/putApiObservationDettagli/',
    putApiObservationAlert: environment.resourceServer + '/api/putApiObservationAlert/',
    postApiObservationDettagli: environment.resourceServer + '/api/postApiObservationDettagli/',
    putApiObservationVisite: environment.resourceServer + '/api/putApiObservationVisite/',
    postApiObservationVisite: environment.resourceServer + '/api/postApiObservationVisite/',
    updateObservation: environment.resourceServer + '/api/updateObservation',

    // Monitoring
    getVitalSignsThresholds: environment.resourceServer + '/api/getVitalSignsThresholds?clinicalImpressionId=',

    // Scale di valutazione
    getQuestionarioGenerali: environment.resourceServer + '/api/getEvaluationScales?clinicalImpressionId=', // Generali
    getQuestionnaireByNameByVersion: environment.resourceServer + '/api/getQuestionnaireByNameByVersion', // Tutti gli altri
    getQuestionnaireByNameByVersionInAttivazione: environment.resourceServer + '/api/getQuestionnaireByNameByVersionInAttivazione', // Tutti gli altri
    updateQuestionnaire: environment.resourceServer + '/api/updateQuestionnaire', // Mandando name creo il questionario
    createQuestionnaire: environment.resourceServer + '/api/createQuestionnaire', // Mandando name, id aggiorna il questionario
    getClinicalImpressionSurveyResponse: environment.resourceServer + '/api/getClinicalImpressionSurveyResponse', // Tutti gli altri
    addClinicalImpressionSurveyResponse: environment.resourceServer + '/api/addClinicalImpressionSurveyResponse',
    getNlabScoreByPatientId: environment.resourceServer + '/api/getNlabScoreByPatientId?patientId=',

    // NLAB senza visita
    addClinicalImpressionSurveyResponseNLAB: environment.resourceServer + '/api/addClinicalImpressionSurveyResponseNLAB',
    addClinicalImpressionSurveyResponseNLABsub: environment.resourceServer + '/api/addClinicalImpressionSurveyResponseNLABsub',
    getClinicalImpressionSurveyResponseNLAB: environment.resourceServer + '/api/getClinicalImpressionSurveyResponseNLAB',

    // Trattamenti e procedure
    addMedicationRequestForVisit: environment.resourceServer + '/api/addMedicationRequestForVisit',
    getMedicationRequestForVisit: environment.resourceServer + '/api/getMedicationRequestForVisit',
    deleteMedicationRequestForVisit: environment.resourceServer + '/api/deleteMedicationRequestForVisit',
    updateMedicationRequestsForVisit: environment.resourceServer + '/api/updateMedicationRequestsForVisit',
    getMedicationRequests: environment.resourceServer + '/api/getMedicationRequests?clinicalImpressionId=',
    addMedicationRequests: environment.resourceServer + '/api/addMedicationRequests',
    updateMedicationRequest: environment.resourceServer + '/api/updateMedicationRequest',

    // CarePlan
    getCarePlanByClinicalImpressionId: environment.resourceServer + '/api/getCarePlanByClinicalImpressionId?clinicalImpressionId=',
    // tslint:disable-next-line: max-line-length
    getProcedureRequestsByClinicalImpressionId: environment.resourceServer + '/api/getProcedureRequestsByClinicalImpressionId?clinicalImpressionId=',
    createCarePlanWithEpisodeOfCare: environment.resourceServer + '/api/createCarePlanWithEpisodeOfCare',
    updateCarePlanAndEpisodeOfCare: environment.resourceServer + '/api/updateCarePlanAndEpisodeOfCare',
    addProcedureToCarePlanById: environment.resourceServer + '/api/addProcedureToCarePlanById',
    getProcedureRequestsByCarePlanId: environment.resourceServer + '/api/getProcedureRequestsByCarePlanId?carePlanId=',
    removeProcedureRequestFromCarePlanById: environment.resourceServer + '/api/removeProcedureRequestFromCarePlanById',
    addProcedureToClinicalImpression: environment.resourceServer + '/api/addProcedureRequest',
    updateCarePlan: environment.resourceServer + '/api/updateCarePlan/',
    updateMonitoringThresholdsByCarePlanId: environment.resourceServer + '/api/updateMonitoringThresholdsByCarePlanId/',
    updateMonitoringProcedureRequestsByCarePlanId: environment.resourceServer + '/api/updateMonitoringProcedureRequestsByCarePlanId/',

    // gestione alert
    getCountAlert: environment.resourceServer + '/api/getAlerts/vital-signs/registered',
    getCountAlertByPatientId: environment.resourceServer + '/api/getObservationByCategoryByStatusBySubjectNotifiche/vital-signs/registered/',
    // NOTA: Viene usato nel report e non posso testare con i pazienti che ho in locale
    getCountAlertByPatientIdByCountByInterval: environment.resourceServer + '/api/getObservationByCategoryByStatusBySubjectByCountByIntervalloNotifiche/vital-signs/registered/',
    getCountAlertByPatientIdByCountByIntervalCorrected: environment.resourceServer + '/api/getObservationByCategoryByStatusBySubjectByCountByIntervalloNotifiche/vital-signs/corrected/',

    // gestione care giver
    createRelatedPerson: environment.resourceServer + '/api/createRelatedPerson',
    updateRelatedPerson: environment.resourceServer + '/api/updateRelatedPerson?id=',
    getAllRelatedPersonByPatientId: environment.resourceServer + '/api/getAllRelatedPersonByPatientId?patientId=',
    // help
    getAllTagsByOrganization: environment.resourceServer + '/api/configurazione/tags',
    postTicket: environment.resourceServer + '/api/ticket',

    // report
    stampaReport: environment.resourceServer + '/api/stampaReport',
    stampaReportTelemonitoraggio: environment.resourceServer + '/api/stampaReportTelemonitoraggio',
    createOrUpdateDiagnisisPrognosis: environment.resourceServer + '/api/diagnosisPrognosisByClinicalImpressionId/',

    //sintomi
    getAllSymptoms: environment.resourceServer + '/api/getAllSymptoms',

    // Esportazione NLAB in Excel
    exportNLABPatient: environment.resourceServer + '/api/exportNLABPatient',
    exportNLABPatientWithAlerts: environment.resourceServer + '/api/exportNLABPatientWithAlerts',
    exportAllNLABPatient: environment.resourceServer + '/api/exportAllNLABPatient',

    // Esportazione dati wearable
    exportDataFromDevices: environment.resourceServer + '/api/exportDataFromDevices',

    // Diario
    getAllPages: environment.resourceServer + '/api/getAllPages',
    createNewPage: environment.resourceServer + '/api/createNewPage',
    updatePage: environment.resourceServer + '/api/updatePage',
    deletePage: environment.resourceServer + '/api/deletePage',

    // Logout
    logout: environment.authServer + '/api/logout',

    //log event 
    logNewEvent: environment.resourceServer + "/logevent/newEvent",
    checkLogEventResource: environment.resourceServer + "/logevent/checkPatientResource",

    //refactoringElastic
    elasticQuery: environment.resourceServer + '/elastic/',

    //Configuratore Questionari
    ordinamentoDomande: environment.resourceServer + '/api/PutOrdinamentoDomande/',
    getQuestionari: environment.resourceServer + '/api/GetQuestionari',
    getDomande: environment.resourceServer + '/api/GetDomande',
    getRisposte: environment.resourceServer + '/api/GetRisposte',
    getRisposteByDomande: environment.resourceServer + '/api/GetRisposteByDomanda/',
    getQuestionariByPaziente: environment.resourceServer + '/api/GetQuestionariByPaziente/',
    getQuestionariAssociatiByPaziente: environment.resourceServer + "/api/get-questionari-associati-by-idpaziente/",
    getDomandeByQuestionario: environment.resourceServer + '/api/GetDomandeByQuestionario/',
    getLinkDomandeByQuestionario: environment.resourceServer + '/api/GetLinkDomandeByQuestionario/',
    getRisposteByTipologia: environment.resourceServer + '/api/GetRisposteByTipologia/',
    postQuestionario: environment.resourceServer + '/api/PostQuestionario',
    putQuestionario: environment.resourceServer + '/api/PutQuestionario/',
    addDomanda: environment.resourceServer + '/api/AddDomanda',
    putQuestionarioToPaziente: environment.resourceServer + '/api/PutQuestionarioToPaziente/',
    putQuestionarioDuplicate: environment.resourceServer + '/api/PutQuestionarioDuplicate/',
    postDomanda: environment.resourceServer + '/api/PostDomanda',
    postRisposta: environment.resourceServer + '/api/PostRisposta',
    deleteQuestionario: environment.resourceServer + '/api/DeleteQuestionario/',
    deleteDomanda: environment.resourceServer + '/api/DeleteDomanda/',
    deleteRisposta: environment.resourceServer + '/api/DeleteRisposta/',
    deleteQuestionario_paziente: environment.resourceServer + '/api/DeleteQuestionario_paziente/',
    deleteDomanda_questionario: environment.resourceServer + '/api/DeleteDomanda_questionario/',
    putDomanda: environment.resourceServer + '/api/PutDomanda/',
    putRisposta: environment.resourceServer + '/api/PutRisposta/',
    getQuestionari_PazienteExport: environment.resourceServer + '/api/GetQuestionari_PazienteExport/',
    getQuestionari_PazienteExportByCadenza: environment.resourceServer + '/api/GetQuestionari_PazienteExportByCadenza/',
    assignQuestionnaireToPatient: environment.resourceServer + "/api/assignQuestionnaireToPatient",
    updateAssignmentToPatient: environment.resourceServer + '/api/updateAssignmentToPatient',

    //gestione notifiche CRUD
    createEventS: environment.mobile + '/eventMobile/create/',
    putEventS: environment.mobile + '/eventMobile/modify/',
    deleteEventS: environment.mobile + '/eventMobile/delete/',
    getEventS: environment.mobile + '/eventMobile/getEventByOrg/',
    creaIndiceElastic: environment.resourceServer + '/elastic/creaIndiceElastic',
    popolaIndiceElastic: environment.resourceServer + '/elastic/popolaIndiceElastic',

    // Pulse-Oximeter
    getDatePulseOximeter: environment.mobile + '/api/continuousmeasurement/get-timestamp/',
    getPulseOximeter: environment.mobile + '/api/continuousmeasurement/get-date-pulse-oximeter/',
    getListPulseOximeter: environment.mobile + '/api/continuousmeasurement/get-listdate-pulse-oximeter/',

    // Ecg
    getDateEcg: environment.mobile + '/api/getDateEcg/',
    getEcg: environment.mobile + '/api/getEcg/',
    getPdf: environment.mobile + '/api/getPdf',
    // Download Excel di tutti i pazienti
    exportAllVisitsAllPatients: environment.resourceServer + '/api/exportAllVisitsAllPatients',

    //eventi avversi
    getEventiAvversi: environment.resourceServer + '/api/PutEventiAvversi',
    getAdverseEvent: environment.resourceServer + '/api/GetEventiAvversi/',
    updateAdverseEvent: environment.resourceServer + '/api/UpdateEventiAvversi',
    //Grafana
    getLogGrafana: environment.resourceServer + '/search/getLog',
    //Devices
    saveDevice: environment.resourceServer + '/api/addDevice',
    getDevices: environment.resourceServer + '/api/getDevices',
    getActiveDevices: environment.resourceServer + '/api/getActiveDevices',
    getInactiveDevices: environment.resourceServer + '/api/getInactiveDevices',
    getDeviceByIdentifier: environment.resourceServer + '/api/getDevice/',
    deleteDevice: environment.resourceServer + '/api/deleteDevice/',
    updateDevice: environment.resourceServer + '/api/updateDevice/',
    getDeviceByCarePlanId: environment.resourceServer + '/api/getDeviceByCarePlanId/',

    // Piano di monitoraggio
    addMonitoringPlan: environment.resourceServer + '/api/addMonitoringPlan',
    getMonitoringCarePlanByClinicalImpressionId: environment.resourceServer + '/api/getMonitoringCarePlanByClinicalImpressionId/',
    getMonitoringByClinicalImpressionId: environment.resourceServer + '/api/getMonitoringByClinicalImpressionId/',
    getMonitoringProcedureRequestsByCarePlanId: environment.resourceServer + '/api/getMonitoringProcedureRequestsByCarePlanId/',
    getMonitoringThresholdsByCarePlanId: environment.resourceServer + '/api/getMonitoringThresholdsByCarePlanId/',
    getMonitoringCarePlanBySubject: environment.resourceServer + '/api/getMonitoringCarePlanBySubject/',
    addMonitoringPlanWithoutClinicalImpression: environment.resourceServer + '/api/addMonitoringPlanWithoutClinicalImpression',
    // getParametriMonitorabiliConSoglie: environment.resourceServer + '/api/getParametriMonitorabiliConSoglie',

    //Documenti
    addDocument: environment.resourceServer + '/api/addDocument',
    deleteDocument: environment.resourceServer + '/api/deleteDocument',
    getDocuments: environment.resourceServer + '/api/getDocuments',

    // Diario clinico
    getAllAnnotationsByPatientId: environment.resourceServer + '/api/getAllAnnotationsByPatientId/',
    createNewAnnotation: environment.resourceServer + '/api/createNewAnnotation',
    updateAnnotation: environment.resourceServer + '/api/updateAnnotation/',
    deleteAnnotation: environment.resourceServer + '/api/deleteAnnotation/',
    getAllPractitionersByIds: environment.resourceServer + '/api/getAllPractitionersByIds/',

    // Schede digitali del paziente (diario clinico del paziente)
    getAllPatientDigitalCardsByPatientReferenceByInterval: environment.mobile + '/api/getAllPatientDigitalCardsByPatientReferenceByInterval/',

    // Eventi del piano di monitoraggio
    getMeasuresWithEventByPatientIdAndSourceAndTimestampRange: environment.mobile + '/getMeasuresWithEventByPatientIdAndSourceAndTimestampRange/',
    getMeasuresWithEventByPatientIdAndAnswareIdAndTimestampRange: environment.mobile + '/getMeasuresWithEventByPatientIdAndAnswareIdAndTimestampRange/',
    getAllDevicesByIds: environment.resourceServer + '/api/getAllDevicesByIds?ids=',

};
