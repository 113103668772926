import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../../service/pazienti.service';
import { getEndpointsMeasure } from '../endpoint-charts';
import { DateFromTo } from '../Model/date-from-to';
import { getBarChartOptions, yAxisMood } from '../Utils/apexchart-opt';
import { getSeriesDataValue, SerieType } from '../Utils/grafici-utils';
// tslint:disable-next-line: max-line-length
import { getInputBrMeasures, getInputFaticaMeasures, getInputHrMeasures, getInputMoodMeasures, getInputPressureMeasures, getInputSleepMeasures, getInputSpo2Measures, getInputStepsMeasures, getInputTemperatureMeasures } from '../Utils/query-input';
import { getTooltipByEventInformation, getTooltipByEventInformationAndValues } from 'src/app/utils/utils';

@Component({
  selector: 'app-grafici-werable',
  templateUrl: './grafici-werable.component.html',
  styleUrls: ['./grafici-werable.component.scss']
})
export class GraficiWerableComponent implements OnInit, OnDestroy {

  @Input() onlyMonitoringEvents = false;
  labelFonte = "";
  allEvents = [];
  timestamp = [];

  @Input() identifier;

  @Input() source;

  @Input() grafico;

  @Input('date') set date(date: DateFromTo) {
    if (!date.compare(this._date)) {
      this.observer.next(date);
    }
  }

  @Output() stats = new EventEmitter<any>();

  // Usato solo per il report
  @Input() showHeader: boolean;
  @Input() tipoGrafico;
  @Input() altezzaGrafico;
  @Input() larghezzaGrafico;

  // tslint:disable-next-line: variable-name
  _date: DateFromTo;

  observer: BehaviorSubject<DateFromTo>;

  dateFromString: string;
  dateToString: string;

  dateFromTimeStamp;
  dateToTimeStamp;

  dateFromES: number;
  dateToES: number;

  opt: SelectItem[];

  chartThreshold;

  patientId;

  selected;

  @ViewChild('chart', { static: true }) chartRef: ElementRef;
  chart: ApexCharts;

  stroke: ApexStroke = {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    width: 3,
    dashArray: 0,
  };

  strokeBar: ApexStroke = {
    width: 1
  };

  hrChartArray: SerieType =
    {
      name: 'Frequenza cardiaca',
      color: ['#FA4443'],
      serie: [],
      display: ['Frequenza cardiaca'],
      type: ['area'],
      maxyaxis: 230,
      stroke: this.stroke,
      markers: false,
      fill: true
    };
  bpChartArray: SerieType =
    {
      name: 'Pressione sanguigna',
      color: ['#FA4443', '#008FFB'],
      serie: [],
      display: ['Pressione (Max)', 'Pressione (Min)'],
      type: ['area', 'area'],
      maxyaxis: 200,
      stroke: this.stroke,
      markers: false,
      fill: true
    };
  breathChartArray: SerieType =
    {
      name: 'Frequenza respiratoria',
      color: ['#008FFB'],
      serie: [],
      display: ['Frequenza respiratoria'],
      type: ['area'],
      maxyaxis: 80,
      stroke: this.stroke,
      markers: false,
      fill: true
    };
  stepsChartArray: SerieType =
    {
      name: 'Passi',
      color: '#008FFB',
      serie: [],
      display: ['Passi'],
      type: ['bar'],
      maxyaxis: 30000,
      stroke: this.strokeBar,
      markers: false,
      fill: true
    };
  moodChartArray: SerieType =
    {
      name: 'Mood',
      color: '#008FFB',
      serie: [],
      display: ['Mood'],
      type: ['scatter'],
      maxyaxis: 90,
      markers: true,
      yaxis: yAxisMood,
      fill: false,
      grid: true
    };
  faticaChartArray: SerieType =
    {
      name: 'Fatica',
      color: '#008FFB',
      serie: [],
      display: ['Energia'],
      type: ['area'],
      maxyaxis: 100,
      stroke: this.stroke,
      markers: false,
      fill: true
    };
  ossigenoChartArray: SerieType =
    {
      name: 'Ossigenazione',
      color: '#008FFB',
      serie: [],
      display: ['Ossigenazione'],
      type: ['area'],
      maxyaxis: 100,
      stroke: this.stroke,
      markers: false,
      fill: true
    };
  sleepChartArray: SerieType =
    {
      name: 'Sonno',
      color: ['#008FFB', '#FA4443'],
      serie: [],
      display: ['Sonno Profondo', 'Sonno Leggero'],
      type: ['column', 'column'],
      maxyaxis: 12,
      stroke: this.strokeBar,
      markers: false,
      fill: true
    };
  temperaturaChartArray: SerieType =
    {
      name: 'Temperatura',
      color: ['#FA4443'],
      serie: [],
      display: ['Temperatura'],
      type: ['area'],
      maxyaxis: 60,
      stroke: this.stroke,
      markers: false,
      fill: true
    };

  constructor(private http: HttpClient, private toastr: ToastrService, private datePipe: DatePipe,
    private userService: UserService, private patientService: PazientiService) {
    this.observer = new BehaviorSubject<DateFromTo>(null);
  }

  ngOnInit() {

    switch (this.source) {
      case "wearable":
        this.labelFonte = "Smartwatch";
        break;
      case "t-shirt":
        this.labelFonte = "Comftech T-Shirt";
        break;
      case "Checkme":
        this.labelFonte = "Checkme";
        break;
      case "AirBP":
        this.labelFonte = "AirBp2";
        break;
    }

    if (this.showHeader === undefined) {
      this.showHeader = true;
    }
    const paziente = this.patientService.getPatient();
    this.patientId = paziente.id;
    if (this.tipoGrafico !== undefined) {
      this.selected = this.tipoGrafico;
    } else {
      this.selected = 'Frequenza cardiaca';
    }
    if (this.grafico) {
      if (this.grafico === 'frequenzaCardiaca') {
        this.opt = [
          { label: 'Frequenza cardiaca', value: 'Frequenza cardiaca' },
        ];
        this.selected = 'Frequenza cardiaca';
      } else if (this.grafico === 'ossigeno') {
        this.opt = [
          { label: 'Ossigenazione', value: 'Ossigenazione' },
        ];
        this.selected = 'Ossigenazione';
      } else if (this.grafico === 'temperatura') {
        this.opt = [
          { label: 'Temperatura', value: 'Temperatura' },
        ];
        this.selected = 'Temperatura';
      }
    } else if (this.source == 'Checkme') {
      this.opt = [
        { label: 'Frequenza cardiaca', value: 'Frequenza cardiaca' },
        { label: 'Pressione sanguigna', value: 'Pressione sanguigna' },
        { label: 'Frequenza respiratoria', value: 'Frequenza respiratoria' },
        { label: 'Passi', value: 'Passi' },
        //{ label: 'Mood', value: 'Mood' },
        //{ label: 'Energia', value: 'Fatica' },
        { label: 'Ossigenazione', value: 'Ossigenazione' },
        //{ label: 'Sonno', value: 'Sonno' },
      ];
    } else if (this.source === 'AirBP') {
      this.opt = [
        { label: 'Pressione sanguigna', value: 'Pressione sanguigna' },
      ];
      this.selected = 'Pressione sanguigna';
    }

    this.observer.subscribe((input) => {
      if (this.identifier && input) {
        this._date = input;
        this.dateFromString = this.datePipe.transform(input.getDateFrom(), 'yyyy-MM-dd');
        this.dateToString = this.datePipe.transform(input.getDateTo(), 'yyyy-MM-dd');
        this.dateFromTimeStamp = Math.round(input.getDateFrom().getTime());
        this.dateToTimeStamp = Math.round(input.getDateTo().getTime());
        this.dateFromES = Number.parseInt(this.datePipe.transform(input.getDateFrom(), 'yyyyMMdd'), 10);
        this.dateToES = Number.parseInt(this.datePipe.transform(input.getDateTo(), 'yyyyMMdd'), 10);
        // this.getClinicalImpressionList(this.selected);
        this.dropdownChange(this.selected);
      }
    });
    if (this.source === 'wearable') {
      this.opt = [
        { label: 'Frequenza cardiaca', value: 'Frequenza cardiaca' },
        { label: 'Ossigenazione', value: 'Ossigenazione' },
        { label: 'Pressione sanguigna', value: 'Pressione sanguigna' },
        { label: 'Frequenza respiratoria', value: 'Frequenza respiratoria' },
        { label: 'Passi', value: 'Passi' },
        /*{ label: 'Mood', value: 'Mood' },
        { label: 'Energia', value: 'Fatica' },
        { label: 'Sonno', value: 'Sonno' },*/

      ];
    } else if (this.source === 't-shirt') {
      this.opt = [
        { label: 'Frequenza cardiaca', value: 'Frequenza cardiaca' },
        { label: 'Temperatura', value: 'Temperatura' },
        { label: 'Frequenza respiratoria', value: 'Frequenza respiratoria' },

      ];
    } else if (this.source === 'Checkme') {
      this.opt = [
        { label: 'Frequenza cardiaca', value: 'Frequenza cardiaca' },
        { label: 'Pressione sanguigna', value: 'Pressione sanguigna' },
        { label: 'Ossigenazione', value: 'Ossigenazione' },
        { label: 'Temperatura', value: 'Temperatura' },
      ];
    }
    this.dropdownChange(this.selected);
  }

  ngOnDestroy(): void {
    this.observer.unsubscribe();
  }

  getThresholds(event, clinicalImpressionId) {
    this.chartThreshold = null;
    this.http.get(endpoints.getVitalSignsThresholds + clinicalImpressionId).toPromise()
      .then((resp: any) => {
        if (resp && resp.success === '1') {
          const monitoringObs = resp.message;
          const find = monitoringObs.find(x => x.code.coding[0].code === this.getCodeMeadsure(event));
          if (find && find.referenceRange[0].low.value > 0) {
            if (this.getCodeMeadsure(event) === '8480-6') {
              const find1 = monitoringObs.find(x => x.code.coding[0].code === '8462-4');
              this.chartThreshold = {
                yaxis: [{
                  y: find.referenceRange[0].low.value,
                  y2: find.referenceRange[0].high.value,
                  borderColor: '#000',
                  fillColor: '#5cb85c',
                  opacity: 0.5,
                  label: {
                    borderColor: '#333',
                    style: {
                      fontSize: '10px',
                      color: '#333',
                      background: '#5cb85c',
                    },
                    offsetY: 10,
                    text: 'Range di monitoraggio',
                  }
                },
                {
                  y: find1.referenceRange[0].low.value,
                  y2: find1.referenceRange[0].high.value,
                  borderColor: '#000',
                  fillColor: '#5cb85c',
                  opacity: 0.5,
                  label: {
                    borderColor: '#333',
                    style: {
                      fontSize: '10px',
                      color: '#333',
                      background: '#5cb85c',
                    },
                    offsetY: 10,
                    text: 'Range di monitoraggio',
                  }
                }]
              };
            } else {
              this.chartThreshold = {
                yaxis: [{
                  y: find.referenceRange[0].low.value,
                  y2: find.referenceRange[0].high.value,
                  borderColor: '#000',
                  fillColor: '#5cb85c',
                  opacity: 0.5,
                  label: {
                    borderColor: '#333',
                    style: {
                      fontSize: '10px',
                      color: '#333',
                      background: '#5cb85c',
                    },
                    offsetY: 10,
                    text: 'Range di monitoraggio',
                  }
                }]
              };
            }
          } else {
            this.chartThreshold = null;
          }
          this.dropdownChange(this.selected);
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  getClinicalImpressionList(event) {
    this.http.get(endpoints.getLastClinicalImpressionByPatientIdMonitoraggio + '/' + this.patientService.getPatientId()).toPromise()
      .then((resp: any) => {
        if (resp.total > 0) {
          this.getThresholds(event, resp.entry[0].resource.id);
        } else if (resp.total === 0) {
          this.dropdownChange(this.selected);
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  getCodeMeadsure(key) {
    switch (key) {
      case 'Frequenza cardiaca': {
        return '8867-4';
      }
      case 'Ossigenazione': {
        return '59408-5';
      }
      case 'Pressione sanguigna': {
        return '8480-6';
      }
      default:
        return null;
    }
  }

  dropdownChange(event) {
    switch (event) {
      case 'Frequenza cardiaca':
        this.createChart(this.hrChartArray, 'FrequenzaCardiacaID', 'Frequenza cardiaca');
        break;
      case 'Pressione sanguigna':
        this.createChart(this.bpChartArray, 'PressioneSanguignaID', 'Pressione sanguigna');
        break;
      case 'Frequenza respiratoria':
        this.createChart(this.breathChartArray, 'FrequenzaRespiratoriaID', 'Frequenza respiratoria');
        break;
      case 'Passi':
        this.createChart(this.stepsChartArray, 'StepsID', 'Passi');
        break;
      case 'Mood':
        this.createChart(this.moodChartArray, 'MoodID', 'Mood');
        break;
      case 'Fatica':
        this.createChart(this.faticaChartArray, 'FaticaID', 'Fatica');
        break;
      case 'Ossigenazione':
        this.createChart(this.ossigenoChartArray, 'OssigenoID', 'Ossigenazione');
        break;
      case 'Sonno':
        this.createChart(this.sleepChartArray, 'SonnoID', 'Sonno');
        break;
      case 'Temperatura':
        this.createChart(this.temperaturaChartArray, 'TemperaturaID', 'Temperatura');
        break;
      default:
        break;
    }

  }

  DataFromElastic(title, date, identifier) {
    this.identifier = identifier;
    this.patientId = identifier;
    this.dateFromTimeStamp = Math.round(date.getDateFrom().getTime());
    this.dateToTimeStamp = Math.round(date.getDateTo().getTime());
    this.getDataNew(title).then((response: any) => {
      return response.hits.hits;
    }).catch(err => {
      console.error(err);
      this.toastr.error('Errore nel recupero dei dati.');
    });

  }

  createChartDataURI(chartArray: SerieType, id, title, date, identifier, chartref) {
    return new Promise((resolve, reject) => {
      this.identifier = identifier;
      this.patientId = identifier;
      this.source = 'helo';
      this.dateFromTimeStamp = Math.round(date.getDateFrom().getTime());
      this.dateToTimeStamp = Math.round(date.getDateTo().getTime());
      this.getDataNew(title).then((response: any) => {
        const series: ApexAxisChartSeries = [];
        let seriesLabels = null;
        let labels = [];
        // tslint:disable-next-line: max-line-length
        if ((response.aggregations && response.aggregations.stepsDays.buckets.length > 0) || (response.hits && response.hits.hits && response.hits.hits.length > 0)) {
          seriesLabels = getSeriesDataValue(response, chartArray.display, chartArray.type, chartArray.name);
          if (Array.isArray(seriesLabels.serie)) {
            seriesLabels.serie.forEach(element => {
              series.push(element);
            });
          } else {
            series.push(seriesLabels.serie);
          }
          labels = seriesLabels.labels;
        }
        if (series && series.length === 0) {
          chartArray.display.forEach((el, i) => {
            series.push({ data: [], type: chartArray.type[i], name: el });
          });
        }

        const chartArr = new Array<SerieType>();
        chartArr.push(chartArray);
        // tslint:disable-next-line: max-line-length
        const options = getBarChartOptions(id, title, series, chartArr, labels, chartArray.yaxis, chartArray.stroke, chartArray.markers, chartArray.fill, chartArray.grid);
        options.annotations = { yaxis: [] };


        options.tooltip.shared = true;
        options.tooltip.intersect = false;
        options.tooltip.followCursor = true;
        this.chart = new ApexCharts(chartref.nativeElement, options);
        this.chart.render();
        resolve(this.chart);

      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel recupero dei dati.');
      });


    }
    );
  }

  createChart(chartArray: SerieType, id, title) {
    this.getDataNew(title).then((response: any) => {
      const series: ApexAxisChartSeries = [];

      let seriesLabels = null;
      let labels = [];
      if (!this.onlyMonitoringEvents) {
        // Ha recuperato i dati da elastic
        // tslint:disable-next-line: max-line-length
        if ((response.aggregations && response.aggregations.stepsDays && response.aggregations.stepsDays.buckets.length > 0) ||
          (response.aggregations && response.aggregations.date_aggs && response.aggregations.date_aggs.buckets.length > 0) ||
          (response.hits && response.hits.hits && response.hits.hits.length > 0)) {
          seriesLabels = getSeriesDataValue(response, chartArray.display, chartArray.type, chartArray.name);
          if (Array.isArray(seriesLabels.serie)) {
            seriesLabels.serie.forEach(element => {
              series.push(element);
            });
          } else {
            series.push(seriesLabels.serie);
          }
          labels = seriesLabels.labels;
        }
        if (series && series.length === 0) {
          chartArray.display.forEach((el, i) => {
            series.push({ data: [], type: chartArray.type[i], name: el });
          });
        }
      } else {
        // Ha recuperato i dati dall'evento del piano di monitoraggio
        (response as []).sort((a, b) => {
          let ta = new Date((a as any).timestampOperation);
          let tb = new Date((b as any).timestampOperation);
          return ta.getTime() - tb.getTime();
        });

        (response as []).forEach((r: any) => {
          let typeMeasure = r.answareId;
          let value = r.value;
          let informazioniEvento = {
            timestampOperation: r.timestampOperation,
            timestampCompletition: r.timestamp_completition,
            source: r.source
          }
          let timestamp = new Date(r.timestampOperation).getTime();
          switch (typeMeasure) {
            case "2":
              if (title != 'Pressione sanguigna') {
                return;
              }
              if (series[0] == undefined) {
                series[0] = {
                  name: "Pressione (Max)",
                  type: "area",
                  data: []
                };
              }
              series[0].data.push({ x: timestamp, y: value } as any);
              labels.push(timestamp);
              this.timestamp.push(timestamp);
              this.allEvents.push({ timestamp: timestamp, info: informazioniEvento });
              break;
            case "3":
              if (title != 'Pressione sanguigna') {
                return;
              }
              if (series[1] == undefined) {
                series[1] = {
                  name: "Pressione (Min)",
                  type: "area",
                  data: []
                };
              }
              series[1].data.push({ x: timestamp, y: value } as any);
              break;
            case "1":
              if (title != 'Frequenza cardiaca') {
                return;
              }
              if (series[0] == undefined) {
                series[0] = {
                  name: title,
                  type: "area",
                  data: []
                };
              }
              series[0].data.push({ x: timestamp, y: value } as any);
              labels.push(timestamp);
              this.timestamp.push(timestamp);
              this.allEvents.push({ timestamp: timestamp, info: informazioniEvento });
              break;
            case "14":
              if (title != 'Temperatura') {
                return;
              }
              if (series[0] == undefined) {
                series[0] = {
                  name: title,
                  type: "area",
                  data: []
                };
              }
              series[0].data.push({ x: timestamp, y: value } as any);
              labels.push(timestamp);
              this.timestamp.push(timestamp);
              this.allEvents.push({ timestamp: timestamp, info: informazioniEvento });
              break;
            case "5":
              if (title != 'Ossigenazione') {
                return;
              }
              if (series[0] == undefined) {
                series[0] = {
                  name: title,
                  type: "area",
                  data: []
                };
              }
              series[0].data.push({ x: timestamp, y: value } as any);
              labels.push(timestamp);
              this.timestamp.push(timestamp);
              this.allEvents.push({ timestamp: timestamp, info: informazioniEvento });
              break;
          }
        });
      }

      if (title === 'Frequenza cardiaca' || title === 'Frequenza respiratoria' || title === 'Ossigenazione') {
        let tmp: number[] = [];
        let sum = 0;
        series[0].data.forEach(element => {
          sum = sum + element.y;
          tmp.push(element.y);
        });

        this.stats.emit({
          min: Math.min.apply(null, tmp),
          avg: Math.round(sum / tmp.length),
          max: Math.max.apply(null, tmp),
        })
      }


      if (title === 'Pressione sanguigna') {
        let tmp: number[] = [];
        let sum = 0;
        series[0].data.forEach(element => {
          sum = sum + element.y;
          tmp.push(element.y);
        });

        let tmp1: number[] = [];
        let sum1 = 0;
        series[1].data.forEach(element => {
          sum1 = sum1 + element.y;
          tmp1.push(element.y);
        });

        this.stats.emit({
          min: Math.min.apply(null, tmp) + '/' + Math.min.apply(null, tmp1),
          avg: Math.round(sum / tmp.length) + '/' + Math.round(sum1 / tmp1.length),
          max: Math.max.apply(null, tmp) + '/' + Math.max.apply(null, tmp1),
        })
      }

      const chartArr = new Array<SerieType>();
      chartArr.push(chartArray);
      // tslint:disable-next-line: max-line-length
      const options = getBarChartOptions(id, title, series, chartArr, labels, chartArray.yaxis, chartArray.stroke, chartArray.markers, chartArray.fill, chartArray.grid);
      if (this.altezzaGrafico !== undefined) {
        options.chart.height = this.altezzaGrafico;
      }
      if (this.larghezzaGrafico !== undefined) {
        options.chart.width = this.larghezzaGrafico;
      }
      if (title === 'Temperatura' || title === 'Ossigenazione') {
        options.yaxis[0].decimalsInFloat = 1;
      }
      options.annotations = { yaxis: [] };
      options.tooltip.shared = true;
      options.tooltip.intersect = false;
      options.tooltip.followCursor = true;

      if (this.onlyMonitoringEvents) {
        options.tooltip.custom = ({ series, seriesIndex, dataPointIndex, w }) => {
          let tf = this.timestamp[dataPointIndex];
          let f = this.allEvents.find(ae => ae.timestamp == tf);
          if (f == undefined) {
            return '';
          }

          if (options.title.text == 'Pressione sanguigna') {
            let value = series[0][dataPointIndex];
            let value2 = series[1][dataPointIndex];
            return getTooltipByEventInformationAndValues(f.info, [value, value2]);
          } else {
            let value = series[seriesIndex][dataPointIndex];
            return getTooltipByEventInformation(f.info, value);
          }
        };
      }

      if (this.chart) {
        if (this.chartThreshold) {
          options.annotations = this.chartThreshold;
        }
        this.chart.updateOptions(options, false, false, true);
        // ApexCharts.exec(id,'updateOptions',options)
      } else {
        if (this.chartThreshold) {
          options.annotations = this.chartThreshold;
        }
        this.chart = new ApexCharts(this.chartRef.nativeElement, options);
        this.chart.render();
      }
    }).catch(err => {
      console.error(err);
      this.toastr.error('Errore nel recupero dei dati.');
    });

  }

  getDataNew(event) {
    let source;
    let tempBool;
    let returnObj;

    if (this.onlyMonitoringEvents) {
      // Recupero i dati dagli eventi del piano di monitoraggio
      return this.http.get(endpoints.getMeasuresWithEventByPatientIdAndSourceAndTimestampRange + this.patientId + "/" + this.source + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp).toPromise();
    }

    if (this.source === 'wearable') {
      source = 'Lite OR Vista OR VYVO';
    } else {
      source = this.source;
    }
    switch (event) {
      case 'Frequenza cardiaca':
        if (getInputHrMeasures(this.dateFromTimeStamp, this.dateToTimeStamp, this.patientId, source).size == 0) {
          tempBool = true;
        } else {
          tempBool = false;
        }
        returnObj = this.http.get(endpoints.elasticQuery + "getHR/" + this.patientId + "/" + source + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-hr-br/" + tempBool).toPromise();
        break;
      case 'Pressione sanguigna':
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getPressure/" + this.patientId + "/" + source + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-pressure").toPromise();
        break;
      case 'Frequenza respiratoria':
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getBR/" + this.patientId + "/" + source + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-hr-br").toPromise();
        break;
      case 'Passi':
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getSteps/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-steps").toPromise();
        break;
      case 'Mood':
        // Calculate new startDate/endDate in order to correct error (subtract 229 days)
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getMood/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-mood").toPromise();
        break;
      case 'Fatica':
        // Calculate new startDate/endDate in order to correct error (subtract 229 days)
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getEnergy/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-mood").toPromise();
        break;
      case 'Ossigenazione':
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getOx/" + this.patientId + "/" + source + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-spo2").toPromise();
        break;
      case 'Temperatura':
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getTemperature/" + this.patientId + "/" + source + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-temperature").toPromise();
        break;
      case 'Sonno':
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getSleep/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-sleep").toPromise();
        break;
      // case 'Temperatura':
      //   // tslint:disable-next-line: max-line-length
      //   return this.http.post(endpointsChart.searchVyvoCovidHr, getInputHeloHrMeasures(this.dateFromTimeStamp, this.dateToTimeStamp, 'this.identifier')).toPromise();
      default:
        break;
    }
    return returnObj;
  }
}
