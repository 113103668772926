import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { ToastrService } from "ngx-toastr";
import { last } from "rxjs-compat/operator/last";
import { endpoints } from "src/endpoint/endpoints";
import { AlertService } from "../main/pazienti/alert-management/service/alert.service";
import { DateFromTo } from "../main/pazienti/monitoraggio/Model/date-from-to";
import { SerieType } from "../main/pazienti/monitoraggio/Utils/grafici-utils";
import { PazientiService } from "../main/pazienti/service/pazienti.service";
import { Condition } from "../model/Condition";
import { TokenService } from "../service/token.service";
import { UserService } from "../service/user-service.service";
import { Farmaco } from "../main/pazienti/visite/visite/trattamenti-procedure/trattamenti-procedure.component";
import { SelectItem } from "primeng/api";
import { optionsDescrizioneFarmaco } from "../main/pazienti/visite/visite/trattamenti-procedure/model/optionsDescrizioneFarmaco";
import { optionsFrequenzaFarmaco } from "../main/pazienti/visite/visite/trattamenti-procedure/model/optionsFrequenzaFarmaco";
import { optionsStatoFarmaco } from "../main/pazienti/visite/visite/trattamenti-procedure/model/optionsStatoFarmaco";
@Component({
  selector: 'app-report-telemonitoraggio',
  templateUrl: './report-telemonitoraggio.component.html',
  styleUrls: ['./report-telemonitoraggio.component.scss']
})
export class ReportTelemonitoraggioComponent implements OnInit {

  @ViewChild('op', { static: false }) op: ElementRef;

  @ViewChild('graficoSintomi', { static: false }) graficoSintomi: any;
  @ViewChild('graficoWerable', { static: false }) graficoWerable: any;
  @ViewChild('graficoDomande', { static: false }) graficoDomande: any;
  @ViewChild('graficoFarmaci', { static: false }) graficoFarmaci: any;
  @ViewChild('graficoHeader', { static: false }) graficoHeader: any;
  @ViewChild('graficoVs', { static: false }) graficoVs: any;
  @ViewChild('all', { static: false }) all: ElementRef;
  @ViewChild('fakeChart', { static: true }) fakeChart: ElementRef;




  statsHR = {
    min: 0,
    avg: 0,
    max: 0,
  };
  statsBR = {
    min: 0,
    avg: 0,
    max: 0,
  };
  statsSys = {
    min: 0,
    avg: 0,
    max: 0,
  };;
  statsDis = {
    min: 0,
    avg: 0,
    max: 0,
  };;
  statsSpo = {
    min: 0,
    avg: 0,
    max: 0,
  };;

  paziente;
  subscriber;
  nome: string;
  datePickerConf = { isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' };
  date: DateFromTo;
  locale = 'it';
  dataGrafici: Date[];
  descrizioneFarmaco: SelectItem[];
  frequenzaFarmaco: SelectItem[];
  statoFarmaco: SelectItem[];
  identifier;
  checkedMenu = [
    { label: 'Oggi', checked: false, days: 0, begin: 0, name: 'oggi' },
    { label: 'Ieri', checked: false, days: 1, begin: 1, name: 'ieri' },
    { label: 'Ultimi 7 giorni', checked: false, days: 7, begin: 0, name: 'ultimi7' },
    { label: 'Ultimi 30 giorni', checked: true, days: 30, begin: 0, name: 'ultimi30' },
    { label: 'Questo Mese', checked: false, days: 0, begin: 0, begin_day: 1, name: 'questo_mese' },
    { label: 'Mese Precedente', checked: false, days: 0, begin: 0, month: 1, name: 'mese_precedente' },
  ];
  cols = [
    { value: 'code', label: 'Codice' },
    { value: 'display', label: 'Descrizione' }
  ];
  descrizione;
  listICD: Condition[];
  filterListICD: Condition[];
  epicrisi;
  clinicalImpressionId;
  listICF = [];
  filterListICF = [];
  valutazioneMonitoraggio;

  observations = [];
  rows = [];
  clinicalImpressionDate: Date;

  diagnosis = "";
  prognosis = "";


  pezzotto = false;
  layout = 1;
  layout2 = 1;


  stroke: ApexStroke = {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    width: 3,
    dashArray: 0,
  };

  strokeBar: ApexStroke = {
    width: 1
  };

  hrChartArray: SerieType =
    {
      name: 'Frequenza cardiaca',
      color: ['#FA4443'],
      serie: [],
      display: ['Frequenza cardiaca'],
      type: ['area'],
      maxyaxis: 230,
      stroke: this.stroke,
      markers: false,
      fill: true
    };

  bpChartArray: SerieType =
    {
      name: 'Pressione sanguigna',
      color: ['#FA4443', '#008FFB'],
      serie: [],
      display: ['Pressione (Max)', 'Pressione (Min)'],
      type: ['area', 'area'],
      maxyaxis: 200,
      stroke: this.stroke,
      markers: false,
      fill: true
    };

  ossigenoChartArray: SerieType =
    {
      name: 'Ossigenazione',
      color: '#008FFB',
      serie: [],
      display: ['Ossigenazione'],
      type: ['area'],
      maxyaxis: 100,
      stroke: this.stroke,
      markers: false,
      fill: true
    };

  stepsChartArray: SerieType =
    {
      name: 'Passi',
      color: '#008FFB',
      serie: [],
      display: ['Passi'],
      type: ['bar'],
      maxyaxis: 30000,
      stroke: this.strokeBar,
      markers: false,
      fill: true
    };

  xAxis = {
    /*type: 'datetime',
    labels: {
      offsetX: -15
    }*/
    type: 'datetime',
    labels: {
      rotate: -45,
      rotateAlways: false,
      offsetX: -15,
      style: {
        fontSize: '12px'
      }
    }
  };

  yAxis = {
    max: 4,
    min: 0,
    tickAmount: 4,
    labels: {
      offsetX: 5,
      style: {
        color: '#008FFB',
        // fontSize: '' + fontSize,
      },
      formatter: (y) => {
        if (typeof y !== 'undefined' && y === 3) {
          return 'Completo';
        } else if (typeof y !== 'undefined' && y === 1) {
          return 'Incompleto';
        } else {
          return '';
        }
      },
      minWidth: 60,
      maxWidth: 160
    }
  };

  capeChartArray: SerieType =
    {
      name: 'Capecitabina',
      color: '#008FFB',
      serie: [],
      display: ['Capecitabina'],
      type: ['scatter'],
      markers: true,
      yaxis: this.yAxis,
      xaxis: this.xAxis,
      fill: false,
      grid: true
    };

  chartB: ApexCharts;
  chartD: ApexCharts;

  // Variabili che utilizzo
  idPaziente = ""; // Viene ricevuto tramite URL
  patient: any = {}; // Usando l'id nell'URL viene richiesto al server
  condizioniCliniche = Array();
  punteggioNLAB = undefined;
  terapie = "";
  terapieDomiciliari = "";
  descrizioneAnamnesiPatologicaProssima = "";
  descrizioneAnamnesiPatologicaRemota = "";
  terapieFarmacologiche = new Array();
  listaFarmaci = new Array();
  dataPresaInCarico = null;
  token = ""; // Viene ricevuto dall'URL
  inizioOsservazione = null;
  fineOsservazione = null; // Viene ricevuto dall'URL. Formato yyymmddyyymmdd
  crea = false; // In caso di errore fa visualizzare la pagina bianca
  nomiFarmaci = new Array(); // Per gestire i grafici dei farmaci
  avvisi = new Array();
  avvisiVerificati = new Array();
  avvisiNonVerificatiInPrimaPagina = true;
  // 0 -> tutto sulla prima pagina e una pagina per quelli visualizzati
  // 1 -> avvisi non visualizzati sulla seconda pagina e visualizzati sulla terza
  // 2 -> una pagina con avvisi visualizzati e non
  NUMERO_ALERT = 6;
  lunghezzaDescrizioni = 0;

  testLog = "";

  reportConAirBpPulsoximeter = false;
  showMediaPulsossimetroAirBp2 = true;
  statsPulsossimetroAll = {
    hr: {
      min: 0,
      avg: 0,
      max: 0,
    },
    o2: {
      min: 0,
      avg: 0,
      max: 0,
    }
  };

  statsPulsossimetroDettagli = {
    hr: {
      min: 0,
      avg: 0,
      max: 0,
    },
    o2: {
      min: 0,
      avg: 0,
      max: 0,
    }
  };

  statsAirbp2 = {
    sys: {
      min: 0,
      avg: 0,
      max: 0,
    },
    dya: {
      min: 0,
      avg: 0,
      max: 0,
    }
  }

  grafici = [];
  pagineGrafici = [];
  showMonitoraggioICF = false;

  constructor(public pazientiService: PazientiService, private toastr: ToastrService,
    public route: ActivatedRoute, public localeService: BsLocaleService,
    public datePipe: DatePipe, private http: HttpClient, private userService: UserService,
    private _sanitizer: DomSanitizer, private router: Router, private tokenService: TokenService, private ref: ChangeDetectorRef,
    private alertService: AlertService) { }

  ngOnInit() {
    this.descrizioneFarmaco = optionsDescrizioneFarmaco;
    this.frequenzaFarmaco = optionsFrequenzaFarmaco;
    this.statoFarmaco = optionsStatoFarmaco;
    // recupero informazioni dall'url
    this.route.queryParamMap.subscribe((params) => {
      if (null === params.get("id") || null === params.get("token") || null === params.get("date")) {
        if (null === params.get("id")) {
          console.error("id utente non passato.");
        }
        if (null === params.get("token")) {
          console.error("token non passato.");
        }
        if (null === params.get("date")) {
          console.error("date non passato.");
        }
        console.error("Non sono stati passati tutti i parametri obbligatori.");
        // Non fa vedere niente
        this.crea = false;
        return;
      }
      if (null !== params.get("grafici")) {
        this.grafici = params.get("grafici").split(",");
      }

      this.aggiornaIdPaziente(params.get("id"));
      this.clinicalImpressionId = params.get("clinicalImpressionId");
      this.token = params.get("token");
      this.tokenService.saveToken({ access_token: this.token });
      this.setDateOsservazione(params.get("date"));



      this.http.get(endpoints.me).subscribe(resp => {
        if (resp) {
          this.userService.saveUser(resp);
          // Recupero le informazioni del paziente usando solo l'id
          this.getListaICD();
          // Questa parte dipende dall'oggetto paziente
          this.pazientiService.getPatientFromServerByPatientId(this.idPaziente).then(resp => {
            this.pazientiService.setPatient(resp);
            this.patient = this.pazientiService.getPatient();
            this.aggiornaCampiPaziente(this.patient);
            this.setDataPresaInCarico();
            this.reportConAirBpPulsoximeter = (this.patient.identificativo as string).startsWith("uildmlc");
            this.generaPagineGrafici();
            this.getMedicationRequests();
            this.getClinicalImpression();
            this.getTerapie();

          });
        }
      }, err => {
        console.error(err);
        this.router.navigate(['/login']);
        this.tokenService.deleteToken();
        this.toastr.error('Errore nella retrive dell\'utente.');
      });
      // Ho caricato tutte le informazioni quindi posso creare
      this.crea = true;
    });
  }

  private pulisciListaGrafici() {
    this.grafici = this.grafici.filter(x => x != '' && x != 'monitoraggioICF');
  }

  private generaPagineGrafici() {
    this.pagineGrafici = [];
    // Controllo se si vuole la pagina del monitoraggio ICF
    this.showMonitoraggioICF = undefined != this.grafici.find(x => x == 'monitoraggioICF');
    this.pulisciListaGrafici();
    // this.grafici = ["smartwatch-fr", "smartwatch-fc", "smartwatch-sleep", "smartwatch-spo2", "smartwatch-bp", "pulseoximeter-all", "pulseoximeter-last", "airbp2-all"];
    let pagine = [];
    if (this.grafici.length > 0) {
      let indicePagina = -1;
      for (let indiceGrafico = 0; indiceGrafico < this.grafici.length; indiceGrafico++) {
        if (indiceGrafico % 3 == 0) {
          // Aggiungo una pagina ogni 3 grafici
          indicePagina++;
        }
        if (pagine[indicePagina] == undefined) {
          pagine[indicePagina] = {};
          pagine[indicePagina].cards = [];
        }
        let indexOfCard = 0;
        let head = this.getHeaderByGraphName(this.grafici[indiceGrafico]);
        for (indexOfCard = 0; indexOfCard < pagine[indicePagina].cards.length; indexOfCard++) {
          if (pagine[indicePagina].cards[indexOfCard].header == head) {
            break;
          }
        }
        if (pagine[indicePagina].cards[indexOfCard] == undefined) {
          pagine[indicePagina].cards[indexOfCard] = {};
          pagine[indicePagina].cards[indexOfCard].header = head;
          pagine[indicePagina].cards[indexOfCard].grafici = [];
        }
        pagine[indicePagina].cards[indexOfCard].grafici.push(this.grafici[indiceGrafico]);
      }
      this.pagineGrafici = pagine;
    }
  }

  stessaFonte(precedente, attuale) {
    let fonteAttuale = attuale.split("-")[0];
    return precedente.startsWith(fonteAttuale);
  }

  getHeaderByGraphName(graphName) {
    let fonte = graphName.split('-')[0];
    switch (fonte) {
      case "smartwatch":
        return 'Parametri vitali: (fonte Smartwatch)';
      case "pulseoximeter":
        return 'Parametri vitali: (fonte Pulsossimetro)';
      case "airbp2":
        return 'Parametri vitali: (fonte AirBp2)';
      default:
        return "Fonte non riconosciuta";
    }
  }

  getParametroIntervalloQueryFromParams(date) {
    const dataInizio = new Date();
    dataInizio.setFullYear(date.substring(0, 4));
    dataInizio.setMonth(date.substring(4, 6));
    dataInizio.setMonth(dataInizio.getMonth() - 1);
    dataInizio.setDate(date.substring(6, 8));
    dataInizio.setDate(dataInizio.getDate() - 1);
    const meseInizio = (dataInizio.getMonth() + 1 > 9) ? dataInizio.getMonth() + 1 : "0" + (dataInizio.getMonth() + 1);
    const giornoInizio = (dataInizio.getDate() > 9) ? dataInizio.getDate() : "0" + (dataInizio.getDate());
    let str = "date=gt" + dataInizio.getFullYear() + "-" + meseInizio + "-" + giornoInizio;
    const dataFine = new Date();
    dataFine.setFullYear(date.substring(8, 12));
    dataFine.setMonth(date.substring(12, 14));
    dataFine.setMonth(dataFine.getMonth() - 1);
    dataFine.setDate(date.substring(14, 16));
    dataFine.setDate(dataFine.getDate() + 1);
    const meseFine = (dataFine.getMonth() + 1 > 9) ? dataFine.getMonth() + 1 : "0" + (dataFine.getMonth() + 1);
    const giornoFine = (dataFine.getDate() > 9) ? dataFine.getDate() : "0" + (dataFine.getDate());
    str += "&date=lt" + dataFine.getFullYear() + "-" + meseFine + "-" + giornoFine;
    return str;
  }

  gestioneLayout() {
    const cardIntestazione = document.getElementById("cardIntestazione");
    const cardAnamnesi = document.getElementById("cardAnamnesi");
    const cardTerapie = document.getElementById("cardTerapie");
    const cardCondizioniCliniche = document.getElementById("cardCondizioniCliniche");
    const cardPrognosi = document.getElementById("cardPrognosi");
    const cardDiagnosi = document.getElementById("cardDiagnosi");
    // Controllo che siano stati renderizzati tutti i campi

    if (cardAnamnesi === null ||
      cardTerapie === null ||
      cardCondizioniCliniche === null ||
      cardPrognosi === null || cardDiagnosi === null || cardIntestazione === null) {
      return;
    }

    if (cardAnamnesi.offsetHeight + cardCondizioniCliniche.offsetHeight + cardTerapie.offsetHeight > 750) {
      this.layout = 1;
    } else {
      this.layout = 0;
    }
    if (cardPrognosi.offsetHeight + cardDiagnosi.offsetHeight > 750) {
      this.layout2 = 1;
    } else {
      this.layout2 = 0;
    }

  }

  /**
   * Si gestisce in base al 10% di tolleranza.
   * 1 = giallo -> non supera il 10%
   * 2 = rosso -> supera il 10%
   */

  setNumeroPagina() {
    const pagine = document.getElementsByClassName("numeroPagina");
    for (let indice = 0; indice < pagine.length; indice++) {
      pagine[indice].textContent = (indice + 1) + "/" + pagine.length;
    }
  }

  aggiornaCampiPaziente(paziente) {
    this.pazientiService.setPatient(paziente);
    // Converto la data
    paziente.birthDate = this.getItalianDateFromPatient(paziente.birthDate);
    // Converto il genere
    paziente.gender = this.getTraduzioneGenere(paziente.gender);
    // Estraggo il codice fiscale
    const tmpCF = paziente.identifier.find(i => i.use === "official");
    paziente.cf = "";
    if (tmpCF != undefined) {
      paziente.cf = tmpCF.value ?? "";
    }
    // Estraggo l'identificativo per il titolo
    paziente.identificativo = (paziente.identifier.find(i => i.use === "usual")).value;
    // Estraggo il nominativo
    paziente.nominativo = this.pazientiService.getPatientNameOrEmpty() + " " + this.pazientiService.getPatientSurnameOrEmpty();
    // Estraggo il numero di cellulare
    const tempCell = paziente.telecom.find(t => t.system === "phone");
    paziente.cell = "";
    if (tempCell != undefined) {
      paziente.cell = tempCell.value ?? "";
    }
    // Estraggo l'email
    const tmpEmail = paziente.telecom.find(t => t.system === "email");
    paziente.email = "";
    if (tmpEmail != undefined) {
      paziente.email = tmpEmail.value ?? "";
    }
  }

  getItalianDateFromPatient(d) {
    const toReturn = d.split('-');
    return "" + toReturn[2] + "/" + toReturn[1] + "/" + toReturn[0];
  }

  getTraduzioneGenere(genere) {
    switch (genere) {
      case "male":
        return "Maschio";
      case "female":
        return "Femmina";
      case "other":
        return "Altro";
      default:
        return "Sconosciuto";
    }
  }

  aggiornaIdPaziente(id) {
    this.identifier = id;
    this.idPaziente = id;
  }

  setDateOsservazione(date) {
    this.inizioOsservazione = date.substring(6, 8) + "/" + date.substring(4, 6) + "/" + date.substring(0, 4);
    this.fineOsservazione = date.substring(14, 16) + "/" + date.substring(12, 14) + "/" + date.substring(8, 12);
    // Setto anche l'oggetto che rappresenta questo periodo
    const tempDateInizio = new Date();
    tempDateInizio.setFullYear(date.substring(0, 4));
    tempDateInizio.setMonth(Number(date.substring(4, 6) - 1));
    tempDateInizio.setDate(date.substring(6, 8));
    this.setDateTimeTo0(tempDateInizio);
    const tempDateFine = new Date();
    tempDateFine.setFullYear(date.substring(8, 12));
    tempDateFine.setMonth(Number(date.substring(12, 14) - 1));
    tempDateFine.setDate(date.substring(14, 16));
    this.setDateTimeTo2359(tempDateFine);
    this.date = new DateFromTo(tempDateInizio, tempDateFine);
  }

  setDateTimeTo0(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
  }

  setDateTimeTo2359(date: Date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
  }

  getListaICD() {
    this.http.get(endpoints.getFindingICD + this.clinicalImpressionId).toPromise()
      .then((resp: any) => {
        let tempICD = resp.message;
        tempICD = tempICD.filter(elem => {
          if (elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaProssima') {
            return false;
          } else {
            return true;
          }
        });
        tempICD.forEach(obj => {
          this.condizioniCliniche.push(obj);
        });
        this.getAnamnesi();
      }).catch(err => {
        console.error(err);
        this.toastr.error('Impossibile caricare le condizioni cliniche.');
      });
  }

  getAnamnesi() {
    this.http.get(endpoints.getAnaPatProx + '?patientId=' + this.idPaziente).toPromise()
      .then((resp: any) => {
        if (resp && resp.success === '1') {
          let listICDDettagli = resp.message;
          this.descrizione = listICDDettagli.find(el => el.code.coding[0].code === 'DescrizioneAnamnesiPatologicaProssima');
          listICDDettagli = listICDDettagli.filter(elem => {
            if (elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaRemota' ||
              elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaProssima') {
              return false;
            } else {
              return true;
            }
          });
          if (this.descrizione == undefined) {
            this.descrizioneAnamnesiPatologicaProssima = "Nessuna anamnesi patologica prossima.";
          } else {
            if (this.gestisciFormatoDescrizione(this.descrizione.code.coding[0].display) === "")
              this.descrizioneAnamnesiPatologicaProssima = '<b>Anamnesi patologica prossima:</b><br>' + "Nessuna Descrizione."
            else
              this.descrizioneAnamnesiPatologicaProssima = '<b>Anamnesi patologica prossima:</b><br>' + this.gestisciFormatoDescrizione(this.descrizione.code.coding[0].display);
            for (let i = 0; i < listICDDettagli.length; i++) {
              this.condizioniCliniche.push(listICDDettagli[i]);
            }
          }
        } else {
          this.toastr.error('Errore nel recupero dei dati.');
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }


  gestisciFormatoDescrizione(descrizione) {
    if (descrizione == undefined) {
      descrizione = ""
    } else {
      this.lunghezzaDescrizioni += descrizione.length;
      descrizione = descrizione.replaceAll('<p><br></p>', '');
      descrizione = descrizione.replaceAll('<br>', '');
    }
    return descrizione;

  }



  setDataPresaInCarico() {
    this.dataPresaInCarico = this.estraiDataAmmissione(this.patient);
  }

  setInformazioniPrimaVisita(visita) {
    return;
    this.dataPresaInCarico = this.getItalianStringDateFromDateObject(new Date(visita.resource.date));
  }

  getItalianStringDateFromDateObject(obj: Date, separator = "/"): String {
    let tempMonth = "" + (obj.getMonth() + 1);
    tempMonth = Number(tempMonth) < 10 ? "0" + tempMonth : tempMonth;
    let tempDay = "" + obj.getDate();
    tempDay = Number(tempDay) < 10 ? "0" + tempDay : tempDay;
    return tempDay + separator + tempMonth + separator + obj.getFullYear();
  }


  getMedicationRequests() {
    this.http.post(endpoints.getMedicationRequestForVisit, { clinicalImpressionId: this.clinicalImpressionId }).toPromise()
      .then((resp: any) => {
        if (resp) {
          this.listaFarmaci = new Array<Farmaco>();
          resp.forEach(f => {
            const farmaco = {} as Farmaco;
            farmaco.descrizione = f.medicationCodeableConcept.coding[0].code;
            farmaco.stato = f.status;
            farmaco.periodo = [];
            farmaco.periodo[0] = this.convertBackendDateToFrontendDate(f.dispenseRequest.validityPeriod.start);
            farmaco.periodo[1] = this.convertBackendDateToFrontendDate(f.dispenseRequest.validityPeriod.end);
            if (f.dosageInstruction[0].timing.event !== undefined) {
              farmaco.tipoFrequenza = 'Mensile';
              farmaco.monthly = new Array<Date>();
              f.dosageInstruction[0].timing.event.forEach(giorno => {
                farmaco.monthly.push(new Date(giorno));
              });
            } else if (f.dosageInstruction[0].timing.repeat.dayOfWeek !== undefined) {
              farmaco.tipoFrequenza = 'Settimanale';
              farmaco.weekly = f.dosageInstruction[0].timing.repeat.dayOfWeek;
            } else {
              farmaco.tipoFrequenza = 'Giornaliero';
            }
            farmaco.canModify = false;
            farmaco.id = f.id;
            this.listaFarmaci.push(farmaco);
          });
        } else {
          this.toastr.error('Errore nel caricamento dei farmaci.');
          console.error('Errore: ', resp);
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel caricamento dei Dati.');
      });
  }

  getDisplayFromCode(codice) {
    let toReturn;
    this.descrizioneFarmaco.forEach(option => {
      if (option.value === codice) {
        toReturn = option.label;
      }
    });
    return toReturn;
  }


  convertBackendDateToFrontendDate(backendDate: string): Date {
    let anno, mese, giorno;
    [anno, mese, giorno] = backendDate.split("T")[0].split("-");
    return new Date(anno, mese - 1, giorno);
  }

  getTerapie() {
    this.http.get(endpoints.getMedicationRequests + this.clinicalImpressionId).toPromise()
      .then((resp: any) => {
        if (resp) {
          if (resp.success === '1') {
            resp.message.forEach(terapia => {
              if (terapia.medicationCodeableConcept.coding[0].code === 'therapy') {
                if (this.gestisciFormatoDescrizione(terapia.medicationCodeableConcept.text) === "")
                  this.terapie = "<b>Terapie:</b><br>" + "Nessuna terapia.";
                else
                  this.terapie = "<b>Terapie:</b><br>" + this.gestisciFormatoDescrizione(terapia.medicationCodeableConcept.text);
              } else if (terapia.medicationCodeableConcept.coding[0].code === 'therapy_at_home') {
                if (this.gestisciFormatoDescrizione(terapia.medicationCodeableConcept.text) === "")
                  this.terapieDomiciliari = "<b>Terapie domiciliari:</b><br>" + "Nessuna terapia domiciliare.";
                else
                  this.terapieDomiciliari = "<b>Terapie domiciliari:</b><br>" + this.gestisciFormatoDescrizione(terapia.medicationCodeableConcept.text);
              }
            });
            if (this.terapie == "") {
              this.terapie = "Nessuna terapia.";
            }
            if (this.terapieDomiciliari == "") {
              this.terapieDomiciliari = "Nessuna terapia domiciliare.";
            }
          } else {
            this.toastr.error('Errore nel caricamento delle terapie.');
            console.error('Errore: ', resp);
          }
        } else {
          this.toastr.error('Errore nel caricamento delle terapie.');
          console.error('Errore: ', resp);
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel caricamento dei Dati.');
      });
  }

  getStatoFromTerapiaFarmacologica(farmaco) {
    switch (farmaco.resource.status) {
      case "active":
        return "attivo";
      case "stopped":
        return "interrotto";
      case "on-hold":
        return "sospeso";
      case "completed":
        return "completato";
    }
  }

  getPeriodoFromTerapiaFarmacologica(farmaco) {
    const startDate = this.getItalianStringDateFromDateObject(new Date(farmaco.periodo[0]));
    const endDate = this.getItalianStringDateFromDateObject(new Date(farmaco.periodo[1]));
    switch (this.getStatoFromTerapiaFarmacologica(farmaco)) {
      case "attivo":
        return startDate;
      default:
        return startDate + " - " + endDate;
    }

  }



  getStatoFromFarmaco(farmaco): String {
    switch (farmaco) {
      case "active":
        return "attivo";
      case "completed":
        return "completo";
      case "stopped":
        return "interrotto";
      case "on-hold":
        return "sospeso";
      default:
        return "non definito";
    }
  }

  getPeriodoFromFarmaco(farmaco) {
    const startDate = this.getItalianStringDateFromDateObject(new Date(farmaco.periodo[0]));
    const endDate = this.getItalianStringDateFromDateObject(new Date(farmaco.periodo[1]));
    return startDate + " - " + endDate;
  }

  getFrequenzaFromFarmaco(farmaco) {
    let temp = farmaco.dosageInstruction[0].timing;
    if (temp.event) {
      return "mensile";
    } else if (temp.repeat.dayOfWeek) {
      return "settimanale";
    } else {
      return "giornaliero";
    }
  }

  getTerapieFarmacologiche() {
    this.http.get(endpoints.getTerapieFarmacologicheByPatientId + this.idPaziente).toPromise()
      .then((resp: any) => {
        this.terapieFarmacologiche = new Array();
        resp.entry.forEach(farmacoServer => {
          this.terapieFarmacologiche.push(farmacoServer.resource.medicationCodeableConcept.text + ", stato: " + this.getStatoFromTerapiaFarmacologica(farmacoServer) + ", periodo: " + this.getPeriodoFromTerapiaFarmacologica(farmacoServer));
        });
      }).catch(err => {
        this.terapieFarmacologiche = new Array();
        console.error(err);
        this.toastr.error('Errore nel caricamento delle terapie farmacologiche.', 'ERRORE');
      });
  }

  ngOnDestroy() {
    //this.subscriber.unsubscribe();
  }

  getMeasure(type, ms) {
    if (type == "HR") {
      this.statsHR = ms;
    } else if (type == "SYS") {
      this.statsSys.min = ms.min.split('/')[0];
      this.statsSys.avg = ms.avg.split('/')[0];
      this.statsSys.max = ms.max.split('/')[0];
      this.statsDis.min = ms.min.split('/')[1];
      this.statsDis.avg = ms.avg.split('/')[1];
      this.statsDis.max = ms.max.split('/')[1];
    } else if (type == "BR") {
      this.statsBR = ms;
    } else if (type == "SPO") {
      this.statsSpo = ms;
    }
  }

  private estraiDataAmmissione(patientIn) {
    if (patientIn.extension && patientIn.extension.length > 1) {
      const f = patientIn.extension.find(elem => elem.url && elem.url === 'http://riatlas.it/extensions#admissiondate');
      if (f.valueDateTime != undefined) {
        return f.valueDateTime.split("-").reverse().join("/");
      } else if (f.valueDate != undefined) {
        return f.valueDate.split("-").reverse().join("/");
      }
    }
    return undefined;
  }

  setStats(key, statsFromData) {
    switch (key) {
      case "statsPulsossimetroAll":
        this.statsPulsossimetroAll = statsFromData;
        break;
      case "statsPulsossimetroDettagli":
        this.statsPulsossimetroDettagli = statsFromData;
        break;
      case "airbp2":
        this.statsAirbp2.sys.min = statsFromData.min.split('/')[0];
        this.statsAirbp2.sys.avg = statsFromData.avg.split('/')[0];
        this.statsAirbp2.sys.max = statsFromData.max.split('/')[0];
        this.statsAirbp2.dya.min = statsFromData.min.split('/')[1];
        this.statsAirbp2.dya.avg = statsFromData.avg.split('/')[1];
        this.statsAirbp2.dya.max = statsFromData.max.split('/')[1];
        break;

    }
  }
  getClinicalImpression() {
    this.http.get(endpoints.getClinicalImpressionApi + this.clinicalImpressionId).toPromise()
      .then((resp: any) => {
        this.pezzotto = false;
        this.ref.detectChanges();
        this.pezzotto = true;
        this.ref.detectChanges();
        this.diagnosis = resp.extension ? this.gestisciFormatoDescrizione(resp.extension[0].valueString) : 'Nessuna diagnosi';
        this.prognosis = resp.extension ? this.gestisciFormatoDescrizione(resp.extension[1].valueString) : 'Nessuna prognosi';
      }).catch(err => {
        this.toastr.error('Errore nel recupero dei dati');
        console.error(err);
      });
  }

  convertiGiorni(farmaco) {
    let stringaGiorni = "";
    if (farmaco.tipoFrequenza == "Settimanale") {
      for (const day of farmaco.weekly) {
        switch (day) {
          case "mon":
            stringaGiorni += "Lunedì";
            break;
          case "tue":
            stringaGiorni += "Martedì"; break;
          case "wed":
            stringaGiorni += "Mercoledì"; break;
          case "thu":
            stringaGiorni += "Giovedì"; break;
          case "fri":
            stringaGiorni += "Venerdì"; break;
          case "sat":
            stringaGiorni += "Sabato"; break;
          case "sun":
            stringaGiorni += "Domenica"; break;
          default:
        }
        stringaGiorni += ", ";
      }
    } else if (farmaco.tipoFrequenza == "Mensile") {
      for (const day of farmaco.monthly) {
        const giorno = String(day.getDate()).padStart(2, '0');
        stringaGiorni += `${giorno}, `;
      }
    }
    return stringaGiorni.slice(0, -2);

  }

}

