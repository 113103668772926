import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoints } from 'src/endpoint/endpoints';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  static readonly MDS: string = "MDS";
  static readonly BARTHEL: string = "Barthel";
  static readonly MDSUPDRS: string = "MDSUPDRS";
  static readonly FIM: string = "FIM";
  static readonly COVID19: string = "COVID19";
  static readonly EORTC_QLQC30: string = "EORTC_QLQ-C30";
  static readonly MOTRICITYINDEX: string = "MotricityIndex";
  static readonly MMSE: string = "MMSE";
  static readonly TCT: string = "TCT";
  static readonly MNA: string = "MNA";
  static readonly CIRSG: string = "CIRSG";
  static readonly BIA: string = "BIA";
  static readonly SPPB: string = "SPPB";
  static readonly PASBAQ: string = "PASBAQ";
  static readonly EUROQOL: string = "EUROQOL";
  static readonly LSQ: string = "LSQ";
  static readonly ESAS: string = "ESAS";
  static readonly PLICOMETRIA: string = "Plicometria";
  static readonly PGSGA: string = "PGSGA";
  static readonly NRS: string = "NRS";
  static readonly MST: string = "MST";
  static readonly MUST: string = "MUST";
  static readonly DINAMOMETRO: string = "Dinamometro";
  static readonly IADL: string = "IADL";
  static readonly MRS: string = "MRS";
  static readonly NLAB: string = "NLAB";
  static readonly SAHFE: string = "SAHFE";
  static readonly BIDEAMBULAZIONE: string = "BIDeambulazione";
  static readonly SDC: string = "SDC";
  static readonly PERCORSOCOVID19: string = "PercorsoCOVID19";

  listaQuestionari:any[]; // Lista dei questionari disponibili
  listaQuestionariPresenti:any[]; // Lista dei questionari presenti

  constructor(private http: HttpClient) {
    this.listaQuestionari = [
      "MDS", "Barthel", "MDSUPDRS", "FIM", "COVID19", "EORTC_QLQ-C30", "MotricityIndex",
      "MMSE", "TCT", "MNA", "CIRSG", "BIA", "SPPB", "PASBAQ", "EUROQOL", "LSQ", "ESAS",
      "Plicometria", "PGSGA", "NRS", "MST", "MUST", "Dinamometro", "IADL", "MRS", "NLAB",
      "SAHFE", "BIDeambulazione", "SDC", "PercorsoCOVID19"
    ];
  }

  getCreateQuestionnaireRequest(nome) {
    if (nome === undefined || nome === null || nome === '') {
      console.error("Il nome del questionario non è valido.");
      return;
    }
    const obj = {name: '' + nome};
    return this.http.post(endpoints.createQuestionnaire, obj).toPromise();
  }

  createQuestionnaire(nome) {
    this.getCreateQuestionnaireRequest(nome).then((resp: any) =>{}).catch(error => {
      console.error("Errore durante la creazione del questionario: ", error);
    });
  }

  getUpdateQuestionnaireRequest(nome, id) {
    if (nome === undefined || nome === null || nome === '') {
      console.error("Il nome del questionario non è valido.");
      return;
    }
    if (id === undefined || id === null || id === '') {
      console.error("L'id del questionario non è valido.");
      return;
    }
    const obj = {name: nome + '', id: ''+ id};
    return this.http.post(endpoints.updateQuestionnaire, obj).toPromise();
  }

  updateQuestionnaire(nome, id) {
    this.getUpdateQuestionnaireRequest(nome, id).then((resp: any) => {}).catch(error => {
      console.error("Errore nell'update del questionario. Errore: ", error);
    });
  }

  getListaQuestionari() {
    return this.listaQuestionari;
  }

  getQuestionarioByNameByVersion(nome, versione) {
    this.getRequestQuestionarioByNameByVersion(nome, versione).then((resp: any) => {
      return resp;
    }).catch(err => {
      if (err.status === 404) {
        return null;
      } else {
        console.error(err);
        return null;
      }
    });
  }

  getRequestQuestionarioByNameByVersion(nome, versione) {
    const obj = {
      nome: '' + nome,
      versione: '' + versione
    };
    return this.http.post(endpoints.getQuestionnaireByNameByVersion, obj).toPromise();
  }

  // TODO: Gestire la versione. Imposto 1 di default
  getQuestionariMancanti() {
    const lista = [];
    let richiesteMancanti = this.listaQuestionari.length;
    this.listaQuestionari.forEach(nome => {
      this.getRequestQuestionarioByNameByVersion(nome, '1').then(resp => {
        this.listaQuestionariPresenti.push(nome);
        richiesteMancanti--;
      }).catch(err => {
        if (err.status === 404) {
          lista.push(nome);
          richiesteMancanti--;
        }
      });
    });
    return lista;
  }

  getQuestionariPresenti() {
    return this.listaQuestionariPresenti;
  }
}
