import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../service/pazienti.service';
import { RelatedPerson } from './../model/relatedPerson';

@Component({
  selector: 'app-related-person-detail',
  templateUrl: './related-person-detail.component.html',
  styleUrls: ['./related-person-detail.component.scss']
})
export class RelatedPersonDetailComponent implements OnInit {

  listRelatedPerson: RelatedPerson[];

  patientId;

  aggiungi = false;

  relatedPersonSelectedObserve = new EventEmitter<RelatedPerson>();

  relatedPersonSelected;

  cols = [
    { value: 'name', label: 'Nome' },
    { value: 'surname', label: 'Cognome' },
    { value: 'phone', label: 'Cellulare' },
    { value: 'email', label: 'Email' }
  ];

  datePickerConf = { isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' };
  locale = 'it';

  @Output() valueChangedEmitter = new EventEmitter<boolean>();

  constructor(private pazientiService: PazientiService, private http: HttpClient, private toastr: ToastrService, private userService: UserService) { }

  ngOnInit() {
    this.patientId = this.pazientiService.getPatientId();
    this.getAllRelatedPerson();
  }

  getAllRelatedPerson() {
    this.http.get(endpoints.getAllRelatedPersonByPatientId + '' + this.patientId).toPromise()
      .then((resp: any) => {
        if (resp.status === 'OK') {
          this.listRelatedPerson = resp.object;
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel recupero dei dati.');
      });
  }

  setAggiungi() {
    this.aggiungi = true;
    const related = {} as RelatedPerson;
    this.relatedPersonSelected = related;
  }

  modificaRelatedPerson(related) {
    this.aggiungi = true;
    this.relatedPersonSelected = related;
  }

  eliminaRelatedPerson(related) {
    related.active = false;
    this.http.put(endpoints.updateRelatedPerson + '' + related.id, related).toPromise().then((resp: any) => {
      if (resp) {
        if (resp.status === 'OK') {
          this.getAllRelatedPerson();
          this.toastr.success('Caregiver eliminato correttamente');
        } else {
          this.toastr.error(resp.message);
        }
      }
    }).catch(err => {
      console.error(err);
      if (err.status == 403) {
        this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
        return;
      }
      this.toastr.error('Errore nell\'eliminazione del caregiver.');
    });
  }


  refreshList(event) {
    if (event) {
      this.getAllRelatedPerson();
      this.aggiungi = false;
      this.valueChangedEmitter.emit(false);
    }
  }

  checkPermessoModifica() {
    return this.userService.checkPermission('Anagrafica pazienti', 'modify', this.pazientiService.getPatient());
  }

  checkPermessoElimina() {
    return this.userService.checkPermission('Anagrafica pazienti', 'delete', this.pazientiService.getPatient());
  }

  onChildValueChanged(value) {
    this.valueChangedEmitter.emit(value);
  }

}
