<!-- <button (click)="creaQuestionario()">Crea questionario</button> -->
<!-- <button (click)="aggiornaQuestionario()">Aggiorna questionario</button> -->
<h1 class="mt-3" style="text-align:center">Scala N-LAB</h1>
<div class="p-fluid mt-3">
    <div class="row mt-3 ml-1" *ngFor="let domanda of domande">
        <ng-container *ngIf="domanda.tipo === 'display'">
            <div class="col-md-12">
                <h4>{{domanda.testo}}</h4>
            </div>
        </ng-container>
        <ng-container *ngIf="domanda.tipo === 'boolean'">
            <div class="col-md-7">
                <h5>{{domanda.testo}}</h5>
            </div>
            <div class="col-md-2">
                <p-selectButton [options]="domanda.risposte" optionLabel="label" optionValue="value"
                    [disabled]="!checkPermessoModifica()" [(ngModel)]="domanda.risposta" (ngModelChange)="dirty = true">
                </p-selectButton>
            </div>
            <div class="col-md-3"></div>
        </ng-container>
        <ng-container *ngIf="domanda.tipo === 'integer' && !domanda.testo.includes('Punteggio N-Lab')">
            <div class="col-md-12">
                <h5>{{domanda.testo}}</h5>
            </div>
            <form style="margin-left: 15px">
                <div class="col-md-12 custom-control custom-radio" *ngFor="let risposta of domanda.risposte">
                    <input type="radio" class="custom-control-input" name="{{domanda.linkId}}{{risposta.value}}"
                        [value]="risposta.value" [(ngModel)]="domanda.risposta" [disabled]="!checkPermessoModifica()"
                        id="{{domanda.linkId}}{{risposta.value}}" (ngModelChange)="dirty = true">
                    <label class="custom-control-label"
                        for="{{domanda.linkId}}{{risposta.value}}">{{risposta.label}}</label>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="domanda.tipo === 'quantity' && domanda.testo.includes('Punteggio')">
            <div class="col-md-12 mt-2 mb-5">
                <h4>{{domanda.testo}}: {{domanda.risposta !== undefined ? domanda.risposta : ""}}</h4>
            </div>
        </ng-container>
        <ng-container *ngIf="domanda.tipo === 'integer' && domanda.testo.includes('Punteggio N-Lab')">
            <div class="col-md-12 mt-2 mb-5">
                <h4>{{domanda.testo}}: {{domanda.risposta !== undefined ? domanda.risposta : ""}}</h4>
                <h4>{{domanda.label || ""}}</h4>
            </div>
        </ng-container>
        <ng-container *ngIf="domanda.tipo === 'quantity' && !domanda.testo.includes('Punteggio')">
            <div class="col-md-5">
                <h5>{{domanda.testo}}
                    <i class="fas fa-edit" (click)="openModal(domanda.testo)">
                    </i>
                </h5>
            </div>
            <div class="col-md-2">
                <input type='number' pInputText [(ngModel)]="domanda.risposta" class="form-control" min="0" value="0"
                    name="{{domanda.linkId}}" [disabled]="!checkPermessoModifica()" (ngModelChange)="dirty = true" />
            </div>
            <div class="col-md-5"></div>
        </ng-container>
    </div>
    <div class="row" style="margin-top: 50px;">
        <div class="col-md-12">
            <div class="float-right" style="margin-right: 15px; margin-bottom: 15px;">
                <button *ngIf="!isVisit" class="to-right p-button-danger mr-2" pButton label="Chiudi" icon="fa fa-times"
                    iconPos="left" id="close" type="button" (click)="close()"></button>
            </div>
            <div class="float-right" style="margin-right: 15px; margin-bottom: 15px;">
                <button *ngIf="checkPermessoModifica()" pButton label="Salva" icon="fa fa-save" iconPos="left"
                    class="p-button-success" type="button" (click)="salva()"></button>
            </div>
        </div>
    </div>
    <p-dialog [dismissableMask]="true" header="{{modalStruct.title}}" (onHide)="hide()"
        [(visible)]="modalStruct.visible" [positionTop]="60" [positionLeft]="350" [modal]="true" [responsive]="true"
        [style]="modalStruct.style">
        <p-scrollPanel #sc [style]="{width: '100%', height: '500px', 'overflow-x': 'hidden'}">
            <div *ngIf="modalStruct.title === 'Rankin modificata'" class="mb-3">
                <app-scale-di-valutazione-mrs></app-scale-di-valutazione-mrs>
            </div>
            <div *ngIf="modalStruct.title === 'Mini mental test'" class="mb-3">
                <app-scale-di-valutazione-mmse></app-scale-di-valutazione-mmse>
            </div>
            <div *ngIf="modalStruct.title === 'Scala disabilità comunicativa'" class="mb-3">
                <app-scale-di-valutazione-sdc></app-scale-di-valutazione-sdc>
            </div>
            <div *ngIf="modalStruct.title === 'Barthel Index (BI) score totale'" class="mb-3">
                <app-scale-di-valutazione-barthel></app-scale-di-valutazione-barthel>
            </div>
            <div *ngIf="modalStruct.title === 'BI solo deambulazione'" class="mb-3">
                <app-scale-di-valutazione-bideambulazione></app-scale-di-valutazione-bideambulazione>
            </div>
            <div *ngIf="modalStruct.title === 'SAHFE score'" class="mb-3">
                <app-scale-di-valutazione-sahfe></app-scale-di-valutazione-sahfe>
            </div>
        </p-scrollPanel>

    </p-dialog>
</div>