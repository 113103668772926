<div class="card">
    <div class="card-header border-0" *ngIf="showHeader !== undefined && showHeader === true">
        <div class="row">
            <div class="col-8">
                <h3 class="card-title">Fonte: "{{labelFonte}}"
                    {{onlyMonitoringEvents ? '(eventi del piano di monitoraggio)':''}}</h3>
            </div>
            <div class="col-4 to-right">
                <p-dropdown class="to-right" [options]="opt" [(ngModel)]="selected" dropdownIcon="fas fa-chevron-down"
                    (ngModelChange)="dropdownChange($event)"></p-dropdown>
            </div>
        </div>

    </div>
    <div class="card-body">
        <div #chart></div>
    </div>
</div>