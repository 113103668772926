<div class="row">
    <div class="col-md-12">
        <p-table [columns]="cols" [value]="listRelatedPerson" [responsive]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                        {{col.label}}
                    </th>
                    <th style="width:12em">Azioni</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns">
                        <span class="p-column-title">{{col.value}}</span>
                        {{rowData[col.value]}}
                    </td>
                    <td>
                        <button class="p-button mt-1" pButton pTooltip="Modifica" icon="fas fa-edit"
                            (click)="modificaRelatedPerson(rowData)" *ngIf="checkPermessoModifica()"></button>
                        <button class="p-button-danger mt-1 ml-2" pTooltip="Elimina" pButton icon="far fa-trash-alt"
                            (click)="eliminaRelatedPerson(rowData)" *ngIf="checkPermessoElimina()"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length+1">
                        <label>Nessun caregiver inserito.</label>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <div class="float-right">
            <button pButton label="Aggiungi" icon="fa fa-plus" iconPos="left" styleClass="p-button-raised" type="button"
                (click)="setAggiungi()" *ngIf="checkPermessoModifica()"></button>
        </div>
    </div>
</div>
<div class="mt-2">
    <app-related-person-component [id]="patientId" [related]="relatedPersonSelected" (onUpdate)="refreshList($event)"
        *ngIf="aggiungi" (valueChangedEmitter)="onChildValueChanged($event)"></app-related-person-component>
</div>