import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TreeNode } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Tree } from 'primeng/tree';
import { endpoints } from 'src/endpoint/endpoints';

@Component({
  selector: 'app-albero-ic',
  templateUrl: './albero-ic.component.html',
  styleUrls: ['./albero-ic.component.scss']
})
export class AlberoICComponent implements OnInit {

  selectedFile: TreeNode;

  data = [];

  @ViewChild('albero', { static: false }) albero: Tree;

  // tslint:disable-next-line: max-line-length
  constructor(private http: HttpClient, private toastr: ToastrService, private ref: DynamicDialogRef, private config: DynamicDialogConfig) {

  }

  static open(dialogService: DialogService, input, altezza: string, larghezza: string, baseZIndex?: number) {
    return dialogService.open(AlberoICComponent, {
      header: 'Albero ' + input.alberoType,
      height: altezza,
      width: larghezza,
      dismissableMask: true,
      data: input,
      baseZIndex: baseZIndex,
    });
  }

  ngOnInit() {
    if (this.config.data.alberoType === 'ICD') {
      this.getDataICD();
    } else if (this.config.data.alberoType === 'ICF') {
      this.getDataICF();
    } else if (this.config.data.alberoType === 'CORESET_ICF') {
      this.getDataCORESET_ICF();
    }
  }

  espansioneNodo(event) {



    if (event.node.leaf === false) {
      event.node.expanded = true;
      this.selectedFile = null;

      if (event.node.expanded) {
        if (this.config.data.alberoType === 'ICD') {
          this.loadNodeICD(event);
        } else if (this.config.data.alberoType === 'ICF') {
          this.loadNodeICF(event);
        } else if (this.config.data.alberoType === 'CORESET_ICF') {
          this.loadNodeCORESET_ICF(event);
        }
      }
    } else {
      this.selectedFile = event.node;
      this.ref.close(this.selectedFile);
    }
  }

  nodeSelect(event) {
    if (event.node.leaf === false) {
      event.node.expanded = !event.node.expanded;
      this.selectedFile = null;

      if (event.node.expanded) {
        if (this.config.data.alberoType === 'ICD') {
          this.loadNodeICD(event);
        } else if (this.config.data.alberoType === 'ICF') {
          this.loadNodeICF(event);
        } else if (this.config.data.alberoType === 'CORESET_ICF') {
          this.loadNodeCORESET_ICF(event);
        }
      }
    } else {
      this.selectedFile = event.node;
      this.ref.close(this.selectedFile);
    }
  }

  getDataICD() {
    setTimeout(() => {
      this.http.post(endpoints.getDataTreeICD, null).toPromise().then((resp: any) => {
        this.data = resp.object;
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nella retrive dell\'albero ICD');
      });
    }, 1);
  }

  getDataICF() {
    setTimeout(() => {
      this.http.post(endpoints.getDataTreeICF, null).toPromise().then((resp: any) => {
        this.data = resp.object;
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nella retrive dell\'albero ICF');
      });
    }, 1);
  }

  getDataCORESET_ICF() {
    setTimeout(() => {
      this.http.post(endpoints.getDataTreeCORESET_ICF, null).toPromise().then((resp: any) => {
        this.data = resp.object;
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nella retrive dell\'albero ICF');
      });
    }, 1);
  }

  loadNodeICD(event) {
    if (event.node) {
      this.http.post(endpoints.getDataTreeICD, event.node.data).toPromise().then((resp: any) => {
        event.node.children = resp.object;
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nella retrive dell\'albero ICD');
      });
    }
  }

  loadNodeICF(event) {
    if (event.node) {
      this.http.post(endpoints.getDataTreeICF, event.node.data).toPromise().then((resp: any) => {
        event.node.children = resp.object;
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nella retrive dell\'albero ICF');
      });
    }
  }

  loadNodeCORESET_ICF(event) {
    if (event.node) {
      this.http.post(endpoints.getDataTreeCORESET_ICF, event.node.data).toPromise().then((resp: any) => {
        event.node.children = resp.object;
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nella retrive dell\'albero ICF');
      });
    }
  }

}
