import { BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MenuItem, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { InfoPazienteModalComponent } from 'src/app/main/info-paziente-modal/info-paziente-modal.component';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { HelpModalComponent } from 'src/app/modals/help-modal/help-modal.component';
import { AppService } from 'src/app/service/app.service';
import { TokenService } from 'src/app/service/token.service';
import { UserService } from 'src/app/service/user-service.service';
import { WebsocketService } from 'src/app/service/websocket.service';
import { endpoints } from 'src/endpoint/endpoints';
import { AlertService } from '../pazienti/alert-management/service/alert.service';
import { PazientiService } from '../pazienti/service/pazienti.service';


declare var $;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  sub;

  username;

  patientId;
  subscriber;
  subscriberVisit;
  subscriberDisplayDimensions;
  subscriberAlert;

  paziente;
  patName;
  patSurname;
  patIdentifier;
  items: MenuItem[];

  visita;

  observer;
  mobile = false;

  showMenuDettagli = false;

  showMenuVisite = false;

  displayPanelInfo = false;

  displayPanelDettagli = false;

  displayPanelHelp = false;

  displayCategory = '';

  countAlerts;

  confermaModalRef: BsModalRef;

  ws;

  orgOpt: SelectItem[];

  listOrganizations = [];

  selectedOrg;

  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
  public searchForm: FormGroup;

  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private tokenService: TokenService, private userService: UserService, private patientService: PazientiService,
    private modalService: BsModalService, private appServ: AppService, private dialogService: DialogService,
    private websocket: WebsocketService, private alertService: AlertService, private http: HttpClient,
    private toastr: ToastrService) {
  }

  ngOnInit() {
    // this.subscriber = this.patientService.patientId.subscribe(id => this.patientId = id);
    this.alertService.findCountAlert();
    this.subscriberAlert = this.alertService.alerts.subscribe(alerts => this.countAlerts = alerts);
    this.subscriberVisit = this.patientService.visita.subscribe(visita => this.visita = visita);
    this.observer = this.appServ.mdAppObs
      .subscribe((statex: BreakpointState) => {
        if (statex.matches) {
          this.mobile = true;
        } else {
          this.mobile = false;
        }
      });
    this.searchForm = new FormGroup({
      search: new FormControl(null)
    });

    this.sub = this.patientService.patient.subscribe(pat => {
      if (pat && !this.router.url.includes('dashboard')) {
        this.paziente = pat;
        this.patientId = pat.id;
        // tslint:disable-next-line: max-line-length
        this.patName = this.patientService.getPatientNameOrEmpty();
        // tslint:disable-next-line: max-line-length
        this.patSurname = this.patientService.getPatientSurnameOrEmpty();
        this.patIdentifier = this.getPatientId(pat);
        this.items = [{
          label: 'Dettagli',
          items: [
            { label: 'Stile di vita', routerLink: '/main/pazienti/profilosanitario/' + this.paziente.id + '/stiledivita' },
            { label: 'Terapie Farmacologiche', routerLink: 'terapiefarmacologiche' },
            { label: 'Devices', routerLink: 'devices' },
            { label: 'Anamnesi Patologica Prossima', routerLink: 'anamnesipatologicaprossima' },
            { label: 'Anamnesi Patologica Remota', routerLink: 'anamnesipatologicaremota' },
          ]
        }];
      } else {
        this.paziente = undefined;
        this.patientId = undefined;
        this.patName = '';
        this.patIdentifier = '';
      }
    });
    if (this.router.url.includes('profilosanitario')) {
      this.showMenuDettagli = true;
    } else {
      this.showMenuDettagli = false;
    }
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url.includes('profilosanitario')) {
          this.showMenuDettagli = true;
        } else {
          this.showMenuDettagli = false;
        }
      }
    });
    if (this.router.url.includes('visite')) {
      this.showMenuVisite = true;
    } else {
      this.showMenuVisite = false;
    }
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url.includes('visite')) {
          this.showMenuVisite = true;
        } else {
          this.showMenuVisite = false;
        }
      }
    });
    this.username = this.userService.getUser().username;
    // PER IL MOMENTO COMMENTATO
    // this.websocket.connect();
    this.ws = this.websocket.connect_ws();
    this.ws.connect({}, frame => {
      this.ws.subscribe('/message/' + this.userService.getCurrentOrganization(), (message) => {
        this.alertService.countAlert();
      });
    });

    this.subscriberDisplayDimensions = this.appServ.smallObs.subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.displayCategory = 'small';
      } else {
        this.displayCategory = 'other';
      }
    });

    if (this.isSuperAdmin()) {
      this.getOrganizations();
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    // this.subscriber.unsubscribe();
    this.subscriberVisit.unsubscribe();
    this.subscriberAlert.unsubscribe();
  }

  isAdmin() {
    return this.userService.isAdmin();
  }

  logout() {
    const initialState = { message: 'Sei sicuro di voler uscire?' };
    this.confermaModalRef = this.modalService.show(ConfirmModalComponent, { initialState });
    const newSubscriber = this.modalService.onHide.subscribe(r => {
      newSubscriber.unsubscribe();
      const res = this.confermaModalRef.content.result;
      if (res) {
        this.http.get(endpoints.logout).toPromise().then(resp => {
          // Elimino il token anche dal frontend
          return this.eliminaTokenAndRedirectLogin();
        }).catch(error => {
          console.error("Errore ", error);
          if (error.status == 404) {
            // Se non trova il token vuol dire che già non può fare altre chiamate
            return this.eliminaTokenAndRedirectLogin();
          } else {
            this.toastr.error("Impossibile effettuare il logout.");
          }
        });
      }
    });
  }
  getPatientId(pat) {
    if (pat && pat.identifier) {
      const identifier = pat.identifier.find(identifierCode => identifierCode.use == "usual");
      return identifier ? identifier.value : "";
    } else {
      return "";
    }
  }
  eliminaTokenAndRedirectLogin() {
    this.tokenService.deleteToken();
    this.userService.deleteUser();
    this.patientService.resetPatient();
    this.router.navigate(['/login']);
    this.toastr.success("Logout effettuato con successo.");
  }

  checkPermessoGestioneUtenti(): boolean {
    return this.userService.checkPermission('Gestione utenti', 'read');
  }

  checkPermessoVisualizzaOsservazioni(): boolean {
    return this.userService.checkPermission('Osservazioni', 'read');
  }

  checkPermessoVisualizzaEsameObiettivo(): boolean {
    return this.userService.checkPermission('Esame obiettivo', 'read');
  }

  checkPermessoVisualizzaTrattamentiProcedure(): boolean {
    return this.userService.checkPermission('Trattamenti e procedure', 'read');
  }

  checkPermessoLetturaMonitoraggio(): boolean {
    return this.userService.checkPermission('Monitoraggio', 'read');
  }

  checkPermessoLetturaNotifiche(): boolean {
    return this.userService.checkPermission('Notifiche', 'read');
  }

  checkPermessoSezioneLetturaNotifiche(): boolean {
    return this.userService.checkOnlyPermission('Notifiche', 'read');
  }

  getSanitizedPhoto() {
    if (this.paziente) {
      if (this.paziente.success !== undefined) {
        this.paziente = this.paziente.message;
      }
      return this.paziente.photo ? 'data:image/png;base64,' + this.paziente.photo[0].data : "";
    } else {
      // this.paziente.photo = './assets/img/patient.png';
      return './assets/img/patient.png';
    }
  }

  showPanelInfo() {
    const input = { patientId: this.patientId };
    const ref = InfoPazienteModalComponent.open(this.dialogService, input, null, (this.displayCategory === 'small' ? '90%' : '50%'));
    ref.onClose.subscribe((res: any) => { });
  }

  showPanelHelp($event) {
    const input = {};
    const ref = HelpModalComponent.open(this.dialogService, input, null, '70%');
    ref.onClose.subscribe((res: any) => { });
  }

  isSuperAdmin() {
    return this.userService.isSuperAdmin();
  }

  getOrganizations() {
    this.http.get(endpoints.getOrganizations).toPromise().then((resp: any) => {
      if (resp) {
        this.listOrganizations = resp.object;
        this.orgOpt = new Array<SelectItem>();
        // this.orgOpt.push({ value: '', label: 'Seleziona', disabled: true });
        this.listOrganizations.map(x => {
          const el = { value: '', label: '' };
          el.value = x.id;
          el.label = x.organization;
          this.orgOpt.push(el);
        });
        const org = this.orgOpt.find(el => {
          if (el.value === this.userService.getUser().organization.id) {
            return true;
          } else {
            return false;
          }
        });
        this.selectedOrg = org;
      }
    }).catch(err => {
      console.error(err);
      if (err.status == 403) {
        this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
        return;
      }
      this.toastr.error('Errore nel caricamento della lista dei ruoli.');
    });
  }

  selectOrganization(event) {
    const find = this.listOrganizations.find(el => el.id === event.value.value);
    this.userService.setCurrentOrganization(find);
    this.patientService.getaggiornaListaPazienti().next(true);
    this.router.navigate(['/main/pazienti/listapazienti']);
  }
}
