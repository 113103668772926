export const environment = {
  production: true,
  authServer: 'https://gw.scampia.riatlas.it/authorization',
  resourceServer: 'https://gw.scampia.riatlas.it/resource',
  fhirServer: 'https://gw.scampia.riatlas.it/fhir',
  elastic: 'https://gw.scampia.riatlas.it/resource',
  mobile: 'https://gw.scampia.riatlas.it/mobile',
  company: 'Riatlas.it',
  baseHref: 'cloud'
};
