import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { CustomizationService } from 'src/app/service/customization.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../../service/pazienti.service';
import { DateFromTo } from '../Model/date-from-to';
import { getBarChartOptions } from '../Utils/apexchart-opt';
import { getSeriesDataValue, SerieType } from '../Utils/grafici-utils';

@Component({
  selector: 'app-grafici-farmaci',
  templateUrl: './grafici-farmaci.component.html',
  styleUrls: ['./grafici-farmaci.component.scss']
})
export class GraficiFarmaciComponent implements OnInit, OnDestroy {

  @Input() identifier;

  @Input('date') set date(date: DateFromTo) {
    if (!date.compare(this._date)) {
      this.observer.next(date);
    }
  }

  // Usato solo per il report
  @Input() showHeader: boolean;
  @Input() tipoGrafico;
  @Input() altezzaGrafico;
  @Input() larghezzaGrafico;

  // tslint:disable-next-line: variable-name
  _date: DateFromTo;

  observer: BehaviorSubject<DateFromTo>;

  dateFromString: string;
  dateToString: string;
  dateFromTimeStamp;
  dateToTimeStamp;

  opt: SelectItem[];

  selected = '';

  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  chart: ApexCharts;

  patientId;

  stroke: ApexStroke = { curve: 'smooth' };
  markers = { size: 4, hover: { size: undefined, sizeOffset: 2 } };

  xAxis = {
    /*type: 'datetime',
    labels: {
      offsetX: -15
    }*/
    type: 'datetime',
    labels: {
      rotate: -45,
      rotateAlways: false,
      offsetX: -15,
      style: {
        fontSize: '12px'
      }
    }
  };

  yAxis = {
    max: 4,
    min: 0,
    tickAmount: 4,
    axisTicks: {
      show: true
    },
    axisBorder: {
      show: true,
      color: '#008FFB'
    },
    labels: {
      style: {
        colors: '#008FFB',
        fontSize: '14px',
      },
      formatter: (val, index) => {
        if (val === 1) {
          return 'SI';
        } else if (val === 2) {
          return ['NO, in accordo alle', 'indicazioni del medico'];
        } else if (val === 3) {
          return 'NO, per dimenticanza';
        } else if (val === 4) {
          return 'NO, altre motivazioni';
        } else {
          return '';
        }
      }
    }
  };


  grid: ApexGrid = {
    row: {
      colors: ['#fff', '#fff', '#f3f4f5', '#f3f4f5'],
      opacity: 1
    }
  }
    ;

  capeChartArray: SerieType =
    {
      name: 'chartName',
      color: '#008FFB',
      serie: [],
      display: ['chartDisplay'],
      type: ['line'],
      markers: true,
      yaxis: this.yAxis,
      xaxis: this.xAxis,
      stroke: this.stroke,

    };

  organization = undefined;

  constructor(private http: HttpClient, private toastr: ToastrService, private datePipe: DatePipe, private userService: UserService, private pazientiService: PazientiService, private customizationService: CustomizationService) {
    this.observer = new BehaviorSubject<DateFromTo>(null);
  }

  ngOnInit() {
    const paziente = this.pazientiService.getPatient();
    this.patientId = paziente.id;
    this.pazientiService.getPatientOrganizationAsPromiseById(this.patientId).then((resp: any) => {
      this.organization = resp.desc;
      this.opt = this.customizationService.getOptionFarmaciByOrganization(this.organization);
      this.selected = this.opt[0].label;
      this.init();
    });
  }

  private init() {
    if (this.showHeader === undefined) {
      this.showHeader = true;
    }
    this.observer.subscribe((input) => {
      if (this.identifier && input) {
        this._date = input;
        this.dateFromString = this.datePipe.transform(input.getDateFrom(), 'yyyy-MM-dd');
        this.dateToString = this.datePipe.transform(input.getDateTo(), 'yyyy-MM-dd');
        this.dateFromTimeStamp = Math.round(input.getDateFrom().getTime());
        this.dateToTimeStamp = Math.round(input.getDateTo().getTime());
        this.dropdownChange(this.selected);
      }
    });

    if (this.tipoGrafico !== undefined) {
      this.dropdownChange(this.tipoGrafico);
      this.selected = this.tipoGrafico;
    }
  }

  ngOnDestroy(): void {
    this.observer.unsubscribe();
  }

  dropdownChange(event) {
    this.createChart(this.capeChartArray, event + 'ChartID', event);
  }

  createChartDataURI(chartArray: SerieType, id, title, date, identifier, chartref) {
    return new Promise((resolve, reject) => {
      this.identifier = identifier;
      this.dateFromString = this.datePipe.transform(date.getDateFrom(), 'yyyy-MM-dd');
      this.dateToString = this.datePipe.transform(date.getDateTo(), 'yyyy-MM-dd');

      this.getData(chartArray.name).then((response: any) => {
        const series: ApexAxisChartSeries = [];
        let seriesLabels = null;
        let labels = [];
        if (response.hits && response.hits.hits && response.hits.hits.length > 0) {
          seriesLabels = getSeriesDataValue(response, chartArray.display, chartArray.type, chartArray.name);
          if (Array.isArray(seriesLabels.serie)) {
            seriesLabels.serie.forEach(element => {
              series.push(element);
            });
          } else {
            series.push(seriesLabels.serie);
          }
          labels = seriesLabels.labels;
        }
        if (series && series.length === 0) {
          chartArray.display.forEach((el, i) => {
            series.push({ data: [], type: chartArray.type[i], name: el });
          });
        }
        const chartArr = new Array<SerieType>();
        chartArr.push(chartArray);
        // tslint:disable-next-line: max-line-length
        const options = getBarChartOptions(id, title, series, chartArr, labels, chartArray.yaxis, chartArray.stroke, chartArray.markers, chartArray.fill, chartArray.grid);
        options.grid = this.grid;

        this.chart = new ApexCharts(chartref.nativeElement, options);
        this.chart.render();
        resolve(this.chart);

      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel recupero dei dati.');
      });
    });
  }

  createChart(chartArray: SerieType, id, title) {
    chartArray.name = title;
    chartArray.display[0] = title;
    this.getData(chartArray.name).then((response: any) => {
      const series: ApexAxisChartSeries = [];
      let seriesLabels = null;
      let labels = [];
      if (response.hits && response.hits.hits && response.hits.hits.length > 0) {
        seriesLabels = getSeriesDataValue(response, chartArray.display, chartArray.type, chartArray.name);
        if (Array.isArray(seriesLabels.serie)) {
          seriesLabels.serie.forEach(element => {
            series.push(element);
          });
        } else {
          series.push(seriesLabels.serie);
        }
        labels = seriesLabels.labels;
      }
      if (series && series.length === 0) {
        chartArray.display.forEach((el, i) => {
          series.push({ data: [], type: chartArray.type[i], name: el });
        });
      }
      const chartArr = new Array<SerieType>();
      chartArr.push(chartArray);
      // tslint:disable-next-line: max-line-length
      const options = getBarChartOptions(id, title, series, chartArr, labels, chartArray.yaxis, chartArray.stroke, chartArray.markers, chartArray.fill, chartArray.grid);
      if (this.altezzaGrafico !== undefined) {
        options.chart.height = this.altezzaGrafico;
      }
      if (this.larghezzaGrafico !== undefined) {
        options.chart.width = this.larghezzaGrafico;
      }

      options.tooltip.y = {
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          const val = response.hits.hits[dataPointIndex]['_source'].value;
          // tslint:disable-next-line: max-line-length
          if (val == 1) {
            return 'SI';
          } else if (val == 2) {
            return 'NO, in accordo alle indicazioni del medico';
          } else if (val == 3) {
            return 'NO, per dimenticanza';
          } else if (val == 4) {
            return 'NO, altre motivazioni';
          } else {
            return '';
          } // The formatter function overrides format property
        }
      }
      //options.grid = this.grid;
      if (this.chart) {
        this.chart.updateOptions(options, false, false, false);
      } else {
        this.chart = new ApexCharts(this.chartRef.nativeElement, options);
        this.chart.render();
      }
    }).catch(err => {
      console.error(err);
      this.toastr.error('Errore nel recupero dei dati.');
    });

  }

  getData(event) {
    if (event === 'Lonsurf/Tas 102') {
      event = "L";
    }
    return this.http.get(endpoints.elasticQuery + "getFarmaco" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + event + "/-events").toPromise();
  }

}
