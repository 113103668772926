import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../token.service';
import { UserService } from '../user-service.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  count = 0;

  // tslint:disable-next-line: max-line-length
  constructor(public tokenService: TokenService, private spinner: NgxSpinnerService, private router: Router, private toastr: ToastrService, private userServ: UserService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.count++;
    this.spinner.show()
    if (request.url.match(/oauth\/token/)) {
      request = request.clone({
        setHeaders: {
          'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
          Authorization: 'Basic ' + btoa('resource_ad:secret')
        }
      });
    } else if (request.url.includes(environment.elastic)) {
      request = request.clone({
        setHeaders: {
          'Content-type': 'application/json; charset=utf-8',
          'Cache-Control': 'no-cache',
          Organization: this.userServ.getCurrentOrganization(),
          Authorization: 'Bearer ' + this.tokenService.getToken()
        }
      });
    } else if (request.url.match(/api\/me/)) {
      request = request.clone({
        setHeaders: {
          'Content-type': 'application/json; charset=utf-8',
          'Cache-Control': 'no-cache',
          Authorization: 'Bearer ' + this.tokenService.getToken()
        }
      });
    } else if (request.url.match(/api\/validateusernameandcode/)) {
      request = request.clone({
        setHeaders: {
          'Content-type': 'application/json; charset=utf-8'
        }
      });
    } else if (request.url.match(/api\/changepassword\?code/)) {
      request = request.clone({
        setHeaders: {
          'Content-type': 'application/json; charset=utf-8'
        }
      });
    } else if (request.url.match(/api\/recuperapassword/)) {
      request = request.clone({
        setHeaders: {
          'Content-type': 'application/json; charset=utf-8'
        }
      });
    } else if (request.url.match(/api\/sequencevalidation/)) {
      request = request.clone({
        setHeaders: {
          'Content-type': 'application/json; charset=utf-8'
        }
      });
    } else if (request.url.match(/api\/historyvalidation/)) {
      request = request.clone({
        setHeaders: {
          'Content-type': 'application/json; charset=utf-8'
        }
      });
    } else if (request.url.match(/api\/usernamevalidation/)) {
      request = request.clone({
        setHeaders: {
          'Content-type': 'application/json; charset=utf-8'
        }
      });
    } else if (request.url.includes(environment.resourceServer + "/api/createNewPage")) {
    } else if (request.url.includes(environment.resourceServer + "/api/addDocument")) {
      request = request.clone({
        setHeaders: {
          //'Content-type': 'multipart/form-data',
          'Cache-Control': 'no-cache',
          Organization: this.userServ.getCurrentOrganization(),
          Authorization: 'Bearer ' + this.tokenService.getToken(),
        },
      });
    }
    else {
      request = request.clone({
        setHeaders: {
          'Content-type': 'application/json; charset=utf-8',
          'Cache-Control': 'no-cache',
          Organization: this.userServ.getCurrentOrganization(),
          Authorization: 'Bearer ' + this.tokenService.getToken()
        }
      });
    }

    return next.handle(request).pipe(
      retry(0),
      finalize(() => {
        this.count--;
        if (this.count < 1) {
          this.count = 0;
          setTimeout(() => this.spinner.hide());
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // 401 handled in auth.interceptor
          if (this.toastr.currentlyActive) {
            this.toastr.remove(this.toastr.currentlyActive);
          }
          this.tokenService.deleteToken();
          this.router.navigate(['/login']);
        }
        return throwError(error);
      })
    );
  }
}
