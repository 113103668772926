<div class="mt-5">
    <div class="col-10 offset-1" style="text-align:center">
        <img src="assets/img/Logo-Login1.png" alt="Logo RiAtlas HEALTHCARE" width="25%">
    </div>
</div>
<div class="mt-5">
    <div class="col-lg-9 offset-lg-2 col-md-8 offset-md-2">
        <h1>Storico Manuali</h1>
        <p-table #dt [columns]="colonne" [value]="manuali" [paginator]="false" [rows]="10" [responsive]="true">
            <ng-template pTemplate="header" let-colonna>
                <tr>
                    <th style="text-align:center" class="col-3">{{colonna[0].name}}</th>
                    <th style="text-align:center" class="col-2">{{colonna[1].name}}</th>
                    <th style="text-align:center" class="col-2">{{colonna[2].name}}</th>
                    <th style="text-align:center" class="col-3">{{colonna[3].name}}</th>
                    <th style="text-align:center" class="col-2">{{colonna[4].name}}</th>
                    <th style="text-align:center" class="col-2">{{colonna[5].name}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-manuale>
                <tr>
                    <td class="col-3">{{manuale.name}}</td>
                    <td class="col-2">{{manuale.versione}}</td>
                    <td class="col-2">{{manuale.data}}</td>
                    <td class="col-2">{{manuale.revisione}}</td>
                    <td class="col-1">{{manuale.lingua}}</td>
                    <td class="col-2" style="text-align:center"><a href={{manuale.link}} target="_blank">
                            <div style="height:100%;width:100%"><i class='far fa-file-pdf' style='font-size:48px'></i>
                            </div>
                        </a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>