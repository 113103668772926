import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EtichettaProdottoComponent } from './etichetta-prodotto/etichetta-prodotto.component';
import { PasswordChangeComponent } from './global/password-change/password-change.component';
import { ListaManualiComponent } from './lista-manuali/lista-manuali.component';
import { LoginComponent } from './login/login.component';
import { ReportComponent } from './report/report.component';
import { ReportDataResolver } from './report/ReportDataResolver';
import { ReportTelemonitoraggioComponent } from './report-telemonitoraggio/report-telemonitoraggio.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'global/password-change', component: PasswordChangeComponent },
  { path: 'manuali', component: ListaManualiComponent },
  { path: 'etichetta-prodotto', component: EtichettaProdottoComponent },
  { path: 'report', component: ReportComponent, resolve: { datiReport: ReportDataResolver } },
  { path: 'report-telemonitoraggio', component: ReportTelemonitoraggioComponent, resolve: { datiReport: ReportDataResolver } },
  { path: '', redirectTo: '/main/dashboard', pathMatch: 'full', },
  // { path: '**', redirectTo: '/main/pagenotfound' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
