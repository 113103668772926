<div class="card">
    <div class="card-header bg-primary">
        <h3 class="card-title">Censimento Dispositivi</h3>
        <button pbutton="" type="button" label="Nuovo" (click)="showBasicDialog()"
            class="p-button-primary p-button p-component" style="float: right"><span
                class="p-button-icon p-button-icon-left fas fa-plus" aria-hidden="true"></span><span
                class="p-button-label">Nuovo</span></button>

    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <div class="card-body">
                <div class="row mt-2">
                    <div class="col-md-12">
                        <p-table #dt [columns]="colonne" [value]="rows" [lazy]="true" [paginator]="true" [rows]="10"
                            [totalRecords]="page.totalElements" [loading]="loading" [responsive]="true"
                            (onLazyLoad)="setPage($event)" sortMode="multiple" [(first)]="first">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th style="width:6em">
                                        <div style="display: inline-block;">
                                            {{colonne[0].name}}
                                        </div>
                                    <th style="width:6em">
                                        <div style="display: inline-block;">
                                            {{colonne[1].name}}
                                        </div>
                                    </th>
                                    <th style="width:4em">
                                        {{colonne[2].name}}
                                    </th>
                                    <th style="width:4em">
                                        {{colonne[3].name}}
                                    </th>
                                    <th style="width:6em">Azioni</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr>
                                    <td *ngFor="let col of columns">
                                        <span class="p-column-title">{{col.name}}</span>
                                        {{rowData[col.prop]}}
                                    </td>
                                    <td style="text-align:center">
                                        <p-button icon="fas fa-search" iconPos="left" type="button" class="ml-2"
                                            (click)="showBasicDialog(rowData)" pTooltip="Visualizza"></p-button>
                                        <button pButton class="p-button-danger p-button p-component ml-2"
                                            icon="fas fa-trash" iconPos="left"
                                            (click)="confirmDelete(rowData)"></button>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-dialog header="{{currentId === '' ? 'Nuovo Dispositivo' : 'Modifica Dispositivo'}}" *ngIf="displayBasic"
        [(visible)]="displayBasic" [draggable]="false" [style]="{width: '80vw'}" [baseZIndex]="50"
        [contentStyle]="{'overflow':'visible'}" (onHide)="resetTable()">
        <div class="card-body">
            <form [formGroup]="devicesForm" class="p-fluid">
                <div class="row">
                    <span class="col-md-4 mt-2">
                        <label for="id">Id dispositivo:{{(isRequired("id") ? "*" : "")}}</label>
                        <input formControlName="id" type="text" id="id" placeholder="Inserire Id del dispositivo"
                            pInputText />
                        <small *ngIf="devicesForm.get('id').invalid && devicesForm.get('id').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                    <span class="col-md-4 mt-2">
                        <label for="mac">Mac:{{(isRequired("mac") ? "*" : "")}}</label>
                        <p-inputMask formControlName="mac" type="text" id="mac"
                            placeholder="Inserire indirizzo Mac dispositivo" mask="**:**:**:**:**:**">
                        </p-inputMask>
                        <small *ngIf="devicesForm.get('mac').invalid && devicesForm.get('mac').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                    <span class="col-md-4 mt-2">
                        <label for="status">Stato dispositivo:{{(isRequired("status") ? "*" : "")}}</label>
                        <p-dropdown id="status" [options]="status" formControlName="status"
                            dropdownIcon="fas fa-chevron-down">
                        </p-dropdown>
                        <small *ngIf="devicesForm.get('status').invalid && devicesForm.get('status').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                </div>
                <div class="row">
                    <span class="col-md-4 mt-2">
                        <label for="manufacturer">Marca dispositivo:{{(isRequired("manufacturer") ? "*" : "")}}</label>
                        <input formControlName="manufacturer" type="text" id="manufacturer"
                            placeholder="Inserire marca dispositivo" pInputText />
                        <small
                            *ngIf="devicesForm.get('manufacturer').invalid && devicesForm.get('manufacturer').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                    <span class="col-md-4 mt-2">
                        <label for="version">Versione dispositivo:{{(isRequired("version") ? "*" : "")}}</label>
                        <input formControlName="version" type="text" id="version"
                            placeholder="Inserire versione software/firmware" pInputText />
                        <small *ngIf="devicesForm.get('version').invalid && devicesForm.get('version').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                    <span class="col-md-4 mt-2">
                        <label for="model">Modello:{{(isRequired("model") ? "*" : "")}}</label>
                        <input formControlName="model" type="text" id="model"
                            placeholder="Inserire  modello del dispositivo" pInputText>
                        <small *ngIf="devicesForm.get('model').invalid && devicesForm.get('model').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                </div>
                <div class="row">
                    <span class="col-md-4 mt-2">
                        <label for="denominazione">Denominazione commerciale dispositivo:{{(isRequired("denominazione")
                            ?
                            "*" : "")}}</label>
                        <input formControlName="denominazione" type="text" id="denominazione"
                            placeholder="Inserire denominazione del dispositivo" pInputText />
                        <small
                            *ngIf="devicesForm.get('denominazione').invalid && devicesForm.get('denominazione').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                    <span class="col-md-4 mt-2">
                        <label for="collegamento">Tipologia di collegamento:{{(isRequired("collegamento") ? "*" :
                            "")}}</label>
                        <p-multiSelect [options]="tipiCollegamento" formControlName="collegamento"
                            placeholder="Selezionare le tipologie di collegamento"
                            defaultLabel="Selezionare le tipologie di collegamento" optionLabel="label"
                            [style]="{'width':'100%'}" optionDisabled="disabled"
                            emptyMessage="Nessuna tipologia disponibile"
                            emptyFilterMessage="Nessuna tipologia disponibile">
                        </p-multiSelect>
                        <small *ngIf="devicesForm.get('collegamento').invalid && devicesForm.get('classe').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                    <span class="col-md-4 mt-2">
                        <label for="alimentazione">Tipo di alimentazione elettrica:{{(isRequired("alimentazione") ? "*"
                            :
                            "")}}</label>
                        <p-multiSelect [options]="tipiAlimentazione" formControlName="alimentazione"
                            placeholder="Selezionare le tipologie di alimentazione"
                            defaultLabel="Selezionare le tipologie di alimentazione" optionLabel="label"
                            [style]="{'width':'100%'}" optionDisabled="disabled"
                            emptyMessage="Nessuna tipologia disponibile"
                            emptyFilterMessage="Nessuna tipologia disponibile">
                        </p-multiSelect>
                        <small
                            *ngIf="devicesForm.get('alimentazione').invalid && devicesForm.get('alimentazione').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                </div>
                <div class="row">
                    <span class="col-md-4 mt-2">
                        <label for="classe">Classe del dispositivo secondo certificazione
                            MDR/IVDR:{{(isRequired("classe") ?
                            "*" : "")}}</label>
                        <p-dropdown id="classe" [options]="classiDispositivoMDR" formControlName="classe"
                            [autoDisplayFirst]="false" placeholder="Selezionare una classe">
                        </p-dropdown>
                        <small *ngIf="devicesForm.get('classe').invalid && devicesForm.get('classe').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>

                    <span class="col-md-4 mt-2">
                        <label for="classificazione">Classificazione Nazionale dei DM
                            (CND):{{(isRequired("classificazione")
                            ? "*" : "")}}</label>
                        <input formControlName="classificazione" type="text" id="classificazione"
                            placeholder="Inserire la classificazione del dispositivo" pInputText />
                        <small
                            *ngIf="devicesForm.get('classificazione').invalid && devicesForm.get('classificazione').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                    <span class="col-md-4 mt-2">
                        <label for="descrizione">Descrizione CND:{{(isRequired("descrizione") ? "*" : "")}}</label>
                        <input formControlName="descrizione" type="text" id="descrizione"
                            placeholder="Inserire descrizione CND" pInputText />
                        <small *ngIf="devicesForm.get('descrizione').invalid && devicesForm.get('descrizione').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                </div>
                <div class="row">
                    <span class="col-md-4 mt-2">
                        <label for="udi">UDI-DI/ EUDAMED ID:{{(isRequired("udi") ? "*" : "")}}</label>
                        <input formControlName="udi" type="text" id="udi" placeholder="Inserire udi" pInputText />
                        <small *ngIf="devicesForm.get('udi').invalid && devicesForm.get('udi').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                    <span class="col-md-4 mt-2">
                        <label for="produttore">Produttore:{{(isRequired("produttore") ? "*" : "")}}</label>
                        <input formControlName="produttore" type="text" id="produttore"
                            placeholder="Inserire indirizzo produttore dispositivo" pInputText>
                        <small *ngIf="devicesForm.get('produttore').invalid && devicesForm.get('produttore').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                    <span class="col-md-4 mt-2">
                        <label for="misurazioni">Misurazioni consentite dal dispositivo:{{(isRequired("misurazioni") ?
                            "*" :
                            "")}}</label>
                        <p-multiSelect class="p-fluid" [options]="misurazioniOpt" formControlName="misurazioni"
                            placeholder="Seleziona misurazioni consentite" optionLabel="label">
                        </p-multiSelect>
                        <small *ngIf="devicesForm.get('misurazioni').invalid && devicesForm.get('misurazioni').touched"
                            class="p-error block">Campo obbligatorio.</small>
                    </span>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12">
                        <div class="float-right">
                            <button pButton label="Salva" icon="fa fa-save" iconPos="left" id="saveDevice"
                                class="p-button-success" type="button" (click)="save()"></button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </p-dialog>