import { Component, OnInit } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'cwa-app';
  currentOrg = 'RIATLAS';
  coloreLogo = '#009479'; // Verde

  constructor(private config: PrimeNGConfig) {
    setTheme('bs4'); // or 'bs4'
  }

  ngOnInit(): void {
    registerLocaleData(localeIt);
    if (window.location.href.includes('nia') || window.location.href.includes('nemolab')) {
      this.currentOrg = 'NEMO';
      this.coloreLogo = '#5C8DA6';
    } else {
      this.currentOrg = 'RIATLAS';
      this.coloreLogo = '#009479';
    }

    // Qui va la configurazione per tradurre i componenti in italiano
    this.config.setTranslation({
      accept: 'Accetta',
      reject: 'Annulla',
      //translations
      dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
      dayNamesShort: ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"],
      dayNamesMin: ["Lu", "Ma", "Me", "Gi", "Ve", "Sa", "Do"],
      monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
      monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
    });
  }

}
