import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Options } from 'ng5-slider/options';
import { forkJoin } from 'rxjs';
import { LogEventService } from 'src/app/main/pazienti/service/log-event.service';
import { checkIsModified, Observation, Range, updateFormByObs, Value } from 'src/app/model/Observation';
import { endpoints } from 'src/endpoint/endpoints';
import { BaseVisiteComponent } from '../../../BaseVisiteComponent';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent extends BaseVisiteComponent implements OnInit {

  check = false;

  optionsF: Options = {
    floor: 0,
    ceil: 200,
    minRange: 1,
    showTicksValues: true,
    tickStep: 10,
    tickValueStep: 50,
    animate: false,
    noSwitching: true,
    disabled: true,
  };

  optionsO: Options = {
    floor: 0,
    ceil: 100,
    minRange: 1,
    showTicksValues: true,
    tickStep: 10,
    tickValueStep: 50,
    animate: false,
    noSwitching: true,
    disabled: true,
  };

  optionsS: Options = {
    floor: 0,
    ceil: 200,
    minRange: 1,
    showTicksValues: true,
    tickStep: 10,
    tickValueStep: 50,
    animate: false,
    noSwitching: true,
    disabled: true,
  };


  optionsD: Options = {
    floor: 0,
    ceil: 200,
    minRange: 1,
    showTicksValues: true,
    tickStep: 10,
    tickValueStep: 50,
    animate: false,
    noSwitching: true,
    disabled: true,
  };

  options2: Options = {
    floor: 50,
    ceil: 100,
    minRange: 1,
    showTicksValues: true,
    tickStep: 2,
    tickValueStep: 5,
    animate: false,
    noSwitching: true,
  };

  options3: Options = {
    floor: 0,
    ceil: 4,
    minRange: 1,
    showTicksValues: true,
    tickStep: 1,
    tickValueStep: 1,
    animate: false,
    noSwitching: true,
  };

  monitoringForm: FormGroup;
  monitoringObs: Observation[];

  constructor(private injector: Injector, private logService: LogEventService) {
    super(injector);
  }

  buildForm() {
    this.monitoringForm = this.fb.group({
      // 'diastolic-threshold': [[80, 120]],
      // 'systolic-threshold': [[80, 120]],
      '8867-4': [[30, 40]],
      '59408-5': [[90, 100]],
      '8480-6': [[110, 135]],
      '8462-4': [[75, 90]],
      'check-box1': [false],
      'check-box2': [false],
      'check-box3': [false],
      'check-box4': [false],
      //'pain': [[2, 4]]
    });
    if (this.monitoringForm && !this.checkPermessoModifica()) {
      this.optionsF = Object.assign({}, this.optionsF, { disabled: true });
      this.optionsO = Object.assign({}, this.optionsO, { disabled: true });
      this.optionsS = Object.assign({}, this.optionsS, { disabled: true });
      this.optionsD = Object.assign({}, this.optionsD, { disabled: true });
    }
  }

  ngOnInit() {
    this.check = false;
    this.getVitalSignsThresholds();
    this.buildForm();
  }


  getVitalSignsThresholds() {
    this.http.get(endpoints.getVitalSignsThresholds + this.clinicalImpressionId).toPromise()
      .then((resp: any) => {
        if (resp && resp.success === '1') {
          for (let i = 0; i < resp.message.length; i++) {
            switch (resp.message[i].code.coding[0].code) {
              case "8867-4":
                if (resp.message[i].status == "corrected") {
                  this.optionsF = Object.assign({}, this.optionsF, { disabled: false });
                  this.monitoringForm.get("check-box1").setValue(true);
                } else {
                  this.optionsF = Object.assign({}, this.optionsF, { disabled: true });
                  this.monitoringForm.get("check-box1").setValue(false);
                }
                break;
              case "59408-5":
                if (resp.message[i].status == "corrected") {
                  this.optionsO = Object.assign({}, this.optionsO, { disabled: false });
                  this.monitoringForm.get("check-box2").setValue(true);
                } else {
                  this.optionsO = Object.assign({}, this.optionsO, { disabled: true });
                  this.monitoringForm.get("check-box2").setValue(false);
                }
                break;
              case "8480-6":
                if (resp.message[i].status == "corrected") {
                  this.optionsS = Object.assign({}, this.optionsS, { disabled: false });
                  this.monitoringForm.get("check-box3").setValue(true);
                } else {
                  this.optionsS = Object.assign({}, this.optionsS, { disabled: true });
                  this.monitoringForm.get("check-box3").setValue(false);
                }
                break;
              case "8462-4":
                if (resp.message[i].status == "corrected") {
                  this.optionsD = Object.assign({}, this.optionsD, { disabled: false });
                  this.monitoringForm.get("check-box4").setValue(true);
                } else {
                  this.optionsD = Object.assign({}, this.optionsD, { disabled: true });
                  this.monitoringForm.get("check-box4").setValue(false);
                }
                break;
              default:
                break;
            }
          }
          this.monitoringObs = resp.message;
          this.monitoringObs = this.checkOrCreate(this.monitoringObs);
          updateFormByObs(this.monitoringObs, this.monitoringForm);
        } else {
          this.printErrorCaricamento(resp, 'Monitoring.');
        }
        this.logService.getAllOpt([this.monitoringObs], null);
      }).catch(err => {
        this.printErrorCaricamento(err, 'Monitoring.');
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
      });
  }

  /** Se l'Observation non esiste la crea */
  checkOrCreate(input: Observation[]): Observation[] {
    const ret = new Array<Observation>();
    Object.keys(this.monitoringForm.controls).forEach(elem => {
      const find = input.find(x => x.code.coding[0].code === elem);
      if (!find) {
        const obs = this.createObservation(elem);
        if (obs) {
          ret.push(obs);
        }
      } else {
        ret.push(find);
      }
    });
    return ret;
  }

  createObservation(key): Observation {
    switch (key) {
      // case 'diastolic-threshold': {
      //   const range = this.costruisciRange();
      //   return new Observation('vital-signs-threshold', 'diastolic-threshold', 'Diastolica', 'http://loinc.org',
      //                           null, null, null, null, range);
      // }
      // case 'systolic-threshold': {
      //   const range = this.costruisciRange();
      //   return new Observation('vital-signs-threshold', 'systolic-threshold', 'Sistolica', 'http://loinc.org',
      //                           null, null, null, null, range);
      // }
      case '8867-4': {
        const range = this.costruisciRange();
        return new Observation('vital-signs-threshold', '8867-4', 'Frequenza cardiaca', 'http://loinc.org',
          null, null, null, null, range);
      }
      case '59408-5': {
        const range = this.costruisciRange2();
        return new Observation('vital-signs-threshold', '59408-5', 'Ossigenazione', 'http://loinc.org',
          null, null, null, null, range);
      }
      case '8310-5': {
        const range = this.costruisciRange3();
        return new Observation('vital-signs-threshold', '8310-5', 'Temperatura', 'http://loinc.org',
          null, null, null, null, range);
      }
      case '8480-6': {
        const range = this.costruisciRange3();
        return new Observation('vital-signs-threshold', '8480-6', 'Sistolica', 'http://loinc.org',
          null, null, null, null, range);
      }
      case '8462-4': {
        const range = this.costruisciRange3();
        return new Observation('vital-signs-threshold', '8462-4', 'Diastolica', 'http://loinc.org',
          null, null, null, null, range);
      }
      case 'pain': {
        const range = this.costruisciRange3();
        return new Observation('vital-signs-threshold', 'pain', 'Dolore', 'http://loinc.org',
          null, null, null, null, range);
      }
      default:
        return null;
    }
  }

  costruisciRange(): Range[] {
    const ret = new Array<Range>();
    const range = {} as Range;
    range.low = {} as Value;
    range.low.value = 0;
    range.high = {} as Value;
    range.high.value = 200;
    ret.push(range);
    return ret;
  }

  costruisciRange2(): Range[] {
    const ret = new Array<Range>();
    const range = {} as Range;
    range.low = {} as Value;
    range.low.value = 0;
    range.high = {} as Value;
    range.high.value = 100;
    ret.push(range);
    return ret;
  }

  costruisciRange3(): Range[] {
    const ret = new Array<Range>();
    const range = {} as Range;
    range.low = {} as Value;
    range.low.value = 0;
    range.high = {} as Value;
    range.high.value = 200;
    ret.push(range);
    return ret;
  }

  costruisciRange4(): Range[] {
    const ret = new Array<Range>();
    const range = {} as Range;
    range.low = {} as Value;
    range.low.value = 0;
    range.high = {} as Value;
    range.high.value = 200;
    ret.push(range);
    return ret;
  }

  costruisciRange5(): Range[] {
    const ret = new Array<Range>();
    const range = {} as Range;
    range.low = {} as Value;
    range.low.value = 0;
    range.high = {} as Value;
    range.high.value = 200;
    ret.push(range);
    return ret;
  }

  costruisciRange6(): Range[] {
    const ret = new Array<Range>();
    const range = {} as Range;
    range.low = {} as Value;
    range.low.value = 2;
    range.high = {} as Value;
    range.high.value = 4;
    ret.push(range);
    return ret;
  }

  salva() {
    const obsToSave = checkIsModified(this.monitoringForm, this.monitoringObs);
    if (obsToSave.length === 0 && this.check == false) {
      this.toastr.info('Nulla da salvare.');
      return;
    } /*else if (this.optionsF.disabled == true || this.optionsD.disabled == true || this.optionsO.disabled == true || this.optionsS.disabled == true) {
      if (this.optionsF.disabled == true) {
        this.monitoringForm.get("8867-4").setValue[0] = this.supportF1;
        this.monitoringForm.get("8867-4").setValue[1] = this.supportF2;
        obsToSave.length++;
      }
      if (this.optionsD.disabled == true) {
        this.monitoringForm.get("8462-4").setValue[0] = this.supportD1;
        this.monitoringForm.get("8462-4").setValue[1] = this.supportD2;
        obsToSave.length++;
      }
      if (this.optionsO.disabled == true) {
        this.monitoringForm.get("59408-5").setValue[0] = this.supportO1;
        this.monitoringForm.get("59408-5").setValue[1] = this.supportO2;
        obsToSave.length++;
      }

      if (this.optionsS.disabled == true) {
        this.monitoringForm.get("8480-6").setValue[0] = this.supportS1;
        this.monitoringForm.get("8480-6").setValue[1] = this.supportS2;
        obsToSave.length++;
      }
    }*/
    const obsPostUpdate = this.updateObsByForm(this.monitoringObs, this.monitoringForm);
    const obsForUpdate = obsPostUpdate.filter(elem => {
      if (elem.id) {
        return true;
      } else {
        return false;
      }
    });
    if (obsForUpdate && obsForUpdate.length > 0) {
      forkJoin(obsForUpdate.map(el => {
        return this.putObservation(el.id, el);
      })).subscribe((resp: Observation[]) => {
        this.getVitalSignsThresholds();
        this.toastr.success('Aggiornamento effettuato con successo.');
      }, (err) => {
        console.error(err);
        this.toastr.error('Errore nell\'aggiornamento dei dati.');
      });
    }
    const obsForAdd = obsPostUpdate.filter(elem => {
      if (!elem.id) {
        return true;
      } else {
        return false;
      }
    });
    if (obsForAdd && obsForAdd.length > 0) {
      const input = {
        clinicalImpressionId: this.clinicalImpressionId,
        patientId: this.patientId,
        observations: [],
        code: {
          coding: {
            code: 'vital-signs-threshold',
            system: 'http://hl7.org/fhir/observation-category'
          }
        }
      };
      obsForAdd.forEach(elem => {
        const obs = {} as any;
        obs.category = {} as any;
        obs.category.coding = elem.category[0].coding[0];
        obs.code = {} as any;
        obs.code.coding = elem.code.coding[0];
        obs.value = {} as any;
        switch (obs.code.coding.code) {
          case "8867-4":
            if (this.optionsF.disabled == true) {
              obs.status = "cancelled";
            } else {
              obs.status = "corrected";
            }
            break;
          case "59408-5":
            if (this.optionsO.disabled == true) {
              obs.status = "cancelled";
            } else {
              obs.status = "corrected";
            }
            break;
          case "8480-6":
            if (this.optionsS.disabled == true) {
              obs.status = "cancelled";
            } else {
              obs.status = "corrected";
            }
            break;
          case "8462-4":
            if (this.optionsD.disabled == true) {
              obs.status = "cancelled";
            } else {
              obs.status = "corrected";
            }
            break;
          default:
            break;
        }
        if (elem.referenceRange) {
          obs.value.type = 'threshold';
          obs.value.value = elem.referenceRange[0].high.value + '/' + elem.referenceRange[0].low.value;
          obs.value.unit = '';
        }
        input.observations.push(obs);
      });
      this.addInvestigation(input);
    }
  }

  updateObsByForm(obs: Observation[], form: FormGroup): Observation[] {
    const ret = new Array<Observation>();
    Object.keys(form.controls).forEach(el => {
      const find = obs.find(x => x.code.coding[0].code === el);
      if (find) {
        switch (find.code.coding[0].code) {
          case "8867-4":
            if (this.optionsF.disabled == true) {
              find.status = "cancelled";
            } else {
              find.status = "corrected";
            }
            break;
          case "59408-5":
            if (this.optionsO.disabled == true) {
              find.status = "cancelled";
            } else {
              find.status = "corrected";
            }
            break;
          case "8480-6":
            if (this.optionsS.disabled == true) {
              find.status = "cancelled";
            } else {
              find.status = "corrected";
            }
            break;
          case "8462-4":
            if (this.optionsD.disabled == true) {
              find.status = "cancelled";
            } else {
              find.status = "corrected";
            }
            break;
          default:
            break;
        }
        if (find.referenceRange) {
          find.referenceRange[0].low.value = form.get(el).value[0];
          find.referenceRange[0].high.value = form.get(el).value[1];
        }
        ret.push(find);
      }
    });
    return ret;
  }

  /**
   * chiamata http PUT observation
   */
  putObservation(id, obsr) {
    return this.http.put(endpoints.putObservationVisite + '/' + id, obsr);
  }

  addInvestigation(input) {
    this.http.post(endpoints.addInvestigationVisite, input).toPromise()
      .then((resp: any) => {
        if (resp && resp.success === '1') {
          this.getVitalSignsThresholds();
          this.toastr.success('Aggiornamento effettuato con successo.');
        } else {
          console.error(resp.message);
          this.toastr.error('Errore nell\'aggiornamento dei dati.');
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nell\'aggiornamento dei dati.');
      });
  }

  checkPermessoModifica() {
    return super.checkPermessoModifica() && this.userService.checkPermission('Osservazioni', 'modify');
  }

  unableSlider2(event?) {
    this.check = true;
    if (!event.checked) {
      this.optionsF = Object.assign({}, this.optionsF, { disabled: true });
    } else {
      this.optionsF = Object.assign({}, this.optionsF, { disabled: false });
    }
  }

  unableSlider1(event?) {
    this.check = true;
    if (!event.checked) {
      this.optionsO = Object.assign({}, this.optionsO, { disabled: true });
    } else {
      this.optionsO = Object.assign({}, this.optionsO, { disabled: false });
    }
  }

  unableSlider3(event?) {
    this.check = true;
    if (!event.checked) {
      this.optionsD = Object.assign({}, this.optionsD, { disabled: true });

    } else {
      this.optionsD = Object.assign({}, this.optionsD, { disabled: false });
    }
  }

  unableSlider4(event?) {
    this.check = true;
    if (!event.checked) {
      this.optionsS = Object.assign({}, this.optionsS, { disabled: true });
    } else {
      this.optionsS = Object.assign({}, this.optionsS, { disabled: false });
    }
  }

}
