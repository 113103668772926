import { BreakpointState } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ScrollPanel } from 'primeng/scrollpanel';
import { Observable } from 'rxjs';
import { LogEventService } from 'src/app/main/pazienti/service/log-event.service';
import { PazientiService } from 'src/app/main/pazienti/service/pazienti.service';
import { AppService } from 'src/app/service/app.service';
import { QuestionnaireService } from 'src/app/service/questionnaire-service.service';
import { CanComponentDeactivate } from 'src/app/service/unsaved-changes-guard.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';

@Component({
  selector: 'app-scale-di-valutazione-nlab',
  templateUrl: './scale-di-valutazione-nlab.component.html',
  styleUrls: ['./scale-di-valutazione-nlab.component.scss']
})
export class ScaleDiValutazioneNLABComponent implements OnInit, CanComponentDeactivate {

  NOME_QUESTIONARIO = "NLAB";

  // Per gestire il mobile
  isMobile = false;
  observer;

  clinicalImpressionId;
  idQuestionario;

  domande;

  modalStruct: any;

  patientId;

  fromList;

  @ViewChild('sc', { static: true }) scrollPanel: ScrollPanel;

  isVisit = false; // TODO: Serve per testare

  @Output()
  update = new EventEmitter<boolean>();

  facoltativi;

  dirty = false;

  constructor(private toastr: ToastrService, private userService: UserService, private modalService: BsModalService, private router: Router,
    // tslint:disable-next-line: max-line-length
    private http: HttpClient, private route: ActivatedRoute, private appServ: AppService, private pazientiService: PazientiService,
    private location: Location, private questionnaireService: QuestionnaireService, private logService: LogEventService) { }

  ngOnInit() {
    this.getIsInVisite();
    this.getQuestionario();
    this.fromList = this.appServ.getTypeViewList();
    this.observer = this.appServ.mdAppObs
      .subscribe((statex: BreakpointState) => {
        this.isMobile = statex.matches;
      });
    this.modalStruct = {};
    this.modalStruct.visible = false;
    this.modalStruct.style = { minWidth: '50%', maxWidth: '70%', minHeight: '40%', maxHeight: '60%' };
    this.patientId = this.pazientiService.getPatientId();

    this.facoltativi = new Array();
    this.facoltativi.push({ nome: 'Mini mental test' });
  }

  // Solo se non esiste nel database. Attenzione a non creare duplicati!!!
  creaQuestionario() {
    this.questionnaireService.createQuestionnaire(this.NOME_QUESTIONARIO);
  }

  // Usare solo quando è realmente necessario
  aggiornaQuestionario() {
    this.questionnaireService.updateQuestionnaire(this.NOME_QUESTIONARIO, this.idQuestionario);
  }


  getIsInVisite() {
    const cId = this.route.parent.parent.snapshot.paramMap.get('vId');
    if (cId) {
      this.isVisit = true;
      this.clinicalImpressionId = cId;
    }
  }

  scrollTop(cc) {
    cc.scrollTop(0);
  }

  getQuestionario() {
    const obj = {
      nome: 'NLAB',
      versione: '1'
    };
    let endpointToCall = endpoints.getQuestionnaireByNameByVersion;
    if (!this.isVisit) {
      endpointToCall = endpoints.getQuestionnaireByNameByVersionInAttivazione;
    }
    this.http.post(endpointToCall, obj).toPromise()
      .then((resp: any) => {
        this.idQuestionario = resp.id;
        this.domande = new Array();
        resp.item.forEach(item => {
          let domanda: any;
          domanda = {};
          domanda.testo = item.text;
          domanda.tipo = item.type;
          domanda.linkId = item.linkId;
          domanda.risposta = undefined;
          domanda.risposte = new Array();
          switch (domanda.tipo) {
            case 'boolean':
              domanda.risposte.push({
                value: 'true',
                label: 'Si'
              });
              domanda.risposte.push({
                value: 'false',
                label: 'No'
              });
              break;
            case 'integer':
              item.option.forEach(opt => {
                domanda.risposte.push({
                  label: opt.valueCoding.display,
                  value: opt.valueCoding.code
                });
              });
              break;
            case 'quantity':
              break;
          }
          this.domande.push(domanda);
        });
        this.getRisposte();
        this.recuperaRisultatiQuestionari();
      });
  }

  getRisposte() {
    let obj: any;
    obj = {};
    let endpointToCall;
    if (this.isVisit) {
      obj = {
        clinicalImpressionId: this.clinicalImpressionId,
        questionnaireId: this.idQuestionario,
        patientId: this.patientId
      };
      endpointToCall = endpoints.getClinicalImpressionSurveyResponse;
    } else {
      obj = {
        questionnaireId: this.idQuestionario,
        patientId: this.patientId
      };
      endpointToCall = endpoints.getClinicalImpressionSurveyResponseNLAB;
    }
    this.http.post(endpointToCall, obj).toPromise()
      .then((resp: any) => {
        const risposte = resp.item;
        let indice = 0;
        (this.domande.filter(d => d.tipo !== 'display')).forEach(domanda => {
          switch (domanda.tipo) {
            case 'quantity':
              domanda.risposta = '' + risposte[indice++].answer[0].valueString;
              if (domanda.risposta === '-1') {
                domanda.risposta = undefined;
              }
              break;
            case 'integer':
              domanda.risposta = '' + risposte[indice++].answer[0].valueInteger;
              break;
            case 'boolean':
              domanda.risposta = (domanda.risposte.find(d => d.value === '' + risposte[indice].answer[0].valueBoolean)).value;
              indice++;
              break;
          }
        });
        this.aggiornaLabelPunteggioNLAB();
        this.logService.getAllOpt([[resp]], null);
      }).catch(err => {
        if (err.status === 404) {
          this.toastr.info('Questionario non eseguito.');
          this.logService.resetGlobalVarLog();
        } else {
          console.error(err);
          if (err.status == 403) {
            this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
            return;
          }
          this.toastr.error('Errore nel caricamento delle risposte del questionario.');
        }
      }).finally(() => {
        this.dirty = false;
      });
  }

  calcolaPunteggioBoolean(domanda) {
    const dp = this.domande.find(d => d.testo.includes('Punteggio') && Number(d.linkId) > Number(domanda.linkId));
    dp.risposta += 1;
  }

  aggiornaLabelPunteggioNLAB() {
    const domanda = this.domande.find(d => d.testo.includes('Punteggio N-Lab'));
    domanda.label = (domanda.risposte.find(d => Number(d.value) === Number(domanda.risposta))).label;
  }

  salva() {
    let obj: any;
    obj = {};
    let endpointToCall;
    if (this.isVisit) {
      obj = {
        clinicalImpressionId: this.clinicalImpressionId,
        questionnaireId: this.idQuestionario,
        answers: []
      };
      endpointToCall = endpoints.addClinicalImpressionSurveyResponse;
    } else {
      obj = {
        patientId: this.patientId,
        questionnaireId: this.idQuestionario,
        answers: []
      };
      endpointToCall = endpoints.addClinicalImpressionSurveyResponseNLAB;
    }

    this.normalizzaFacoltativi();

    try {
      const domandaPunteggioA = this.domande.find(d => d.testo.includes('Punteggio Anamnesi premorbosa'));
      domandaPunteggioA.risposta = 0;
      const domandaPunteggioB = this.domande.find(d => d.testo.includes('Punteggio Presenza di marcatori'));
      domandaPunteggioB.risposta = 0;
      const domandaPunteggioC = this.domande.find(d => d.testo.includes('Punteggio Presenza di indicatori'));
      domandaPunteggioC.risposta = 0;
      const domandaPunteggioD = this.domande.find(d => d.testo.includes('Punteggio Assessment score'));
      domandaPunteggioD.risposta = 0;
      const domandaPunteggioNLAB = this.domande.find(d => d.testo.includes('Punteggio N-Lab'));
      domandaPunteggioNLAB.risposta = 0;

      let r;
      (this.domande.filter(d => d.tipo !== 'display')).forEach(domanda => {
        switch (domanda.tipo) {
          case 'quantity':
            if (domanda.testo.includes('Punteggio')) {
              switch (Number(domanda.linkId)) {
                case Number(domandaPunteggioA.linkId):
                  if (domandaPunteggioA.risposta < 4) {
                    domandaPunteggioA.risposta = 0;
                  } else {
                    domandaPunteggioA.risposta = 1;
                  }
                  break;
                case (Number(domandaPunteggioB.linkId)):
                  if (domandaPunteggioB.risposta < 7) {
                    domandaPunteggioB.risposta = 0;
                  } else {
                    domandaPunteggioB.risposta = 1;
                  }
                  break;
                case (Number(domandaPunteggioC.linkId)):
                  if (domandaPunteggioC.risposta < 4) {
                    domandaPunteggioC.risposta = 0;
                  } else {
                    domandaPunteggioC.risposta = 1;
                  }
                  break;
                case (Number(domandaPunteggioD.linkId)):
                  if (domandaPunteggioD.risposta < 3) {
                    domandaPunteggioD.risposta = 0;
                  } else {
                    domandaPunteggioD.risposta = 1;
                  }
              }
              // domanda.risposta = r;
            } else {
              if (domanda.risposta === undefined || domanda.risposta === null) {
                throw new Error('Non sono state risposte tutte le domande.');
              }
              if (domanda.testo.includes('Rankin')) {
                if (Number(domanda.risposta) < 4) {
                  domandaPunteggioD.risposta += 0;
                } else {
                  domandaPunteggioD.risposta += 1;
                }
              } else if (domanda.testo.includes('Mini mental')) {
                if (Number(domanda.risposta) !== -1) {
                  if (Number(domanda.risposta) > 24) {
                    domandaPunteggioD.risposta += 0;
                  } else {
                    domandaPunteggioD.risposta += 1;
                  }
                }
              } else if (domanda.testo.includes('disabilità comunicativa')) {
                if (Number(domanda.risposta) > 1) {
                  domandaPunteggioD.risposta += 0;
                } else {
                  domandaPunteggioD.risposta += 1;
                }
              } else if (domanda.testo.includes('Barthel Index')) {
                if (Number(domanda.risposta) < 50) {
                  domandaPunteggioD.risposta += 1;
                } else {
                  domandaPunteggioD.risposta += 0;
                }
              } else if (domanda.testo.includes('BI solo')) {
                if (Number(domanda.risposta) > 3) {
                  domandaPunteggioD.risposta += 0;
                } else {
                  domandaPunteggioD.risposta += 1;
                }
              } else if (domanda.testo.includes('SAHFE')) {
                if (Number(domanda.risposta) < 4) {
                  domandaPunteggioD.risposta += 0;
                } else {
                  domandaPunteggioD.risposta += 1;
                }
              }
            }
            r = domanda.risposta;
            break;
          case 'integer':
            if (domanda.testo.includes('Punteggio N-Lab')) {
              domandaPunteggioNLAB.risposta += domandaPunteggioA.risposta;
              domandaPunteggioNLAB.risposta += domandaPunteggioB.risposta;
              domandaPunteggioNLAB.risposta += domandaPunteggioC.risposta;
              domandaPunteggioNLAB.risposta += domandaPunteggioD.risposta;
              this.aggiornaLabelPunteggioNLAB();
              r = domanda.risposta;
            }
            break;
          case 'boolean':
            if (domanda.risposta === undefined || domanda.risposta === null) {
              throw new Error('Non sono state risposte tutte le domande.');
            } else {
              r = domanda.risposta;
              if (r === 'true') {
                this.calcolaPunteggioBoolean(domanda);
              }
            }
            break;
        }

        obj.answers.push({
          linkId: domanda.linkId,
          answer: r + '',
          type: domanda.tipo
        });
      });
    } catch (Exception) {
      this.toastr.error(Exception.message);
      return;
    }

    this.http.post(endpointToCall, obj).toPromise()
      .then((resp: any) => {
        this.toastr.success('Salvataggio avvenuto con successo.');
        this.getQuestionario();
        this.update.next(true);
      }).catch(err => {
        console.error(err);
        this.update.next(false);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel salvataggio delle risposte del questionario.');
      });
  }

  openModal(nomeQuestionario) {
    this.modalStruct.title = nomeQuestionario;
    this.modalStruct.visible = true;
    setTimeout(() => this.scrollTop(this.scrollPanel), 100);
  }

  hide() {
    this.modalStruct.visible = false;
    switch (this.modalStruct.title) {
      case 'Rankin modificata':
        this.getRisposteQuestionario('MRS', '1');
        break;
      case 'Mini mental test':
        this.getRisposteQuestionario('MMSE', '1');
        break;
      case 'Scala disabilità comunicativa':
        this.getRisposteQuestionario('SDC', '1');
        break;
      case 'Barthel Index (BI) score totale':
        this.getRisposteQuestionario('Barthel', '1');
        break;
      case 'BI solo deambulazione':
        this.getRisposteQuestionario('BIDeambulazione', '1');
        break;
      case 'SAHFE score':
        this.getRisposteQuestionario('SAHFE', '1');
        break;
    }
    this.modalStruct.title = '';
  }

  recuperaRisultatiQuestionari() {
    this.getRisposteQuestionario('MRS', '1');
    this.getRisposteQuestionario('MMSE', '1');
    this.getRisposteQuestionario('SDC', '1');
    this.getRisposteQuestionario('Barthel', '1');
    this.getRisposteQuestionario('BIDeambulazione', '1');
    this.getRisposteQuestionario('SAHFE', '1');
  }

  getRisposteQuestionario(nomeQuestionario, versioneQuestionario) {
    let localIdQuestionario;
    let obj: any;
    obj = {
      nome: nomeQuestionario,
      versione: versioneQuestionario
    };
    let endpointToCall = endpoints.getQuestionnaireByNameByVersion;
    if (!this.isVisit) {
      endpointToCall = endpoints.getQuestionnaireByNameByVersionInAttivazione;
    }
    this.http.post(endpointToCall, obj).toPromise()
      .then((resp: any) => {
        localIdQuestionario = resp.id;
        let endpointToCall;
        if (this.isVisit) {
          obj = {
            clinicalImpressionId: this.clinicalImpressionId,
            questionnaireId: localIdQuestionario
          };
          endpointToCall = endpoints.getClinicalImpressionSurveyResponse;
        } else {
          obj = {
            patientId: this.patientId,
            questionnaireId: localIdQuestionario
          };
          endpointToCall = endpoints.getClinicalImpressionSurveyResponseNLAB;
        }
        let risposteServer;
        this.http.post(endpointToCall, obj).toPromise()
          .then((resp: any) => {
            risposteServer = resp.item;
            this.aggiornaRispostaQuestionario(nomeQuestionario, risposteServer);
            return risposteServer;
          }).catch(err => {
            if (err.status === 404) {
              risposteServer = undefined;
              return risposteServer;
            } else {
              console.error(err);
              if (err.status == 403) {
                this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
                return;
              }
              this.toastr.error('Errore nel caricamento delle risposte del questionario.');
            }
          });
      }).catch(err => {
        if (err.status === 404) {
          localIdQuestionario = undefined;
          return localIdQuestionario;
        } else {
          console.error(err);
          if (err.status == 403) {
            this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
            return;
          }
          this.toastr.error('Errore nel caricamento del questionario ' + nomeQuestionario + '.');
        }
      });
  }

  aggiornaRispostaQuestionario(nomeQuestionario, risposteQuestionario) {
    switch (nomeQuestionario) {
      case 'MRS':
        (this.domande.find(d => d.testo === 'Rankin modificata')).risposta = risposteQuestionario[risposteQuestionario.length - 1].answer[0].valueQuantity.value;
        break;
      case 'MMSE':
        (this.domande.find(d => d.testo === 'Mini mental test')).risposta = risposteQuestionario[risposteQuestionario.length - 1].answer[0].valueString;
        break;
      case 'SDC':
        (this.domande.find(d => d.testo === 'Scala disabilità comunicativa')).risposta = risposteQuestionario[risposteQuestionario.length - 1].answer[0].valueString;
        break;
      case 'Barthel':
        (this.domande.find(d => d.testo === 'Barthel Index (BI) score totale')).risposta = risposteQuestionario[risposteQuestionario.length - 1].answer[0].valueString;
        break;
      case 'BIDeambulazione':
        (this.domande.find(d => d.testo === 'BI solo deambulazione')).risposta = risposteQuestionario[risposteQuestionario.length - 1].answer[0].valueString;
        break;
      case 'SAHFE':
        (this.domande.find(d => d.testo === 'SAHFE score')).risposta = risposteQuestionario[risposteQuestionario.length - 1].answer[0].valueString;
        break;
    }
  }

  checkPermessoModifica() {
    if (this.isVisit) {
      return this.userService.checkPermission('Osservazioni', 'modify') && this.userService.checkPermission('Visite', 'modify');
    } else {
      return this.userService.checkPermission('Anagrafica pazienti', 'modify')
    }
  }

  normalizzaFacoltativi() {
    this.facoltativi.forEach(fac => {
      const domanda = this.domande.find(d => d.testo === fac.nome);
      if (domanda.risposta === undefined || domanda.risposta === null || domanda.risposta.length === 0) {
        domanda.risposta = '-1';
      }
    });
  }

  close() {
    this.location.back();
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return !this.checkPermessoModifica() || !this.dirty;
  }

}
