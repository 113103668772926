import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { CustomizationService } from 'src/app/service/customization.service';
import { NetworkService } from 'src/app/service/network-service.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../../service/pazienti.service';
import { DateFromTo } from '../Model/date-from-to';
import { getBarChartOptions } from '../Utils/apexchart-opt';
import { getSeriesResponses, SerieType } from '../Utils/grafici-utils';
import { getFirstProperty } from 'src/app/utils/utils';
@Component({
  selector: 'app-grafici-domande',
  templateUrl: './grafici-domande.component.html',
  styleUrls: ['./grafici-domande.component.scss']
})
export class GraficiDomandeComponent implements OnInit, OnDestroy {

  @Input() identifier;
  @Input() domande;
  @Input() titolo;

  @Input('date') set date(date: DateFromTo) {
    if (!date.compare(this._date)) {
      this.observer.next(date);
    }
  }

  // Usato solo per il report
  @Input() showHeader: boolean;
  @Input() tipoGrafico;
  @Input() altezzaGrafico;
  @Input() larghezzaGrafico;

  // tslint:disable-next-line: variable-name
  _date: DateFromTo;

  observer: BehaviorSubject<DateFromTo>;

  dateFromString: string;
  dateToString: string;
  dateFromTimeStamp;
  dateToTimeStamp;

  opt: SelectItem[];

  selected;
  questions;

  xAxis = {
    type: 'datetime',
    labels: {
      rotate: -45,
      rotateAlways: false,
      offsetX: -15,
      style: {
        fontSize: '12px'
      }
    }
  };

  stroke: ApexStroke = { curve: 'smooth' };
  markers = { size: 4, hover: { size: undefined, sizeOffset: 2 } };

  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  chart: ApexCharts;
  patientId;
  // tslint:disable-next-line: max-line-length
  constructor(private http: HttpClient, private toastr: ToastrService, private datePipe: DatePipe, private network: NetworkService,
    private userService: UserService, private patientService: PazientiService, private customizationService: CustomizationService) {
    this.observer = new BehaviorSubject<DateFromTo>(null);
  }

  ngOnInit() {
    this.titolo = this.titolo ?? "";
    const paziente = this.patientService.getPatient();
    this.patientId = paziente.id;
    if (this.showHeader === undefined) {
      this.showHeader = true;
    }
    // Se vengono passate in input le domande usa quelle, altrimenti usa quelle di default per l'organization
    this.questions = this.domande ?? this.customizationService.getDefaultQuestionnaireNameByOrganizationName(this.userService.getCurrentOrganization());
    // Seleziono il primo
    this.selected = getFirstProperty(this.questions);

    this.observer.subscribe((input) => {
      if (this.identifier && input) {
        this._date = input;
        this.dateFromString = this.datePipe.transform(input.getDateFrom(), 'yyyy-MM-dd');
        this.dateToString = this.datePipe.transform(input.getDateTo(), 'yyyy-MM-dd');
        this.dateFromTimeStamp = Math.round(input.getDateFrom().getTime());
        this.dateToTimeStamp = Math.round(input.getDateTo().getTime());
        this.dropdownChange(this.selected);
      }
    });
    this.opt = this.getOpt();
  }

  ngOnDestroy(): void {
    this.observer.unsubscribe();
  }

  getOpt() {
    const ret = new Array<SelectItem>();
    Object.keys(this.questions).forEach(el => {
      ret.push({
        label: this.questions[el].graficoName,
        value: this.questions[el]
      });
    });
    return ret;
  }

  getChartArray(input): SerieType {
    return {
      name: input.graficoName,
      color: ['#008FFB'],
      display: [input.graficoName],
      type: ['line'],
      xaxis: this.xAxis,
      yaxis: this.getYaxis(input.graficoName),
      stroke: this.stroke,
      markers: true
    } as SerieType;
  }

  dropdownChange(event) {
    this.createChart(this.getChartArray(event), event.graficoName.trim() + 'ID', event.testo, event);
  }

  createChart(chartArray: SerieType, id, title, event) {
    this.getData(event).then((response: any) => {
      const series: ApexAxisChartSeries = [];
      // chartArray.display.forEach((el, i) => {
      //   series.push({ data: [], type: chartArray.type[i], name: el });
      // });
      let seriesLabels = null;
      let labels = [];
      if (response.hits && response.hits.hits && response.hits.hits.length > 0) {
        seriesLabels = getSeriesResponses(response, chartArray.display[0], chartArray.display, chartArray.type, this.userService.getCurrentOrganization(), this.customizationService);
        if (Array.isArray(seriesLabels.serie)) {
          seriesLabels.serie.forEach(element => {
            series.push(element);
          });
        } else {
          series.push(seriesLabels.serie);
        }
        labels = seriesLabels.labels;
      }
      const chartArr = new Array<SerieType>();
      chartArr.push(chartArray);
      // tslint:disable-next-line: max-line-length
      const options = getBarChartOptions(
        id,
        title,
        series,
        chartArr,
        labels,
        chartArray.yaxis,
        chartArray.stroke,
        chartArray.markers,
        chartArray.fill,
        chartArray.grid,
        chartArray.xaxis,
      );
      if (this.altezzaGrafico !== undefined) {
        options.chart.height = this.altezzaGrafico;
      }
      if (this.larghezzaGrafico !== undefined) {
        options.chart.width = this.larghezzaGrafico;
      }
      options.dataLabels = { enabled: false };
      // options.title.offsetX = 0;

      if (this.chart) {
        this.chart.updateOptions(options, false, false, false);
        // ApexCharts.exec(id,'updateOptions',options)
      } else {
        this.chart = new ApexCharts(this.chartRef.nativeElement, options);
        this.chart.render();
      }
    }).catch(err => {
      console.error(err);
      this.toastr.error('Errore nel recupero dei dati.');
    });

  }

  getData(event) {
    return this.http.get(endpoints.elasticQuery + "getSintomi" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + event.code + "/-answers").toPromise();
  }

  getYaxis(label) {
    const yAxis: ApexYAxis = {
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true,
        color: '#008FFB'
      },
      labels: {
        style: {
          fontSize: '30px',
        }
      },
      title: {
        text: label,
        offsetX: 10,
        style: {
          color: '#008FFB',
          fontSize: '14px',
        }
      },
    };

    // tslint:disable-next-line: variable-name
    const labels1_3 = {
      style: {
        colors: '#008FFB',
        fontSize: '14px',
      },
      formatter: (val, index) => {
        if (val === 1) {
          return '1-3';
        } else if (val === 2) {
          return '3-8';
        } else if (val === 3) {
          return '>8';
        } else if (val === 4) {
          return '';
        } else if (val === 0) {
          return '0';
        } else {
          return '';
        }
      }
    };

    // tslint:disable-next-line: variable-name
    const labels1_4 = {
      style: {
        colors: '#008FFB',
        fontSize: '14px',
      },
      formatter: (val, index) => {
        if (val === 5) {
          return 'Normale';
        } else if (val === 6) {
          return 'Solida';
        } else if (val === 7) {
          return 'Liquida';
        } else {
          return '';
        }
      }
    };

    const labelsSimple = {
      style: {
        colors: '#008FFB',
        fontSize: '14px',
      },
    };

    const labelsMolto = {
      style: {
        colors: '#008FFB',
        fontSize: '14px',
      },
      formatter: (val, index) => {
        if (val === 17) {
          return 'No';
        } else if (val === 18) {
          return 'Un po\'';
        } else if (val === 19) {
          return 'Molto';
        } else if (val === 20) {
          return 'Moltiss.';
        } else {
          return '';
        }
      },
      minWidth: 60,
      maxWidth: 160
    };

    const labelsSiNo = {
      style: {
        colors: '#008FFB',
        fontSize: '14px',
      },
      formatter: (y, index) => {
        if (typeof y === 'undefined' || y == null) {
          return 'ND';
        } else if (y === 8) {
          return 'No';
        } else if (y === 9) {
          return 'Si';
        }
      }
    };

    if (this.questions.evaquazioniGiornaliere_frequenza && this.questions.evaquazioniGiornaliere_frequenza.graficoName === label) {
      yAxis.labels = labels1_3;
      yAxis.min = 0;
      yAxis.max = 4;
      yAxis.tickAmount = 4;
    } else if (this.questions.evaquazioniGiornaliere_tipo && this.questions.evaquazioniGiornaliere_tipo.graficoName === label) {
      yAxis.labels = labels1_4;
      yAxis.min = 4;
      yAxis.max = 7;
      yAxis.tickAmount = 3;
    } else if (this.questions.diarrea && this.questions.diarrea.graficoName === label) {
      yAxis.labels = labelsSimple;
      yAxis.min = 0;
      yAxis.max = 10;
      yAxis.tickAmount = 10;
    } else if (this.questions.stipsi && this.questions.stipsi.graficoName === label) {
      yAxis.labels = labelsSimple;
      yAxis.min = 0;
      yAxis.max = 10;
      yAxis.tickAmount = 10;
    } else if (this.questions.dolore1 && this.questions.dolore1.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.dolore2 && this.questions.dolore2.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.fatica1 && this.questions.fatica1.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.fatica2 && this.questions.fatica2.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.fatica3 && this.questions.fatica3.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.nausea1 && this.questions.nausea1.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.nausea2 && this.questions.nausea2.graficoName === label) {
      yAxis.labels = labelsSimple;
      yAxis.min = 0;
      yAxis.max = 10;
      yAxis.tickAmount = 10;
    } else if (this.questions.vomito && this.questions.vomito.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.rash1 && this.questions.rash1.graficoName === label) {
      yAxis.labels = labelsSiNo;
      yAxis.min = 7;
      yAxis.max = 9;
      yAxis.tickAmount = 2;
    } else if (this.questions.rash2 && this.questions.rash2.graficoName === label) {
      yAxis.labels = labelsSimple;
      yAxis.min = 0;
      yAxis.max = 10;
      yAxis.tickAmount = 10;
    } else if (this.questions.rash3 && this.questions.rash3.graficoName === label) {
      yAxis.labels = labelsSimple;
      yAxis.min = 0;
      yAxis.max = 10;
      yAxis.tickAmount = 10;
    } else if (this.questions.rash4 && this.questions.rash4.graficoName === label) {
      yAxis.labels = labelsSimple;
      yAxis.min = 0;
      yAxis.max = 10;
      yAxis.tickAmount = 10;
    } else if (this.questions.stomatite1 && this.questions.stomatite1.graficoName === label) {
      yAxis.labels = labelsSiNo;
      yAxis.min = 7;
      yAxis.max = 9;
      yAxis.tickAmount = 2;
    } else if (this.questions.stomatite2 && this.questions.stomatite2.graficoName === label) {
      yAxis.labels = labelsSimple;
      yAxis.min = 0;
      yAxis.max = 10;
      yAxis.tickAmount = 10;
    } else if (this.questions.stomatite3 && this.questions.stomatite3.graficoName === label) {
      yAxis.labels = labelsSimple;
      yAxis.min = 0;
      yAxis.max = 10;
      yAxis.tickAmount = 10;
    } else if (this.questions.parestesie && this.questions.parestesie.graficoName === label) {
      yAxis.labels = labelsSiNo;
      yAxis.min = 7;
      yAxis.max = 9;
      yAxis.tickAmount = 2;
    } else if (this.questions.cefalea && this.questions.cefalea.graficoName === label) {
      yAxis.labels = labelsSiNo;
      yAxis.min = 7;
      yAxis.max = 9;
      yAxis.tickAmount = 2;
    } else if (this.questions.febbre1 && this.questions.febbre1.graficoName === label) {
      yAxis.labels = labelsSiNo;
      yAxis.min = 7;
      yAxis.max = 9;
      yAxis.tickAmount = 2;
    } else if (this.questions.febbre2 && this.questions.febbre2.graficoName === label) {
      yAxis.labels = labelsSimple;
      yAxis.min = 0;
      yAxis.max = 10;
      yAxis.tickAmount = 10;
    } else if (this.questions.febbre3 && this.questions.febbre3.graficoName === label) {
      yAxis.labels = labelsSimple;
      yAxis.min = 0;
      yAxis.max = 10;
      yAxis.tickAmount = 10;
    } else if (this.questions.febbre4 && this.questions.febbre4.graficoName === label) {
      yAxis.labels = labelsSiNo;
      yAxis.min = 7;
      yAxis.max = 9;
      yAxis.tickAmount = 2;
    } else if (this.questions.umore1 && this.questions.umore1.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.umore2 && this.questions.umore2.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.umore3 && this.questions.umore3.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.umore4 && this.questions.umore4.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.difficoltaFinanziarie && this.questions.difficoltaFinanziarie.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.funzionalitaFisica1 && this.questions.funzionalitaFisica1.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.funzionalitaFisica2 && this.questions.funzionalitaFisica2.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    } else if (this.questions.funzionalitaFisica3 && this.questions.funzionalitaFisica3.graficoName === label) {
      yAxis.labels = labelsMolto;
      yAxis.min = 16;
      yAxis.max = 20;
      yAxis.tickAmount = 4;
    }
    return yAxis;
  }
}
