import { ApexOptions } from 'apexcharts';
import { SerieType } from './grafici-utils';

// tslint:disable-next-line: max-line-length
export function getBarChartOptions(idIn: string, titolo: string, seriesIn: ApexAxisChartSeries,
    series: SerieType[], labels: any[], assiY?: any, stroke?: ApexStroke,
    markers?: boolean, fill?: boolean, grid?: boolean, assiX?: any): ApexOptions {
    // tslint:disable-next-line: one-variable-per-declaration
    const chart: ApexChart = {};
    const options: ApexOptions = {};
    chart.defaultLocale = 'it';
    chart.id = idIn;
    chart.height = 300;
    chart.width = '100%';
    chart.animations = { enabled: false };
    // chart.type = !Array.isArray(series[0].type) && series.length === 1  ? series[0].type : null;
    if (series.length === 1 && series[0].display.length === 1) {
        chart.type = Array.isArray(series[0].type) ? series[0].type[0] : series[0].type;
    } else {
        chart.type = 'area';
    }
    chart.stacked = false;
    chart.toolbar = {
        show: false,
        tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
        },
        autoSelected: 'zoom'
    };
    chart.defaultLocale = 'it';
    chart.locales = [{
        name: 'it',
        options: {
            // tslint:disable-next-line: max-line-length
            months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
            shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
            days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
            shortDays: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
            toolbar: {
                download: 'Scarica SVG',
                selection: 'Selezione',
                selectionZoom: 'Selezione Zoom',
                zoomIn: 'Zoom In',
                zoomOut: 'Zoom Out',
                pan: 'Panning',
                reset: 'Reset Zoom',
            }
        }
    }];
    chart.zoom = {
        enabled: false
    };

    options.chart = chart;

    if (fill) {
        options.fill = {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
            },
            colors: series[0].color
        };
        if (series.length === 1) {
            options.fill.colors = Array.isArray(series[0].color) ? series[0].color : [series[0].color];
        } else {
            options.fill.colors = [];
            series.forEach((el, i) => {
                if (Array.isArray(el.color)) {
                    el.color.forEach(elem => options.fill.colors.push(elem));
                } else {
                    options.fill.colors.push(el.color);
                }
            });
        }
    } else {
        options.fill = {
            type: 'solid',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
            },
            // colors: Array.isArray(series[0].color) ? series[0].color : [series[0].color]
        };
        if (series.length === 1) {
            options.fill.colors = Array.isArray(series[0].color) ? series[0].color : [series[0].color];
        } else {
            options.fill.colors = [];
            series.forEach((el, i) => {
                if (Array.isArray(el.color)) {
                    el.color.forEach(elem => options.fill.colors.push(elem));
                } else {
                    options.fill.colors.push(el.color);
                }
            });
        }
    }

    options.plotOptions = {
        bar: {
            horizontal: false,
            columnWidth: '50%',
            endingShape: 'flat',
            distributed: false
        }
    };

    options.dataLabels = {
        enabled: false
    };

    options.series = seriesIn;
    if (stroke) {
        options.stroke = stroke;
    } else {
        options.stroke = {
            // width: [1, 1, 1]
            // width: 1
            curve: 'straight',
            dashArray: 0,
            lineCap: 'butt',
            show: true,
            width: 2
        };
    }

    options.title = {
        text: titolo,
        align: 'left',
        offsetX: 70,
        style: {
            fontSize: '14px',
        }
    };
    options.colors = new Array();
    series.forEach(el => {
        if (Array.isArray(el.color)) {
            el.color.forEach(elem => options.colors.push(elem));
        } else {
            options.colors.push(el.color);
        }
    });

    options.xaxis = {
        type: 'datetime',
        labels: {
            datetimeUTC: false,
            // formatter: function (value, timestamp) {
            //     const shortMonths = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
            //     const d = new Date(value);
            //     // tslint:disable-next-line: max-line-length
            //     let detail = '';
            //     if (d.getMilliseconds() !== 0) {
            //         let min = '';
            //         let hour = '';
            //         if (d.getMinutes() <= 9) {
            //             min = '0';
            //         }
            //         if (d.getHours() <= 9) {
            //             hour = '0';
            //         }
            //         detail = hour + d.getHours() + ':' + min + d.getMinutes() ;
            //         return detail;
            //     }
            //     // tslint:disable-next-line: max-line-length
            //     return d.getDate() + ' ' + shortMonths[d.getMonth()]; // The formatter function overrides format property
            // }
        }
        /*labels: {
          formatter: function (value, timestamp) {
            return moment(timestamp)
              .format('DD MMM') // The formatter function overrides format property
          }
        }*/
    };
    options.labels = labels;
    if (!assiY) {
        const yaxis = new Array();
        if (series.length === 1) {
            if (Array.isArray(series[0].display)) {
                series[0].display.forEach((el, i) => {
                    const y: ApexYAxis = {
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: true,
                            color: Array.isArray(series[0].color) ? series[0].color[i] : series[0].color
                        },
                        labels: {
                            style: {
                                colors: Array.isArray(series[0].color) ? series[0].color[i] : series[0].color,
                                fontSize: '14px',
                            }
                        },
                        title: {
                            text: el,
                            style: {
                                color: Array.isArray(series[0].color) ? series[0].color[i] : series[0].color,
                                fontSize: '14px',
                            }
                        },
                        min: 0,
                        max: series[0].maxyaxis ? series[0].maxyaxis : 100,
                        decimalsInFloat: 0
                        /*,
                        tooltip: {
                          enabled: true
                        }*/
                    };
                    if (i !== 0) {
                        y.opposite = true;
                    }
                    yaxis.push(y);
                });
            }
        } else {
            series.forEach((el, i) => {
                const y: ApexYAxis = {
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true,
                        color: Array.isArray(el.color) ? el.color[i] : el.color
                    },
                    labels: {
                        style: {
                            colors: Array.isArray(el.color) ? el.color[i] : el.color,
                            fontSize: '14px',
                        }
                    },
                    title: {
                        text: el.name,
                        style: {
                            color: Array.isArray(el.color) ? el.color[i] : el.color,
                            fontSize: '14px',
                        }
                    },
                    min: 0,
                    max: el.maxyaxis ? el.maxyaxis : 100,
                    decimalsInFloat: 0
                    /*,
                    tooltip: {
                      enabled: true
                    }*/
                };
                if (i !== 0) {
                    y.opposite = true;
                }
                yaxis.push(y);
            });
        }
        options.yaxis = yaxis;

    } else {
        options.yaxis = assiY;
    }

    // options.xaxis.min = labels[0];
    // options.xaxis.max = labels[labels.length];

    options.tooltip = {
        shared: false,
        intersect: true,
        followCursor: false,
        x: {
            show: true,
            formatter: function (value, timestamp) {
                const shortMonths = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
                const d = new Date(value);
                // tslint:disable-next-line: max-line-length
                let detail = '';
                if (d.getMilliseconds() !== 0) {
                    let min = '';
                    let hour = '';
                    if (d.getMinutes() <= 9) {
                        min = '0';
                    }
                    if (d.getHours() <= 9) {
                        hour = '0';
                    }
                    detail = hour + d.getHours() + ':' + min + d.getMinutes();
                }
                // tslint:disable-next-line: max-line-length
                return d.getDate() + ' ' + shortMonths[d.getMonth()] + ' ' + d.getFullYear() + ' ' + detail; // The formatter function overrides format property
            }
        },
        fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60
        }
    };

    options.legend = {
        horizontalAlign: 'left',
        offsetX: 40,
    };

    options.responsive = [
        {
            breakpoint: 600,
            options: {
                title: {
                    offsetX: 35,
                    style: {
                        fontSize: '14px'
                    }
                }
            }
        },
        {
            breakpoint: 1000,
            options: {
                title: {
                    offsetX: 110,
                    style: {
                        fontSize: '14px'
                    }
                }
            }
        }
    ];
    if (markers) {
        options.markers = {
            size: 5,
            strokeWidth: 2,
            strokeColors: '#fff',
            hover: {
                size: 7,
                sizeOffset: 3
            }
        };

    } else {
        options.markers = {
            size: 0,
            hover: {
                size: 6,
            }
        };
    }
    if (grid) {
        options.grid = {
            position: 'back',
            row: {
                colors: [
                    '#008FFB',
                    '#FEB019',
                    '#FA4443'
                ],
                opacity: 0.5
            },
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        };
    } else {
        options.grid = {
            show: true,
            // borderColor: '#90A4AE',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
            row: {
                colors: undefined,
                opacity: 0.5
            },
            column: {
                colors: undefined,
                opacity: 0.5,
            },
            // padding: {
            //     top: 0,
            //     right: 0,
            //     bottom: 0,
            //     left: 0
            // },
        };
        // options.grid = Object.assign({}, {});
    }

    if (assiX) {
        options.xaxis = assiX;
    }

    return options;
}

export const optionsBarChartResponse = {
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '50%',
            endingShape: 'flat',
            distributed: false
        }
    },
    series: [],
    markers: {
        size: 5,
        hover: {
            size: undefined,
            sizeOffset: 3
        }
    },
    stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        width: 2,
        dashArray: 0
    },
    title: {
        text: ''
    },
    labels: [],
    xaxis: {
        type: 'datetime',
        labels: {
            format: 'dd MMM'
        }
        /*labels: {
          formatter: function (value, timestamp) {
            return moment(timestamp)
              .format('DD MMM') // The formatter function overrides format property
          }
        }*/
    },

};

export const yaxisDiarreaChart = [
    {
        seriesName: 'Diarrea (Frequenza)',
        axisTicks: {
            show: true
        },
        axisBorder: {
            show: true,
            color: '#008FFB'
        },
        labels: {
            style: {
                colors: '#008FFB',
                fontSize: '14px',
            },
            formatter: (val, index) => {
                if (val === 1) {
                    return '1-3';
                } else if (val === 2) {
                    return '3-8';
                } else if (val === 3) {
                    return '>8';
                } else if (val === 4) {
                    return '';
                } else if (val === 0) {
                    return '0';
                } else {
                    return '';
                }
            }
        },
        title: {
            text: 'Diarrea (Frequenza)',
            style: {
                color: '#008FFB',
                fontSize: '14px',
            }
        },
        min: 0,
        max: 4,
        tickAmount: 4
    },
    {
        seriesName: 'Diarrea (Farmaci)',
        opposite: true,
        axisTicks: {
            show: true
        },
        axisBorder: {
            show: true,
            color: '#FA4443'
        },
        labels: {
            style: {
                colors: '#FA4443',
                fontSize: '14px',
            }
        },
        title: {
            text: 'Diarrea (Farmaci)',
            style: {
                color: '#FA4443',
                fontSize: '14px',
            }
        },
        min: 0,
        max: 10,
        decimalsInFloat: 0
    }
];

export const yaxisStipsiChart = [
    {
        seriesName: 'Stipsi (Frequenza)',
        axisTicks: {
            show: true
        },
        axisBorder: {
            show: true,
            color: '#008FFB'
        },
        labels: {
            style: {
                colors: '#008FFB',
                fontSize: '14px',
            },
            formatter: (val, index) => {
                if (val === 1) {
                    return '1-3';
                } else if (val === 2) {
                    return '3-8';
                } else if (val === 3) {
                    return '>8';
                } else if (val === 4) {
                    return '';
                } else if (val === 0) {
                    return '0';
                } else {
                    return '';
                }
            }
        },
        title: {
            text: 'Stipsi (Frequenza)',
            style: {
                color: '#008FFB',
                fontSize: '14px',
            }
        },
        min: 0,
        max: 4,
        tickAmount: 4
    },
    {
        seriesName: 'Stipsi (Farmaci)',
        opposite: true,
        axisTicks: {
            show: true
        },
        axisBorder: {
            show: true,
            color: '#FA4443'
        },
        labels: {
            style: {
                colors: '#FA4443',
                fontSize: '14px'
            }
        },
        title: {
            text: 'Stipsi (Farmaci)',
            style: {
                color: '#FA4443',
                fontSize: '14px',
            }
        },
        min: 0,
        max: 10,
        decimalsInFloat: 0
    }
];

export const yaxisNauseaChart = [
    {
        seriesName: 'Nausea',
        axisBorder: {
            color: '#008FFB',
            show: true
        },
        axisTicks: {
            show: true
        },
        labels: {
            style: {
                colors: '#008FFB',
                fontSize: '14px'
            },
            formatter: (val, index) => {
                if (val === 17) {
                    return 'No';
                } else if (val === 18) {
                    return 'Un po\'';
                } else if (val === 19) {
                    return 'Molto';
                } else if (val === 20) {
                    return 'Moltiss.';
                } else {
                    return '';
                }
            }
        },
        max: 20,
        min: 16,
        tickAmount: 4,
        title: {
            style: {
                color: '#008FFB',
                fontSize: '14px',
            },
            text: 'Nausea (Frequenza)'
        }
    },
    {
        seriesName: 'Nausea (Farmaci)',
        axisBorder: {
            color: '#FA4443',
            show: true
        },
        axisTicks: {
            show: true
        },
        decimalsInFloat: 0,
        labels: {
            style: {
                colors: '#FA4443',
                fontSize: '14px'
            }
        },
        max: 10,
        min: 0,
        opposite: true,
        title: {
            style: {
                color: '#FA4443',
                fontSize: '14px',
            },
            text: 'Nausea (Farmaci)'
        }
    }
];

export const yAxisMood: ApexYAxis = {
    max: 90,
    min: 0,
    tickAmount: 3,
    labels: {
        formatter: (y) => {
            if (typeof y !== 'undefined' && y === 75) {
                return 'Eccitato';
            } else if (typeof y !== 'undefined' && y === 45) {
                return 'Calmo';
            } else if (typeof y !== 'undefined' && y === 15) {
                return 'Fiacco';
            }
            return y + '';
        }
    },
    axisTicks: {
        show: false
    }
};

export const yAxisFatica: ApexYAxis = {
    max: 90,
    min: 0,
    tickAmount: 3,
    labels: {
        formatter: (y) => {
            if (typeof y !== 'undefined' && y === 75) {
                return 'Energico';
            } else if (typeof y !== 'undefined' && y === 45) {
                return 'Stanco';
            } else if (typeof y !== 'undefined' && y === 15) {
                return 'Molto stanco';
            }
            return y + '';
        }
    },
    axisTicks: {
        show: false
    }
};

export function getAssiYCombo(serieTypeArray: SerieType[], questions?: any) {
    const yaxis = new Array();
    serieTypeArray.forEach((element, i) => {
        if (Array.isArray(element.display)) {
            element.display.forEach((display, y) => {
                let assiY: ApexYAxis = {};
                assiY.seriesName = display;
                assiY.axisTicks = {
                    show: true
                };
                assiY.axisBorder = {
                    show: true,
                    color: Array.isArray(element.color) ? element.color[i] : element.color
                };
                assiY.labels = {
                    style: {
                        colors: Array.isArray(element.color) ? element.color[i] : element.color,
                        fontSize: '14px',
                    },
                    formatter: (val) => {
                        if (val === undefined || val === null) {
                            return 'ND';
                        } else {
                            return val + '';
                        }
                    }
                };
                assiY.title = {
                    text: display,
                    style: {
                        color: Array.isArray(element.color) ? element.color[i] : element.color,
                        fontSize: '14px',
                    }
                };
                switch (display) {
                    case 'Dolore':
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 100;
                        assiY.decimalsInFloat = 0;
                        break;
                    case 'Fatica':
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 100;
                        assiY.decimalsInFloat = 0;
                        break;
                    case 'Nausea':
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 100;
                        assiY.decimalsInFloat = 0;
                        break;
                    case 'Diarrea (Frequenza)':
                        assiY.labels = {
                            style: {
                                colors: Array.isArray(element.color) ? element.color[i] : element.color
                            },
                            formatter: (val, index) => {
                                if (val == undefined || val == null) {
                                    return 'ND';
                                }
                                if (val === 1) {
                                    return '1-3';
                                } else if (val === 2) {
                                    return '3-8';
                                } else if (val === 3) {
                                    return '>8';
                                } else if (val === 4) {
                                    return '';
                                } else if (val === 0) {
                                    return '0';
                                } else {
                                    return '';
                                }
                            }
                        };
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 4;
                        assiY.decimalsInFloat = 0;
                        assiY.tickAmount = 4;
                        break;
                    case 'Diarrea (Farmaci)':
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 10;
                        assiY.decimalsInFloat = 0;
                        assiY.tickAmount = 10;
                        break;
                    case 'Stipsi (Frequenza)':
                        assiY.labels = {
                            style: {
                                colors: Array.isArray(element.color) ? element.color[i] : element.color
                            },
                            formatter: (val, index) => {
                                if (val === 1) {
                                    return '1-3';
                                } else if (val === 2) {
                                    return '3-8';
                                } else if (val === 3) {
                                    return '>8';
                                } else if (val === 4) {
                                    return '';
                                } else if (val === 0) {
                                    return '0';
                                } else {
                                    return '';
                                }
                            }
                        };
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 4;
                        assiY.decimalsInFloat = 0;
                        assiY.tickAmount = 4;
                        break;
                    case 'Stipsi (Farmaci)':
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 10;
                        assiY.decimalsInFloat = 0;
                        assiY.tickAmount = 10;
                        break;
                    case 'Frequenza cardiaca':
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 200;
                        assiY.decimalsInFloat = 0;
                        assiY.tickAmount = 10;
                        break;
                    case 'Pressione (min)':
                    case 'Pressione (Min)':
                    case 'Pressione (Max)':
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 200;
                        assiY.decimalsInFloat = 0;
                        assiY.tickAmount = 10;
                        break;
                    case 'Frequenza respiratoria':
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 80;
                        assiY.decimalsInFloat = 0;
                        assiY.tickAmount = 10;
                        break;
                    case 'Mood':
                        assiY.labels = {
                            style: {
                                colors: Array.isArray(element.color) ? element.color[i] : element.color
                            },
                            formatter: (val) => {
                                if (val == undefined || val == null) {
                                    return 'ND';
                                }
                                if (val === 75) {
                                    return 'Eccitato';
                                } else if (val === 45) {
                                    return 'Calmo';
                                } else if (val === 15) {
                                    return 'Fiacco';
                                } else {
                                    return '';
                                }
                            }
                        };
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 90;
                        assiY.decimalsInFloat = 0;
                        assiY.tickAmount = 6;
                        break;
                    case 'Energia':
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 100;
                        assiY.decimalsInFloat = 0;
                        break;
                    case 'Ossigenazione':
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 100;
                        assiY.decimalsInFloat = 1;
                        break;
                    case 'Temperatura':
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 200;
                        assiY.decimalsInFloat = 1;
                        assiY.tickAmount = 10;
                        break;
                    case 'Passi':
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 30000;
                        assiY.decimalsInFloat = 0;
                        assiY.tickAmount = 10;
                        break;
                    case 'Peso (kg)':
                        assiY.min = 0;
                        assiY.max = element.maxyaxis ? element.maxyaxis : 200;
                        assiY.decimalsInFloat = 0;
                        assiY.tickAmount = 10;
                        break;
                    case 'Capecitabina':
                    case 'Regorafenib':
                    case 'Lonsurf/Tas 102':
                        assiY = {
                            max: 4,
                            min: 0,
                            tickAmount: 4,
                            axisTicks: {
                                show: true
                            },
                            axisBorder: {
                                show: true,
                                color: '#008FFB'
                            },
                            labels: {
                                style: {
                                    colors: '#008FFB',
                                    fontSize: '14px',
                                },
                                formatter: (val, index) => {
                                    if (val == undefined || val == null) {
                                        return 'ND';
                                    }
                                    if (val === 1) {
                                        return 'SI';
                                    } else if (val === 2) {
                                        return ['NO, in accordo alle', 'indicazioni del medico'] as any;
                                    } else if (val === 3) {
                                        return 'NO, per dimenticanza';
                                    } else if (val === 4) {
                                        return 'NO, altre motivazioni';
                                    } else {
                                        return '';
                                    }
                                },
                            }
                        };
                        break;
                    default:
                        assiY = getYaxis(questions, display);
                        break;
                }
                if (i > 0 || y > 0) {
                    assiY.opposite = true;
                }
                if (element.display.length > 1) {
                    const color = element.color[y];
                    assiY.axisBorder.color = color;
                    assiY.labels.style.colors = color;
                    assiY.title.style.color = color;
                }

                yaxis.push(assiY);
            });
        }
    });
    return yaxis;
}

export function getYaxis(questions: any, label: string) {
    const yAxis: ApexYAxis = {
        axisTicks: {
            show: true
        },
        axisBorder: {
            show: true,
            color: '#008FFB'
        },
        labels: {
            style: {
                fontSize: '30px',
            }
        },
        title: {
            text: label,
            offsetX: 10,
            style: {
                color: '#008FFB',
                fontSize: '14px',
            }
        },
    };

    // tslint:disable-next-line: variable-name
    const labels1_3 = {
        style: {
            colors: '#008FFB',
            fontSize: '14px',
        },
        formatter: (val, index) => {
            if (val == undefined || val == null) {
                return 'ND';
            }
            if (val === 1) {
                return '1-3';
            } else if (val === 2) {
                return '3-8';
            } else if (val === 3) {
                return '>8';
            } else if (val === 4) {
                return '';
            } else if (val === 0) {
                return '0';
            } else {
                return '';
            }
        }
    };

    // tslint:disable-next-line: variable-name
    const labels1_4 = {
        style: {
            colors: '#008FFB',
            fontSize: '14px',
        },
        formatter: (val, index) => {
            if (val == undefined || val == null) {
                return 'ND';
            }
            if (val === 5) {
                return 'Normale';
            } else if (val === 6) {
                return 'Solida';
            } else if (val === 7) {
                return 'Liquida';
            } else {
                return '';
            }
        }
    };

    const labelsSimple = {
        style: {
            colors: '#008FFB',
            fontSize: '14px',
        },
    };

    const labelsMolto = {
        style: {
            colors: '#008FFB',
            fontSize: '14px',
        },
        formatter: (val, index) => {
            if (val == undefined || val == null) {
                return 'ND';
            }
            if (val === 17) {
                return 'No';
            } else if (val === 18) {
                return 'Un po\'';
            } else if (val === 19) {
                return 'Molto';
            } else if (val === 20) {
                return 'Moltiss.';
            } else {
                return '';
            }
        },
        minWidth: 60,
        maxWidth: 160
    };

    const labelsSiNo = {
        style: {
            colors: '#008FFB',
            fontSize: '14px',
        },
        formatter: (y, index) => {
            if (typeof y === 'undefined' || y == null) {
                return 'ND';
            } else if (y === 8) {
                return 'No';
            } else if (y === 9) {
                return 'Si';
            }
        }
    };

    if (questions.evaquazioniGiornaliere_frequenza && questions.evaquazioniGiornaliere_frequenza.graficoName === label) {
        yAxis.labels = labels1_3;
        yAxis.min = 0;
        yAxis.max = 4;
        yAxis.tickAmount = 4;
    } else if (questions.evaquazioniGiornaliere_tipo && questions.evaquazioniGiornaliere_tipo.graficoName === label) {
        yAxis.labels = labels1_4;
        yAxis.min = 4;
        yAxis.max = 7;
        yAxis.tickAmount = 3;
    } else if (questions.diarrea && questions.diarrea.graficoName === label) {
        yAxis.labels = labelsSimple;
        yAxis.min = 0;
        yAxis.max = 10;
        yAxis.tickAmount = 10;
    } else if (questions.stipsi && questions.stipsi.graficoName === label) {
        yAxis.labels = labelsSimple;
        yAxis.min = 0;
        yAxis.max = 10;
        yAxis.tickAmount = 10;
    } else if (questions.dolore1 && questions.dolore1.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.dolore2 && questions.dolore2.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.fatica1 && questions.fatica1.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.fatica2 && questions.fatica2.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.fatica3 && questions.fatica3.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.nausea1 && questions.nausea1.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.nausea2 && questions.nausea2.graficoName === label) {
        yAxis.labels = labelsSimple;
        yAxis.min = 0;
        yAxis.max = 10;
        yAxis.tickAmount = 10;
    } else if (questions.vomito && questions.vomito.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.rash1 && questions.rash1.graficoName === label) {
        yAxis.labels = labelsSiNo;
        yAxis.min = 7;
        yAxis.max = 9;
        yAxis.tickAmount = 2;
    } else if (questions.rash2 && questions.rash2.graficoName === label) {
        yAxis.labels = labelsSimple;
        yAxis.min = 0;
        yAxis.max = 10;
        yAxis.tickAmount = 10;
    } else if (questions.rash3 && questions.rash3.graficoName === label) {
        yAxis.labels = labelsSimple;
        yAxis.min = 0;
        yAxis.max = 10;
        yAxis.tickAmount = 10;
    } else if (questions.rash4 && questions.rash4.graficoName === label) {
        yAxis.labels = labelsSimple;
        yAxis.min = 0;
        yAxis.max = 10;
        yAxis.tickAmount = 10;
    } else if (questions.stomatite1 && questions.stomatite1.graficoName === label) {
        yAxis.labels = labelsSiNo;
        yAxis.min = 7;
        yAxis.max = 9;
        yAxis.tickAmount = 2;
    } else if (questions.stomatite2 && questions.stomatite2.graficoName === label) {
        yAxis.labels = labelsSimple;
        yAxis.min = 0;
        yAxis.max = 10;
        yAxis.tickAmount = 10;
    } else if (questions.stomatite3 && questions.stomatite3.graficoName === label) {
        yAxis.labels = labelsSimple;
        yAxis.min = 0;
        yAxis.max = 10;
        yAxis.tickAmount = 10;
    } else if (questions.parestesie && questions.parestesie.graficoName === label) {
        yAxis.labels = labelsSiNo;
        yAxis.min = 7;
        yAxis.max = 9;
        yAxis.tickAmount = 2;
    } else if (questions.cefalea && questions.cefalea.graficoName === label) {
        yAxis.labels = labelsSiNo;
        yAxis.min = 7;
        yAxis.max = 9;
        yAxis.tickAmount = 2;
    } else if (questions.febbre1 && questions.febbre1.graficoName === label) {
        yAxis.labels = labelsSiNo;
        yAxis.min = 7;
        yAxis.max = 9;
        yAxis.tickAmount = 2;
    } else if (questions.febbre2 && questions.febbre2.graficoName === label) {
        yAxis.labels = labelsSimple;
        yAxis.min = 0;
        yAxis.max = 10;
        yAxis.tickAmount = 10;
    } else if (questions.febbre3 && questions.febbre3.graficoName === label) {
        yAxis.labels = labelsSimple;
        yAxis.min = 0;
        yAxis.max = 10;
        yAxis.tickAmount = 10;
    } else if (questions.febbre4 && questions.febbre4.graficoName === label) {
        yAxis.labels = labelsSiNo;
        yAxis.min = 7;
        yAxis.max = 9;
        yAxis.tickAmount = 2;
    } else if (questions.umore1 && questions.umore1.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.umore2 && questions.umore2.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.umore3 && questions.umore3.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.umore4 && questions.umore4.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.difficoltaFinanziarie && questions.difficoltaFinanziarie.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.funzionalitaFisica1 && questions.funzionalitaFisica1.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.funzionalitaFisica2 && questions.funzionalitaFisica2.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    } else if (questions.funzionalitaFisica3 && questions.funzionalitaFisica3.graficoName === label) {
        yAxis.labels = labelsMolto;
        yAxis.min = 16;
        yAxis.max = 20;
        yAxis.tickAmount = 4;
    }
    return yAxis;
}




