import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { toPublicName } from '@angular/compiler/src/i18n/serializers/xmb';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { CustomizationService } from 'src/app/service/customization.service';
import { NetworkService } from 'src/app/service/network-service.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../../service/pazienti.service';
import { getEndpointsData, getEndpointsEvents, getEndpointsMeasure } from '../endpoint-charts';
import { DateFromTo } from '../Model/date-from-to';
import { getAssiYCombo, getBarChartOptions, yAxisMood } from '../Utils/apexchart-opt';
// tslint:disable-next-line: max-line-length
import { cutSeries, cutSeriesV2, getSeriesDataValue, getSeriesResponses, getSeriesScores, refillSeries, SeriesLabels, SerieType } from '../Utils/grafici-utils';
import { getInputBrMeasures, getInputEventsReported1, getInputFaticaMeasures, getInputHrMeasures, getInputMoodMeasures, getInputPressureMeasures, getInputSelfReported, getInputSpo2Measures, getInputStepsMeasures } from '../Utils/query-input';

@Component({
  selector: 'app-grafici-vs',
  templateUrl: './grafici-vs.component.html',
  styleUrls: ['./grafici-vs.component.scss']
})
export class GraficiVsComponent implements OnInit, OnDestroy {

  @Input() identifier;

  @Input('date') set date(date: DateFromTo) {
    if (!date.compare(this._date)) {
      this.observer.next(date);
    }
  }

  // tslint:disable-next-line: variable-name
  _date: DateFromTo;

  // Colori definiti dal tema
  COLORE_DOLORE = '#de2826';
  COLORE_NAUSEA = '#fedb00';
  COLORE_FATICA = '#ff6c00';

  observer: BehaviorSubject<DateFromTo>;

  dateFromString: string;
  dateToString: string;
  dateFromTimeStamp;
  dateToTimeStamp;
  questions;

  xAxis = {
    type: 'datetime',
    labels: {
      rotate: -45,
      rotateAlways: false,
      offsetX: -15,
      style: {
        fontSize: '12px'
      }
    }
  };

  grid: ApexGrid = {
    row: {
      colors: ['#fff', '#fff', '#f3f4f5', '#f3f4f5'],
      opacity: 1
    }
  }
    ;

  optMobileapp;

  optDolore;

  optSmartwatch;

  selectedMobileapp = null;
  selectedSmartwatch = null;

  stroke: ApexStroke = {
    show: true,
    curve: 'straight',
    lineCap: 'butt',
    width: 2,
    dashArray: 0,
  };


  xAxisFarmaco = {
    /*type: 'datetime',
    labels: {
      offsetX: -15
    }*/
    type: 'datetime',
    labels: {
      rotate: -45,
      rotateAlways: false,
      offsetX: -15,
      style: {
        fontSize: '12px'
      }
    }
  };

  yAxisFarmaco = {
    max: 4,
    min: 0,
    tickAmount: 4,
    axisTicks: {
      show: true
    },
    axisBorder: {
      show: true,
      color: '#008FFB'
    },
    labels: {
      style: {
        colors: '#008FFB',
        fontSize: '14px',
      },
      formatter: (val, index) => {
        if (val === 1) {
          return 'SI';
        } else if (val === 2) {
          return ['NO, in accordo alle', 'indicazioni del medico'];
        } else if (val === 3) {
          return 'NO, per dimenticanza';
        } else if (val === 4) {
          return 'NO, altre motivazioni';
        } else {
          return 'ND';
        }
      }
    }
  };


  // gridFarmaco: ApexGrid = {
  //   position: 'back',
  //   xaxis: {
  //     lines: {
  //       show: false
  //     }
  //   },
  //   yaxis: {
  //     lines: {
  //       show: false
  //     }
  //   },
  //   row: {
  //     colors: ['#17a2b8', '#17a2b8', '#ffc107', '#ffc107'],
  //     opacity: 0.5
  //   }
  // };


  stroke1: ApexStroke = { curve: 'smooth' };

  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  chart: ApexCharts;

  doloreType = { name: 'Dolore', color: this.COLORE_DOLORE, serie: [], topic: 'dolore', type: 'column', display: ['Dolore'] };

  organization = undefined;
  patientId;

  // tslint:disable-next-line: max-line-length
  constructor(private http: HttpClient, private toastr: ToastrService, private datePipe: DatePipe, private network: NetworkService, private userService: UserService, private pazientiService: PazientiService, private customizationService: CustomizationService) {
    this.observer = new BehaviorSubject<DateFromTo>(null);
  }

  ngOnInit() {
    this.organization = this.pazientiService.getPatientOrganization();
    const paziente = this.pazientiService.getPatient();
    this.patientId = paziente.id;
    this.pazientiService.getPatientOrganizationAsPromiseById(this.pazientiService.getPatientId()).then((resp: any) => {
      this.organization = resp.desc;
      this.init();
    });
  }

  private init() {
    this.optMobileapp = this.customizationService.getOpt2ByOrganizationName(this.organization);
    this.optDolore = this.customizationService.getOptDoloreByOrganizationName(this.organization);
    this.optSmartwatch = this.optDolore;
    this.observer.subscribe((input) => {
      if (this.identifier && input) {
        this._date = input;
        this.dateFromString = this.datePipe.transform(input.getDateFrom(), 'yyyy-MM-dd');
        this.dateToString = this.datePipe.transform(input.getDateTo(), 'yyyy-MM-dd');
        this.dateFromTimeStamp = Math.round(input.getDateFrom().getTime());
        this.dateToTimeStamp = Math.round(input.getDateTo().getTime());
        this.dropdownChange(this.selectedMobileapp);
      }
    });
    this.questions = this.customizationService.getDefaultQuestionnaireNameByOrganizationName(this.userService.getCurrentOrganization());
    Object.keys(this.questions).forEach(elem => {
      this.optMobileapp.push({ label: this.questions[elem].graficoName, value: this.questions[elem].graficoName + "_" + this.questions[elem].code + "_" + this.questions[elem].testo })
    });
  }

  ngOnDestroy(): void {
    this.observer.unsubscribe();
  }

  getTendinaOptChange(key) {
    this.selectedSmartwatch = this.optSmartwatch[0].value;
  }

  dropdownChange(event, tendina?) {
    if (tendina === 1) {
      this.getTendinaOptChange(event);
    }
    this.callCreateChart(this.selectedMobileapp + 'ComboChartID', this.selectedSmartwatch, this.selectedMobileapp);
  }

  callCreateChart(id, selected, selected1) {
    if (selected && selected1) {
      const array = new Array<SerieType>();
      let serie = this.getSerieType(selected1, this.selectedSmartwatch);
      if (Array.isArray(serie)) {
        serie.forEach(el => array.push(el));
      } else {
        array.push(serie);
      }
      serie = this.getSerieType(selected, this.selectedSmartwatch);
      if (Array.isArray(serie)) {
        serie.forEach(el => array.push(el));
      } else {
        array.push(serie);
      }
      this.createChart(id, selected1 + ' vs ' + selected, array);
    }
  }

  createChart(id, title, chartArray, assiY?) {
    forkJoin(chartArray.map(sintomo => {
      return this.getSelfReportedData(sintomo.name, sintomo.topic);
    })).subscribe((resp: any) => {

      if (resp) {
        let series: ApexAxisChartSeries = [];
        let countResp = 0;
        let tempResponse;
        let lbs = [];
        resp.forEach((response, index) => {
          let seriesLabels = {} as SeriesLabels;
          seriesLabels.serie = new Array();
          seriesLabels.labels = new Array();
          if (title.includes("_")) {
            title = title.split("_")[2];
          }
          if (title.includes('Passi')) {
            seriesLabels = getSeriesDataValue(response, chartArray[index].display, chartArray[index].type, chartArray[index].name);
          }
          if (title.includes('Frequenza cardiaca')) {
            seriesLabels = getSeriesDataValue(response, chartArray[index].display, chartArray[index].type, chartArray[index].name);
          }
          if (response.hits && response.hits.hits && response.hits.hits.length > 0) {
            // tslint:disable-next-line: max-line-length
            if (response.hits.hits[0]._source.idDomanda === 'fatica' || response.hits.hits[0]._source.idDomanda === 'dolore' || response.hits.hits[0]._source.idDomanda === 'nausea'
              || response.hits.hits[0]._source.idDomanda === 'funzionalita_fisica') {
              seriesLabels = getSeriesScores(response, chartArray[index].display, chartArray[index].type);
            } else if (response.hits.hits[0]._index.includes('answers')) {
              seriesLabels = getSeriesResponses(response, chartArray[index].name, chartArray[index].display, chartArray[index].type, this.organization, this.customizationService);
            } else if (response.hits.hits[0]._index.includes('events')) {
              seriesLabels = getSeriesDataValue(response, chartArray[index].display, chartArray[index].type, chartArray[index].name);
            } else if (response.hits.hits[0]._index.includes('hr-br') ||
              response.hits.hits[0]._index.includes('sleep') ||
              response.hits.hits[0]._index.includes('pressure') ||
              response.hits.hits[0]._index.includes('spo2') ||
              response.hits.hits[0]._index.includes('mood') ||
              response.hits.hits[0]._index.includes('steps')) {
              seriesLabels = getSeriesDataValue(response, chartArray[index].display, chartArray[index].type, chartArray[index].name);
            }
          }
          if (Array.isArray(seriesLabels.serie)) {
            seriesLabels.serie.forEach(element => {
              series.push(element);
              element.data.forEach(tmpData => {
                lbs.push(tmpData.x)
              });
            });
          } else {
            series.push(seriesLabels.serie);
            seriesLabels.serie.forEach
          }

          countResp++;
          tempResponse = response;
        });
        if (series[0].name === 'Diarrea (Frequenza)' || series[0].name === 'Stipsi (Frequenza)') {
          const seriess = cutSeries(series[0], series[1]);
          series[0] = seriess[0];
          series.splice(1, 1);
          chartArray.splice(1, 1);
        }
        if (series.length > 1) {
          const ss = this.getCC(series);
          series = ss;
        }
        let options = getBarChartOptions(id, title, series, chartArray, [], getAssiYCombo(chartArray, this.questions), this.stroke,
          false, true);
        options.tooltip.shared = true;
        options.tooltip.intersect = false;
        options.tooltip.followCursor = true;
        options.labels = lbs;
        if (this.chart) {
          this.chart.updateOptions(options, false, false, false);
        } else {
          this.chart = new ApexCharts(this.chartRef.nativeElement, options);
          this.chart.render();
        }

      }
    }, (err) => {
      console.error(err);
      this.toastr.error('Errore nel recupero dei dati.');
    });
  }

  isMobileAppSerie(serieName) {
    switch (serieName) {
      case "Frequenza cardiaca":
      case "Pressione (Min)":
      case "Pressione (Max)":
      case "Frequenza respiratoria":
      case "Ossigenazione":
      case "Passi":
        return false;
      default:
        return true;
    }
  }


  getCC(series) {

    let serieMobileApp = series.find(s => this.isMobileAppSerie(s.name));
    if (serieMobileApp == undefined) {
      return series;
    }

    let serieSmartwatch = series.find(s => !this.isMobileAppSerie(s.name));

    serieMobileApp.data = serieMobileApp.data.sort((a, b) => a.x - b.x);
    serieSmartwatch.data = serieSmartwatch.data.sort((a, b) => a.x - b.x);

    // Riempio con valori null a vicenda in modo da avere il tooltip condiviso
    let seriesSmartwatch = series.filter(s => !this.isMobileAppSerie(s.name));
    let tmpData;
    seriesSmartwatch.forEach(serieSmartwatch => {
      tmpData = serieMobileApp.data;
      for (let index = 0; index < serieSmartwatch.data.length; index++) {
        let tmpX = serieSmartwatch.data[index].x;
        let f = tmpData.find(val => this.getYYYYMMddFromTimestamp(val.x) == this.getYYYYMMddFromTimestamp(tmpX));
        if (undefined != f) {
          tmpData.push({ "x": tmpX, "y": f.y });
        } else {
          tmpData.push({ "x": tmpX, "y": null });
        }
      }

      serieMobileApp.data = tmpData;
      serieMobileApp.data = serieMobileApp.data.sort((a, b) => a.x - b.x);
    });

    seriesSmartwatch.forEach(serieSmartwatch => {
      tmpData = [];
      for (let index = 0; index < serieMobileApp.data.length; index++) {
        let tmpX = serieMobileApp.data[index].x;
        let f = serieSmartwatch.data.find(val => val.x == tmpX);
        if (undefined == f) {
          tmpData.push({ "x": tmpX, "y": null });
        } else {
          tmpData.push({ "x": tmpX, "y": f.y });
        }
      }

      serieSmartwatch.data = tmpData;
      serieSmartwatch.data = serieSmartwatch.data.sort((a, b) => a.x - b.x);
    });

    return series;
  }

  getSelfReportedData(sintomo, topic) {
    if (topic === '1') {
      // tslint:disable-next-line: max-line-length
      return this.http.get(endpoints.elasticQuery + "getSintomi" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + topic + "/-answers").toPromise();
    } else if (topic === '3') {
      // tslint:disable-next-line: max-line-length
      return this.http.get(endpoints.elasticQuery + "getSintomi" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + topic + "/-answers").toPromise();
    } else if (topic === '4') {
      // tslint:disable-next-line: max-line-length
      return this.http.get(endpoints.elasticQuery + "getSintomi" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + topic + "/-answers").toPromise();
    } else if (topic === '10') {
      // tslint:disable-next-line: max-line-length
      return this.http.get(endpoints.elasticQuery + "getSintomi" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + topic + "/-answers").toPromise();
    } else if (topic === '11') {
      // tslint:disable-next-line: max-line-length
      return this.http.get(endpoints.elasticQuery + "getSintomi" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + topic + "/-answers").toPromise();
    } else if (topic === 'werable') {
      return this.getDataNew(sintomo);
    } else if (topic == 'Capecitabina' || topic == 'Regorafenib' || topic == 'Lonsurf/Tas 102') {
      if (topic === 'Lonsurf/Tas 102') {
        topic = "L";
      }
      return this.http.get(endpoints.elasticQuery + "getFarmaco" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + topic + "/-events").toPromise();
    }
    else {
      // tslint:disable-next-line: max-line-length
      return this.http.get(endpoints.elasticQuery + "getSintomi" + "/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/" + topic + "/-answers").toPromise();
    }
  }


  getDataNew(event) {
    let source;
    let tempBool;
    let returnObj;

    source = 'Lite OR Vista Or VYVO';
    switch (event) {
      case 'Frequenza cardiaca':
        if (getInputHrMeasures(this.dateFromTimeStamp, this.dateToTimeStamp, this.patientId, source).size == 0) {
          tempBool = true;
        } else {
          tempBool = false;
        }
        returnObj = this.http.get(endpoints.elasticQuery + "getHR/" + this.patientId + "/" + source + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-hr-br/" + tempBool).toPromise();
        break;
      case 'Pressione sanguigna':
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getPressure/" + this.patientId + "/" + source + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-pressure").toPromise();
        break;
      case 'Frequenza respiratoria':
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getBR/" + this.patientId + "/" + source + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-hr-br").toPromise();
        break;
      case 'Passi':
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getSteps/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-steps").toPromise();
        break;
      case 'Mood':
        // Calculate new startDate/endDate in order to correct error (subtract 229 days)
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getMood/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-mood").toPromise();
        break;
      case 'Fatica':
        // Calculate new startDate/endDate in order to correct error (subtract 229 days)
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getEnergy/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-mood").toPromise();
        break;
      case 'Ossigenazione':
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getOx/" + this.patientId + "/" + source + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-spo2").toPromise();
        break;
      case 'Temperatura':
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getTemperature/" + this.patientId + "/" + source + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-temperature").toPromise();
        break;
      case 'Sonno':
        // tslint:disable-next-line: max-line-length
        returnObj = this.http.get(endpoints.elasticQuery + "getSleep/" + this.patientId + "/" + this.dateFromTimeStamp + "/" + this.dateToTimeStamp + "/-sleep").toPromise();
        break;
      // case 'Temperatura':
      //   // tslint:disable-next-line: max-line-length
      //   return this.http.post(endpointsChart.searchVyvoCovidHr, getInputHeloHrMeasures(this.dateFromTimeStamp, this.dateToTimeStamp, 'this.identifier')).toPromise();
      default:
        break;
    }
    return returnObj;
  }

  getSerieType(selected, selected1) {
    switch (selected) {
      case 'Dolore':
        if (selected1 === 'Frequenza cardiaca' || selected1 === 'Pressione sanguigna' || selected1 === 'Frequenza respiratoria'
          || selected1 === 'Mood' || selected1 === 'Energia' || selected1 === 'Ossigenazione' || selected1 === 'Passi') {
          return { name: 'Dolore', color: this.COLORE_DOLORE, topic: 'dolore', type: 'area', display: ['Dolore'], stroke: this.stroke, };
        } else {
          return { name: 'Dolore', color: this.COLORE_DOLORE, topic: 'dolore', type: 'column', display: ['Dolore'] };
        }
      case 'Diarrea (Frequenza)':
        return [
          {
            name: 'Evacuazioni giornaliere (frequenza)',
            color: '#FA4443',
            topic: '1',
            display: ['Diarrea (Frequenza)'],
            type: 'area',
            markers: false,
            fill: true,
          },
          {
            name: 'Diarrea', color: '#FA4443', serie: [], topic: '3', display: ['Diarrea (Farmaci)'], type: 'area', markers: false,
            fill: true,
          }
        ];
      case 'Diarrea (Farmaci)':
        return {
          name: 'Diarrea', color: '#FA4443', topic: '3', display: ['Diarrea (Farmaci)'], type: 'column', markers: false,
          fill: true,
        };
      case 'Stipsi (Frequenza)':
        return [
          {
            name: 'Evacuazioni giornaliere (frequenza)',
            color: '#FA4443',
            topic: '1',
            display: ['Stipsi (Frequenza)'],
            type: 'area',
            markers: false,
            fill: true,
          },
          {
            name: 'Stipsi', color: '#FA4443', serie: [], topic: '4', display: ['Stipsi (Farmaci)'], type: 'area', markers: false,
            fill: true,
          }
        ];
      case 'Stipsi (Farmaci)':
        return {
          name: 'Stipsi', color: '#FA4443', topic: '4', display: ['Stipsi (Farmaci)'], type: 'column', markers: false,
          fill: true,
        };
      case 'Frequenza cardiaca':
        return {
          name: 'Frequenza cardiaca',
          color: '#008FFB',
          topic: 'werable',
          display: ['Frequenza cardiaca'],
          type: ['area'],
          maxyaxis: 230,
          markers: false,
          fill: true,
          stroke: this.stroke
        };
      case 'Ossigenazione':
        return {
          name: 'Ossigenazione',
          color: '#008FFB',
          topic: 'werable',
          display: ['Ossigenazione'],
          type: ['area'],
          maxyaxis: 100,
          markers: false,
          fill: true,
          stroke: this.stroke
        };
      case 'Pressione sanguigna':
        return [
          {
            name: 'Pressione sanguigna',
            color: ['#008FFB', '#FA4443'],
            topic: 'werable',
            display: ['Pressione (Max)', 'Pressione (Min)'],
            type: ['area', 'area'],
            maxyaxis: 200,
            stroke: this.stroke,
            markers: false,
            fill: true
          }
        ];
      case 'Frequenza respiratoria':
        return {
          name: 'Frequenza respiratoria',
          color: '#008FFB',
          topic: 'werable',
          display: ['Frequenza respiratoria'],
          type: ['area'],
          maxyaxis: 80,
          stroke: this.stroke,
          markers: false,
          fill: true
        };
      case 'Mood':
        return {
          name: 'Mood',
          color: '#008FFB',
          topic: 'werable',
          display: ['Mood'],
          type: ['column'],
          maxyaxis: 90,
          markers: false,
          fill: true
        };
      case 'Energia':
        return {
          name: 'Energia',
          color: '#FA4443',
          topic: 'werable',
          display: ['Energia'],
          type: ['area'],
          maxyaxis: 90,
          stroke: this.stroke,
          markers: false,
          fill: true
        };
      case 'Temperatura':
        return {
          name: 'Temperatura',
          color: '#FA4443',
          topic: 'valutazione',
          display: ['Temperatura'],
          type: ['column'],
          maxyaxis: 45,
          markers: false,
          fill: true
        };
      case 'Fatica':
        if (selected1 === 'Frequenza cardiaca' || selected1 === 'Pressione sanguigna' || selected1 === 'Frequenza respiratoria'
          || selected1 === 'Mood' || selected1 === 'Energia' || selected1 === 'Passi' || selected1 === 'Ossigenazione') {
          return {
            name: 'Fatica',
            color: this.COLORE_FATICA,
            topic: 'fatica',
            type: 'area',
            display: ['Fatica'],
            stroke: this.stroke,
            maxyaxis: 100
          };
        } else {
          return { name: 'Fatica', color: this.COLORE_FATICA, serie: [], topic: 'fatica', type: 'column', display: ['Fatica'], maxyaxis: 100 };
        }
      case 'Passi':
        if (selected1 === 'Peso') {
          return {
            name: 'Passi',
            color: '#008FFB',
            topic: 'werable',
            display: ['Passi'],
            type: 'column',
            maxyaxis: 30000,
            stroke: this.stroke,
            markers: false,
            fill: true
          };
        } else {
          return {
            name: 'Passi',
            color: '#008FFB',
            topic: 'werable',
            display: ['Passi'],
            type: 'column',
            maxyaxis: 30000,
            stroke: this.stroke,
            markers: false,
            fill: true
          };
        }
      case 'Nausea':
        if (selected1 === 'Frequenza cardiaca' || selected1 === 'Pressione sanguigna' || selected1 === 'Frequenza respiratoria'
          || selected1 === 'Mood' || selected1 === 'Energia' || selected1 === 'Passi') {
          return {
            name: 'Nausea',
            color: this.COLORE_NAUSEA,
            topic: 'nausea',
            type: 'area',
            display: ['Nausea'],
            stroke: this.stroke,
            maxyaxis: 100
          };
        } else {
          return { name: 'Nausea', color: this.COLORE_NAUSEA, topic: 'nausea', type: 'column', display: ['Nausea'], maxyaxis: 100 };
        }
      case 'Funzionalita':
        if (selected1 === 'Frequenza cardiaca' || selected1 === 'Pressione sanguigna' || selected1 === 'Frequenza respiratoria'
          || selected1 === 'Mood' || selected1 === 'Energia' || selected1 === 'Passi' || selected1 === 'Ossigenazione') {
          return {
            name: 'Funzionalita',
            color: this.COLORE_NAUSEA,
            topic: 'funzionalita_fisica',
            type: 'area',
            display: ['Funzionalità fisica'],
            stroke: this.stroke,
            maxyaxis: 100
          };
        } else {
          // tslint:disable-next-line: max-line-length
          return { name: 'Funzionalita', color: this.COLORE_NAUSEA, topic: 'funzionalita_fisica', type: 'column', display: ['Funzionalità fisica'], maxyaxis: 100 };
        }
      case 'Peso':
        return {
          name: 'Peso corporeo',
          color: '#00E396',
          topic: 'valutazione',
          display: ['Peso (kg)'],
          type: 'column',
          maxyaxis: 200
        };
      case 'Capecitabina':
      case 'Regorafenib':
      case 'Lonsurf/Tas 102':
        return {
          name: selected,
          color: '#00E396',
          serie: [],
          display: [selected],
          type: ['area'],
          stroke: this.stroke,
          markers: false,
          topic: selected,
          yaxis: this.yAxisFarmaco,
        };
      default:
        return {
          name: selected.split("_")[0],
          color: '#00E396',
          topic: '' + selected.split("_")[1],
          display: [selected.split("_")[0]],
          type: 'area',
          stroke: this.stroke,
          markers: true
        };
        break;
    }
  }

  getYYYYMMddFromTimestamp(timestamp) {
    const dateElem = new Date(timestamp);
    const dateElemStr = dateElem.getFullYear() + '/' + dateElem.getMonth() + '/' + dateElem.getDate();
    return dateElemStr;
  }
}
