import { BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { LogEventService } from 'src/app/main/pazienti/service/log-event.service';
import { PazientiService } from 'src/app/main/pazienti/service/pazienti.service';
import { AppService } from 'src/app/service/app.service';
import { QuestionnaireService } from 'src/app/service/questionnaire-service.service';
import { CanComponentDeactivate } from 'src/app/service/unsaved-changes-guard.service';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';

@Component({
  selector: 'app-scale-di-valutazione-barthel',
  templateUrl: './scale-di-valutazione-barthel.component.html',
  styleUrls: ['./scale-di-valutazione-barthel.component.scss']
})
export class ScaleDiValutazioneBarthelComponent implements OnInit, CanComponentDeactivate {

  clinicalImpressionId;
  patientId;

  domande;
  idQuestionario;
  esito = '';

  // Per gestire il mobile
  dropdownOptions = new Array();
  isMobile = false;
  observer;

  isVisit = false;

  dirty = false;

  constructor(private toastr: ToastrService, private fb: FormBuilder, private pazientiService: PazientiService,
    private userService: UserService, private http: HttpClient, private route: ActivatedRoute, private appServ: AppService,
    private questionariService: QuestionnaireService, private logService: LogEventService) { }

  ngOnInit() {
    this.getIsInVisite();
    this.patientId = this.pazientiService.getPatientId();
    this.observer = this.appServ.mdAppObs
      .subscribe((statex: BreakpointState) => {
        this.isMobile = statex.matches;
      });
    this.getQuestionario();
  }

  getQuestionario() {
    const obj = {
      nome: 'Barthel',
      versione: '1'
    };
    this.http.post(endpoints.getQuestionnaireByNameByVersion, obj).toPromise()
      .then((resp: any) => {
        this.idQuestionario = resp.id;
        this.domande = new Array();
        resp.item.forEach(item => {
          let domanda: any;
          domanda = {};
          domanda.testo = item.text;
          domanda.tipo = item.type;
          domanda.linkId = item.linkId;
          domanda.risposta = undefined;
          domanda.risposte = new Array();
          if (domanda.tipo === 'integer') {
            item.option.forEach(opt => {
              domanda.risposte.push({
                label: opt.valueCoding.display,
                value: opt.valueCoding.code
              });
            });
            domanda.options = this.getOptionForDomanda(item);
            domanda.risposta = 0;
          }
          this.domande.push(domanda);
        });
        this.getRisposte();
      });
  }

  getIsInVisite() {
    const cId = this.route.parent.parent.snapshot.paramMap.get('vId');
    if (cId) {
      this.isVisit = true;
      this.clinicalImpressionId = cId;
    }
  }

  getRisposte() {
    let endpointToCall;
    let obj: any;
    obj = {};
    if (this.isVisit) {
      obj = { clinicalImpressionId: this.clinicalImpressionId, questionnaireId: this.idQuestionario };
      endpointToCall = endpoints.getClinicalImpressionSurveyResponse;
    } else {
      obj = { patientId: this.patientId, questionnaireId: this.idQuestionario };
      endpointToCall = endpoints.getClinicalImpressionSurveyResponseNLAB;
    }
    this.http.post(endpointToCall, obj).toPromise()
      .then((resp: any) => {
        if (resp) {
          const domandaTotale = this.domande.find(d => d.testo === 'Totale');
          const risposte = resp.item;
          let indice = 0;
          (this.domande.filter(d => d.tipo !== 'display')).forEach(domanda => {
            switch (domanda.tipo) {
              case 'quantity':
                domanda.risposta = '' + risposte[indice++].answer[0].valueString;
                break;
              case 'integer':
                domanda.risposta = '' + risposte[indice++].answer[0].valueInteger;
                break;
            }
          });
          this.aggiornaLabelEsito();
        } else {
          this.toastr.error('Errore nel caricamento delle risposte del questionario.');
          console.error('Errore: ', resp);
        }
        this.logService.getAllOpt([[resp]], null);
      }).catch(err => {
        if (err.status === 404) {
          this.toastr.info('Questionario non eseguito.');
          this.logService.resetGlobalVarLog();
        } else {
          console.error(err);
          if (err.status == 403) {
            this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
            return;
          }
          this.toastr.error('Errore nel caricamento delle risposte del questionario.');
        }
      }).finally(() => {
        this.dirty = false;
      });
  }

  aggiornaLabelEsito() {
    const domandaTotale = this.domande.find(d => d.testo === 'Totale');
    if (Number(domandaTotale.risposta) > 79 && Number(domandaTotale.risposta) < 101) {
      this.esito = 'Indipendente';
    } else if (Number(domandaTotale.risposta) > 59 && Number(domandaTotale.risposta) < 80) {
      this.esito = 'Minimamente indipendente';
    } else if (Number(domandaTotale.risposta) > 39 && Number(domandaTotale.risposta) < 60) {
      this.esito = 'Parzialmente dipendente';
    } else if (Number(domandaTotale.risposta) > 19 && Number(domandaTotale.risposta) < 40) {
      this.esito = 'Molto dipendente';
    } else if (Number(domandaTotale.risposta) < 20) {
      this.esito = 'Totalmente dipendente';
    }
  }

  getOptionForDomanda(domanda) {
    const customStepsArray = new Array();
    domanda.option.forEach(opt => {
      customStepsArray.push({
        value: opt.valueCoding.code,
        legend: opt.valueCoding.display
      });
    });
    return {
      showTicksValues: true,
      stepsArray: customStepsArray,
      disabled: !this.checkPermessoModifica()
    };
  }

  checkPermessoModifica() {
    if (this.isVisit) {
      return this.userService.checkPermission('Osservazioni', 'modify');
    } else {
      return true;
    }
  }

  salvaRisposteBarthel() {
    let endpointToCall;
    let obj: any;
    obj = {};
    if (this.isVisit) {
      obj = {
        answers: new Array(),
        questionnaireId: this.idQuestionario,
        clinicalImpressionId: this.clinicalImpressionId
      };
      endpointToCall = endpoints.addClinicalImpressionSurveyResponse;
    } else {
      obj = {
        answers: new Array(),
        questionnaireId: this.idQuestionario,
        patientId: this.patientId
      };
      endpointToCall = endpoints.addClinicalImpressionSurveyResponseNLABsub;
    }
    const domandaTotale = this.domande.find(d => d.testo === 'Totale');
    domandaTotale.risposta = 0;
    try {
      (this.domande.filter(d => d.tipo !== 'display')).forEach(domanda => {
        switch (domanda.tipo) {
          case 'integer':
            if (domanda.risposta === undefined || domanda.risposta === null) {
              throw new Error('Non sono state risposte tutte le domande.');
            }
            domandaTotale.risposta += Number(domanda.risposta);
            obj.answers.push({ type: domanda.tipo, linkId: domanda.linkId, value: '' + domanda.risposta });
            break;
          case 'quantity':
            obj.answers.push({ type: domanda.tipo, linkId: domanda.linkId, value: '' + domanda.risposta });
            break;
        }
      });
    } catch (Exception) {
      this.toastr.error(Exception.message);
      return;
    }

    this.http.post(endpointToCall, obj).toPromise()
      .then((resp: any) => {
        this.toastr.success('Salvataggio avvenuto con successo.');
        this.getRisposte();
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel salvataggio delle risposte del questionario.');
      });
  }

  // Solo se non esiste nel database. Attenzione a non creare duplicati!!!
  creaQuestionario() {
    this.questionariService.createQuestionnaire(QuestionnaireService.BARTHEL);
  }

  // Usare solo quando è realmente necessario
  aggiornaQuestionario() {
    this.questionariService.updateQuestionnaire(QuestionnaireService.BARTHEL, this.idQuestionario);
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return !this.checkPermessoModifica() || !this.dirty;
  }

}
