import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/service/user-service.service';
import { PazientiService } from '../main/pazienti/service/pazienti.service';
import { CustomizationService } from './customization.service';
import { SezioniAuthGuard } from './model/sezioni-auth-guard';
import { TokenService } from './token.service';
import { eliminaTokenAndRedirectLogin } from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class AuthGuardService implements CanActivate {
  listSections = SezioniAuthGuard;

  privacyVersion = "v1";
  cookieVersion = "v1";

  constructor(private tokenService: TokenService, private router: Router, private userService: UserService,
    private toastr: ToastrService,
    private http: HttpClient, private pazientiService: PazientiService, private customizationService: CustomizationService) {
  }

  /**
   * Metodo principale che controlla i permessi in base alla pagina che si vuole visualizzare.
   * Inoltre, verifica anche il token delle credenziali.
   * @param next pagina a cui vuole accedere l'utente.
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.privacyVersion = "v1_" + this.customizationService.getOrganizationFromUrl().toLocaleLowerCase();
    this.cookieVersion = "v1_" + this.customizationService.getOrganizationFromUrl().toLocaleLowerCase();
    if (state.url.split('/').find(j => j === 'password-change')) {
      return true;
    } else if (!this.userService.isAccountNotnew()) {
      this.router.navigate(['global/password-change']);
      return false;
    }
    if (this.tokenService.checkToken()) {
      this.tokenService.deleteToken();
      this.userService.deleteUser();
      this.router.navigate(['/login']);
      return false;
    }
    if (!localStorage.getItem('privacy_user_' + this.privacyVersion) || !localStorage.getItem('cookie_policy_' + this.cookieVersion)) {
      this.tokenService.deleteToken();
      this.userService.deleteUser();
      this.router.navigate(['/login']);
      return false;
    }
    if (!this.userService.isSuperAdmin() && this.userService.getCurrentOrganization() !== this.customizationService.getOrganizationFromUrl()) {
      this.tokenService.deleteToken();
      this.userService.deleteUser();
      this.router.navigate(['/login']);
      return false;
    }
    return this.home(next, state);
  }

  /**
   * Controlla se l'utente può vedere la sezione e paziente selezionato.
   */
  home(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.userService.isSuperAdmin()) {
      // Se è superadmin può vedere tutte le pagine
      return true;
    }
    const sezione = state.url.split('/').find(j => j === 'visite' || j === 'profilosanitario' || j === 'monitoraggio' || j === 'schedapaziente'
      || j === 'ruoli' || j === 'organizations' || j === 'pannello_log' || j === 'pannelloElastic' || j === 'utenti' || j === 'questionari' || j === 'devices');
    if (sezione) {
      // Se è una zona dove servono i permessi o vengono visualizzate informazioni dei pazienti, vengono controllati i permessi
      const patientIdByPath = this.extractPatientFromUrl(state);
      if (patientIdByPath !== '-1') {
        let patient = this.pazientiService.patient.getValue();
        if (!patient || patient.id !== patientIdByPath) {
          return this.pazientiService.getPatientFromServerByPatientId(patientIdByPath).then((resp: any) => {
            if (resp.message != undefined) {
              resp = resp.message;
            }
            this.pazientiService.setPatient(resp);
            patient = this.pazientiService.getPatient();
            return this.esito(this.controlli(next, state, patient));
          });
        } else {
          return this.esito(this.controlli(next, state, patient));
        }
      } else if (sezione == "schedapaziente") {
        // Se è la pagina dove si inserisce un nuovo paziente e non ha selezionato nessun paziente,
        // controllo solo il permesso per l'inserimento di un nuovo paziente.
        return this.userService.checkOnlyPermission('Anagrafica pazienti', 'insert');
      } else if (sezione == "ruoli" || sezione === 'organizations' || sezione === 'pannello_log' || sezione === 'pannelloElastic' || sezione === 'utenti' || sezione === 'questionari' || sezione === 'devices') {
        // Se è una pagina all'interno di administration panel, controlla se l'utente non superadmin ha il permesso di accedervi
        // in caso di azione negata viene bloccata la navigazione ed eseguito il logout
        if (this.isAdmin() && (sezione === 'utenti' || sezione === 'pannello_log' || sezione === 'devices')) {
          return true;
        }
        eliminaTokenAndRedirectLogin(this.tokenService, this.userService, this.pazientiService, this.router);
        return false;
      }
      return false;
    } else {
      // Caso in cui la pagina non ha bisogno di permessi
      return true;
    }
  }

  controlli(next: ActivatedRouteSnapshot, state: RouterStateSnapshot, patient) {
    if (next && next.url && next.url[0] && next.url[0].path) {
      const sezione = this.estraiSezioneByPath(next);
      if (sezione) {
        return this.userService.checkPermission(sezione, "read", patient);
      }
    }
    return true;
  }

  esito(e: boolean, msg?: string) {
    if (!e) {
      this.toastr.error(msg ?? 'Non si dispone dei permessi per visualizzare questo paziente.');
      this.pazientiService.resetPatient();
      this.router.navigate(['/login']);
    }
    return e;
  }

  /**
   * Recupera il paziente e controlla se il medico è associato ad esso.
   */
  checkPractitioner(state: RouterStateSnapshot, next: ActivatedRouteSnapshot, patient) {
    const loggedUser = this.userService.retriveUser(); // Mi serve il referenceId
    if (!loggedUser) {
      console.error('Impossibile caricare le informazioni dell\'utente autenticato.');
      return false;
    }
    return this.cercaPractitioner(loggedUser.referenceId, patient);
  }

  /**
   * Controlla se il paziente ha il medico associato, se quest'ultimo non è admin (che può vedere tutti) restituisce false.
   */
  cercaPractitioner(practitioner, patient) {
    if (patient.generalPractitioner === undefined || (patient.generalPractitioner.filter(p => p.reference === practitioner)).length === 0) {
      return false;
    } else {
      this.pazientiService.setPatient(patient);
      return true;
    }
  }

  /**
   * Controlla se l'utente loggato ha i permessi per la sezione che vuole visualizzare.
   * Se può vedere tutte le sezioni (es. admin) viene skippato il controllo specifico della sezione;
   * Altrimenti si controlla il permesso di lettura della sezione.
   */
  checkSezione(next) {
    if (next && next.url && next.url[0] && next.url[0].path) {
      const sezione = this.estraiSezioneByPath(next);
      if (sezione) {
        const permissions = this.userService.getPermissionsFromAuthorities(sezione);
        if (permissions != undefined && permissions["read"] != undefined) {
          return permissions["read"];
        }
      }
    }
    return true;
  }

  /**
   * Estrai l'id del paziente desiderato dall'url della pagina a cui si vuole accedere.
   * Restituisce -1 se è una pagina in cui non serve l'id del paziente.
   */
  extractPatientFromUrl(state: RouterStateSnapshot) {
    if (!state.url) {
      return undefined;
    }
    let segments = state.url.split('/');
    segments = segments.filter(f => f.length > 0 && !isNaN(Number(f)));
    if (segments.length > 0) {
      return segments[0];
    } else {
      return '-1';
    }
  }

  isAdmin() {
    return this.userService.isAdmin();
  }

  /**
   * Estrae la sezione della piattaforma dalla path della pagina a cui vogliamo accedere.
   */
  estraiSezioneByPath(next) {
    if (!next || !next.url || !next.url[0] || !next.url[0].path || !this.listSections) {
      return '';
    }
    let sec = next.url[0].path;
    if (sec === 'profilosanitario') {
      sec = 'Dettagli';
    } else if (sec === 'schedapaziente') {
      sec = 'Anagrafica pazienti';
    }
    const toReturn = this.listSections.find(obj => sec.toLowerCase() === obj.section.toLowerCase());
    if (toReturn) {
      return toReturn.section;
    } else {
      return undefined;
    }
  }
}
