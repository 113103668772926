import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { forkJoin, Observable } from 'rxjs';
import { checkIsModified, Code, CodeCoding, Observation, updateFormByObs } from 'src/app/model/Observation';
import { UserService } from 'src/app/service/user-service.service';
import { getLabelFromOptions } from 'src/app/utils/utils';
import { endpoints } from 'src/endpoint/endpoints';
import { LogEventService } from '../../service/log-event.service';
import { PazientiService } from '../../service/pazienti.service';
// tslint:disable-next-line: max-line-length
import { alcoliciOpt, alimentazioneOpt, attivitaFisicaOpt, fumatoreOpt, livelloDiIstrOpt, statoCivileOpt } from './model/StileDiVitaOptions';
import { CanComponentDeactivate } from 'src/app/service/unsaved-changes-guard.service';
@Component({
  selector: 'app-stile-di-vita',
  templateUrl: './stile-di-vita.component.html',
  styleUrls: ['./stile-di-vita.component.scss']
})
export class StileDiVitaComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  CODE = "StileDiVita";
  isInVisite = false;
  tempPutPath = "";
  tempPostPath = "";
  tempLastCIPath = "";
  tempLFSTLPath = "";

  patientId;
  clinicalImpressionId;

  observation = new Array<Observation>();

  stileDiVitaForm: FormGroup;

  patient;

  subscriber;

  statoCivileOpt: SelectItem[];
  livelloDiIstrOpt: SelectItem[];
  fumatoreOpt: SelectItem[];
  alcoliciOpt: SelectItem[];
  alimentazioneOpt: SelectItem[];
  attivitaFisicaOpt: SelectItem[];

  lastVisit;
  newEvent;
  resourceHistory;
  history;
  tempObj = [];
  loggedUser;

  allObservationCode = ['patient-marital-status', '63504-5', '72166-2', '68518-0', 'alimentation', 'physical-activity', '11295-3'];

  constructor(private http: HttpClient, private pazientiService: PazientiService, private fb: FormBuilder,
    private toastr: ToastrService, private route: ActivatedRoute, private userService: UserService,
    private logService: LogEventService
  ) { }

  ngOnInit() {
    this.loggedUser = this.userService.getUser().username;
    this.getIsInVisite();
    // creo il form
    this.buildForm();
    this.patientId = this.pazientiService.getPatientId();
    this.CODE = this.CODE + "/" + this.patientId;
    if (this.isInVisite) {
      this.getSocialHistory();
      this.getLastClinicalImpression();
    } else {
      this.getLifeStyleByPatientId();
    }
    // this.patient = this.pazientiService.getPatient();
    this.subscriber = this.pazientiService.patient.subscribe(patient => {
      if (patient != null) {
        this.patient = patient;
        this.history = this.patient.meta.versionId;
        if (!this.isInVisite && patient) {
          this.updateFormByPatient(patient);
        }
      }
    });
    this.statoCivileOpt = statoCivileOpt;
    this.livelloDiIstrOpt = livelloDiIstrOpt;
    this.fumatoreOpt = fumatoreOpt;
    this.alcoliciOpt = alcoliciOpt;
    this.alimentazioneOpt = alimentazioneOpt;
    this.attivitaFisicaOpt = attivitaFisicaOpt;
  }

  ngOnDestroy(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  getIsInVisite() {
    const cId = this.route.parent.snapshot.paramMap.get('vId');
    if (cId) {
      this.isInVisite = true;
      this.clinicalImpressionId = cId;
    }
    if (this.clinicalImpressionId != null) {
      this.tempObj = [{ id: this.clinicalImpressionId, resourceType: "ClinicalImpression" }];
      this.logService.newEvent("ClinicalImpression/" + this.clinicalImpressionId + "/_history/1", null, false, this.loggedUser).then((resp: any) => {
        this.logService.getAllOpt([this.observation, this.tempObj], null);
      });
    }
  }
  /**
   * Metodo che crea il form localmente
   */
  buildForm() {
    this.stileDiVitaForm = this.fb.group({
      'patient-marital-status': ['UNK'],
      '63504-5': [''],
      '72166-2': [''],
      '68518-0': [''],
      alimentation: [''],
      'physical-activity': [''],
      '11295-3': ['']
    });
    if (!this.checkPermessoModifica()) {
      this.stileDiVitaForm.disable();
    }
  }
  /**
   * Aggiorna il valore del form con il valore del patient
   *
   */
  updateFormByPatient(patient) {
    if (patient && patient.maritalStatus) {
      this.stileDiVitaForm.get('patient-marital-status').setValue(patient.maritalStatus.coding[0].code);
      this.stileDiVitaForm.markAsPristine();
    }
  }

  getSocialHistory() {
    this.http.get(endpoints.getLifeStyleByClinicalImpressionIdVisite + this.clinicalImpressionId).toPromise()
      .then((resp: any) => {
        if (resp) {
          if (resp.success === '1') {
            this.observation = resp.message;
            this.observation = this.checkOrCreate(this.observation);
            updateFormByObs(this.observation, this.stileDiVitaForm);
          } else {
            console.error(resp.message);
            this.toastr.error('Errore nel caricamento dello Stile di vita.');
          }
        }
        this.logService.getAllOpt([this.observation], null);
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel caricamento dello Stile di vita.');
      });
  }

  getLifeStyleByPatientId() {
    if (this.isInVisite) {
      this.tempLFSTLPath = endpoints.getLifeStyleByPatientIdApiVisite;
    } else {
      this.tempLFSTLPath = endpoints.getLifeStyleByPatientIdApiDettagli;
    }
    this.http.get(this.tempLFSTLPath + this.patientId).toPromise()
      .then((resp: any) => {
        if (resp) {
          if (resp.entry) {
            if (this.isInVisite && this.isLastVisit()) {
              resp.entry.forEach(element => {
                if (element.resource.id != null && element.resource.meta != null) {
                  this.logService.newEventNoCheck(element.resource.resourceType + "/" + element.resource.id + "/_history/" + element.resource.meta.versionId, null, null, this.userService.getUser().username);
                }
              });
            }
            resp.entry.forEach(element => {
              this.observation.push(element.resource);
            });
            this.observation = this.checkOrCreate(this.observation);
            updateFormByObs(this.observation, this.stileDiVitaForm);
          } else {
            this.observation = this.checkOrCreate(this.observation);
            updateFormByObs(this.observation, this.stileDiVitaForm);
          }
          // rifatto qui per questioni di concorrenza
          this.updateFormByPatient(this.patient);
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel caricamento dello Stile di vita.');
      });
  }

  save() {
    if (this.isInVisite) {
      const obsToSave = checkIsModified(this.stileDiVitaForm, this.observation);
      if (obsToSave.length === 0) {
        this.toastr.info('Nulla da salvare.');
        return;
      }
      const obsPostUpdate = this.updateObsByForm(obsToSave, this.stileDiVitaForm);
      obsToSave.forEach((el: any) => {
        if (el.meta != undefined) {
          this.logService.newEventNoCheck(el.resourceType + "/" + el.id + "/_history/" + el.meta.versionId, null, null, this.loggedUser);
        }
      });
      const obsForUpdate = obsPostUpdate.filter(elem => {
        if (elem.id) {
          return true;
        } else {
          return false;
        }
      });
      if (obsForUpdate && obsForUpdate.length > 0) {
        forkJoin(obsForUpdate.map(elem => {
          const obs = {} as any;
          obs.id = elem.id;
          if (this.isLastVisit()) {
            obs.updatePatient = this.patientId;
            this.logService.newEventNoCheck("Observation/" + obs.id + "/_history/" + this.history, null, null, this.loggedUser)
          }
          obs.value = {} as any;
          obs.value.unit = '';
          if (elem.valueCodeableConcept) {
            obs.value.type = 'concept';
            obs.value.value = {} as any;
            obs.value.value.coding = elem.valueCodeableConcept.coding[0];

          } else if (elem.valueString != null || elem.valueString !== undefined) {
            obs.value.type = 'string';
            obs.value.value = {} as any;
            obs.value.value = elem.valueString;
          }
          this.checkAndSave();
          return this.updateObservation(obs);
        })).subscribe((resp: any[]) => {
          let error = false;
          resp.forEach(el => {
            if (el.success === '0') {
              error = true;
            }
          });
          if (error) {
            console.error(resp);
            this.toastr.error('Errore nell\'aggiornamento dei dati.');
          } else {
            this.toastr.success('Aggiornamento effettuato con successo.');
            this.getSocialHistory();
            this.stileDiVitaForm.markAsPristine();
          }
        }, (err) => {
          console.error(err);
          this.toastr.error('Errore nell\'aggiornamento dei dati.');
        });
      }
      const obsForAdd = obsPostUpdate.filter(elem => {
        if (!elem.id) {
          return true;
        } else {
          return false;
        }
      });
      if (obsForAdd && obsForAdd.length > 0) {
        let input = {} as any;
        input = {
          clinicalImpressionId: this.clinicalImpressionId,
          observations: [],
          code: {
            coding: {
              code: 'social-history',
              system: 'http://hl7.org/fhir/observation-category'
            }
          }
        };
        obsForAdd.forEach(elem => {
          const obs = {} as any;
          obs.category = {} as any;
          obs.category.coding = elem.category[0].coding[0];
          obs.code = {} as any;
          obs.code.coding = elem.code.coding[0];
          obs.value = {} as any;
          obs.value.units = '';
          if (elem.valueCodeableConcept) {
            obs.value.type = 'concept';
            obs.value.value = {} as any;
            obs.value.value.coding = elem.valueCodeableConcept.coding[0];
          } else if (elem.valueString != null || elem.valueString !== undefined) {
            obs.value.type = 'string';
            obs.value.value = {} as any;
            obs.value.value = elem.valueString;
          }
          input.observations.push(obs);
        });
        if (this.isLastVisit()) {
          input.updatePatient = this.patientId;
        }
        this.addInvestigation(input);
      }
    } else {
      this.checkAndSave().subscribe(el => {
        if (el && el.length > 0) {
          el.forEach((elem) => {
            if (elem.resourceType === 'Observation') {
              if (elem.code && elem.code.coding[0]) {
                if (this.stileDiVitaForm.get(elem.code.coding[0].code)) {
                  if (elem.valueCodeableConcept) {
                    this.stileDiVitaForm.get(elem.code.coding[0].code).setValue(elem.valueCodeableConcept.coding[0].code);
                  } else if (elem.valueString != null || elem.valueString !== undefined) {
                    this.stileDiVitaForm.get(elem.code.coding[0].code).setValue(elem.valueString);
                  }
                }
              }
              // rifatto qui per questioni di concorrenza
              this.getLifeStyleByPatientId();
            } else {
              this.getLifeStyleByPatientId();
              //this.pazientiService.setPatient(elem);
            }
          });
          this.toastr.success('Informazioni aggiornate correttamente.');
          this.stileDiVitaForm.markAsPristine();
        }
      }, err => {
        console.error(err);
        this.toastr.error('Errore nell\'aggiornamento dei dati.');
      });
    }
  }

  addInvestigation(input) {
    var tempPath;
    if (this.isInVisite) {
      tempPath = endpoints.addInvestigationVisite;
    } else {
      tempPath = endpoints.addInvestigationDettagli;
    }
    this.http.post(tempPath, input).toPromise()
      .then((resp: any) => {
        if (resp && resp.success === '1') {
          if (this.isLastVisit()) {
            this.getLifeStyleByPatientId();
          }
          this.getSocialHistory();
          this.toastr.success('Informazioni aggiornate correttamente.');
          this.stileDiVitaForm.markAsPristine();
        } else {
          console.error(resp.message);
          this.toastr.error('Errore nell\'aggiornamento dei dati.');
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nell\'aggiornamento dei dati.');
      });
  }

  /**
   * Controlla che le observation siano state modificate in quel caso fa partire
   * chiamate al server in parallelo per l'aggiornamento o l'inserimento
   */
  checkAndSave() {
    // array in cui setto tutti gli oggetti da salvare
    const ret = [];
    // uso le chiavi del form per scorrere le observation
    Object.keys(this.stileDiVitaForm.controls).forEach(key => {
      // uso il FormControl per fare il check del valore
      const control = this.stileDiVitaForm.get(key);
      if (control instanceof FormControl) {
        if (this.checkValueChanges(control, key)) {
          // discrimino lo statoCivile perchè fa parte del paziente
          if (key !== 'patient-marital-status') {
            let obs: Observation = this.getObs(key);
            // aggiorno il valore dell'observation
            obs = this.updateObservationValue(obs, key, control);
            ret.push(obs);
          } else {
            // sono nel caso in cui è stato civile e devo aggiornare il paziente
            if (!this.patient.maritalStatus) {
              // entro se nel paziente non è mai stato settato lo statoCivile (maritalStatus)
              this.patient.maritalStatus = {};
              this.patient.maritalStatus.coding = new Array<CodeCoding>();
              this.patient.maritalStatus.coding.push({} as Code);
            }
            // controllo se lo stato civile è stato modificato
            if (this.patient.maritalStatus.coding[0].code !== control.value) {
              if (this.isInVisite && this.isLastVisit()) {
                return this.logService.newEventNoCheck("Patient/" + this.patient.id + "/_history/" + (Number(this.patient.meta.versionId) + 1), "StileDiVita/" + this.patient.id, true, this.loggedUser);
              }
              this.patient.maritalStatus.coding[0].code = control.value;
              this.patient.maritalStatus.coding[0].display = getLabelFromOptions(control.value, this.statoCivileOpt);
              const pat = this.patient;
              pat.id = this.patientId;
              ret.push(pat);
            }
          }
        }
      }
    });
    // restituisco le chiamate parallele
    return forkJoin(ret.map(el => {
      if (el.resourceType === 'Observation') {
        if (el.id) {
          return this.putObservation(el.id, el);
        } else {
          return this.postObservation(el);
        }
      } else {
        return this.updatePatient(el, this.patientId);
      }
    }));
  }

  /**
   * chiamata http PUT observation
   */
  putObservation(id, obsr) {
    if (!this.isInVisite) {
      this.tempPutPath = endpoints.putApiObservationDettagli;
    } else {
      this.tempPutPath = endpoints.putApiObservationVisite;
    }
    return this.http.put(this.tempPutPath + id, obsr);
  }

  updateObservation(input) {
    return this.http.post(endpoints.updateObservationVisite, input);
  }

  /**
   * chiamata http POST observation
   */
  postObservation(obsr) {
    if (!this.isInVisite) {
      this.tempPostPath = endpoints.postApiObservationDettagli;
    } else {
      this.tempPostPath = endpoints.postApiObservationVisite;
    }
    return this.http.post(this.tempPostPath, obsr);
  }
  /**
   * Restituisce l'Observation che ha come Code l'input passato
   */
  getObs(key): Observation {
    let ret: Observation = null;
    this.observation.forEach(elem => {
      if (elem.code.coding[0].code === key) {
        ret = elem;
      }
    });
    return ret;
  }
  /**
   * aggiorna nell'array locale l'Observation
   * usato dopo la risposta del server
   */
  updateObsPostResp(obs) {
    this.observation.forEach(elem => {
      if (elem.code.coding[0].code === obs.code.coding[0].code) {
        elem = obs;
      }
    });
  }
  /**
   * Controlla che il valore della forn control sia diverso dal valore precedente dell'Observation
   * in pratica controlla se è stato modificato se è statoCivile ritorna True
   */
  checkValueChanges(control: FormControl, formControlName): boolean {
    let ret = false;
    const value = control.value;
    this.observation.forEach(elem => {
      if (formControlName !== 'patient-marital-status') {
        if (elem.code && elem.code.coding[0]) {
          if (elem.code.coding[0].code === formControlName) {
            if (elem.valueCodeableConcept) {
              if (elem.valueCodeableConcept.coding[0].code === value) {
                ret = false;
              } else {
                ret = true;
              }
            } else if (elem.valueString != null || elem.valueString !== undefined) {
              if (elem.valueString === value) {
                ret = false;
              } else {
                ret = true;
              }
            }
          }
        }
      } else {
        ret = true;
      }
    });
    return ret;
  }

  /**
   * Ritorna l'option a secoda della key
   */
  getOptionsByControl(key) {
    switch (key) {
      case 'patient-marital-status':
        return this.statoCivileOpt;
      case '63504-5':
        return this.livelloDiIstrOpt;
      case '72166-2':
        return this.fumatoreOpt;
      case '68518-0':
        return this.alcoliciOpt;
      case 'alimentation':
        return this.alimentazioneOpt;
      case 'physical-activity':
        return this.attivitaFisicaOpt;
      default:
        break;
    }
  }

  /**
   * aggiorna il valore dell'observation dal form control
   */
  updateObservationValue(obs: Observation, key, control) {
    if (key === '11295-3') {
      obs.valueString = control.value;
      return obs;
    }
    const opt = this.getOptionsByControl(key);
    const label = getLabelFromOptions(control.value, opt);
    // controllo che sia di tipo valueCodeableConcept
    if (obs.valueCodeableConcept) {
      obs.valueCodeableConcept.coding[0].code = control.value;
      obs.valueCodeableConcept.coding[0].display = label;
      // controllo che sia di tipo valueString
    } else if (obs.valueString != null || obs.valueString !== undefined) {
      obs.valueString = control.value;
    }
    return obs;
  }

  updatePatient(pat, id): Observable<any> {
    var updatePat = this.http.put(endpoints.putPatientByPatientIdByObject + id, pat);
    var tempSplit = this.CODE.split("/");
    this.resourceHistory = "Patient/" + this.patientId + "/_history/" + this.history;
    this.newEvent = { utente: this.loggedUser, risorsa: "Patient/" + this.patientId + "/_history/" + this.history, effective_date: new Date().toUTCString(), tipologia: "PUT", code: tempSplit.shift() };
    this.http.post(endpoints.logNewEvent, this.newEvent).toPromise();
    return updatePat;
  }
  /**
   * crea una oservation a seconda del tipo
   */
  getObservation(type): Observation {
    switch (type) {
      case '63504-5': {
        return new Observation('social-history', '63504-5', 'Livello di istruzione', 'http://loinc.org',
          'Patient/' + this.patientId, null, 'http://loinc.org');
      }
      case '72166-2': {
        return new Observation('social-history', '72166-2', 'Fumo', 'http://loinc.org',
          'Patient/' + this.patientId, null, 'http://loinc.org');
      }
      case '68518-0': {
        return new Observation('social-history', '68518-0', 'Uso di alcolici', 'http://loinc.org',
          'Patient/' + this.patientId, null, 'http://loinc.org');
      }
      case 'alimentation': {
        return new Observation('social-history', 'alimentation', 'Alimentazione', 'http://loinc.org', 'Patient/' + this.patientId, '');
      }
      case 'physical-activity': {
        return new Observation('social-history', 'physical-activity', 'Attività  fisica', 'http://loinc.org',
          'Patient/' + this.patientId, '');
      }
      case '11295-3': {
        return new Observation('social-history', '11295-3', 'Attività  lavorativa', 'http://loinc.org', 'Patient/' + this.patientId, '');
      }
      case 'patient-marital-status': {
        return new Observation('social-history', 'patient-marital-status', 'maritalStatus', 'http://loinc.org',
          'Patient/' + this.patientId, null, 'http://hl7.org/fhir/v3/MaritalStatus');
      }
      default:
        return null;
    }
  }
  /** Se l'Observation non esiste la crea */
  checkOrCreate(input: Observation[]): Observation[] {
    const ret = new Array<Observation>();
    this.allObservationCode.forEach(elem => {
      const find = input.find(x => x.code.coding[0].code === elem);
      if (!find) {
        const obs = this.getObservation(elem);
        if (obs) {
          ret.push(obs);
        }
      } else {
        if (find.code.coding[0].code === '11295-3' && !find.valueString) {
          find.valueString = '';
        }
        ret.push(find);
      }
    });
    if (this.observation != undefined && !this.isInVisite) {
      if (this.tempObj != null)
        this.logService.getAllOpt([this.observation, this.tempObj], this.CODE);

    }
    return ret;
  }

  updateObsByForm(obs: Observation[], form: FormGroup): Observation[] {
    const ret = new Array<Observation>();
    Object.keys(form.controls).forEach(el => {
      const find = obs.find(x => x.code.coding[0].code === el);
      if (find) {
        if (find.valueCodeableConcept) {
          const option = this.getOptionsByControl(el).find(x => x.value === form.get(el).value);
          find.valueCodeableConcept.coding[0].code = form.get(el).value;
          if (option) {
            find.valueCodeableConcept.coding[0].display = option.label;
          }
        } else if (find.valueString != null && find.valueString !== undefined) {
          find.valueString = form.get(el).value;
        }
        ret.push(find);
      }
    });
    return ret;
  }

  getLastClinicalImpression() {
    if (this.isInVisite) {
      this.tempLastCIPath = endpoints.getLastClinicalImpressionByPatientIdVisite;
    } else {
      this.tempLastCIPath = endpoints.getLastClinicalImpressionByPatientIdDettagli;
    }
    this.http.get(this.tempLastCIPath + '/' + this.patientId).toPromise()
      .then((resp: any) => {
        this.lastVisit = undefined;
        if (resp.total > 0) {
          this.lastVisit = resp.entry[0].resource;
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel caricamento dei Dati.');
      });
  }

  isLastVisit(): boolean {
    if (this.lastVisit) {
      // tslint:disable-next-line: triple-equals
      if (this.clinicalImpressionId == this.lastVisit.id) {
        return true;
      }
    }
    return false;
  }

  // Controlla il permesso di modifica
  checkPermessoModifica(): boolean {
    if (!this.isInVisite) {
      return this.userService.checkPermission('Dettagli', 'modify');
    } else {
      return this.userService.checkPermission('Visite', 'modify') && this.userService.checkPermission('Osservazioni', 'modify');
    }
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return !this.stileDiVitaForm.dirty;
  }

}
