<div class="card">
    <div class="card-header border-0">
        <div class="p-fluid">
            <h3 class="card-title">Pulsossimetro in dettaglio</h3>
            <div class="to-right">
                <div class="row">
                    <div class="col-md-12" style="width: 10%;">
                        <p-dropdown [options]="opt" [(ngModel)]="selected" dropdownIcon="fas fa-chevron-down"
                            (onChange)="dropdownChange($event)"></p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div id="wrapper">
            <div #chart1></div>
            <div #chart2></div>
        </div>
    </div>
</div>