import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { Observable, forkJoin } from 'rxjs';
import { checkIsModified, Observation, updateFormByObs } from 'src/app/model/Observation';
import { UserService } from 'src/app/service/user-service.service';
import { endpoints } from 'src/endpoint/endpoints';
import { LogEventService } from '../../service/log-event.service';
import { PazientiService } from '../../service/pazienti.service';
import { alvoOpt, autonomiaOpt, deambulazioneOpt, diuresiMinzioneOpt, siNoOpt, sonnoOpt } from './model/stato-di-salute-options';
import { CanComponentDeactivate } from 'src/app/service/unsaved-changes-guard.service';

@Component({
  selector: 'app-stato-di-salute-tab',
  templateUrl: './stato-di-salute-tab.component.html',
  styleUrls: ['./stato-di-salute-tab.component.scss']
})
export class StatoDiSaluteTabComponent implements OnInit, CanComponentDeactivate {

  isInVisite = false;
  lastVisit;
  tempPutPath = "";
  tempPostPath = "";
  tempLastCIPath = "";
  tempHealthStatusPath = "";
  statoDiSaluteForm: FormGroup;

  deambulazioneOpt: SelectItem[];
  autonomiaOpt: SelectItem[];
  siNoOpt: SelectItem[];
  alvoOpt: SelectItem[];
  diuresiMinzioneOpt: SelectItem[];
  sonnoOpt: SelectItem[];
  patientId;
  clinicalImpressionId;
  /** Variabile che contiene da response dal dettaglio paziente */
  statoDiSaluteObsPatient: Observation[];
  statoDiSaluteObs: Observation[];

  tempObj: any;
  constructor(private http: HttpClient, private pazientiService: PazientiService, private fb: FormBuilder,
    private toastr: ToastrService, private route: ActivatedRoute, private userService: UserService,
    private logService: LogEventService
  ) {
  }

  ngOnInit() {
    this.getIsInVisite();
    this.buildForm();
    this.patientId = this.pazientiService.getPatientId();
    if (this.isInVisite) {
      this.getHealthStatusByClinicalImpressionId();
    } else {
      this.getHealthStatusByPatientId();
    }

    this.getLastClinicalImpression();

    this.deambulazioneOpt = deambulazioneOpt;
    this.autonomiaOpt = autonomiaOpt;
    this.siNoOpt = siNoOpt;
    this.alvoOpt = alvoOpt;
    this.diuresiMinzioneOpt = diuresiMinzioneOpt;
    this.sonnoOpt = sonnoOpt;
  }

  getIsInVisite() {
    const cId = this.route.parent.snapshot.paramMap.get('vId');
    if (cId) {
      this.isInVisite = true;
      this.clinicalImpressionId = cId;
      this.tempObj = [{ id: this.clinicalImpressionId, resourceType: "ClinicalImpression" }];
      this.logService.newEvent("ClinicalImpression/" + this.clinicalImpressionId + "/_history/1", null, false, this.userService.getUser().username).then((resp: any) => {
        //   this.logService.getAllOpt([this.tempObj],null);
      });
    }
  }

  buildForm() {
    this.statoDiSaluteForm = this.fb.group({
      '57251-1': [''],
      autonomia: [''],
      nutrizione: [''],
      '72527-5': [''],
      '80263-7': [''],
      '80332-0': [''],
      '82235-3': [''],
    });
    if (!this.checkPermessoModifica()) {
      this.statoDiSaluteForm.disable();
    }
  }

  salva() {
    if (this.isInVisite) {
      const obsToSave = checkIsModified(this.statoDiSaluteForm, this.statoDiSaluteObs);
      if (obsToSave.length === 0) {
        this.toastr.info('Nulla da salvare.');
        return;
      }
      var tempObj;
      var newEvent;
      var tempId;
      obsToSave.forEach((element: any) => {
        if (element.meta != undefined) {
          tempId = Number(element.meta.versionId) + 1;
          tempObj = "Observation/" + element.id + "/_history/" + tempId; // +1
          this.http.post(endpoints.checkLogEventResource, tempObj).toPromise()
            .then((resp: any) => {
              if (!resp) {
                newEvent = { utente: this.userService.getUser().username, risorsa: tempObj, effective_date: new Date().toUTCString(), tipologia: "PUT", code: null };
                this.http.post(endpoints.logNewEvent, newEvent).toPromise();
              };
            });
        }
      });
      const obsPostUpdate = this.updateObsByForm(obsToSave, this.statoDiSaluteForm);
      const obsForUpdate = obsPostUpdate.filter(elem => {
        if (elem.id) {
          return true;
        } else {
          return false;
        }
      });
      if (obsForUpdate && obsForUpdate.length > 0) {
        forkJoin(obsForUpdate.map(el => {
          return this.putObservation(el.id, el);
        })).subscribe((resp: Observation[]) => {
          this.getHealthStatusByClinicalImpressionId();
          this.toastr.success('Aggiornamento effettuato con successo.');
        }, (err) => {
          console.error(err);
          this.toastr.error('Errore nell\'aggiornamento dei dati.', 'Errore');
        });
      }
      const obsForAdd = obsPostUpdate.filter(elem => {
        if (!elem.id) {
          return true;
        } else {
          return false;
        }
      });
      if (obsForAdd && obsForAdd.length > 0) {
        let input = {} as any;
        input = {
          clinicalImpressionId: this.clinicalImpressionId,
          observations: [],
          code: {
            coding: {
              code: 'health-status',
              system: 'http://hl7.org/fhir/observation-category'
            }
          }
        };
        if (this.isLastVisit()) {
          input.updatePatient = this.patientId;
        }
        obsForAdd.forEach(elem => {
          const obs = {} as any;
          obs.category = {} as any;
          obs.category.coding = elem.category[0].coding[0];
          obs.code = {} as any;
          obs.code.coding = elem.code.coding[0];
          obs.value = {} as any;
          obs.value.units = '';
          if (elem.valueCodeableConcept) {
            obs.value.type = 'concept';
            obs.value.value = {} as any;
            obs.value.value.coding = elem.valueCodeableConcept.coding[0];
          } else if (elem.valueString != null || elem.valueString !== undefined) {
            obs.value.type = 'string';
            obs.value.value = {} as any;
            obs.value.value = elem.valueString;
          }
          input.observations.push(obs);
        });
        this.addInvestigation(input);
      }
    } else {
      const obsToSave = checkIsModified(this.statoDiSaluteForm, this.statoDiSaluteObs);
      const obsPostUpdate = this.updateObsByForm(obsToSave, this.statoDiSaluteForm);
      forkJoin(obsPostUpdate.map(el => {
        if (el.id) {
          return this.putObservation(el.id, el);
        } else {
          return this.postObservation(el);
        }
      })).subscribe((resp: Observation[]) => {
        //updateFormByObs(resp, this.statoDiSaluteForm);
        this.getHealthStatusByPatientId();
        this.toastr.success('Aggiornamento effettuato con successo.');
      }, (err) => {
        console.error(err);
        this.toastr.error('Errore nell\'aggiornamento dei dati.', 'Errore');
      });
    }
  }

  getHealthStatusByPatientId() {
    if (this.isInVisite) {
      this.tempHealthStatusPath = endpoints.getHealthStatusByPatientIdApiVisite;
    } else {
      this.tempHealthStatusPath = endpoints.getHealthStatusByPatientIdApiDettagli;
    }
    this.http.get(this.tempHealthStatusPath + '/' + this.patientId).toPromise()
      .then((resp: any) => {
        this.statoDiSaluteObsPatient = new Array<Observation>();
        if (resp) {
          if (resp.entry) {
            if (this.isInVisite && this.isLastVisit()) {
              resp.entry.forEach(element => {
                if (element.resource.id != null && element.resource.meta != null) {
                  this.logService.newEventNoCheck(element.resource.resourceType + "/" + element.resource.id + "/_history/" + element.resource.meta.versionId, null, null, this.userService.getUser().username);
                }
              });
            }
            if (resp.entry.length > 0) {
              resp.entry.forEach(element => {
                this.statoDiSaluteObsPatient.push(element.resource);
              });
            }
          }
        }
        this.statoDiSaluteObs = this.checkOrCreate(this.statoDiSaluteObsPatient);
        updateFormByObs(this.statoDiSaluteObs, this.statoDiSaluteForm);
        this.tempObj = [this.statoDiSaluteObs];
        this.logService.getAllOpt(this.tempObj, null)
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel caricamento dello Stato di Salute.');
      });
  }

  getHealthStatusByClinicalImpressionId() {
    this.http.get(endpoints.getHealthStatusByCIVisite + this.clinicalImpressionId).toPromise()
      .then((resp: any) => {
        if (resp && resp.success === '1') {
          if (resp.message) {
            this.statoDiSaluteObs = resp.message;
          }
          this.statoDiSaluteObs = this.checkOrCreate(this.statoDiSaluteObs);
          updateFormByObs(this.statoDiSaluteObs, this.statoDiSaluteForm);
        } else {
          this.toastr.error('Errore nel caricamento dello Stato di Salute.');
        }
        this.logService.getAllOpt([this.tempObj, this.statoDiSaluteObs], null);
      })
      .catch(err => {
        console.error(err);
        this.toastr.error('Errore nel caricamento dello Stato di Salute.');
      });
  }

  /**
   * chiamata http PUT observation
   */
  putObservation(id, obsr) {
    const obj = {} as any;
    obj.id = obsr.id;
    if (this.isLastVisit()) {
      obj.updatePatient = this.patientId;
    }
    if (obsr.valueString || obsr.valueCodeableConcept.coding[0].code === 'nutrizione') {
      obj.value = {
        type: 'string',
        value: obsr.valueString
      };
    } else {
      obj.value = {
        type: 'concept',
        value: {
          coding: {
            code: obsr.valueCodeableConcept.coding[0].code,
            display: obsr.valueCodeableConcept.coding[0].display,
            system: obsr.valueCodeableConcept.coding[0].system
          }
        }
      };
    }
    return this.http.put(endpoints.updateObservationDettagli, obj);
  }

  /**
   * chiamata http POST observation
   */
  postObservation(obsr) {
    if (!this.isInVisite) {
      this.tempPostPath = endpoints.postApiObservationDettagli;
    } else {
      this.tempPostPath = endpoints.postApiObservationVisite;
    }
    return this.http.post(this.tempPostPath, obsr);
  }

  addInvestigation(input) {
    var tempPath;
    if (this.isInVisite) {
      tempPath = endpoints.addInvestigationVisite;
    } else {
      tempPath = endpoints.addInvestigationDettagli;
    }
    this.http.post(tempPath, input).toPromise()
      .then((resp: any) => {
        if (resp && resp.success === '1') {
          this.getHealthStatusByClinicalImpressionId();
          if (this.isLastVisit()) {
            this.getHealthStatusByPatientId();
          }
          this.toastr.success('Aggiornamento effettuato con successo.');
        } else {
          console.error(resp.message);
          this.toastr.error('Errore nell\'aggiornamento dei dati.', 'Errore');
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nell\'aggiornamento dei dati.', 'Errore');
      });
  }

  /** Se l'Observation non esiste la crea */
  checkOrCreate(input: Observation[]): Observation[] {
    const ret = new Array<Observation>();
    Object.keys(this.statoDiSaluteForm.controls).forEach(elem => {
      const find = input.find(x => x.code.coding[0].code === elem);
      if (!find) {
        const obs = this.createObservation(elem);
        if (obs) {
          ret.push(obs);
        }
      } else {
        // caso speciale
        if (find.code.coding[0].code === 'nutrizione' && !find.valueString) {
          find.valueString = '';
        }
        ret.push(find);
      }
    });
    return ret;
  }

  createObservation(key): Observation {
    switch (key) {
      case '57251-1':
        return new Observation('health-status', key, 'Deambulazione', 'http://loinc.org',
          'Patient/' + this.patientId, null, 'http://loinc.org');
      case 'autonomia':
        return new Observation('health-status', key, 'Autonomia', 'http://loinc.org',
          'Patient/' + this.patientId, '');
      case 'nutrizione':
        return new Observation('health-status', key, 'Nutrizione', 'http://loinc.org',
          'Patient/' + this.patientId, '');
      case '72527-5':
        return new Observation('health-status', key, 'Decubito', 'http://loinc.org',
          'Patient/' + this.patientId, null, 'http://loinc.org');
      case '80263-7':
        return new Observation('health-status', key, 'Alvo', 'http://loinc.org',
          'Patient/' + this.patientId, null, 'http://loinc.org');
      case '80332-0':
        return new Observation('health-status', key, 'Diuresi', 'http://loinc.org',
          'Patient/' + this.patientId, null, 'http://loinc.org');
      case '82235-3':
        return new Observation('health-status', key, 'Sonno', 'http://loinc.org',
          'Patient/' + this.patientId, null, 'http://loinc.org');
      default:
        return null;
    }
  }

  getOptByKey(key) {
    switch (key) {
      case '57251-1':
        return this.deambulazioneOpt;
      case 'autonomia':
        return this.autonomiaOpt;
      case '72527-5':
        return this.siNoOpt;
      case '80263-7':
        return this.alvoOpt;
      case '80332-0':
        return this.diuresiMinzioneOpt;
      case '82235-3':
        return this.sonnoOpt;
    }
  }

  updateObsByForm(obs: Observation[], form: FormGroup): Observation[] {
    const ret = new Array<Observation>();
    Object.keys(form.controls).forEach(el => {
      const find = obs.find(x => x.code.coding[0].code === el);
      if (find) {
        if (find.valueCodeableConcept) {
          const option = this.getOptByKey(el).find(x => x.value === form.get(el).value);
          find.valueCodeableConcept.coding[0].code = form.get(el).value;
          if (option) {
            find.valueCodeableConcept.coding[0].display = option.label;
          }
        } else if (find.valueString != null && find.valueString !== undefined) {
          find.valueString = form.get(el).value;
        }
        ret.push(find);
      }
    });
    return ret;
  }

  getLastClinicalImpression() {
    if (this.isInVisite) {
      this.tempLastCIPath = endpoints.getLastClinicalImpressionByPatientIdVisite;
    } else {
      this.tempLastCIPath = endpoints.getLastClinicalImpressionByPatientIdDettagli;
    }
    this.http.get(this.tempLastCIPath + '/' + this.patientId).toPromise()
      .then((resp: any) => {
        this.lastVisit = undefined;
        if (resp.total > 0) {
          this.lastVisit = resp.entry[0].resource;
        }
      }).catch(err => {
        console.error(err);
        this.toastr.error('Errore nel caricamento dei Dati.');
      });
  }

  isLastVisit(): boolean {
    if (this.lastVisit) {
      // tslint:disable-next-line: triple-equals
      if (this.clinicalImpressionId == this.lastVisit.id) {
        return true;
      }
    }
    return false;
  }

  checkPermessoModifica(): boolean {
    if (!this.isInVisite) {
      return this.userService.checkPermission('Dettagli', 'modify');
    } else {
      return this.userService.checkPermission('Visite', 'modify') && this.userService.checkPermission('Osservazioni', 'modify');
    }
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    const obsToSave = checkIsModified(this.statoDiSaluteForm, this.statoDiSaluteObs);
    if (obsToSave != undefined && obsToSave.length > 0) {
      return false;
    }
    return true;
  }


}
