import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { HelpModalComponent } from '../modals/help-modal/help-modal.component';

@Component({
  selector: 'app-lista-manuali',
  templateUrl: './lista-manuali.component.html',
  styleUrls: ['./lista-manuali.component.scss']
})
export class ListaManualiComponent implements OnInit {

  colonne = [
    { name: 'Manuale', prop: 'manuale' },
    { name: 'Versione prodotto', prop: 'versione' },
    { name: 'Data ultimo aggiornamento manuale', prop: 'data_ultimo_aggiornamento_file' },
    { name: 'Revisione manuale', prop: 'revisione' },
    { name: 'Lingua', prop: 'lingua' },
    { name: 'PDF', prop: 'link' },
  ];

  manuali = [
    { name: 'Manuale Medico', versione: '3.0.0', data: '27/07/2024', revisione: '07', lingua: 'ITA', link: '/assets/manuale_medico.pdf' },
  ];

  constructor(private router: Router) { }

  ngOnInit(): void {

  }

}
