<div class="card">
    <div class="card-header border-0" *ngIf="showHeader">
        <ng-container *ngIf="onlyMonitoringEvents">
            <div class="row">
                <div class="col-10">
                    <h3 class="card-title">Pulsossimetro {{onlyMonitoringEvents ? '(eventi piano di monitoraggio)'
                        : ''}}</h3>
                </div>
                <div class="col-2">
                    <p-dropdown [options]="pulseoximeterOptions" [(ngModel)]="selectedOption" placeholder=""
                        [group]="false" [autoDisplayFirst]="false" (onChange)="dropdownChange($event)"></p-dropdown>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!onlyMonitoringEvents">
            <div class="p-fluid">
                <h3 class="card-title">Pulsossimetro {{onlyMonitoringEvents ? '(eventi piano di monitoraggio)' :
                    ''}}
                </h3>
            </div>
        </ng-container>
    </div>
    <div class="card-body">
        <div class="row" *ngIf="!showHeader">
            <div class="col-12">
                <div class="to-right">
                    <h6 *ngIf="onlyLast">Ultima misurazione: {{timestamp != undefined ? (timestamp[0] |
                        date:"dd/MM/yyyy hh:mm") : "Non disponibile"}}</h6>
                    <h6 *ngIf="!onlyLast">Intero periodo selezionato</h6>
                </div>
            </div>
        </div>
        <div id="wrapper">
            <div #chart3></div>
        </div>
    </div>
</div>