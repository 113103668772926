import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { itLocale } from 'ngx-bootstrap/locale';
import { ToastrService } from 'ngx-toastr';
import { ValidatorsManager } from 'src/app/model/ValidatorsManager';
import { UserService } from 'src/app/service/user-service.service';
import { capitalize } from 'src/app/utils/utils';
import { endpoints } from 'src/endpoint/endpoints';
import { gender } from '../../administration-panel/domain/gender.enum';
import { RelatedPerson } from '../model/relatedPerson';

@Component({
  selector: 'app-related-person-component',
  templateUrl: './related-person-component.component.html',
  styleUrls: ['./related-person-component.component.scss']
})
export class RelatedPersonComponentComponent implements OnInit, OnDestroy {

  relatedPersonForm: FormGroup;

  relatedPerson = {} as RelatedPerson;

  gender = gender;

  relationshipOpt = [
    { value: '', label: 'Seleziona una opzione', disabled: true },
    { value: 'SONC', label: 'Figlio' },
    { value: 'DAUC', label: 'Figlia' },
    { value: 'WIFE', label: 'Moglie' },
    { value: 'HUSB', label: 'Marito' },
    { value: 'FTH', label: 'Padre' },
    { value: 'MTH', label: 'Madre' },
    { value: 'FAMMEMB', label: 'Familiare' },
    { value: 'FRND', label: 'Amico' }
  ];

  datePickerConf = { isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-dark-blue' };

  locale = 'it';

  @Input('related') set related(related) {
    if (related) {
      this.relatedPerson = related;
    }
    this.buildForm(this.relatedPerson);
  }

  id = undefined;

  @Input() section;

  @Input('id') set pstientId(patId) {
    this.id = patId;
  }

  subscribeObj = null;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onUpdate = new EventEmitter<boolean>();

  validatorsManager: ValidatorsManager = new ValidatorsManager();

  @Output() valueChangedEmitter = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private toastr: ToastrService, private localeService: BsLocaleService,
    private http: HttpClient, private datePipe: DatePipe, private userService: UserService) { }

  ngOnInit() {
    itLocale.invalidDate = 'Data non valida';
    defineLocale(this.locale, itLocale);
    this.localeService.use(this.locale);
    if (this.related) {
      this.relatedPerson = this.related;
    }
    this.buildForm(this.relatedPerson);
  }

  buildForm(relatedIn) {

    // Popolo la lista di campi obbligatori
    // NOTA: Usare il formControlName
    this.validatorsManager.addCampoObbligatorio("birthDate");
    this.validatorsManager.addCampoObbligatorio("relationship");
    this.validatorsManager.addCampoObbligatorio("gender");
    this.validatorsManager.addCampoObbligatorio("name");
    this.validatorsManager.addCampoObbligatorio("phone");

    relatedIn.gender = capitalize(relatedIn.gender);

    this.relatedPersonForm = this.fb.group({
      name: [relatedIn.name],
      surname: [relatedIn.surname],
      phone: [relatedIn.phone],
      email: [relatedIn.email],
      refPatient: [''],
      fiscalCode: [relatedIn.fiscalCode],
      birthDate: [relatedIn.birthDate],
      address: [relatedIn.address],
      birthPlace: [null],
      relationship: [relatedIn.relationship],
      gender: [relatedIn.gender + ''],
      id: [''],
      active: [true]
    }, { validators: this.ValidateDate.bind(this) });
    // Aggiungo i validators obbligatori ai campi obbligatori
    this.validatorsManager.form = this.relatedPersonForm;
    this.validatorsManager.aggiungiValidatorObbligatori();
    this.validatorsManager.setAllValidatorsToForm();

    this.relatedPersonForm.markAsPristine();

    this.relatedPersonForm.valueChanges.subscribe(v => {
      this.valueChangedEmitter.emit(true);
    });

  }

  isRequired(formControlName: string): boolean {
    return this.validatorsManager.isRequired(formControlName);
  }

  ngOnDestroy(): void { }

  close() {
    this.onUpdate.emit(true);
  }

  ValidateDate(control: AbstractControl) {
    if (control.get('birthDate').value && !moment(control.get('birthDate').value, 'DD/MM/YYYY', true).isValid()) {
      this.toastr.error('Data non valida.');
    }
  }

  salva() {
    if (this.relatedPersonForm.invalid) {
      this.relatedPersonForm.markAllAsTouched();
      this.toastr.warning('Compilare i campi obbligatori del caregiver');
      return;
    }
    const pat: RelatedPerson = this.relatedPersonForm.getRawValue();
    pat.birthDate = this.adattaData(pat.birthDate);
    pat.refPatient = 'Patient/' + this.id;
    if (!this.relatedPerson.id) {
      this.http.post(endpoints.createRelatedPerson, pat).toPromise().then((resp: any) => {
        if (resp) {
          if (resp.status === 'OK') {
            // this.selected = resp.object;
            this.onUpdate.emit(true);
            this.toastr.success('Caregiver modificato correttamente');
          } else {
            this.onUpdate.emit(false);
            this.toastr.error(resp.message);
          }
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel salvataggio del caregiver.');
      });
    } else {
      pat.id = this.relatedPerson.id;
      pat.birthDate = this.relatedPersonForm.getRawValue().birthDate;
      if (JSON.stringify(pat) === JSON.stringify(this.relatedPerson)) {
        this.toastr.info("Nessuna modifica apportata.");
        this.onUpdate.emit(true);
        return;
      }
      pat.birthDate = this.adattaData(pat.birthDate);
      this.http.put(endpoints.updateRelatedPerson + '' + this.relatedPerson.id, pat).toPromise().then((resp: any) => {
        if (resp) {
          if (resp.status === 'OK') {
            // this.selected = resp.object;
            this.onUpdate.emit(true);
            this.toastr.success('Caregiver modificato correttamente');
          } else {
            this.onUpdate.emit(false);
            this.toastr.error(resp.message);
          }
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel salvataggio del caregiver.');
      });
    }


  }


  adattaData(date): any {
    if (typeof date === 'string') {
      const dateParts = date.split('/');
      // tslint:disable-next-line: radix
      const dateToTrans = new Date(Number.parseInt(dateParts[2]), Number.parseInt(dateParts[1]) - 1, Number.parseInt(dateParts[0]));
      return this.datePipe.transform(dateToTrans, 'yyyy-MM-dd');
    } else {
      return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

  }

  checkPermessoModifica() {
    return this.userService.checkPermission('Anagrafica pazienti', 'modify');
  }

}
