import { BreakpointState } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { gender } from 'src/app/main/administration-panel/domain/gender.enum';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { AppService } from 'src/app/service/app.service';
import { UserService } from 'src/app/service/user-service.service';
import { SanitizePipe } from 'src/app/utils/sanitize.pipe';
import { generateRandom } from 'src/app/utils/utils';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../../service/pazienti.service';

@Component({
  selector: 'app-paziente-card',
  templateUrl: './paziente-card.component.html',
  styleUrls: ['./paziente-card.component.scss']
})
export class PazienteCardComponent implements OnInit, OnDestroy {

  @Input() set id(id) {
    this.id$ = id;
    if (this.id$) {
      this.getPatient();
    }
  }
  @Input() set showModifyDelete(showModifyDelete) {
    this.modifyDelete = showModifyDelete;
  }
  @Input() set update(update: EventEmitter<any>) {
    this.update$ = update;
    if (update) {
      update.subscribe(() => this.getPatient());
      this.getPatient();
    }
  }

  update$: EventEmitter<any>;
  id$;

  @Output() modifica = new EventEmitter<boolean>();

  modify;

  paziente;

  modifyDelete = true;

  random = [];

  confermaModalRef: BsModalRef;

  observer;
  mobile;
  mobileYear;

  nomePaziente;
  cognomePaziente;

  constructor(private http: HttpClient, private toastr: ToastrService, private sanitize: SanitizePipe,
    private modalService: BsModalService, private router: Router, private pazientiService: PazientiService,
    private userService: UserService, private appSrv: AppService) {
    this.random = generateRandom(5);
    this.observer = this.appSrv.mdAppObs
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobile = true;
        } else {
          this.mobile = false;
        }
      });
    this.appSrv.mdPlusObs
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.mobileYear = true;
        } else {
          this.mobileYear = false;
        }
      });
  }

  ngOnInit() {
    this.modify = false;
    this.getPatient();
  }

  ngOnDestroy(): void {
    if (this.update$) {
      this.update$.unsubscribe();
    }
    if (this.observer) {
      this.observer.unsubscribe();
    }
  }

  getPatient() {
    if (this.id$) {
      this.http.get(endpoints.getPatientByPatientId + this.id$).toPromise().then((resp: any) => {
        if (resp && resp.success == "1") {
          this.paziente = resp.message;
          this.pazientiService.setPatient(resp.message);
          this.nomePaziente = this.pazientiService.getPatientNameOrEmpty();
          this.cognomePaziente = this.pazientiService.getPatientSurnameOrEmpty();
        }
      }).catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nel retrive del Paziente', err);
      });
    }
  }

  showModifica() {
    this.modify = !this.modify;
    this.modifica.emit(this.modify);
  }

  getSanitizedPhoto() {
    if (this.paziente && this.paziente.photo) {
      return this.sanitize.transform('url(data:image/png;base64,' + this.paziente.photo[0].data + ')', 'style');
    } else {
      return 'url(\'./assets/img/patient.png\')';
    }
  }

  getIdentifier() {
    let ret = '';
    if (this.paziente && this.paziente.identifier) {
      for (const element of this.paziente.identifier) {
        if (element.use === 'usual') {
          ret = element.value;
        }
      }
    }
    return ret;
  }

  getGender() {
    if (this.paziente) {
      switch (this.paziente.gender) {
        case gender.FEMALE.toLowerCase():
          return 'Femmina';
        case gender.MALE.toLowerCase():
          return 'Maschio';
        case gender.UNKNOW.toLowerCase():
          return 'Sconosciuto';
        case gender.OTHER.toLowerCase():
          return 'Altro';
        default:
          return '';
      }
    }
  }

  cancella() {
    const initialState = { message: 'Sei sicuro di voler eliminare il Paziente?' };
    this.confermaModalRef = this.modalService.show(ConfirmModalComponent, { initialState });
    const newSubscriber = this.modalService.onHide.subscribe(r => {
      newSubscriber.unsubscribe();
      const res = this.confermaModalRef.content.result;
      if (res) {
        this.cancellaOn();
      }
    });
  }

  cancellaOn() {
    this.http.get(endpoints.removePatient + '?patientId=' + this.id$).toPromise()
      .then((resp: any) => {
        if (resp) {
          if (resp.success === '1') {
            this.pazientiService.resetPatient();
            this.toastr.success('Eliminazione avvenuta con successo.', 'Successo');
            this.router.navigate(['/main/pazienti/listapazienti']);
          } else {
            this.toastr.error('Errore nell\'eliminazione del paziente.', 'Errore');
          }
        } else {
          this.toastr.error('Errore nell\'eliminazione del paziente.', 'Errore');
        }

      })
      .catch(err => {
        console.error(err);
        if (err.status == 403) {
          this.toastr.error("Non si dispone dei permessi necessari per eseguire questa operazione.");
          return;
        }
        this.toastr.error('Errore nell\'eliminazione del paziente.', 'Errore');
      });
  }

  checkPermessoModifica() {
    return this.userService.checkPermission('Anagrafica pazienti', 'modify');
  }

  checkPermessoElimina() {
    return this.userService.checkPermission('Anagrafica pazienti', 'delete');
  }

}
