<div class="row" style="margin-top:5%">
    <div class="col-lg-5 offset-lg-4 col-md-8 offset-md-2">
        <p-table [value]="righe" class="noBorder">
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="4">
                        <div class="row">
                            <div class="col-4">
                                <img src="assets/img/Logo-Login1.png" alt="Logo RiAtlas HEALTHCARE" width="100%">
                            </div>
                            <div class="col-2" style="text-align:right">
                                <img src="assets/img/iconaEtichetta1.png" alt="Manufacturer" width="100%"
                                    style="margin-top: -10%">
                            </div>
                            <div class="col-6 noSpace">
                                <label class="displayBlock" *ngFor="let riga of infoRiAtlas">{{riga}}</label>
                            </div>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <ng-container *ngFor="let colData of rowData">
                        <td class="alignContentRight">
                            <img src="{{colData['symbol']}}">
                        </td>
                        <td>
                            <a href="{{colData['link']}}" *ngIf="colData['link'] != undefined"
                                target="_blank">{{colData['description']}}</a>
                            <p class="middle" *ngIf="colData['link'] == undefined && !colData['isMultiline']">
                                {{colData['description']}}</p>
                            <ng-container *ngIf="colData['link'] == undefined && colData['isMultiline']">
                                <label class="middle" *ngFor="let r of colData['description']">{{r}}</label>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
            <!-- <ng-template pTemplate="footer" let-columns>
                <tr>
                    <td colspan="4">
                        <p></p>
                        <p *ngFor="let c of testoSotto">
                            {{c}}
                        </p>
                    </td>
                </tr>
            </ng-template> -->
        </p-table>
    </div>
</div>


<!-- <div class="row mt-1">
    <div class="col-lg-5 offset-lg-4 col-md-8 offset-md-2">
        {{revisione}}
    </div>
</div> -->