<div class="card" *ngIf="paziente">
    <div class="card-header bg-primary">Paziente</div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-12 d-flex justify-content-center mb-2">
                <div class="avatar-preview">
                    <div id="imagePreview{{random[2]}}" [style.background-image]="getSanitizedPhoto()"></div>
                </div>
            </div>
        </div>


        <h3 class="profile-username text-center">
            {{nomePaziente}}
            {{cognomePaziente}}</h3>

        <p class="text-muted text-center">ID: {{getIdentifier()}}</p>
        <div class="row">
            <div class="col-md-12">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <b>Genere </b> <a [ngClass]="{'float-right': !mobile}">{{getGender()}}</a>
                    </li>
                    <li class="list-group-item">
                        <b>Data di Nascita </b> <a [ngClass]="{'float-right': !mobileYear}">{{paziente.birthDate | date:
                            'dd/MM/yyyy'}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card-footer" *ngIf="modifyDelete">
        <div *ngIf="!mobile">
            <button *ngIf="checkPermessoModifica()" pButton label="Modifica" class="p-button-primary" icon="fas fa-edit"
                iconPos="left" type="button" (click)="showModifica()"></button>
            <div class="to-right">
                <button *ngIf="checkPermessoElimina()" pButton label="Elimina" class="p-button-danger"
                    icon="fas fa-trash" iconPos="left" type="button" (click)="cancella()"></button>
            </div>
        </div>
        <div *ngIf="mobile">
            <button *ngIf="checkPermessoModifica()" pButton class="p-button-primary" icon="fas fa-edit" iconPos="left"
                type="button" (click)="showModifica()"></button>
            <div class="to-right">
                <button *ngIf="checkPermessoElimina()" pButton class="p-button-danger" icon="fas fa-trash"
                    iconPos="left" type="button" (click)="cancella()"></button>
            </div>
        </div>
    </div>
</div>