import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { ConfirmModalComponent } from 'src/app/modals/confirm-modal/confirm-modal.component';
import { Page } from 'src/app/model/page';
import { ValidatorsManager } from 'src/app/model/ValidatorsManager';
import { endpoints } from 'src/endpoint/endpoints';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomizationService } from 'src/app/service/customization.service';

@Component({
  selector: 'app-pannello-device',
  templateUrl: './pannello-device.component.html',
  styleUrls: ['./pannello-device.component.scss']
})
export class PannelloDeviceComponent implements OnInit {

  ColumnMode = ColumnMode;
  page = new Page();
  pageRef;
  count = 10;
  first = 0;
  loading: boolean;
  rows = [];
  currentId;
  validatorsManager: ValidatorsManager = new ValidatorsManager();
  status: SelectItem[];
  displayBasic: boolean;
  devicesForm;
  misurazioniOpt;
  confermaModalRef: BsModalRef;

  parametriQuery = {
    count: null,
    pageoffset: null
  };

  colonne = [
    { name: 'Modello', prop: 'model' },
    { name: 'ID device', prop: 'id' },
    { name: 'Mac device', prop: 'mac' },
    { name: 'Stato device', prop: 'status' },
  ];

  classiDispositivoMDR = [];
  tipiCollegamento = [];
  tipiAlimentazione = [];

  constructor(private http: HttpClient, private fb: FormBuilder, private customizationService: CustomizationService,
    private toastr: ToastrService, private modalService: BsModalService,) { }

  ngOnInit(): void {

    // Recupero i valori dei dropdown
    this.classiDispositivoMDR = this.customizationService.getClassiDispositivo();
    this.tipiCollegamento = this.customizationService.getTipiDiCollegamento();
    this.tipiAlimentazione = this.customizationService.getTipiDiAlimentazione();

    this.page.size = 10;
    this.rows = [];
    this.currentId = "";
    this.status = [
      { value: '', label: 'Seleziona stato dispositivo', disabled: true },
      { value: 'active', label: 'Accoppiato' },
      { value: 'inactive', label: 'Disaccoppiato' },
    ];
    this.misurazioniOpt = [
      { label: "Frequenza cardiaca", code: "8867-4" },
      { label: "Ossigenazione", code: "59408-5" },
      { label: "Pressione sanguigna", code: "55284-4" },
      { label: "Sistolica", code: "8480-6" },
      { label: "Diastolica", code: "8462-4" },
      { label: "Temperatura corporea", code: "8310-5" },
      { label: "Peso", code: "29463-7" },
      { label: "ECG", code: "58253-6" }
    ];
    this.parametriQuery.count = this.page.size;
    this.getAll();
  }


  buildForm(device?) {

    this.validatorsManager.addValidatorByFormControlName('id', this.noWhiteSpaceValidator);
    this.validatorsManager.addValidatorByFormControlName('mac', this.noWhiteSpaceValidator);
    this.validatorsManager.addValidatorByFormControlName('manufacturer', this.noWhiteSpaceValidator);
    this.validatorsManager.addValidatorByFormControlName('model', this.noWhiteSpaceValidator);
    this.validatorsManager.addValidatorByFormControlName('version', this.noWhiteSpaceValidator);
    this.validatorsManager.addValidatorByFormControlName('produttore', this.noWhiteSpaceValidator);
    this.validatorsManager.addValidatorByFormControlName('denominazione', this.noWhiteSpaceValidator);
    this.validatorsManager.addValidatorByFormControlName('classificazione', this.noWhiteSpaceValidator);
    this.validatorsManager.addValidatorByFormControlName('descrizione', this.noWhiteSpaceValidator);
    this.validatorsManager.addValidatorByFormControlName('udi', this.noWhiteSpaceValidator);
    this.validatorsManager.addCampoObbligatorio('id');
    this.validatorsManager.addCampoObbligatorio('mac');
    this.validatorsManager.addCampoObbligatorio('status');
    this.validatorsManager.addCampoObbligatorio('manufacturer');
    this.validatorsManager.addCampoObbligatorio('model');
    this.validatorsManager.addCampoObbligatorio('version');
    this.validatorsManager.addCampoObbligatorio('misurazioni');
    this.validatorsManager.addCampoObbligatorio('produttore');
    this.validatorsManager.addCampoObbligatorio('denominazione');
    this.validatorsManager.addCampoObbligatorio('collegamento');
    this.validatorsManager.addCampoObbligatorio('alimentazione');
    this.validatorsManager.addCampoObbligatorio('classificazione');
    this.validatorsManager.addCampoObbligatorio('descrizione');
    this.validatorsManager.addCampoObbligatorio('classe');
    this.validatorsManager.addCampoObbligatorio('udi');

    this.devicesForm = this.fb.group({
      id: [''],
      mac: [''],
      status: [''],
      manufacturer: [''],
      model: [''],
      version: [''],
      produttore: [''],
      denominazione: [''],
      collegamento: [''],
      alimentazione: [''],
      classificazione: [''],
      descrizione: [''],
      classe: [''],
      udi: [''],
      misurazioni: ['']
    });
    this.validatorsManager.form = this.devicesForm;
    this.validatorsManager.aggiungiValidatorObbligatori();
    this.validatorsManager.setAllValidatorsToForm();
    if (device) {
      this.getSingoloDevice(device);
    }
  }

  setPage(pageInfo) {
    this.pageRef = pageInfo;
    this.first = this.pageRef.first;
    this.parametriQuery.pageoffset = pageInfo.first;
    this.page.pageNumber = pageInfo.first;
    this.getAll();
  }

  isRequired(formControlName: string): boolean {
    return this.validatorsManager.isRequired(formControlName);
  }

  public noWhiteSpaceValidator(control: FormControl) {
    const isWhiteSpace = (control.value || '').trim().length === 0;
    const isValid = !isWhiteSpace;
    return isValid ? null : { whitespace: null };
  }


  save() {
    this.devicesForm.markAllAsTouched();
    if (this.devicesForm.invalid) {
      this.toastr.warning('Compilare i campi obbligatori');
      return;
    }
    let device = this.devicesForm.getRawValue();
    device.misurazioni = this.multiselectToString(device.misurazioni, ";");
    device.collegamento = this.multiselectToString(device.collegamento);
    device.alimentazione = this.multiselectToString(device.alimentazione);
    if (this.currentId === "") {
      this.http.post(endpoints.saveDevice, device).toPromise()
        .then((resp: any) => {
          this.getAll();
          this.displayBasic = false;
          this.devicesForm.reset();
          this.toastr.success('Salvataggio avvenuto con successo.');
        }).catch(err => {
          if (err.status == 500) {
            this.toastr.error("Errore nel salvataggio del device,.");
            return;
          }
          else {
            this.toastr.error(err.error);
          }
        });
    } else {
      this.http.put(endpoints.updateDevice + this.currentId, device).toPromise()
        .then((resp: any) => {
          this.getAll();
          this.currentId = '';
          this.displayBasic = false;
          this.toastr.success('Modifica avvenuta con successo.');
        }).catch(err => {
          if (err.status == 500) {
            this.toastr.error("Errore nel salvataggio del device.");
            return;
          }
          else {
            this.toastr.error(err.error);
          }
        });
    }

  }


  getAll() {

    let path = endpoints.getDevices;
    for (let parametro in this.parametriQuery) {
      path += "/" + this.parametriQuery[parametro];
    }
    this.http.get(path).toPromise()
      .then((resp: any) => {
        this.page.totalElements = resp.total;
        this.page.totalPages = resp.total / 10;
        if (resp.entry == undefined) {
          resp.entry = new Array();
        }
        if (resp.entry) {
          this.rows = resp.entry.map(elem => ({
            refId: elem.resource.id,
            id: elem.resource.identifier.find(el => el.type.coding[0].code == "id_device").value,
            mac: elem.resource.identifier.find(el => el.type.coding[0].code == "mac").value,
            status: elem.resource.status,
            model: elem.resource.model,
          })
          );
        } else {
          this.rows = null;
        }
      }).catch(err => {
        if (err.status == 400) {
          this.toastr.error("Errore nel recupero dei device.");
          return;
        }
        console.error(err);
        this.toastr.error("Errore nel recupero dei device.");
      });
  }
  getAllActive() {
    this.http.get(endpoints.getActiveDevices).toPromise()
      .then((resp: any) => {
      }).catch(err => {
        if (err.status == 400) {
          this.toastr.error("Errore nel recupero dei device.");
          return;
        }
        console.error(err);
        this.toastr.error("Errore nel recupero dei device.");
      });
  }

  getSingoloDevice(device) {
    if (device.refId == null) {
      this.toastr.error("Errore nel recupero dei device.");
      return;
    }
    this.currentId = device.refId;
    this.http.get(endpoints.getDeviceByIdentifier + this.currentId).toPromise()
      .then((resp: any) => {
        this.devicesForm.get("id").setValue(resp.identifier.find(el => el.type.coding[0].code == "id_device").value);
        this.devicesForm.get("mac").setValue(resp.identifier.find(el => el.type.coding[0].code == "mac").value);
        this.devicesForm.get("status").setValue(resp.status);
        this.devicesForm.get("manufacturer").setValue(resp.manufacturer);
        this.devicesForm.get("model").setValue(resp.model);
        this.devicesForm.get("version").setValue(resp.version);
        if (resp.udi)
          resp.udi.deviceIdentifier ? this.devicesForm.get("udi").setValue(resp.udi.deviceIdentifier) : this.devicesForm.get("udi").setValue('');
        this.devicesForm.get("alimentazione").setValue('');
        this.devicesForm.get("classificazione").setValue('');
        this.devicesForm.get("classe").setValue('');
        this.devicesForm.get("denominazione").setValue('');
        this.devicesForm.get("produttore").setValue('');
        this.devicesForm.get("descrizione").setValue('');
        this.devicesForm.get("collegamento").setValue('');

        let tmpArray = undefined;

        for (let i = 0; i < resp.extension.length; i++) {
          switch (resp.extension[i].url) {
            case "http://riatlas.it/extensions#device_measurements":
              var support = this.stringToMultiselect(resp.extension[i].valueString, ";");
              this.devicesForm.get("misurazioni").setValue(this.misurazioniOpt.filter(t => support.includes(t.value ?? t.code)));
              break;
            case "http://riatlas.it/extensions#device_alimentazione":
              tmpArray = this.stringToMultiselect(resp.extension[i].valueString);
              this.devicesForm.get("alimentazione").setValue(this.tipiAlimentazione.filter(t => tmpArray.includes(t.value)));
              break;
            case "http://riatlas.it/extensions#device_classificazione":
              this.devicesForm.get("classificazione").setValue(resp.extension[i].valueString);
              break;
            case "http://riatlas.it/extensions#device_classe":
              this.devicesForm.get("classe").setValue(resp.extension[i].valueString);
              break;
            case "http://riatlas.it/extensions#device_denominazione":
              this.devicesForm.get("denominazione").setValue(resp.extension[i].valueString);
              break;
            case "http://riatlas.it/extensions#device_produttore":
              this.devicesForm.get("produttore").setValue(resp.extension[i].valueString);
              break;
            case "http://riatlas.it/extensions#device_descrizione":
              this.devicesForm.get("descrizione").setValue(resp.extension[i].valueString);
              break;
            case "http://riatlas.it/extensions#device_collegamento":
              tmpArray = this.stringToMultiselect(resp.extension[i].valueString);
              this.devicesForm.get("collegamento").setValue(this.tipiCollegamento.filter(t => tmpArray.includes(t.value)));
              break;
          }
        }

      }).catch(err => {
        if (err.status == 400) {
          this.toastr.error("Errore nel recupero dei device. cos");
          return;
        }
        console.error(err);
        this.toastr.error("Errore nel recupero dei device. cos2");
      });
  }

  deleteDevice(device) {
    if (device.refId == null) {
      this.toastr.error("Errore nel recupero dei device.");
      return;
    }
    this.http.delete(endpoints.deleteDevice + device.refId).toPromise()
      .then((resp: any) => {
        this.devicesForm.reset();
        this.getAll();
        this.currentId = "";
        this.toastr.success('Device eliminato con successo.');
      }).catch(err => {
        if (err.status == 400) {
          this.toastr.error("Errore nell'eliminazione del device.");
          return;
        }
        console.error(err);
        this.toastr.error("Errore nell'eliminazione del device.");
      });
  }

  confirmDelete(device) {
    const initialState = { message: 'Eliminare il device? La procedura NON è reversibile.' };
    this.confermaModalRef = this.modalService.show(ConfirmModalComponent, { initialState });
    const newSubscriber = this.modalService.onHide.subscribe(r => {
      newSubscriber.unsubscribe();
      const res = this.confermaModalRef.content.result;
      if (res) {
        this.deleteDevice(device);
      } else {
        this.toastr.info('Azione annullata.');
      }
    });
  }

  showBasicDialog(device?) {
    if (!device) {
      this.currentId = '';
    }
    this.buildForm(device);
    this.displayBasic = true;
  }

  resetTable() {
    this.devicesForm.reset();
  }

  multiselectToString(values, delimiter = ",") {
    let onlyValue = values.map(v => v.code ?? v.value);
    return onlyValue.join(delimiter);
  }

  stringToMultiselect(value, delimiter = ",") {
    if (!value.includes(delimiter)) {
      return [value];
    }
    return value.split(delimiter).map(v => v.trim());
  }


}



