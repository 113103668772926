import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, LOCALE_ID, OnInit, Output, ViewChild } from '@angular/core';
import { ApexOptions } from 'apexcharts';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/endpoint/endpoints';
import { PazientiService } from '../../service/pazienti.service';
import { DateFromTo } from '../Model/date-from-to';
import { SerieType } from '../Utils/grafici-utils';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { SelectItem } from 'primeng/api';
import { getTooltipByEventInformation } from 'src/app/utils/utils';

@Component({
  selector: 'app-grafici-pulse-oximeter',
  templateUrl: './grafici-pulse-oximeter.component.html',
  styleUrls: ['./grafici-pulse-oximeter.component.scss']
})

export class GraficiPulseOximeterComponent implements OnInit {

  @Input('date') set date(date: DateFromTo) {
    this.dateRange = date;
    // this.getDateForPulseOximeter();
    // this.reset();
    // this.finalInit();
    // if (this.chart3) {
    //   this.chart3.updateOptions(this.chartOptions1, false, false, false);
    // }
    // FUNZIONE AVG
    //this.resetAvg();
  }
  @Input() annotations: ApexAnnotations;

  @ViewChild('chart3', { static: true }) chartRef1: ElementRef;
  chart3: ApexCharts;


  stroke: ApexStroke = {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    width: 3,
    dashArray: 0,
  };

  COLOR_HR = '#f70d1a';
  COLOR_O2 = '#008FFB';

  bpChartArray: SerieType =
    {
      name: 'Pulsossimetro',
      color: [this.COLOR_O2, this.COLOR_HR],
      serie: [],
      display: ['O2', 'HR'],
      type: ['area', 'area'],
      maxyaxis: 250,
      stroke: this.stroke,
      markers: false,
      fill: true,
    };
  chartOptions1: any;
  chartOptions2;
  // FUNZIONE AVG
  //labelPO;
  //avgHr = 0;
  //avgO2 = 0;
  chartArr = new Array<SerieType>();

  // Variabili introdotte per inglobare questo componente nel PDF del self-report
  @Input() identifier;
  @Input() showHeader: boolean = true;
  @Input() altezzaGrafico;
  @Input() larghezzaGrafico;
  @Output() stats = new EventEmitter<any>();
  @Input() onlyLast: boolean = false;
  // se true prende i dati dagli eventi del piano di monitoraggio
  @Input() onlyMonitoringEvents: boolean = false;
  // Metterò le informazioni dell'evento salvato con il timestamp del singolo valore
  allEventsHr = [];
  allEventsO2 = [];

  pulseoximeterOptions = [
    { label: "Frequenza cardiaca", value: "hr" } as SelectItem,
    { label: "Ossigenazione", value: "o2" } as SelectItem
  ];
  selectedOption = undefined;
  timestampHr = [];
  timestampO2 = [];

  constructor(private http: HttpClient, private toastr: ToastrService, private patientService: PazientiService, private cdr: ChangeDetectorRef) {
    registerLocaleData(localeIt)
  }

  timestampList = [];
  annotationPointer = [0];
  labelPulseOximeter = [];
  data = [];
  dataO2 = [];
  dataHr = [];
  timestamp = [];
  minuteCounter = 0;
  opt = [];
  options;
  selected;
  dateRange;
  datePipe = new DatePipe('en-US');
  go: boolean = false;

  yAxisHR = {
    max: 250,
    min: 0,
    enabled: true,
    seriesName: 'HR',
    title: {
      text: 'HR',
      style: { color: this.COLOR_HR }
    },
    labels: {
      style: {
        colors: this.COLOR_HR
      }
    },
    tooltip: {
      show: false
    }
  };

  yAxisO2 = {
    max: 100,
    min: 80,
    opposite: true,
    seriesName: 'O2',
    labels: {
      style: {
        colors: this.COLOR_O2
      }
    },
    title: {
      text: 'O2',
      style: { color: this.COLOR_O2 }
    },
    tooltip: {
      show: false
    }
  };

  ngOnInit() {
    if (this.identifier == undefined) {
      this.identifier = this.patientService.getPatientId();
    }
    if (this.showHeader === undefined) {
      this.showHeader = true;
    }

    this.selectedOption = this.pulseoximeterOptions[0].value;

    this.getData();
  }

  refreshChart() {
    if (this.chart3 != undefined) {
      this.chart3.updateOptions(this.chartOptions1, false, false, false);
    } else {
      this.chart3 = new ApexCharts(this.chartRef1.nativeElement, this.chartOptions1);
      this.chart3.render();
    }
  }

  loadData() {
    this.minuteCounter = 0;
    this.http.post(endpoints.getListPulseOximeter, this.timestampList).toPromise().then((response: any) => {
      for (let data of response) {
        this.data = JSON.parse(data.value);
        if (data.type_measure === "5") {
          for (let value of this.data) {
            this.dataO2.push(value.value);
            this.timestamp.push(value.timestamp);
          }
        } else {
          for (let value of this.data) {
            this.dataHr.push(value.value);
          }
        }
      }
      this.timestamp.sort();

      if (this.onlyLast) {
        // Filtro i dati per visualizzare solo le ultime misurazioni
        let indice;
        for (indice = this.timestamp.length - 1; indice > 1; indice--) {
          if (this.timestamp[indice] - this.timestamp[indice - 1] > 10000) {
            break;
          }
        }
        this.timestamp = this.timestamp.slice(indice);
        this.dataHr = this.dataHr.slice(indice);
        this.dataO2 = this.dataO2.slice(indice);
      }

      this.finalInit();

      this.refreshChart();

      this.calculateStatsAndEmit();

    }).catch((err) => {
      console.error("Errore valori pulsossimetro ", err);
      this.toastr.error("Errore nel recupero valori pulsossimetro ");
    });
  }

  getDateForPulseOximeter() {
    if (!this.go) {
      return;
    }
    this.timestampList = [];
    this.opt = [];
    this.opt.push({ value: '', label: 'Seleziona una data', disabled: true });
    let from = new Date(this.dateRange.dateFrom).getTime();
    let to = new Date(this.dateRange.dateTo).getTime()
    this.http.get(endpoints.getDatePulseOximeter + this.identifier + '/' + from + '/' + to).toPromise().then((response: Array<any>) => {
      response.forEach(timestamp => {
        this.timestampList.push(new Date(timestamp).getTime())
        this.opt.push({ label: this.datePipe.transform(timestamp.toString(), 'dd/MM/YYYY [HH:mm:ss]'), value: new Date(timestamp).getTime() });
      });
      this.minuteCounter = 0;
      if (this.opt.length > 1) {
        this.opt = this.sortByDate(this.opt);
      }
      this.loadData();
    }).catch((err) => {
      if (err.status == 404) {
        return;
      }
      console.error("errore pulsossimetro ", err);
      this.toastr.error("Errore nel recupero date Pulsossimetro")
    });

  }

  sortByDate(array) {
    return array.sort((a, b) => <any>new Date(b.value) - <any>new Date(a.value));
  }

  getDataO2() {
    let arr = [];
    this.dataO2.forEach((data, index) => {
      arr.push([index, data]);
    });
    return arr;
  }

  getDataHr() {
    let arr = [];
    this.dataHr.forEach((data, index) => {
      arr.push([index, data]);
    });

    return arr;
  }

  reset() {
    this.data = [];
    this.dataO2 = [];
    this.dataHr = [];
    this.timestamp = [];
    this.labelPulseOximeter = [];
  }

  finalInit() {
    let serieToShow = [];
    let colorsToUse = [];
    let yAxisToUse = [];
    let customTooltip: any;
    if (this.onlyMonitoringEvents) {
      if (this.selectedOption == undefined) {
        this.selectedOption = this.pulseoximeterOptions[0].value;
      }
      switch (this.selectedOption) {
        case "hr":
          serieToShow = [
            {
              name: "HR",
              data: this.getDataHr()
            }
          ];
          this.timestamp = this.timestampHr;
          colorsToUse = ["#f70d1a"];
          yAxisToUse.push(this.yAxisHR);
          customTooltip = ({ series, seriesIndex, dataPointIndex, w }) => {
            let value = series[seriesIndex][dataPointIndex];
            let tf = this.timestamp[dataPointIndex];
            let f = this.allEventsHr.find(aehr => aehr.timestamp == tf);
            if (f == undefined) {
              return '<h5>Valore:</h5><h6>' + value + '</h6>'
            }
            return getTooltipByEventInformation(f.info, value);
          };
          break;
        case "o2":
          serieToShow = [
            {
              name: "O2",
              data: this.getDataO2(),
            }
          ];
          this.timestamp = this.timestampO2;
          colorsToUse = ["#008FFB"];
          this.yAxisO2.opposite = false;
          yAxisToUse.push(this.yAxisO2);
          customTooltip = ({ series, seriesIndex, dataPointIndex, w }) => {
            let value = series[seriesIndex][dataPointIndex];
            let tf = this.timestamp[dataPointIndex];
            let f = this.allEventsHr.find(aehr => aehr.timestamp == tf);
            if (f == undefined) {
              return '<h5>Valore:</h5><h6>' + value + '</h6>'
            }
            return getTooltipByEventInformation(f.info, value);
          };
          break;
      }
    } else {
      serieToShow = [
        {
          name: "HR",
          data: this.getDataHr()
        },
        {
          name: "O2",
          data: this.getDataO2(),
        }
      ];
      colorsToUse = ["#f70d1a", "#008FFB"];
      this.yAxisO2.opposite = true;
      yAxisToUse = [this.yAxisHR, this.yAxisO2];
    }

    this.chartOptions1 = {
      series: serieToShow,
      chart: {
        type: "area",
        height: 230,
        toolbar: {
          autoSelected: "pan",
          show: false
        },
        zoom: {
          enabled: false,
          autoScaleYaxis: false
        }
      },
      tooltip: {
        x: {
          show: !this.onlyMonitoringEvents,
          format: "dd/MM/yyyy [HH:mm:ss]",
          formatter: (value) => {
            return this.datePipe.transform(this.timestamp[value], 'dd/MM/YYYY [HH:mm:ss]');
          }
        },
        // y: {
        //   formatter: (value) => { return value; }
        // }
        // fixed: {
        //   enabled: true,
        //   position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        //   offsetY: 30,
        //   offsetX: 60
        // },
      },
      colors: colorsToUse,
      stroke: {
        width: 3
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: 1
      },
      markers: {
        size: 0
      },
      xaxis: {
        type: "category",
        labels: {
          show: false,
        },
        tickPlacement: 'on',
      },
      yaxis: yAxisToUse,
    };

    if (this.onlyMonitoringEvents) {
      this.chartOptions1.tooltip.custom = customTooltip;
    } else {
      this.chartOptions1.tooltip.custom = undefined;
    }

    this.chartOptions1.tooltip.shared = true;
    this.chartOptions1.tooltip.intersect = false;
    this.chartOptions1.tooltip.followCursor = true;
    if (this.altezzaGrafico !== undefined) {
      this.chartOptions1.chart.height = this.altezzaGrafico;
    }
    if (this.larghezzaGrafico !== undefined) {
      this.chartOptions1.chart.width = this.larghezzaGrafico;
    }

    this.chartOptions1.tooltip.fixed = {
      enabled: !this.onlyMonitoringEvents,
      position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
      offsetY: 30,
      offsetX: 60
    };

    this.refreshChart();
  }

  setMax() {
    return this.timestamp[this.timestamp.length - 1];
  }

  calculateStatsAndEmit() {
    let sommaHR = 0;
    let sommaO2 = 0;

    // Devo ricalcolare min, max e avg
    let indice = 0;
    sommaHR = 0;
    sommaO2 = 0;
    for (indice = 0; indice < this.dataHr.length; indice++) {
      sommaHR += this.dataHr[indice];
      sommaO2 += this.dataO2[indice];
    }

    // Restituisco le stats utilizzabili nel report pdf
    let toEmit = {
      hr: {
        min: 0,
        avg: 0,
        max: 0,
      }, o2: {
        min: 0,
        avg: 0,
        max: 0,
      }
    };
    if (this.dataHr.length > 0) {
      toEmit.hr = {
        min: Math.min.apply(null, this.dataHr),
        avg: Math.round(sommaHR / this.dataHr.length),
        max: Math.max.apply(null, this.dataHr),
      }
    }
    if (this.dataO2.length > 0) {
      toEmit.o2 = {
        min: Math.min.apply(null, this.dataO2),
        avg: Math.round(sommaO2 / this.dataO2.length),
        max: Math.max.apply(null, this.dataO2),
      }
    }

    this.stats.emit(toEmit);
  }

  getDataFromMonitoringEvents() {

    this.allEventsHr = [];
    this.allEventsO2 = [];
    this.dataHr = [];
    this.dataO2 = [];

    let from = new Date(this.dateRange.dateFrom).getTime();
    let to = new Date(this.dateRange.dateTo).getTime()
    this.http.get(endpoints.getMeasuresWithEventByPatientIdAndSourceAndTimestampRange + this.identifier + "/" + "FS20F" + "/" + from + "/" + to)
      .toPromise().then(resp => {
        // Ordino in base al timestamp dell'evento
        (resp as []).sort((a, b) => {
          let ta = new Date((a as any).timestampOperation).getTime();
          let tb = new Date((b as any).timestampOperation).getTime();
          return ta - tb;
        });

        (resp as []).forEach((r: any) => {
          let typeMeasure = r.answareId;
          let value = JSON.parse(r.value);
          let informazioniEvento = {
            timestampOperation: r.timestampOperation,
            timestampCompletition: r.timestamp_completition,
            source: r.source
          }
          switch (typeMeasure) {
            case "1":
              // Frequenza cardiaca
              this.dataHr = this.dataHr.concat(value.map(v => {
                this.timestampHr.push(v.timestamp);
                this.allEventsHr.push({ timestamp: v.timestamp, info: informazioniEvento });
                return v.value;
              }));
              break;
            case "5":
              this.dataO2 = this.dataO2.concat(value.map(v => {
                this.timestampO2.push(v.timestamp);
                this.allEventsO2.push({ timestamp: v.timestamp, info: informazioniEvento });
                return v.value;
              }));
              break;
          }
        });
        // Creo il grafico
        this.finalInit();
      }).catch(error => {
        console.error(error);
        this.toastr.error("Errore nel recupero dei dati del pulsossimetro.")
      });
  }

  dropdownChange(event) {
    this.finalInit();
  }

  getData() {
    delay(500).then(() => {
      this.go = true;
      if (this.onlyMonitoringEvents) {
        this.getDataFromMonitoringEvents();
      } else {
        this.getDateForPulseOximeter();
      }
    });
  }

  ngOnChanges() {
    this.getData();
  }

}


export type ChartOptions = {
  options: ApexOptions;
  series: ApexAxisChartSeries;
  seriesType: SerieType[];
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
};

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}