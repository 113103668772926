<div class="row p-fluid" *ngIf="isReport === false">
    <div class="col-md-4" *ngIf="showChart('dolore')">
        <div class="small-box"  [ngClass]="{'bg-gradient-dolore': doloreSeries}">
            <!-- <div *ngIf="!doloreSeries && !faticaSeries && !nauseaSeries" class="p-fluid text-center">
                <p-progressSpinner></p-progressSpinner>
            </div> -->
            <!-- <apx-chart *ngIf="doloreSeries && faticaSeries && nauseaSeries" #doloreChartRef [chart]="doloreChart" [series]="doloreSeries"
                [xaxis]="xaxis" [yaxis]="yaxis" [tooltip]="tooltipDolore" [stroke]="stroke" [fill]="fill"
                [labels]="labelsDolore" [colors]="['#D4526E']" [title]="titleDolore"
                [subtitle]="subtitleDolore"></apx-chart> -->
            <div #doloreChartRef></div>
        </div>
    </div>
    <div class="col-md-4" *ngIf="showChart('fatica')">
        <div class="small-box"  [ngClass]="{'bg-gradient-fatica': faticaSeries}">
            <!-- <div *ngIf="!doloreSeries && !faticaSeries && !nauseaSeries" class="p-fluid text-center">
                <p-progressSpinner></p-progressSpinner>
            </div> -->
            <!-- <apx-chart *ngIf="doloreSeries && faticaSeries && nauseaSeries" #faticaChartRef
                [chart]="faticaChart" [series]="faticaSeries" [xaxis]="xaxis" [yaxis]="yaxis"
                [tooltip]="tooltipFatica" [stroke]="stroke" [fill]="fill" [labels]="labelsFatica"
                [colors]="['#546E7A']" [title]="titleFatica" [subtitle]="subtitleFatica"></apx-chart> -->
            <div #faticaChartRef></div>
        </div>
    </div>
    <div class="col-md-4" *ngIf="showChart('nausea')">
        <div class="small-box"  [ngClass]="{'bg-gradient-nausea': nauseaSeries}">
            <!-- <div *ngIf="!doloreSeries && !faticaSeries && !nauseaSeries" class="p-fluid text-center">
                <p-progressSpinner></p-progressSpinner>
            </div> -->
            <!-- <apx-chart *ngIf="doloreSeries && faticaSeries && nauseaSeries" #nauseaChartRef
                [chart]="nauseaChart" [series]="nauseaSeries" [xaxis]="xaxis" [yaxis]="yaxis"
                [tooltip]="tooltipNausea" [stroke]="stroke" [fill]="fill" [labels]="labelsNausea"
                [colors]="['#A5978B']" [title]="titleNausea" [subtitle]="subtitleNausea"></apx-chart> -->
            <div #nauseaChartRef></div>
        </div>
    </div>
    <div class="col-md-4" *ngIf="showChart('funzionalita')">
        <div class="small-box"  [ngClass]="{'bg-gradient-nausea': funzionalitaSeries}">
            <!-- <div *ngIf="!doloreSeries && !faticaSeries && !nauseaSeries" class="p-fluid text-center">
                <p-progressSpinner></p-progressSpinner>
            </div> -->
            <!-- <apx-chart *ngIf="doloreSeries && faticaSeries && nauseaSeries" #nauseaChartRef
                [chart]="nauseaChart" [series]="nauseaSeries" [xaxis]="xaxis" [yaxis]="yaxis"
                [tooltip]="tooltipNausea" [stroke]="stroke" [fill]="fill" [labels]="labelsNausea"
                [colors]="['#A5978B']" [title]="titleNausea" [subtitle]="subtitleNausea"></apx-chart> -->
            <div #funzionalitaChartRef></div>
        </div>
    </div>
</div>

<ng-container *ngIf="isReport === true">
    <div class="row">
        <div class="col-md-12" *ngIf="showChart('dolore')">
            <div #doloreChartRef></div>
        </div>
        <div class="col-md-12" *ngIf="showChart('fatica')">
            <div #faticaChartRef></div>
        </div>
        <div class="col-md-12" *ngIf="showChart('nausea')">
            <div #nauseaChartRef></div>
        </div>
        <div class="col-md-12" *ngIf="showChart('funzionalita')" [ngClass]="{'bg-gradient-nausea': funzionalitaSeries}">
            <div #funzionalitaChartRef></div>
        </div>
    </div>
</ng-container>
