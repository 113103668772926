import { FormGroup, FormControl } from '@angular/forms';

export function markAllAsUntouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.get(key);
        if (control instanceof FormControl) {
            control.markAsUntouched();
        }
    });
}

export function markAllAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.get(key);
        if (control instanceof FormControl) {
            control.markAsTouched();
        }
    });
}

export function markAllAsValid(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.get(key);
        if (control instanceof FormControl) {
            control.setErrors(null);

        }
    });
}

export function updateValueAndValidity(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.get(key);
        if (control instanceof FormControl) {
            control.updateValueAndValidity();
        }
    });
}

export function generateRandom(num: number): any[] {
    const random = [];
    for (let i = 0; i < num; i++) {
        random.push('_' + Math.random().toString(36).substr(2, 9));
    }
    return random;
}

export const capitalize = (s) => {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export function getLabelFromOptions(value: string, options): string {
    return options.forEach(el => {
        if (el.value === value) {
            return el.label;
        }
    });
}

export function isNullOrEmpty(str: string): boolean {
    return (str === undefined || str === '' || str === null);
}

export function getFirstProperty(obj: any) {
    let restituito = false;
    for (const prop in obj) {
        if (!restituito) {
            restituito = true;
            return obj[prop];
        }
    }
}

export function eliminaTokenAndRedirectLogin(tokenService, userService, patientService, router) {
    tokenService.deleteToken();
    userService.deleteUser();
    patientService.resetPatient();
    router.navigate(['/login']);
}

export function getAge(patient) {
    const nascitaPaziente = new Date(patient.birthDate);
    const today = new Date();
    let diff = Math.abs(today.getTime() - nascitaPaziente.getTime());
    return Math.floor((diff / (1000 * 3600 * 24)) / 365.25);
}

export function getDayName(dayNumber) {
    let dayNames = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];
    return dayNames[dayNumber];
}

export function getDayNumberByShortName(name) {
    let dayShortNames = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
    return dayShortNames.indexOf(name);
}

export function convertValue1ToValue2(values1, values2): [] {
    return values1.map(t => {
        return values2.find(ta => ta.value == t.trim())?.label ?? "";
    });
}

export function addPaddingToNumber(value: number): string {
    if (isNaN(value)) {
        return "" + value;
    }
    if (value < 10) {
        return "0" + value;
    }
    return "" + value;
}

// Restituisce il nome del mese in forma estesa
// Gennaio = 0
export function getMonthNameByMonthNumber(monthNumber) {
    let nomiMesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
    if (monthNumber < 0 || monthNumber > 11) {
        return undefined;
    }
    return nomiMesi[monthNumber];
}

// Metodi che sevono sia al monitoraggio che al diario del clinico

export function getPossibleKeysForPatientDigitalCards() {
    return ["SYMPTOM_TYPE", "VITAL_SIGN_MEASUREMENT", "ACTIVITY_INTENSITY"];
}

export function getPossibleKeysForValueForPatientDigitalCards() {
    return ["SYMPTOM_INTENSITY", "VITAL_SIGN_VALUE", "ACTIVITY_DURATION"];
}

export function getValueByObjectKey(key, values) {
    let toReturn = undefined;
    values.forEach(element => {
        if (toReturn != undefined) {
            return;
        }
        let k = Object.keys(element)[0];
        if (k == key) {
            toReturn = element[k];
        }
    });
    return toReturn;
}

export function getCardValueByCardValue(cardValue, possibleKeysForValue) {
    // Cerco La chiave con il bruteforce
    let keyList = Object.values(cardValue).map(v => Object.keys(v)).map(v => v[0]);

    let found = undefined;
    keyList.forEach(klv => {
        if (found == undefined && possibleKeysForValue.includes(klv)) {
            found = klv;
        }
    });

    if (found == undefined) {
        return undefined;
    }

    return getValueByObjectKey(found, cardValue);
}

export function getSerieNameByCardValue(cardValue, possibleKeys) {
    let key = findKey(cardValue, possibleKeys);
    return getValueByObjectKey(key, cardValue);
}

export function findKey(cardValue, possibleKeys) {
    let key = undefined;
    cardValue.forEach(element => {
        Object.keys(element).forEach(campo => {
            if (key == undefined) {
                if (possibleKeys.includes(campo)) {
                    key = campo;
                    return; // Dovrebbe terminare il foreach
                }
            }
        });
    });
    return key;
}

function extractDataPianificatoOrarioPianificatoDataCompletatoOrarioCompletato(informazioniEvento) {
    let d = new Date(informazioniEvento.timestampOperation);
    let giorno = "" + d.getDate();
    giorno = addPaddingToNumber(Number(giorno));
    let mese = d.getMonth();
    mese++;
    mese = Number(addPaddingToNumber(mese));
    let anno = d.getFullYear();
    let dataPianificato = ([giorno, mese, anno].join("/"));
    let ora = addPaddingToNumber(d.getHours());
    let minuti = addPaddingToNumber(d.getMinutes());
    let orarioPianificato = "" + ora + ":" + minuti;

    let timestampCompletition = informazioniEvento.timestampCompletition;
    if (timestampCompletition == undefined) {
        timestampCompletition = informazioniEvento.timestamp_completition;
    }
    d = new Date(timestampCompletition);
    giorno = "" + d.getDate();
    giorno = addPaddingToNumber(Number(giorno));
    mese = d.getMonth();
    mese++;
    mese = Number(addPaddingToNumber(mese));
    anno = d.getFullYear();
    ora = addPaddingToNumber(d.getHours());
    minuti = addPaddingToNumber(d.getMinutes());
    let orarioCompletato = "" + ora + ":" + minuti;

    let dataCompletato = ([giorno, mese, anno].join("/"));

    let source = "";
    if (informazioniEvento.source.split("_")[1] != undefined) {
        source = informazioniEvento.source.split("_")[1];
    }
    return [dataPianificato, orarioPianificato, dataCompletato, orarioCompletato, source];
}

export function getTooltipByEventInformation(informazioniEvento, valore) {

    let results = extractDataPianificatoOrarioPianificatoDataCompletatoOrarioCompletato(informazioniEvento);
    let template = `<span style="padding: 0.5rem"><h6>Evento pianificato il ${results[0]} alle ${results[1]}</h6><h6>Completato il giorno ${results[2]} alle ${results[3]}</h6><h6>Valore registrato: ${valore}</h6>`;

    if (results[4] != "") {
        template += "<h6>Dispositivo: " + results[4] + "</h6>";
    }

    template += "</span>";
    return template;
}

export function getTooltipByEventInformationAndValues(informazioniEvento, valori: any[], separatore = "/") {
    let results = extractDataPianificatoOrarioPianificatoDataCompletatoOrarioCompletato(informazioniEvento);

    let template = `<span style="padding: 0.5rem"><h6>Evento pianificato il ${results[0]} alle ${results[1]}</h6><h6>Completato il giorno ${results[2]} alle ${results[3]}</h6><h6>Valore registrato: ${valori[0]}${separatore}${valori[1]}</h6></span>`;

    if (results[4] != "") {
        template += "<h6>Dispositivo: " + results[4] + "</h6>";
    }

    template += "</span>";

    return template;
}

export function getMonthIndexByMonthName(monthName) {
    let nomiMesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
    let f = nomiMesi.findIndex(f => f.substring(0, 3) == monthName.substr(0, 3));
    return f;
}

export async function getIconFromFile(event, fileInput, toastrService) {
    return new Promise(async (resolve) => {
        if (await isImage(event)) {
            resolve(await setIconFromFile(event.target));
        } else {
            toastrService.error("Il file non è un'immagine.");
            fileInput.nativeElement.value = null;
            resolve(imgDefault);
        }
    });
}
export function setIconFromFile(input: HTMLInputElement) {
    return new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = () => {
            resolve(imgDefault);
        }
        fileReader.readAsDataURL(input.files[0]);
    });
}
export function isImage(event) {
    return new Promise((resolve) => {
        const file = event.target.files[0];
        const fileReader = new FileReader();
        let mimeType;
        fileReader.onloadend = () => {
            const arr = new Uint8Array(fileReader.result as ArrayBuffer).subarray(0, 4);
            const header = Array.from(arr, element => element.toString(16)).join('');
            mimeType = getMimeType(header);
            resolve(mimeType.startsWith("image/"));
        }
        fileReader.onerror = () => {
            resolve(false);
        }
        fileReader.readAsArrayBuffer(file);
    })
}
const imgDefault = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+FJREFUeNrsnX1PGkEQxlcoBALBYM9iSUlNbfmc/a42CHraSiQQCGn7kGxCDPJy7szN3j1PYvhDvLnd3+3e7szseHY3/vPTUWZ09h/IX3aDHVXYBQRCEQiBUARCIBSBEAhFIARCEQhFIARCZdGHWG/8ZfbiZrPZzt+1Wi3XbrUJRANCmqbuefp81PfPO+cuSZKo4EQBZL6Yu9FotAFyigAOPwDS7/dds9E031bzAarJ/cSNJ+Mg17rqXbnepx5f6ll1++s2GAwI18I1CSQjjKffT8Gvi2tahmISCOZ9CRjbUI5dGJQeyHq9VnmCYQO2COSA0sdUpaNgA7YI5EAnPaQPavZgy9ooMQUE87pmB8GWtXeJKSBvuUKKZjMaIKfuxGO1GQ2Q5XJZCpvR7tTLKAIhEIpACCSb6vV6KWxGAySPyJ61aKIpIIiFl8FmNEAQA69Wq2r2YAs2CWRPB10ml2r2YEvzAYhylZV8TFQ6CTZgi6usIzpq8GUgbgc2rI0Os/sQzOsX3Qux6+Pa1t4d5jeGeIIloOCaGiMwq0wnyqHjsHELlQrU/9xXXTQUDgiExLZOp5Mpc3F788fMRQExt9eY0LG+c5n9bhhOkUT3O4FQBEIgFIEQCEUgBEJxY7hD2JXP53O3Wq02n8hax8ncfYLfCvGOZrPparXa5jOGjaRJIPBVwS2Czs/qUPTAXv89QLXb7Y17xWJMxIRz0Z/TmE6n6uc1AAXeZO0EC5NATvXeasDpdru5jpxcpiycgkVBAGtHAXzlBwTFEIeRDCObGCFWQbylPMCoAMlaq8SKNCOO4kAwKkZ3I5Nnwk8RXviIyUuPFtF3iFR5jLxWgmgPluOSWSsVwjh9xEtWmhABggP5RYSxDUWqwEFwIFhBYSVVdEmtFoMD0apVYuGdIlErJTgQq2WPpDaSUUxZZVEUUxZlDAgKTZZFEm0NDgS+nyJmFL4W2ihR4VRkyrr+em3u/HdIwaeFNkazMYTfBzds8chYiLYNBnLH4SqST9HNt5tCQUFb0CZJr29FemgXBYoGDJVlr4cS8zsF964BQ20fgoYMvw+jOFKW971XNIf88MfQ/KHLbeFecc+aU656kgOibki7QUzBqpsFUxSCUHnsp3JLA/Le0pD//SDU7lurvIcpIF4+fpJ3QAuxcuy88158mDkW7cFoVrf25ZksgDAHZHsqAxRkNB5KqH7Pygnn162kj5oGsgsOMj2Q05V1EYCnHy9on2BteaMaVSUHCGAAarFY7P1eo9HYdHxsnufoCgf4DrZaXimajSFFIARCEQiBUARCIBSBUARCIBSBEAj1Tv0TYAC7cNXTT7whBQAAAABJRU5ErkJggg==";

const jpeg = 'image/jpeg'
const mimeTypes = {
    '89504e47': 'image/png',
    '47494638': 'image/gif',
    'ffd8ffe0': jpeg,
    'ffd8ffe1': jpeg,
    'ffd8ffe2': jpeg,
    'ffd8ffe3': jpeg,
    'ffd8ffe8': jpeg,
};
function getMimeType(header: string): string {
    return mimeTypes[header] || '';
}

export function rimuoviDuplicatiDaArray(array) {
    return array.filter((el, i, a) => i === a.indexOf(el))
}
