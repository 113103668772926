<div class="card">
    <div class="card-header border-0">
        <div class="p-fluid">
            <div class="row">
                <div class="col-6">
                    <h3 class="card-title">Fonte: "Mobile App + Parametri Vitali"</h3>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="offset-2 col-5">
                            <p-dropdown [options]="optMobileapp" [(ngModel)]="selectedMobileapp"
                                dropdownIcon="fas fa-chevron-down" (ngModelChange)="dropdownChange($event, 1)"
                                [autoDisplayFirst]="false" placeholder="Seleziona una voce"></p-dropdown>
                        </div>
                        <div class="col-5">
                            <p-dropdown [options]="optSmartwatch" [(ngModel)]="selectedSmartwatch"
                                dropdownIcon="fas fa-chevron-down"
                                (ngModelChange)="dropdownChange($event, 2)"></p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div #chart></div>
    </div>
</div>