import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PazientiService } from '../../service/pazienti.service';

@Component({
  selector: 'app-condizioni-cliniche',
  templateUrl: './condizioni-cliniche.component.html',
  styleUrls: ['./condizioni-cliniche.component.scss']
})
export class CondizioniClinicheComponent implements OnInit, OnDestroy {

  @Input() set id(id) {
    this.id$ = id;
    if (this.id$) {
      this.getCondizioniCliniche();
    }
  }

  condizioniCliniche = [];
  aggiorna;
  id$;

  constructor(private http: HttpClient, private toastr: ToastrService, private pazientiService: PazientiService) { }

  ngOnInit() {
    if (this.id$) {
      this.getCondizioniCliniche();
    }
    this.aggiorna = this.pazientiService.getAggiornaCondCli().subscribe(input => {
      if (input) {
        this.getCondizioniCliniche();
      }
    });
  }

  ngOnDestroy(): void {
    this.aggiorna.unsubscribe();
  }

  getCondizioniCliniche() {
    this.pazientiService.getCondizioniCliniche(this.id$).then((resp: any) => {
      this.condizioniCliniche = resp;
      this.condizioniCliniche = this.condizioniCliniche.filter(elem => {
        if (elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaRemota' ||
          elem.code.coding[0].code === 'DescrizioneAnamnesiPatologicaProssima') {
          return false;
        } else {
          return true;
        }
      });
    }).catch(err => {
      console.error(err);
      this.toastr.error('Impossibile caricare le condizioni cliniche.');
    });
  }

}
