import { Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/service/user-service.service';
import { PazientiService } from '../service/pazienti.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';

export class BaseVisiteComponent {

    patientId;
    clinicalImpressionId;
    public pazientiService: PazientiService;
    public toastr: ToastrService;
    public route: ActivatedRoute;
    public userService: UserService;
    public http: HttpClient;
    public fb: FormBuilder;

    constructor(injector: Injector) {

        this.pazientiService = injector.get(PazientiService);
        this.toastr = injector.get(ToastrService);
        this.route = injector.get(ActivatedRoute);
        this.userService = injector.get(UserService);
        this.http = injector.get(HttpClient);
        this.fb = injector.get(FormBuilder);

        this.patientId = this.pazientiService.getPatientId();
        const cId = this.route.parent.snapshot.paramMap.get('vId');
        if (cId) {
            this.clinicalImpressionId = cId;
        }
    }

    printErrorCaricamento(err, tab?) {
        console.error(err);
        this.toastr.error('Errore nel caricamento dei Dati. ' + tab);
    }

    printErrorSalvataggio(err, tab?) {
        console.error(err);
        this.toastr.error('Errore nel salvataggio dei Dati. ' + tab);
    }

    checkPermessoModifica(): boolean {
        return this.userService.checkPermission('Visite', 'modify');
    }

}
