<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4">
  <a [routerLink]="['/']" class="brand-link logo-switch" *ngIf="org != 'NEMO'">
    <img src="assets/img/logo_riatlas_smallb.png" alt="Riatlas" class="brand-image-xl logo-xs">
    <img src="assets/img/logoriatlasb.png" alt="Riatlas" class="brand-image-xs logo-xl"
      style="margin-top: 0.3em;margin-left:0.4em">
  </a>
  <a [routerLink]="['/']" class="" *ngIf="org == 'NEMO'">
    <img src="assets/img/provaLogo.png" alt="Riatlas" class="brand-image-xs logo-xs" style="margin-left: 0.1rem; min-width: 100%;">
    <img src="assets/img/provaLogo.png" alt="Riatlas" class="brand-image-xl logo-xl">
  </a>

  <!-- Sidebar -->
  <div class="sidebar" #sidebar>
    <div class="mt-2">
      <p-panelMenu [model]="items" [multiple]="false" ></p-panelMenu>
    </div>
    
    <!-- Sidebar user (optional) -->
    <!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img [src]="appService.user.image" class="img-circle elevation-2" alt="User Image" />
      </div>
      <div class="info">
        <a [routerLink]="['/profile']" class="d-block">
          {{ appService.user.firstName + ' ' + appService.user.lastName }}
        </a>
      </div>
    </div> -->

    <!-- Sidebar Menu -->
    <nav>
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
        <!-- <li class="nav-item has-treeview">
          <a href="#" class="nav-link">
            <i class=" nav-icon fas fa-tachometer-alt"></i>
            <p>
              Dashboard
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a href="../../index.html" class="nav-link">
                <i class="fa fa-circle-o nav-icon"></i>
                <p>Dashboard v1</p>
              </a>
            </li>
          </ul>
        </li> -->
        <!-- <li class="nav-item">
          <a [routerLink]="['/login']" routerLinkActive="active"  class="nav-link">
            <i class="fa fa-circle nav-icon"></i>
            <p>Login</p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/blank']" routerLinkActive="active"  class="nav-link">
            <i class="fa fa-circle nav-icon"></i>
            <p>Blank</p>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/profile']" routerLinkActive="active"  class="nav-link">
            <i class="fa fa-circle nav-icon"></i>
            <p>Profile</p>
          </a>
        </li> -->

        <!-- <li class="nav-item">
          <a class="nav-link" style="cursor: pointer;" (click)="dashboardHandler($event, dashboard)" #dashboard>
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>Dashboard</p>
          </a>
        </li> -->

        <!-- <li class="nav-item has-treeview" #menuPazienti>
          <a class="nav-link" style="cursor: pointer; color: #c2c7d0;">
            <i class="nav-icon far fa-edit"></i>
            <p>
              Pazienti
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="checkPermessoNuovoPaziente()">
              <a [routerLink]="['/main/pazienti/schedapaziente']" routerLinkActive="active" class="nav-link" >
                <i class="fas fa-user-plus nav-icon"></i>
                <p>Nuovo Paziente</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/main/pazienti/listapazienti']" routerLinkActive="active" class="nav-link" (click)="resetPatient()">
                <i class="fas fa-users nav-icon"></i>
                <p>Lista Pazienti</p>
              </a>
            </li>
          </ul>
        </li> -->
        <!-- <li class="nav-item" *ngIf="checkPermessoNuovoPaziente()">
          <a class="nav-link" style="cursor: pointer;" (click)="nuovoPazienteHandler($event, nuovopaziente)"
            #nuovopaziente>
            <i class="fas fa-user-plus nav-icon"></i>
            <p>Nuovo Paziente</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" style="cursor: pointer;" (click)="listaPazientiHandler($event, listapazienti)"
            #listapazienti>
            <i class="fas fa-users nav-icon"></i>
            <p>Lista Pazienti</p>
          </a>
        </li> -->
        <!-- <li class="nav-item" *ngIf="this.org === 'NEMO'">
          <a class="nav-link" style="cursor: pointer;" (click)="listaPazientiAttivazioneHandler($event, listapazientiattivazione)"
            #listapazientiattivazione>
            <i class="fas fa-users nav-icon"></i>
            <p>Lista Pazienti in Attivazione</p>
          </a>
        </li> -->
        <!-- <li class="nav-item has-treeview" #menuDettagli data-widget="treeview"
          *ngIf="checkPermessoLetturaDettagli() && patient">
          <a class="nav-link" href="#">
            <i class="nav-icon fas fa-notes-medical"></i>
            <p>
              Dettagli
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" #menuDettagliChildren>
            <li class="nav-item">
              <a (click)="stileDiVitaHandler($event, stiledivita)" class="nav-link" style="cursor: pointer;"
                #stiledivita>
                <i class="far fa-id-badge nav-icon"></i>
                <p>Stile di vita</p>
              </a>
            </li>
            <li class="nav-item">
              <a (click)="terapieHandler($event, terapiefarm)" class="nav-link" style="cursor: pointer;" #terapiefarm>
                <i class="fas fa-pills nav-icon"></i>
                <p>Terapie Farmacologiche</p>
              </a>
            </li>
            <li class="nav-item">
              <a (click)="devicesHandler($event, devices)" class="nav-link" style="cursor: pointer;" #devices>
                <i class="fas fa-first-aid nav-icon"></i>
                <p>Devices</p>
              </a>
            </li>
            <li class="nav-item">
              <a (click)="statoDiSaluteHandler($event, statodisalute)" class="nav-link" style="cursor: pointer;"
                #statodisalute>
                <i class="fas fa-briefcase-medical nav-icon"></i>
                <p>Stato di salute</p>
              </a>
            </li>
            <li class="nav-item">
              <a (click)="anamnesiPatPossHandler($event, anamnesipatologicaprossima)" class="nav-link"
                style="cursor: pointer;" #anamnesipatologicaprossima>
                <i class="fas fa-file-medical-alt nav-icon"></i>
                <p>Anamnesi Pat. Prossima</p>
              </a>
            </li>
            <li class="nav-item">
              <a (click)="anamnesiPatRemHandler($event, anamnesipatologicaremota)" class="nav-link"
                style="cursor: pointer;" #anamnesipatologicaremota>
                <i class="fas fa-file-medical nav-icon"></i>
                <p>Anamnesi Pat. Remota</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item" *ngIf="checkPermessoLetturaVisite()">
          <a (click)="visiteHandler($event, visite)" class="nav-link" style="cursor: pointer;" #visite>
            <i class="fas fa-user-md nav-icon"></i>
            <p> Visite</p>
          </a>
        </li>
        <li class="nav-item">
          <a (click)="monitoraggioHandler($event, monitoraggio)" class="nav-link" style="cursor: pointer;"
            #monitoraggio>
            <i class="fas fa-chart-line nav-icon"></i>
            <p> Monitoraggio</p>
          </a>
        </li>
        <li class="nav-item">
          <a (click)="monitoraggioIcfHandler($event, monitoraggioIcf)" class="nav-link" style="cursor: pointer;"
            #monitoraggioIcf>
            <i class="fas fa-chart-line nav-icon"></i>
            <p> Monitoraggio Stato di salute</p>
          </a>
        </li>
        <li class="nav-item">
          <a (click)="alertHandler($event, alert)" class="nav-link" style="cursor: pointer;" #alert>
            <i class="fas fa-bell nav-icon"></i>
            <p> Alert</p> <span style="font-size:0.8em" *ngIf="countAlerts && countAlerts!=0"
              class="badge badge-danger right">{{countAlerts}}</span>
          </a>
        </li>
        <li class="nav-item has-treeview " #menuAmministrazione
          *ngIf="isAdmin() || checkPermessoGestioneUtenti()">
          <a class="nav-link" href="#" style="cursor: pointer;">
            <i class="nav-icon fas fa-cogs"></i>
            <p>
              Amministrazione
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="checkPermessoGestioneUtenti()">
              <a style="cursor: pointer;" class="nav-link"
                (click)="amministrazioneUtentiHandler($event, amministrazioneutenti)" #amministrazioneutenti>
                <i class="fas fa-users-cog nav-icon"></i>
                <p>Gestione Utenti</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="isSuperAdmin()">
              <a style="cursor: pointer;" class="nav-link"
                (click)="amministrazioneRuoliHandler($event, amministrazioneruoli)" #amministrazioneruoli>
                <i class="fas fa-user-shield nav-icon"></i>
                <p>Gestione Ruoli</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="isSuperAdmin()">
              <a style="cursor: pointer;" class="nav-link"
                (click)="amministrazioneOrganizationHandler($event, amministrazioneorganizations)"
                #amministrazioneorganizations>
                <i class="fas fa-hospital nav-icon"></i>
                <p>Gestione Organizations</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="isSuperAdmin()">
              <a style="cursor: pointer;" class="nav-link"
                (click)="amministrazioneQuestionariHandler($event, amministrazionequestionari)"
                #amministrazionequestionari>
                <i class="fas fa-question nav-icon"></i>
                <p>Gestione questionari</p>
              </a>
            </li>
          </ul>
        </li> -->
        <li class="nav-item" *ngIf="mobile">
          <a (click)="showPanelHelp($event)" class="nav-link" style="cursor: pointer;">
            <i class="far fa-question-circle nav-icon"></i>
            <p>Aiuto</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="mobile">
          <a (click)="logout()" class="nav-link" style="cursor: pointer;">
            <i class="fas fa-sign-out-alt nav-icon"></i>
            <p *ngIf="!mobile">Esci</p>
            <p *ngIf="mobile">Esci ({{loggedUser.username}})</p>
          </a>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>